import React from "react";
import "./index.css";

const CanadaTeerTable = () => {
  return (
    <div>
      <table className="my-table">
        <thead>
          <tr>
            <th>TEER</th>
            <th>Occupation Types</th>
            <th>Examples</th>
          </tr>
        </thead>
        <tbody>
            
          <tr>
            <td rowSpan="2">TEER 0</td>
            <td rowSpan="2">Management occupations</td>
            <td>Advertising, marketing, and public relations managers </td>
          </tr>
          <tr>
            <td>Financial managers</td>
          </tr>

          <tr>
            <td rowSpan="2">TEER 1</td>
            <td rowSpan="2">
              Occupations that usually require a university degree
            </td>
            <td>Data Financial advisors</td>
          </tr>
          <tr>
            <td>Software engineers</td>
          </tr>

          <tr>
            <td rowSpan="3">TEER 2</td>
            <td>Occupations that usually require</td>
            <td>Computer network and web technicians</td>
          </tr>
          <tr>
            <td>a college diploma</td>
            <td>Medical laboratory technologists</td>
          </tr>
          <tr>
            <td>
              apprenticeship training of 2 or more years or supervisory
              occupations
            </td>
            <td></td>
          </tr>

          <tr>
            <td rowSpan="4">TEER 3</td>
            <td>Occupations that usually require</td>
            <td>Bakers</td>
          </tr>
          <tr>
            <td>a college diploma</td>
            <td>Dental assistants and dental laboratory assistants</td>
          </tr>
          <tr>
            <td>apprenticeship training of less than 2 years, or</td>
            <td></td>
          </tr>

          <tr>
            <td>more than 6 months of on-the-job training</td>
            <td></td>
          </tr>

          <tr>
            <td rowSpan="4">TEER 4</td>
            <td>Occupations that usually require</td>
            <td>Home child care providers</td>
          </tr>
          <tr>
            <td>a high school diploma, </td>
            <td>Retail salespersons and visual merchandisers</td>
          </tr>
          <tr>
            <td>several weeks of on-the-job training</td>
            <td></td>
          </tr>



          {/* <tr>
            <td rowSpan="2">TEER 5</td>
            <td rowSpan="2">
              Occupations that usually need short-term work demonstration and no
              formal education
            </td>
            <td>Landscaping and grounds maintenance labourers</td>
          </tr>
          <tr>
            <td>Delivery service drivers and door-to-door distributors</td>
          </tr> */}

          {/* Add more rows as needed */}
        </tbody>
      </table>
    </div>
  );
};

export default CanadaTeerTable;

import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { createCountryUrl } from "../../Utilities/commonFunctions";
import Header from "../../commonComponents/header";
import Footer from "../../commonComponents/footer";
import AbroadForm from "../../commonComponents/abroadForm";
import CandaPageComponents from "../../canadExpressPnpPage";
import SimpleTable from "../../canadaPage/simpletable";
import LocateNoc from "../../canadaPage/locateNOC";
import ArrageEmployement from "../../canadaPage/arrangeEmplpoyment";
import "./index.css";
import ApplyNow from "../../commonButtonComponent/applyNow";
import { Helmet } from "react-helmet";

const FedralSkilledWorker = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const navigationHeader = [
    {
      title: "Home",
      link: `/`,
    },
    {
      title: "About Us",
      subItems: [
        {
          title: "About",
          link: `/about-us/`,
        },
        {
          title: "Reviews",
          link: ` /reviews/`,
        },
        {
          title: "Gallery",
          link: `/gallery/`,
        },
      ],
    },
    {
      title: "Canada",
      subItems: [
        {
          title: "Canada Express Entry",
          // link: `/${createCountryUrl("canada")}/express-entry/`,
          subDataItems: [
            {
              title: "Canada Express Entry",
              link: `/${createCountryUrl("canada")}/express-entry/`,
            },
            {
              title: "CRS Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/crs-calculator/`,
            },
            {
              title: "CRS Chart",
              link: `/${createCountryUrl("canada")}/express-entry/crs-chart/`,
            },
            {
              title: "67 Points Calculator and charts",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/${createCountryUrl(
                "67 Points Calculator and charts"
              )}`,
            },
            {
              title: "Latest Express Entry Draw",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/latest-express-entry-draw/`,
            },
            {
              title: "Benefits of the Program",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/benefits-of-the-program/`,
            },
            {
              title: "Occupation Demand",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/occupation-demand/`,
            },
            {
              title: "Provisional Nominee Program",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/provisional-nominee-program/`,
            },
            {
              title: "Federal Skilled Worker Program",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/fedral-skilled-worker-program/`,
            },
            {
              title: "Federal Skilled Trade Program",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/fedral-skilled-trade-program/`,
            },
            {
              title: "Canadian Experience Class",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/canadian-experience-class/`,
            },
            {
              title: "Super Visa From India",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/super-visa-from-india/`,
            },
            {
              title: "IELTS",
              link: `/${createCountryUrl("canada")}/express-entry/ielts/`,
            },
          ],
        },
        {
          title: "Provincial Nominee Program",
          subDataItems: [
            {
              title: "Manitoba PNP Program ",
              link: `/${createCountryUrl(
                "canada"
              )}/pnp-program/${createCountryUrl("Manitoba PNP Program")}/`,
            },
            {
              title: "Yukon Nominee Program ",
              link: `/${createCountryUrl(
                "canada"
              )}/pnp-program/${createCountryUrl("Yukon Nominee Program")}/`,
            },
            {
              title: "Ontario PNP Program ",
              link: `/${createCountryUrl(
                "canada"
              )}/pnp-program/${createCountryUrl("Ontario PNP Program")}/`,
            },
            {
              title: " Nova Scotia PNP ",
              link: `/${createCountryUrl(
                "canada"
              )}/pnp-program/${createCountryUrl("Nova Scotia PNP")}/`,
            },
            {
              title: "Alberta PNP Program ",
              link: `/${createCountryUrl(
                "canada"
              )}/pnp-program/${createCountryUrl("Alberta PNP Program")}/`,
            },
            {
              title: "BC PNP Program ",
              link: `/${createCountryUrl(
                "canada"
              )}/pnp-program/${createCountryUrl("BC PNP Program")}/`,
            },
            {
              title: "Quebec Skilled Worker Program ",
              link: `/pnp-program/${createCountryUrl(
                "Quebec Skilled Worker Program"
              )}/`,
            },
            {
              title: "Saskatchewan PNP ",
              link: `/pnp-program/${createCountryUrl("Saskatchewan PNP ")}/`,
            },
            {
              title: "New Brunswick PNP Program ",
              link: `/pnp-program/${createCountryUrl(
                "New Brunswick PNP Program "
              )}/`,
            },
            {
              title: "Atlantic Immigration Pilot Program ",
              link: `/pnp-program/${createCountryUrl(
                "Atlantic Immigration Pilot Program "
              )}/`,
            },
            {
              title: "PEI PNP Program ",
              link: `/pnp-program/${createCountryUrl("PEI PNP Program ")}/`,
            },
            {
              title: "NLPNP Program ",
              link: `/pnp-program/${createCountryUrl("NLPNP Program")}/`,
            },
          ],
        },
        {
          title: "Point Calculator",
          subDataItems: [
            {
              title: "CRS Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl("CRS Calculator")}/`,
            },
            {
              title: "Saskatchewan Points Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl(
                "Saskatchewan Points Calculator"
              )}`,
            },
            {
              title: "Canada 67 points Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl(
                "Canada 67 points Calculator"
              )}`,
            },
            {
              title: "British columbia Points Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl(
                "British columbia Points Calculator"
              )}`,
            },
            {
              title: "Manitoba Points Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl(
                "Manitoba Points Calculator"
              )}`,
            },
            {
              title: "Nova Scotia Points Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl(
                "Nova Scotia Points Calculator"
              )}`,
            },
            {
              title: "Alberta Points Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl(
                "Alberta Points Calculator"
              )}`,
            },
            {
              title: "Ontario Points Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl(
                "Ontario Points Calculator"
              )}`,
            },
            {
              title: " Quebec Points Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl(
                " Quebec Points Calculator"
              )}`,
            },
          ],
        },
        {
          title: "LMIA",
          link: `/${createCountryUrl("Canada")}/lmia/`,
        },
        {
          title: "Canada Spouse Dependent Visa",
          link: `/${createCountryUrl("Canada")}/spouse-dependent-visa/`,
        },
        {
          title: "Super Visa",
          link: `/${createCountryUrl("Canada")}/super-visa/`,
        },
        {
          title: "Quebec Skilled Worker Program",
          link: `/${createCountryUrl("Canada")}/quebec-skilled-worker-program/`,
        },
        {
          title: "Atlantic Immigration Program",
          link: `/${createCountryUrl("Canada")}/atlantic-immigration-program/`,
        },
        {
          title: "Startup Visa",
          link: `/${createCountryUrl("Canada")}/startup-visa/`,
        },
        {
          title: "Entrepreneur Investment Visa",
          link: `/${createCountryUrl("Canada")}/entrepreneur-investment-visa/`,
        },
      ],
    },
    {
      title: "Australia",
      subItems: [
        {
          title: "Australia Skilled Occupation List",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Australia Skilled Occupation List"
          )}`,
        },
        {
          title: "Australia 65 Points Calculator",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Australia 65 Points Calculator"
          )}`,
        },
        {
          title: "Employer Nomination Subclass 186",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Employer Nomination Subclass 186"
          )}`,
        },
        {
          title: "Skilled Independent Subclass 189",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Skilled Independent Subclass 189"
          )}`,
        },
        {
          title: "Skilled Nominated Subclass 190",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Skilled Nominated Subclass 190"
          )}`,
        },
        {
          title: "Australia Subclass 491",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Australia Subclass 491"
          )}`,
        },
        {
          title: "Australia PR Visa From India",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Australia PR Visa From India"
          )}`,
        },
        {
          title: "Australia Spouse Visa",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "AAustralia Spouse Visa"
          )}`,
        },
        {
          title: "Invitation To Apply",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Invitation To Apply"
          )}`,
        },
        {
          title: "Australia Tourist Visa From India",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Australia Tourist Visa From India"
          )}`,
        },
        {
          title: "Australia Subclass 476",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Australia Subclass 476"
          )}`,
        },
      ],
    },
    {
      title: "Travel Visa",
      link: "/travel-visa/",
      subItems: [
        {
          title: "Canada",
          link: `/travel-visa/${createCountryUrl("Canada")}/`,
        },
        {
          title: "Australia",
          link: `/travel-visa/${createCountryUrl("Australia")}/`,
        },
        {
          title: "USA",
          link: `/travel-visa/${createCountryUrl("USA")}/`,
        },
        {
          title: "UK",
          link: `/travel-visa/${createCountryUrl("UK")}/`,
        },
        {
          title: "New Zealand",
          link: `/travel-visa/${createCountryUrl("New Zealand")}/`,
        },
        {
          title: "Dubai",
          link: `/travel-visa/${createCountryUrl("Dubai")}/`,
        },
        {
          title: "Germany",
          link: `/travel-visa/${createCountryUrl("Germany")}/`,
        },
        {
          title: "Schengen",
          link: `/travel-visa/${createCountryUrl("Schegan")}/`,
        },
        {
          title: "Turkey",
          link: `/travel-visa/${createCountryUrl("Turkey")}/`,
        },
        {
          title: "Malaysia",
          link: `/travel-visa/${createCountryUrl("Malaysia")}/`,
        },
        {
          title: "Singapore",
          link: `/travel-visa/${createCountryUrl("Singapore")}/`,
        },

        {
          title: "Ireland",
          link: `/travel-visa/${createCountryUrl("Ireland")}/`,
        },
        {
          title: "Japan",
          link: `/travel-visa/${createCountryUrl("Japan")}/`,
        },

        {
          title: "Bangladesh",
          link: `/travel-visa/${createCountryUrl("Bangladesh")}/`,
        },

        {
          title: "Sri Lanka",
          link: `/travel-visa/${createCountryUrl("Sri Lanka")}/`,
        },
        {
          title: "South Africa",
          link: `/travel-visa/${createCountryUrl("South Africa")}/`,
        },
        {
          title: "South Korea",
          link: `/travel-visa/${createCountryUrl("South Korea")}/`,
        },

        {
          title: "Thailand",
          link: `/travel-visa/${createCountryUrl("Thailand")}/`,
        },

        {
          title: "UAE",
          link: `/travel-visa/${createCountryUrl("UAE")}/`,
        },

        {
          title: "Vietnam",
          link: `/travel-visa/${createCountryUrl("Vietnam")}/`,
        },
      ],
    },
    {
      title: "Job Search Program",
      subItems: [
        {
          title: "Malta",
          link: `/job-search-visa/${createCountryUrl("Malta")}/`,
        },
        {
          title: "Poland",
          link: `/job-search-visa/${createCountryUrl("Poland")}/`,
        },
        {
          title: "Schengen",
          link: `/job-search-visa/${createCountryUrl("Schengen")}/`,
        },
        {
          title: "Singapore",
          link: `/job-search-visa/${createCountryUrl("Singapore")}/`,
        },
        { title: "UAE", link: `/job-search-visa/${createCountryUrl("UAE")}/` },
        { title: "UK", link: `/job-search-visa/${createCountryUrl("UK")}/` },
        {
          title: "Australia",
          link: `/job-search-visa/${createCountryUrl("Australia")}/`,
        },
        {
          title: "Dubai",
          link: `/job-search-visa/${createCountryUrl("Dubai")}/`,
        },
        {
          title: "Germany",
          link: `/job-search-visa/${createCountryUrl("Germany")}/`,
        },
        {
          title: "Gulf",
          link: `/job-search-visa/${createCountryUrl("Gulf")}/`,
        },
      ],
    },
    {
      title: "Resources",
      link: "/resources/",
      subItems: [
        {
          title: "Career",
          link: "/career/",
        },
        {
          title: "Blogs",
          link: "/blog/",
        },
        {
          title: "News",
          link: "/news/",
        },
      ],
    },
    {
      title: "Connect Us",
      link: "/connect-us/",
    },
  ];

  const enquiryCountries = [
    { label: "Ireland", value: "Ireland" },
    { label: "Japan", value: "Japan" },
    { label: "Australia", value: "Australia" },
    { label: "Bangladesh", value: "Bangladesh" },
    { label: "Canada", value: "Canada" },
    { label: "Dubai", value: "Dubai" },
    { label: "Germany", value: "Germany" },
    { label: "Malaysia", value: "Malaysia" },
    { label: "New Zealand", value: "New Zealand" },
    { label: "Sri Lanka", value: "Sri Lanka" },
    { label: "Schengen", value: "Schegan" },
    { label: "Singapore", value: "Singapore" },
    { label: "South Africa", value: "South Africa" },
    { label: "South Korea", value: "South Kora" },
    { label: "Turkey", value: "Turkey" },
    { label: "Thailand", value: "Thailand" },
    { label: "USA", value: "USA" },
    { label: "UAE", value: "UAE" },
    { label: "UK", value: "UK" },
    { label: "Vietnam", value: "Vietnam" },
    { label: "Malta", value: "Malta" },
    { label: "Poland", value: "POland" },
    { label: "Gulf", value: "Gulf" },
  ];

  const what_fswp = {
    introduction: {
      heading: "What is FSWP",
      content: `
      <p>
      The Federal Skilled Workers Program (FSWP) is a way to immigrate to Canada, and it's
managed by a department called IRCC (Immigration, Refugees and Citizenship Canada). They use something called the Express Entry System to select candidates.
      </p>
      <p>
      In this system, people who want to move to Canada create profiles, and those with the highest scores (called CRS scores) get invited to apply for a Canadian PR Visa through FSWP. If you get an Invitation to Apply (ITA), you need to send in your application to IRCC within a specific timeframe.
      </p>
      <p>
      It's also a bonus if you have a job offer approved by the Canadian government. This whole
system, called Express Entry Immigration, started on January 1, 2015, and it's part of the
Economic Class, which includes FSWP and other programs.
      </p>
      `,
    },
  };

  const requirements = {
    introduction: {
      heading: "Requirements for FSWP",
      content: `
      <p>To be eligible for the Federal Skilled Workers Program (FSWP), you need to meet these three basic requirements:</p>
      <ul>
      <li>Have experience in a skilled job.</li>
      <li>Be able to communicate well in English or French.</li>
      <li>Have a good level of education.</li>
      </ul>
      <p><b>You have to meet all of these requirements to apply for the program.</b></p>
      `,
    },
  };

  const eligibility = {
    introduction: {
      heading: "Eligibility Criteria for FSWP",
      content: `
      <p>Federal Skilled Workers (FSW) are folks who have the right age, work experience, and language skills in English or French, and they are selected through the Express Entry system to apply for a Canadian Permanent Resident Visa.</p>
      <p>
      If you're an FSW, there are 347 specific jobs you can have, and if you meet the basic
requirements, you can create a profile called an Expression of Interest (EOI) in the Express Entry system. These profiles are then ranked using a system called the Comprehensive Ranking System (CRS).
      </p>
      <p>
      The people with the highest rankings will be invited to apply for a Canadian PR Visa. If that's
you, you need to complete your application within 3 months.
      </p>
      
      `,
    },
  };

  const conditions_matched = {
    introduction: {
      heading: "Conditions to be met for Federal Skilled Workers",
      content: `
      <p>
      <b>If you want to join the Express Entry Pool as a Federal Skilled Worker, you need to meet these important conditions:</b>
      </p>
      <p>
    <b>Work Experience: </b>You must have at least one year of paid full-time work experience (or part-
        time equivalent) in the last 10 years. This experience should be in one of the 347 specific jobs listed in the National Occupational Classification (NOC) system
      </p>
      <p>
     <b>Points Score: </b>You need to score at least 67 points in a system that looks at six different factors to determine your eligibility.
      </p>
      <p>
      <b>Language Test: </b>You have to take a language test in either French or English from a recognized organization.
      </p>
        <p>
        <b>Settlement Funds:</b> You should have enough money to support yourself when you move to Canada.
        </p>
        <p>
      <b>Medical and Security Checks:</b> You'll need to pass medical and security checks successfully.
        </p>
      `,
    },
  };

  const calc_of_fswp = {
    introduction: {
      heading: "67 Points Calculator for FSWP",
    },
  };

  const calc_fswp = {
    introduction: {
      content: `
      <p>For the Federal Skilled Worker Program in Express Entry, there are six factors that determine if you're eligible. These factors are different from the ones used to rank you in the Express Entry pool.</p>
      <p>We evaluate these factors and give you a score out of 100. If your score is 67 points or higher, you may qualify for the Federal Skilled Worker Program. Then, you can create a profile in the Express Entry pool.</p>
      <p>If your score is below 67 points, you won't be eligible for the program. However, you can
      improve your chances by:</p>
      <b>
      <ul>
      <li>Getting better at the language skills required.</li>
      <li>Earning another degree, diploma, or certificate.</li>
      <li>Receiving a job offer in Canada.</li>
      </ul>
      </b>

      <p><b>Language Skills</b></p>
      <p>Being able to speak one or both of Canada's official languages, which are English and French, is crucial.</p>
      <p>You can earn a maximum of 28 points for your language skills in English and French.</p>
      <p><b>To show how well you can speak English or French, you need to take an official language test. These tests help us understand your language abilities.</b></p>
      `,
    },
  };

  const analyse_points = {
    introduction: {
      heading: "Analyze your language points from the below table",
    },
  };

  const analyze_points_table = {
    row1: {
      column1: "First official language ",
      column2: "Speaking",
      column3: "Listening",
      column4: "Reading",
      column5: "Writing",
    },
    row2: {
      column1: "CLB level 9 or higher",
      column2: "6",
      column3: "6",
      column4: "6",
      column5: "6",
    },
    row3: {
      column1: "CLB level 8",
      column2: "5",
      column3: "5",
      column4: "5",
      column5: "5",
    },
    row4: {
      column1: "CLB level 7",
      column3: "4",
      column4: "4",
      column2: "4",
      column5: "4",
    },
    row5: {
      column1: "Below CLB level 7",
      column3: "Not eligible to apply ",
      column4: "Not eligible to apply ",
      column2: "Not eligible to apply ",
      column5: "Not eligible to apply ",
    },
  };

  const second_land = {
    introduction: {
      content: `
      <p><b>Second official language points criteria</b></p>
      <p>You can earn 4 points for your second official language only if you score at least CLB 5 in all
      four language skills.</p>`,
    },
  };

  const second_lang_table = {
    row1: {
      column1: "Second official language",
      column2: "Points",
    },
    row2: {
      column1: "At least CLB 5 in all of the 4 abilities",
      column2: "0",
    },
    row3: {
      column1: "CLB 4 or less in any of the 4 abilities",
      column2: "0",
    },
  };

  const education_points = {
    introduction: {
      heading: "Education Points",
      content: `
      <p>
      To earn points for your education (up to 25 points), you need to meet certain requirements. If you study in Canada, you should have a certificate, diploma, or degree from either a Canadian high school or a post-secondary institution.
      </p>
      <p>
      If you studied outside Canada, you must have an Educational Credential Assessment (ECA)
report from a recognized organization.
      </p>
      `,
    },
  };

  const work_exp_points = {
    introduction: {
      content: `
      <p><b>Work Experience Points</b></p>
      <p><b>You can earn points for your work experience, but there are some conditions:</b></p>
      <b>
      <ul>
      <li>The work must be in a skilled job listed in the TEER categories 0, 1, 2, or 3 of the 2021 National Occupational Classification (NOC).</li>
     <li>You need to have worked full-time (at least 30 hours per week) for a certain number of years.
     </li>
      </ul>
      </b>
      <p>Your work experience counts whether it was in Canada or another country, while you
      were studying, or even if you were self-employed.</p>
    `,
    },
  };

  const find_noc = {
    introduction: {
      heading: "Locate your NOC",
      content: `
      <p>The National Occupational Classification (NOC) is a list of all the jobs in Canada, and it helps
      categorize different types of work in the country. It explains what each job involves, the skills needed, and where you might work.</p>
      <p>To apply for Express Entry, you'll need to know the "NOC code" for each of your previous jobs. This code tells us about your job and what you did in it. Keep this information handy because you'll need it later.</p>
      <p>If your job description and duties match what's listed in the NOC, you can count that work
      experience for points. You can use a chart to see how many points you can get based on how many years you've worked in that job.</p>
      `,
    },
  };

  const arrange_employement = {
    introduction: {
      heading: "Arrange Employment Points",
      content: `
      <p>
      You can earn up to 10 points if you have a job offer from a Canadian employer that lasts for at
least one year. However, you must secure this job offer before applying to move to Canada as a Federal Skilled Worker.
      </p>

      <p><b>Here are four situations where you can get points for a job offer in Canada:</b></p>
      `,
    },
  };

  const arrange_emp_pointers = [
    {
      heading: "Situation 1",
      content: `
      <ul>
      <li>You are currently working in Canada with a valid work permit.</li>
      <li>Your work permit was given based on a positive assessment from Employment and Social Development Canada, and your job is in TEER categories 0, 1, 2, or 3 of the NOC.</li>
     <li>You work for the employer named on your work permit. </li>
     <li>Your employer has offered you a valid job because you are a skilled worker. </li>
      </ul>
      `,
    },
    {
      heading: "Situation 2",
      content: `
      <ul>
      <li>You are currently working in Canada in a job that doesn't need a labor assessment due to reasons like international agreements or significant benefits to Canada.</li>
      <li>You have a valid work permit.   </li>
      <li>Your current employer has offered you a valid job as a skilled worker. </li>
      <li>You have been working for this employer for at least a year, either full-time or part-time. </li>
      </ul>
      `,
    },
    {
      heading: "Situation 3",
      content: `
      <ul>
      <li>You don't have a work permit and don't plan to work in Canada before becoming a permanent resident.  </li>
      <li>An employer has received a labor assessment (LMIA) and offered you a job based on your skilled worker status.</li>
      </ul>
      `,
    },
    {
      heading: "Situation 4",
      content: `
      <ul>
      <li>You have a valid work permit or can work in Canada without one.</li>
      <li>You are currently working in Canada in a job that doesn't need an LMIA but also isn't under any international or federal-provincial agreement.  </li>
      <li>A different employer from your current one has received an LMIA and offered you a job based on your skilled worker status. </li>
      </ul>
      `,
    },
  ];

  const labour_market = {
    introduction: {
      heading: "Labor Market Impact Assessment (LMIA) and job offers",
      content: `
    <p>You can't apply for a Labor Market Impact Assessment (LMIA) by yourself; your employer has  to do it for you.</p>
   <p>The government department called Employment and Social Development Canada will only approve job offers for jobs in categories 
   TEER 0, 1, 2, or 3 of the National Occupational cClassification (NOC).</p>   
    `,
      subHeading: "Adaptability Points",
      content2: `You and your spouse or partner who is coming with you to Canada can earn up to 10 points for adaptability. These points are based on 
    how well you and your partner are expected to adjust and  settle in Canada.`,
    },
  };

  const adaptabilityPointsTable = {
    row1: {
      column1: "Adaptability",
      column2: "Maximum Points",
    },
    row2: {
      column1: `<p><b>Spouse or Partner’s Language level</b></p>
      <p>Your husband, wife, or partner needs to have a good level of English or French in speaking, listening, reading, and writing. They should score at least CLB 4 on a language test. When you apply for permanent residence, make sure their language test results are not older than 2 years from the date they took the test.</p>`,
      column2: "5",
    },
    row3: {
      column1: `<p><b>Past Studies in Canada</b></p>
      <p>You need to have studied in Canada for at least two full academic years in a program that lasted for at least two years. Full-time study means you have at least 15 hours of classes every week, and you should have maintained good grades as per the school's rules during this time.</p>`,
      column2: "5",
    },
    row4: {
      column1: `<p><b>Spouse or Partner Studies in Canada</b></p>
      <p>For your spouse or partner to qualify, they need to have studied in Canada for at least two full academic years in a program that lasted for at least two years. Full-time study means they have at least 15 hours of classes every week, and they should have maintained good grades as per the school's rules during this time.</p>`,
      column2: "5",
    },
    row5: {
      column1: `<p><b>Past Work in Canada</b></p>
      <p>​
      You need to have worked full-time in Canada for at least one year in a job that falls under categories 0, 1, 2, or 3 of the National Occupational Classification (NOC). Plus, you should have had a valid work permit or been allowed to work in Canada during that time.</p>`,
      column2: "10",
    },
    row6: {
      column1: `<p><b>Spouse or Partner Work in Canada</b></p>
      <p>Your spouse or partner needs to have worked full-time in Canada for at least one year, either with a valid work permit or when they were allowed to work in Canada.</p>`,
      column2: "5",
    },
    row7: {
      column1: `<p><b>Arranged Employment</b></p>
      <p>You get 5 points for arranged employment in Canada</p>`,
      column2: "5",
    },
    row8: {
      column1: `<p><b>Spouse/Partner have a family member who</b></p>
      <p>Lives in Canada.​ </p>
      <p>Is 18 years old or older.​</p>
     <p>Is either a Canadian citizen or a permanent residen</p>`,
      column2: "5",
    },
    row9: {
      column1: `<p><b>This family member should be one of the following:</b></p>
      <p>Your parent.​
      Your grandparent.​
      Your child.​
      Your grandchild.​
      Your brother or sister or your spouse's sibling​
      Your aunt or uncle or your spouse's aunt or uncle whether related by blood or marriage.​
      Your niece or nephew or your spouse's niece or nephew</p>`,
      column2: "5",
    },
  };

  const canonicalURL =
    "https://achintyaconsultant.com/canada/express-entry/fedral-skilled-worker-program/";
  return (
    <div>
      <Helmet>
        <link rel="canonical" href={canonicalURL} />
        <title>
          Secure Your Future in Canada| Federal Skilled Worker Program
        </title>
        <meta name="robots" content="index,follow" />
        <meta
          name="description"
          content="Explore Canada's Federal Skilled Worker Program with Achintya Immigration Services. Find out how to immigrate to Canada based on your skills and qualifications"
        />
        <meta
          name="keywords"
          content="federal skilled worker program canada | federal skilled worker program latest draw"
        />
      </Helmet>
      <Header item={navigationHeader} />
      <div className="fedral-worker-banner-outer-container linear-back-grad">
        <div className="container_wrapper">
          <div className="vertical-text-worker">#Since 2015</div>
          <div className="canada-latest-banner-inner-container">
            <div className="latest-banner-lest-container">
              <h2>Federal Skilled Worker Program</h2>
              <p>Canada Express Entry</p>
              <div className="apply-now-btn-container-banner">
                <ApplyNow />
              </div>
            </div>
            <div className="latest-banner-right-container">
              <img
                className="fedral-image-width"
                src="https://achintyaconsultant.s3.ap-south-1.amazonaws.com/pppppppppppp+1.png"
                alt="Federal Skilled Worker banner"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container_wrapper">
        <div className="canada-page-flex-container">
          <div className="canada-page-flex-left-container">
            <CandaPageComponents props={what_fswp} />
            <CandaPageComponents props={requirements} />
            <CandaPageComponents props={eligibility} />
            <CandaPageComponents props={conditions_matched} />
            <CandaPageComponents props={calc_of_fswp} />
            <div className="link-to-page">
              <Link to={"/"}>
                <p>LINK FOR 67 POINTS CALCULATOR</p>
              </Link>
            </div>
            <CandaPageComponents props={calc_fswp} />
            <div className="link-to-page">
              <Link to={"/"}>
                <p>Language testing</p>
              </Link>
            </div>
            <CandaPageComponents props={analyse_points} />
            <SimpleTable data={analyze_points_table} />
            <CandaPageComponents props={second_land} />
            <SimpleTable data={second_lang_table} />
            <CandaPageComponents props={education_points} />
            <div className="link-to-page">
              <Link to={"/"}>
                <p style={{ marginBottom: "0" }}>67 POINT CALCULATOR</p>
              </Link>
            </div>
            <CandaPageComponents props={work_exp_points} />
          </div>
          <div className="canada-page-right-container">
            <div className="fixed-container">
              <AbroadForm enquiryCountries={enquiryCountries} />
            </div>
          </div>
        </div>
      </div>
      <LocateNoc props={find_noc} />
      <ArrageEmployement
        props={arrange_employement}
        points={arrange_emp_pointers}
      />
      <div className="container_wrapper">
        <CandaPageComponents props={labour_market} />
        <SimpleTable data={adaptabilityPointsTable} />
      </div>

      <Footer />
    </div>
  );
};

export default FedralSkilledWorker;

import React from "react";
import "./index.css";
import { Link } from "react-router-dom";

const CanadaPNPProcess = () => {
  return (
    <div className="canada-pnp-process-outer-container">
      <div className="canada-pnp-eligiblity-container">
        <div className="container_wrapper">
          <h2>Eligibility for CANADA PNP</h2>
          <div className="canada-pnp-eligibility-inner-container">
            <div className="canada-pnp-eligibility-left-container">
              <p>
                Each Canadian province has its own set of rules for its
                Provincial Nominee Programs (PNP), which are meant to bring in
                immigrants who can help the province's economy and are likely to
                stay there. Some PNPs focus on attracting immigrants with job
                skills that are needed in that province. Others may prefer
                immigrants who have a connection to the province, like family
                members already living there because they are more likely to
                stay. In general, younger people who speak English or French
                well, have a good education, and work experience in high-demand
                jobs have a better chance of being accepted. Each PNP has
                different ways for different types of immigrants to apply,
                depending on their skills and qualifications.
              </p>
            </div>
            <div className="canada-pnp-eligibility-right-container">
              <img src="https://achintyaconsultant.s3.ap-south-1.amazonaws.com/canada-pnp/Desk.png" />
            </div>
          </div>
          <div
            className="link-to-page"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              paddingTop: "5px",
            }}
          >
            <Link to={"/"}>
              <p>Check Your Eligibility</p>
            </Link>
          </div>
        </div>
      </div>

      <div className="container_wrapper">
        <div className="canada-pnp-process-inner-container">
          <h2>The process to apply for PNP</h2>
          <p>
            Here are the easy steps to apply for the Provincial Nominee Program
            (PNP) in Canada
          </p>
          <div className="canada-pnp-process-img">
            <img
              src="https://achintyaconsultant.s3.ap-south-1.amazonaws.com/canada-pnp/Group+259.png"
              alt="pnp process in canada"
            />
          </div>
        </div>
      </div>

      <div className="canada-pnp-paper-container">
        <div className="container_wrapper">
          <div className="canada-pnp-paper-inner-container">
            <h2>CANADA PNP through a paper-based process</h2>
            <p>
              If your Provincial Nominee Program (PNP) isn't connected to the
              online Express Entry system, you'll need to use a paper
              application. When you receive a provincial nomination, you can
              send in this paper application for Canadian permanent residency to
              the Immigration, Refugees, and Citizenship Canada (IRCC).
            </p>
            <p>
              However, it's important to know that the paper-based process takes
              much longer compared to the online process through Express Entry.
              On average, Express Entry applications are processed in about 6
              months, but paper applications take about 18 months to process.
              So, it's much faster to go through Express Entry if you can.
            </p>
          </div>
        </div>
      </div>

      <div className="canada-pnp-express">
        <div className="container_wrapper">
          <h2>CANADA PNP through Express Entry process</h2>
          <div className="canada-pnp-express-inner-container">
            <div className="canada-pnp-express-left-container">
              <ol>
                <li style={{ paddingBottom: "24px" }}>
                  Create an online account and fill out an Express Entry
                  profile.
                </li>
                <li style={{ paddingBottom: "24px" }}>
                  In your profile, make sure to mention that you've been
                  nominated by a Canadian province or territory.
                </li>
                <li style={{ paddingBottom: "24px" }}>
                  Once they confirm your provincial nomination, you'll be added
                  to the Express Entry pool.
                </li>
                <li style={{ paddingBottom: "24px" }}>
                  Because of this nomination, you'll receive an extra 600 points
                  on your Comprehensive Ranking System (CRS) score.
                </li>
              </ol>
              <p style={{ paddingBottom: "24px" }}>
                With these extra points, you're almost certain to get an
                Invitation to Apply (ITA) for a Canada Permanent Resident (PR)
                Visa.
              </p>
            </div>
            <div className="canada-pnp-express-right-container">
              <img src="https://achintyaconsultant.s3.ap-south-1.amazonaws.com/canada-pnp/object.png" />
            </div>
          </div>
        </div>
      </div>

      <div className="canada-pnp-paper-container">
        <div className="container_wrapper">
          <div className="canada-pnp-paper-inner-container">
            <h2>Express Entry PNP</h2>
            <p>
              Many Provincial Nominee Programs (PNPs) have a special "enhanced"
              stream. This stream lets provinces pick skilled immigrants from
              the Express Entry pool who can fill specific job needs in their
              area.
            </p>
            <p>
              <b>Here's how it works</b>
            </p>
            <div className="pnpn-list">
              <ul>
                <li>
                  Express Entry candidates can apply for provincial nomination
                  through this enhanced stream.
                </li>
                <li>
                  When they get the provincial nomination certificate, they
                  receive an extra 600 points on their Express Entry profile.
                </li>
                <li>
                  These extra points almost guarantee that they'll get an
                  Invitation to Apply (ITA) for permanent residency in Canada in
                  the next rounds of invitations.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="processing-time-outer-container">
        <div className="container_wrapper">
          <div className="processing-time-inner-container">
            <h2>Processing Time for PNP</h2>
            <p>
              How long it takes for your Provincial Nominee Program (PNP)
              application to be processed depends on whether you applied through
              the fast Express Entry system or the slower non-Express Entry
              process.
            </p>

            <ol>
              <li>
                For Express Entry-linked streams, it usually takes about 6 to 8
                months from the time you apply to the province until you get
                your Permanent Resident (PR) Visa.
              </li>
              <li>
                But if you apply through the non-Express Entry process (the
                paper-based one), it takes longer—typically between 1 to 2 years
                for your application to be processed by the federal government.
              </li>
            </ol>

            <p>
              So, the total processing time for a PNP application includes the
              time it takes for the province to review your application and the
              extra time it takes for the federal government to process your PR
              Visa application. Express Entry is faster, while non-Express Entry
              takes longer.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CanadaPNPProcess;

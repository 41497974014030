import React from "react";
import "./index.css";

const CandaPageComponents = ({ props }) => {
  // console.log("props : ", props);
  return (
    <div className="canada-page-outer-container">
      <div>
        <div className="canada-page-points-container">
          {props?.introduction?.heading ? (
            <h2>{props?.introduction?.heading}</h2>
          ) : (
            ""
          )}
          <p
            dangerouslySetInnerHTML={{
              __html: props?.introduction?.content,
            }}
          />
        </div>

        <div className="canada-page-points-container-subHeading">
          {props?.introduction?.subHeading ? (
            <h2>{props?.introduction?.subHeading}</h2>
          ) : (
            ""
          )}
          <p
            dangerouslySetInnerHTML={{
              __html: props?.introduction?.content2,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default CandaPageComponents;

import React, { useState } from "react";
import "./index.css";
import EnquiryComponent from "../../commonComponents/enquiryComponent";

const ApplyNow = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleButtonClick = () => {
    // console.log(isOpen);
    setIsOpen(!isOpen);
  };
  return (
    <div className="apply-outer-container">
      <button onClick={handleButtonClick}>Apply Now</button>
      {/* {console.log("isOpen : ", isOpen)} */}
      {isOpen && <EnquiryComponent />}
    </div>
  );
};

export default ApplyNow;

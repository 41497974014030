import React from "react";
import "./index.css";

const CanadaExpressPnpBanner = () => {
  return (
    <div className="banner-canada-pnp">
      <div className="image-container-pnp">
        <picture>
          <source
            media="(min-width: 769px)"
            srcSet="https://achintyaconsultant.s3.ap-south-1.amazonaws.com/canada-pnp/canadaPnp.png"
          />
          <img
            src="https://achintyaconsultant.s3.ap-south-1.amazonaws.com/canada-pnp/canadPanpMob.png"
            alt="Mobile Image"
            className="express-mobile-image"
          />
        </picture>
        <div className="overlay-text-pnp">Provincial Nominee Program</div>
      </div>
    </div>
  );
};

export default CanadaExpressPnpBanner;

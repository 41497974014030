import React from "react";
import "./index.css";

const WhyPrCountry = ({ whyPRCountry, country }) => {
  const dataForCountry = whyPRCountry[country.toLowerCase()];

  return (
    <div className="whyPr-outer-country">
      <div className="container_wrapper">
        <div className="whyPr-inner-container">
          <h2 className="whyPr-heading">{whyPRCountry?.heading}</h2>
          <div className="whyPr-description" dangerouslySetInnerHTML={{ __html: whyPRCountry?.content }} />
        </div>
      </div>
    </div>
  );
};

export default WhyPrCountry;

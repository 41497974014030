import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./index.css";
import Header from "../../commonComponents/header";
import Footer from "../../commonComponents/footer";
import privacyBanner from "../../assets/privacy-policy.png";
import { Helmet } from "react-helmet";
import { createCountryUrl } from "../../Utilities/commonFunctions";

const PrivacyPolicy = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const navigationHeader = [
    {
      title: "Home",
      link: `/`,
    },
    {
      title: "About Us",
      subItems: [
        {
          title: "About",
          link: `/about-us/`,
        },
        {
          title: "Reviews",
          link: ` /reviews/`,
        },
        {
          title: "Gallery",
          link: `/gallery/`,
        },
      ],
    },
    {
      title: "Canada",
      subItems: [
        {
          title: "Canada Express Entry",
          // link: `/${createCountryUrl("canada")}/express-entry/`,
          subDataItems: [
            {
              title: "Canada Express Entry",
              link: `/${createCountryUrl("canada")}/express-entry/`,
            },
            {
              title: "CRS Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/crs-calculator/`,
            },
            {
              title: "CRS Chart",
              link: `/${createCountryUrl("canada")}/express-entry/crs-chart/`,
            },
            {
              title: "67 Points Calculator and charts",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/${createCountryUrl(
                "67 Points Calculator and charts"
              )}`,
            },
            {
              title: "Latest Express Entry Draw",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/latest-express-entry-draw/`,
            },
            {
              title: "Benefits of the Program",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/benefits-of-the-program/`,
            },
            {
              title: "Occupation Demand",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/occupation-demand/`,
            },
            {
              title: "Provisional Nominee Program",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/provisional-nominee-program/`,
            },
            {
              title: "Federal Skilled Worker Program",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/fedral-skilled-worker-program/`,
            },
            {
              title: "Federal Skilled Trade Program",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/fedral-skilled-trade-program/`,
            },
            {
              title: "Canadian Experience Class",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/canadian-experience-class/`,
            },
            {
              title: "Super Visa From India",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/super-visa-from-india/`,
            },
            {
              title: "IELTS",
              link: `/${createCountryUrl("canada")}/express-entry/ielts/`,
            },
          ],
        },
        {
          title: "Provincial Nominee Program",
          subDataItems: [
            {
              title: "Manitoba PNP Program ",
              link: `/${createCountryUrl(
                "canada"
              )}/pnp-program/${createCountryUrl("Manitoba PNP Program")}/`,
            },
            {
              title: "Yukon Nominee Program ",
              link: `/${createCountryUrl(
                "canada"
              )}/pnp-program/${createCountryUrl("Yukon Nominee Program")}/`,
            },
            {
              title: "Ontario PNP Program ",
              link: `/${createCountryUrl(
                "canada"
              )}/pnp-program/${createCountryUrl("Ontario PNP Program")}/`,
            },
            {
              title: " Nova Scotia PNP ",
              link: `/${createCountryUrl(
                "canada"
              )}/pnp-program/${createCountryUrl("Nova Scotia PNP")}/`,
            },
            {
              title: "Alberta PNP Program ",
              link: `/${createCountryUrl(
                "canada"
              )}/pnp-program/${createCountryUrl("Alberta PNP Program")}/`,
            },
            {
              title: "BC PNP Program ",
              link: `/${createCountryUrl(
                "canada"
              )}/pnp-program/${createCountryUrl("BC PNP Program")}/`,
            },
            {
              title: "Quebec Skilled Worker Program ",
              link: `/pnp-program/${createCountryUrl(
                "Quebec Skilled Worker Program"
              )}/`,
            },
            {
              title: "Saskatchewan PNP ",
              link: `/pnp-program/${createCountryUrl("Saskatchewan PNP ")}/`,
            },
            {
              title: "New Brunswick PNP Program ",
              link: `/pnp-program/${createCountryUrl(
                "New Brunswick PNP Program "
              )}/`,
            },
            {
              title: "Atlantic Immigration Pilot Program ",
              link: `/pnp-program/${createCountryUrl(
                "Atlantic Immigration Pilot Program "
              )}/`,
            },
            {
              title: "PEI PNP Program ",
              link: `/pnp-program/${createCountryUrl("PEI PNP Program ")}/`,
            },
            {
              title: "NLPNP Program ",
              link: `/pnp-program/${createCountryUrl("NLPNP Program")}/`,
            },
          ],
        },
        {
          title: "Point Calculator",
          subDataItems: [
            {
              title: "CRS Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl("CRS Calculator")}/`,
            },
            {
              title: "Saskatchewan Points Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl(
                "Saskatchewan Points Calculator"
              )}`,
            },
            {
              title: "Canada 67 points Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl(
                "Canada 67 points Calculator"
              )}`,
            },
            {
              title: "British columbia Points Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl(
                "British columbia Points Calculator"
              )}`,
            },
            {
              title: "Manitoba Points Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl(
                "Manitoba Points Calculator"
              )}`,
            },
            {
              title: "Nova Scotia Points Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl(
                "Nova Scotia Points Calculator"
              )}`,
            },
            {
              title: "Alberta Points Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl(
                "Alberta Points Calculator"
              )}`,
            },
            {
              title: "Ontario Points Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl(
                "Ontario Points Calculator"
              )}`,
            },
            {
              title: " Quebec Points Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl(
                " Quebec Points Calculator"
              )}`,
            },
          ],
        },
        {
          title: "LMIA",
          link: `/${createCountryUrl("Canada")}/lmia/`,
        },
        {
          title: "Canada Spouse Dependent Visa",
          link: `/${createCountryUrl("Canada")}/spouse-dependent-visa/`,
        },
        {
          title: "Super Visa",
          link: `/${createCountryUrl("Canada")}/super-visa/`,
        },
        {
          title: "Quebec Skilled Worker Program",
          link: `/${createCountryUrl("Canada")}/quebec-skilled-worker-program/`,
        },
        {
          title: "Atlantic Immigration Program",
          link: `/${createCountryUrl("Canada")}/atlantic-immigration-program/`,
        },
        {
          title: "Startup Visa",
          link: `/${createCountryUrl("Canada")}/startup-visa/`,
        },
        {
          title: "Entrepreneur Investment Visa",
          link: `/${createCountryUrl("Canada")}/entrepreneur-investment-visa/`,
        },
      ],
    },
    {
      title: "Australia",
      subItems: [
        {
          title: "Australia Skilled Occupation List",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Australia Skilled Occupation List"
          )}`,
        },
        {
          title: "Australia 65 Points Calculator",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Australia 65 Points Calculator"
          )}`,
        },
        {
          title: "Employer Nomination Subclass 186",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Employer Nomination Subclass 186"
          )}`,
        },
        {
          title: "Skilled Independent Subclass 189",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Skilled Independent Subclass 189"
          )}`,
        },
        {
          title: "Skilled Nominated Subclass 190",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Skilled Nominated Subclass 190"
          )}`,
        },
        {
          title: "Australia Subclass 491",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Australia Subclass 491"
          )}`,
        },
        {
          title: "Australia PR Visa From India",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Australia PR Visa From India"
          )}`,
        },
        {
          title: "Australia Spouse Visa",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "AAustralia Spouse Visa"
          )}`,
        },
        {
          title: "Invitation To Apply",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Invitation To Apply"
          )}`,
        },
        {
          title: "Australia Tourist Visa From India",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Australia Tourist Visa From India"
          )}`,
        },
        {
          title: "Australia Subclass 476",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Australia Subclass 476"
          )}`,
        },
      ],
    },
    {
      title: "Travel Visa",
      link: "/travel-visa/",
      subItems: [
        {
          title: "Canada",
          link: `/travel-visa/${createCountryUrl("Canada")}/`,
        },
        {
          title: "Australia",
          link: `/travel-visa/${createCountryUrl("Australia")}/`,
        },
        {
          title: "USA",
          link: `/travel-visa/${createCountryUrl("USA")}/`,
        },
        {
          title: "UK",
          link: `/travel-visa/${createCountryUrl("UK")}/`,
        },
        {
          title: "New Zealand",
          link: `/travel-visa/${createCountryUrl("New Zealand")}/`,
        },
        {
          title: "Dubai",
          link: `/travel-visa/${createCountryUrl("Dubai")}/`,
        },
        {
          title: "Germany",
          link: `/travel-visa/${createCountryUrl("Germany")}/`,
        },
        {
          title: "Schengen",
          link: `/travel-visa/${createCountryUrl("Schegan")}/`,
        },
        {
          title: "Turkey",
          link: `/travel-visa/${createCountryUrl("Turkey")}/`,
        },
        {
          title: "Malaysia",
          link: `/travel-visa/${createCountryUrl("Malaysia")}/`,
        },
        {
          title: "Singapore",
          link: `/travel-visa/${createCountryUrl("Singapore")}/`,
        },

        {
          title: "Ireland",
          link: `/travel-visa/${createCountryUrl("Ireland")}/`,
        },
        {
          title: "Japan",
          link: `/travel-visa/${createCountryUrl("Japan")}/`,
        },

        {
          title: "Bangladesh",
          link: `/travel-visa/${createCountryUrl("Bangladesh")}/`,
        },

        {
          title: "Sri Lanka",
          link: `/travel-visa/${createCountryUrl("Sri Lanka")}/`,
        },
        {
          title: "South Africa",
          link: `/travel-visa/${createCountryUrl("South Africa")}/`,
        },
        {
          title: "South Korea",
          link: `/travel-visa/${createCountryUrl("South Korea")}/`,
        },

        {
          title: "Thailand",
          link: `/travel-visa/${createCountryUrl("Thailand")}/`,
        },

        {
          title: "UAE",
          link: `/travel-visa/${createCountryUrl("UAE")}/`,
        },

        {
          title: "Vietnam",
          link: `/travel-visa/${createCountryUrl("Vietnam")}/`,
        },
      ],
    },
    {
      title: "Job Search Program",
      subItems: [
        {
          title: "Malta",
          link: `/job-search-visa/${createCountryUrl("Malta")}/`,
        },
        {
          title: "Poland",
          link: `/job-search-visa/${createCountryUrl("Poland")}/`,
        },
        {
          title: "Schengen",
          link: `/job-search-visa/${createCountryUrl("Schengen")}/`,
        },
        {
          title: "Singapore",
          link: `/job-search-visa/${createCountryUrl("Singapore")}/`,
        },
        { title: "UAE", link: `/job-search-visa/${createCountryUrl("UAE")}/` },
        { title: "UK", link: `/job-search-visa/${createCountryUrl("UK")}/` },
        {
          title: "Australia",
          link: `/job-search-visa/${createCountryUrl("Australia")}/`,
        },
        {
          title: "Dubai",
          link: `/job-search-visa/${createCountryUrl("Dubai")}/`,
        },
        {
          title: "Germany",
          link: `/job-search-visa/${createCountryUrl("Germany")}/`,
        },
        {
          title: "Gulf",
          link: `/job-search-visa/${createCountryUrl("Gulf")}/`,
        },
      ],
    },
    {
      title: "Resources",
      link: "/resources/",
      subItems: [
        {
          title: "Career",
          link: "/career/",
        },
        {
          title: "Blogs",
          link: "/blog/",
        },
        {
          title: "News",
          link: "/news/",
        },
      ],
    },
    {
      title: "Connect Us",
      link: "/connect-us/",
    },
  ];

  const canonicalURL = "https://achintyaconsultant.com/privacy-policy/";

  return (
    <div>
      <Helmet>
        <link rel="canonical" href={canonicalURL} />
        <title>Safeguarding Your Data: Our Transparent Privacy Policy </title>
        <meta name="robots" content="index,follow" />
        <meta
          name="description"
          content="Explore Achintya's comprehensive privacy policy to understand how we prioritize your privacy rights and maintain the confidentiality of your sensitive data."
        />
        <meta
          name="keywords"
          content="privacy policy for immigration and citzenship system,private policy for permanent resident,immigration website private policy"
        />
      </Helmet>
      <Header item={navigationHeader} />

      <div className="privacy-outer-container">
        <div className="privacy-outer-heading-container">
          <div className="container_wrapper">
            <div className="privacy-heading-container">
              <h2>Privacy Policy</h2>
              <img
                className="privacy-image"
                src={privacyBanner}
                alt="privacy image"
              />
            </div>
          </div>
        </div>
        <div className="privacy-inner-container">
          <div className="container_wrapper">
            <div className="privay-content-container">
              <p className="privacy-sub-heading">
                ACHINTYA IMMIGRATION CONSULTANT PRIVATE LIMITED PRIVACY POLICY
              </p>
              <div className="privacy-content1">
                <p>
                  The website www.achintyaconsultant.com as specified in this
                  Privacy Policy (hereinafter referred as AICPL), places
                  significant emphasis on safeguarding the privacy of clients.
                  This applies to individuals who access, avail products or
                  services through AICPL website. Consequently, AICPL has
                  implemented stringent protocols to ensure the protection of
                  clients data.
                </p>
                <p>
                  The term “We”/ “Us” / “Our” used in this document refer to
                  AICPL and "You" / "Your" /"Yourself" refer to the CLIENT, who
                  visit or access or use AICPL website.
                </p>
                <p>
                  AICPL recognize the importance of preserving complete
                  confidentiality. Any personal information and/or business
                  proprietary material that you might disclose to us is treated
                  with strict confidence.
                </p>
                <p>
                  All the information given to AICPL by its prospective clients,
                  is kept strictly confidential and on a need-to-know basis and
                  is protected. We will only record your personal details if you
                  send us a message. You can be assured that your personal
                  information is used only by our trained staff for the purpose
                  of the work you have engaged our office to do.{" "}
                </p>
              </div>
              <div className="privacy-sub-sub-haeding margin-bottom-change">
                <p>CONSENT</p>
              </div>
              <div className="privacy-content1 ">
                <p>
                  By using our website, you hereby consent to our Privacy Policy
                  and agree to its terms.
                </p>
              </div>
              <div className="privacy-sub-sub-haeding margin-bottom-change">
                <p>HOW WE USE YOUR INFORMATION </p>
              </div>
              <div className="privacy-content1 ">
                <div>
                  <p>
                    We use the information we collect in various ways,
                    including:
                  </p>
                  <ul>
                    <li>Provide, operate, and maintain our website; </li>
                    <li>Improve, personalize, and expand our website; </li>
                    <li>Understand and analyse how you use our website; </li>
                    <li>
                      Develop new products, services, features, and
                      functionality;{" "}
                    </li>
                    <li>
                      Communicate with you, either directly or through one of
                      our partners, including for customer service, to provide
                      you with updates and other information relating to the
                      website, and for marketing and promotional purposes;{" "}
                    </li>
                    <li>
                      Call you back for more information or discuss your query,
                      questions as specified on the website;{" "}
                    </li>
                    <li>Find and prevent fraud; </li>
                    <li>
                      Calls made from and to our published contact numbers are
                      recorded for training and monitoring purposes;
                    </li>
                  </ul>
                  <p>
                    We will not use your e-mail address for any other purpose,
                    and will not disclose it, without your consent. Your
                    information is never used for marketing or solicitation and
                    is never sold or given to anyone for these purposes.{" "}
                  </p>
                  <p>
                    AICPL never supplies any other person or organization with
                    your information except those government agencies involved
                    in your immigration process.{" "}
                  </p>
                  <p>
                    You can register with our website if you would like to
                    receive our catalogue or updates on our new products and
                    services. Information you submit on our website will not be
                    used for this purpose unless you have given us your specific
                    consent to do so.{" "}
                  </p>
                  <p>
                    The Site has security measures in place to protect against
                    the loss, misuse and alteration of the information under our
                    control. We have ensured high degree of security for your
                    information and use our best efforts to prevent it from
                    being read or intercepted as the information travels over
                    the internet. While we are committed to protecting your
                    information, we cannot ensure or warrant the security of any
                    information you transmit to us.{" "}
                  </p>
                </div>
              </div>
              <div className="privacy-sub-sub-haeding margin-bottom-change">
                <p>THIRD PARTY PRIVACY POLICIES</p>
              </div>
              <div className="privacy-content1 ">
                <p>
                  The Site provides links to web sites and access to content,
                  products and services from third parties, including clients,
                  advertisers, payment gateway, affiliates and sponsors of the
                  Site. You agree that AICPL is not responsible for the
                  availability of, and content provided on, third party web
                  sites. The Client is requested to peruse the policies posted
                  by other web sites regarding privacy and other topics before
                  use. AICPL is not responsible for third party content
                  accessible through the Site, including opinions, advice,
                  statements and advertisements, and Client shall bear all risks
                  associated with the use of such content. AICPL is not
                  responsible for any loss or damage of any sort of Client may
                  incur from dealing with any third party.
                </p>
                <p>
                  Our Privacy Policy may change from time to time. Clients
                  should check our Web site frequently to see any recent
                  changes. Our current Privacy Policy applies to all information
                  that we have about you and your account, unless stated
                  otherwise.
                </p>
                <p>
                  By sharing your details, you agree to receive updates on SMS,
                  Email & WhatsApp.
                </p>
              </div>
              <div className="privacy-sub-sub-haeding margin-bottom-change">
                <p>Complaints/Redressal System: </p>
              </div>
              <div className="privacy-content1 ">
                <p>
                  If you have any concern related to this Privacy Policy or
                  collection, storage, retention or disclosure of Your
                  Information under the terms of this Privacy Policy or Terms of
                  Use or any other terms and conditions of AICPL, including any
                  queries or grievances, You can contact us at the below given
                  details:{" "}
                </p>
                <br />
                <div className="privacy-des-flex">
                  <p className="privacy-contact-info">
                    <b>
                      <u>Email</u>:
                    </b>
                  </p>
                  <a href="mailto:grievance@achintyaconsultant.com ">
                    <p className="privacy-contact-info">
                      {" "}
                      grievance@achintyaconsultant.com{" "}
                    </p>
                  </a>
                </div>
                <div className="privacy-des-flex">
                  <p className="privacy-contact-info">
                    <b>
                      <u>Telephone Number</u>:
                    </b>
                  </p>
                  <a href="tel:9315890309 ">
                    <p className="privacy-contact-info">93158-90309 </p>
                  </a>
                </div>

                <div className="privacy-des-flex">
                  <p className="privacy-contact-info">
                    <b>
                      <u>Working Days</u>:
                    </b>
                  </p>
                  <p className="privacy-contact-info">
                    Monday to Saturday (Excluding Public Holidays){" "}
                  </p>
                </div>

                <div className="privacy-des-flex">
                  <p className="privacy-contact-info">
                    <b>
                      <u>Working Hours</u>:
                    </b>
                  </p>
                  <p className="privacy-contact-info">
                    1000 Hours to 1900 Hours (10 AM – 07 PM){" "}
                  </p>
                </div>
                <br />
                <p>
                  We will strive to address your feedback and concerns in a
                  timely and effective manner.
                </p>
                <br />
                <p>
                  Please note that the details of the Data Protection
                  Officer/Grievance Officer may be changed by Us from time to
                  time by updating this Privacy Policy.{" "}
                </p>
              </div>

              <div className="privacy-sub-sub-haeding margin-bottom-change">
                <p>DISPUTE RESOLUTION </p>
              </div>

              <div className="privacy-content1">
                <p>
                  In the event of any dispute, difference or claim arising out
                  of this Privacy Policy the same shall be settled in accordance
                  with the laws of India through regular judicial process and
                  the court of Delhi, India shall have exclusive jurisdiction.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;

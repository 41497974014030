import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import Header from "../../commonComponents/header";
import Footer from "../../commonComponents/footer";
import WhatsApp from "../../commonComponents/whatsapp";
import AboutAchintya from "../../aboutPageComponent/aboutAchintya";
import WhyChooseAchintya from "../../commonComponents/whyChoose";
import AboutBanner from "../../aboutPageComponent/aboutBanner";
import { createCountryUrl } from "../../Utilities/commonFunctions";
import Services from "../../aboutPageComponent/ourServices";
import monumentImage from "../../assets/Layer_1.png";

const AboutPage = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const canonicalURL = "https://achintyaconsultant.com/about-us/";
  const navigationHeader = [
    {
      title: "Home",
      link: `/`,
    },
    {
      title: "About Us",
      subItems: [
        {
          title: "About",
          link: `/about-us/`,
        },
        {
          title: "Reviews",
          link: ` /reviews/`,
        },
        {
          title: "Gallery",
          link: `/gallery/`,
        },
      ],
    },
    {
      title: "Canada",
      subItems: [
        {
          title: "Canada Express Entry",
          // link: `/${createCountryUrl("canada")}/express-entry/`,
          subDataItems: [
            {
              title: "Canada Express Entry",
              link: `/${createCountryUrl("canada")}/express-entry/`,
            },
            {
              title: "CRS Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/crs-calculator/`,
            },
            {
              title: "CRS Chart",
              link: `/${createCountryUrl("canada")}/express-entry/crs-chart/`,
            },
            {
              title: "67 Points Calculator and charts",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/${createCountryUrl(
                "67 Points Calculator and charts"
              )}`,
            },
            {
              title: "Latest Express Entry Draw",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/latest-express-entry-draw/`,
            },
            {
              title: "Benefits of the Program",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/benefits-of-the-program/`,
            },
            {
              title: "Occupation Demand",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/occupation-demand/`,
            },
            {
              title: "Provisional Nominee Program",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/provisional-nominee-program/`,
            },
            {
              title: "Federal Skilled Worker Program",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/fedral-skilled-worker-program/`,
            },
            {
              title: "Federal Skilled Trade Program",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/fedral-skilled-trade-program/`,
            },
            {
              title: "Canadian Experience Class",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/canadian-experience-class/`,
            },
            {
              title: "Super Visa From India",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/super-visa-from-india/`,
            },
            {
              title: "IELTS",
              link: `/${createCountryUrl("canada")}/express-entry/ielts/`,
            },
          ],
        },
        {
          title: "Provincial Nominee Program",
          subDataItems: [
            {
              title: "Manitoba PNP Program ",
              link: `/${createCountryUrl(
                "canada"
              )}/pnp-program/${createCountryUrl("Manitoba PNP Program")}/`,
            },
            {
              title: "Yukon Nominee Program ",
              link: `/${createCountryUrl(
                "canada"
              )}/pnp-program/${createCountryUrl("Yukon Nominee Program")}/`,
            },
            {
              title: "Ontario PNP Program ",
              link: `/${createCountryUrl(
                "canada"
              )}/pnp-program/${createCountryUrl("Ontario PNP Program")}/`,
            },
            {
              title: " Nova Scotia PNP ",
              link: `/${createCountryUrl(
                "canada"
              )}/pnp-program/${createCountryUrl("Nova Scotia PNP")}/`,
            },
            {
              title: "Alberta PNP Program ",
              link: `/${createCountryUrl(
                "canada"
              )}/pnp-program/${createCountryUrl("Alberta PNP Program")}/`,
            },
            {
              title: "BC PNP Program ",
              link: `/${createCountryUrl(
                "canada"
              )}/pnp-program/${createCountryUrl("BC PNP Program")}/`,
            },
            {
              title: "Quebec Skilled Worker Program ",
              link: `/pnp-program/${createCountryUrl(
                "Quebec Skilled Worker Program"
              )}/`,
            },
            {
              title: "Saskatchewan PNP ",
              link: `/pnp-program/${createCountryUrl("Saskatchewan PNP ")}/`,
            },
            {
              title: "New Brunswick PNP Program ",
              link: `/pnp-program/${createCountryUrl(
                "New Brunswick PNP Program "
              )}/`,
            },
            {
              title: "Atlantic Immigration Pilot Program ",
              link: `/pnp-program/${createCountryUrl(
                "Atlantic Immigration Pilot Program "
              )}/`,
            },
            {
              title: "PEI PNP Program ",
              link: `/pnp-program/${createCountryUrl("PEI PNP Program ")}/`,
            },
            {
              title: "NLPNP Program ",
              link: `/pnp-program/${createCountryUrl("NLPNP Program")}/`,
            },
          ],
        },
        {
          title: "Point Calculator",
          subDataItems: [
            {
              title: "CRS Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl("CRS Calculator")}/`,
            },
            {
              title: "Saskatchewan Points Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl(
                "Saskatchewan Points Calculator"
              )}`,
            },
            {
              title: "Canada 67 points Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl(
                "Canada 67 points Calculator"
              )}`,
            },
            {
              title: "British columbia Points Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl(
                "British columbia Points Calculator"
              )}`,
            },
            {
              title: "Manitoba Points Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl(
                "Manitoba Points Calculator"
              )}`,
            },
            {
              title: "Nova Scotia Points Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl(
                "Nova Scotia Points Calculator"
              )}`,
            },
            {
              title: "Alberta Points Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl(
                "Alberta Points Calculator"
              )}`,
            },
            {
              title: "Ontario Points Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl(
                "Ontario Points Calculator"
              )}`,
            },
            {
              title: " Quebec Points Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl(
                " Quebec Points Calculator"
              )}`,
            },
          ],
        },
        {
          title: "LMIA",
          link: `/${createCountryUrl("Canada")}/lmia/`,
        },
        {
          title: "Canada Spouse Dependent Visa",
          link: `/${createCountryUrl("Canada")}/spouse-dependent-visa/`,
        },
        {
          title: "Super Visa",
          link: `/${createCountryUrl("Canada")}/super-visa/`,
        },
        {
          title: "Quebec Skilled Worker Program",
          link: `/${createCountryUrl("Canada")}/quebec-skilled-worker-program/`,
        },
        {
          title: "Atlantic Immigration Program",
          link: `/${createCountryUrl("Canada")}/atlantic-immigration-program/`,
        },
        {
          title: "Startup Visa",
          link: `/${createCountryUrl("Canada")}/startup-visa/`,
        },
        {
          title: "Entrepreneur Investment Visa",
          link: `/${createCountryUrl("Canada")}/entrepreneur-investment-visa/`,
        },
      ],
    },
    {
      title: "Australia",
      subItems: [
        {
          title: "Australia Skilled Occupation List",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Australia Skilled Occupation List"
          )}`,
        },
        {
          title: "Australia 65 Points Calculator",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Australia 65 Points Calculator"
          )}`,
        },
        {
          title: "Employer Nomination Subclass 186",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Employer Nomination Subclass 186"
          )}`,
        },
        {
          title: "Skilled Independent Subclass 189",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Skilled Independent Subclass 189"
          )}`,
        },
        {
          title: "Skilled Nominated Subclass 190",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Skilled Nominated Subclass 190"
          )}`,
        },
        {
          title: "Australia Subclass 491",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Australia Subclass 491"
          )}`,
        },
        {
          title: "Australia PR Visa From India",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Australia PR Visa From India"
          )}`,
        },
        {
          title: "Australia Spouse Visa",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "AAustralia Spouse Visa"
          )}`,
        },
        {
          title: "Invitation To Apply",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Invitation To Apply"
          )}`,
        },
        {
          title: "Australia Tourist Visa From India",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Australia Tourist Visa From India"
          )}`,
        },
        {
          title: "Australia Subclass 476",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Australia Subclass 476"
          )}`,
        },
      ],
    },
    {
      title: "Travel Visa",
      link: "/travel-visa/",
      subItems: [
        {
          title: "Canada",
          link: `/travel-visa/${createCountryUrl("Canada")}/`,
        },
        {
          title: "Australia",
          link: `/travel-visa/${createCountryUrl("Australia")}/`,
        },
        {
          title: "USA",
          link: `/travel-visa/${createCountryUrl("USA")}/`,
        },
        {
          title: "UK",
          link: `/travel-visa/${createCountryUrl("UK")}/`,
        },
        {
          title: "New Zealand",
          link: `/travel-visa/${createCountryUrl("New Zealand")}/`,
        },
        {
          title: "Dubai",
          link: `/travel-visa/${createCountryUrl("Dubai")}/`,
        },
        {
          title: "Germany",
          link: `/travel-visa/${createCountryUrl("Germany")}/`,
        },
        {
          title: "Schengen",
          link: `/travel-visa/${createCountryUrl("Schegan")}/`,
        },
        {
          title: "Turkey",
          link: `/travel-visa/${createCountryUrl("Turkey")}/`,
        },
        {
          title: "Malaysia",
          link: `/travel-visa/${createCountryUrl("Malaysia")}/`,
        },
        {
          title: "Singapore",
          link: `/travel-visa/${createCountryUrl("Singapore")}/`,
        },

        {
          title: "Ireland",
          link: `/travel-visa/${createCountryUrl("Ireland")}/`,
        },
        {
          title: "Japan",
          link: `/travel-visa/${createCountryUrl("Japan")}/`,
        },

        {
          title: "Bangladesh",
          link: `/travel-visa/${createCountryUrl("Bangladesh")}/`,
        },

        {
          title: "Sri Lanka",
          link: `/travel-visa/${createCountryUrl("Sri Lanka")}/`,
        },
        {
          title: "South Africa",
          link: `/travel-visa/${createCountryUrl("South Africa")}/`,
        },
        {
          title: "South Korea",
          link: `/travel-visa/${createCountryUrl("South Korea")}/`,
        },

        {
          title: "Thailand",
          link: `/travel-visa/${createCountryUrl("Thailand")}/`,
        },

        {
          title: "UAE",
          link: `/travel-visa/${createCountryUrl("UAE")}/`,
        },

        {
          title: "Vietnam",
          link: `/travel-visa/${createCountryUrl("Vietnam")}/`,
        },
      ],
    },
    {
      title: "Job Search Program",
      subItems: [
        {
          title: "Malta",
          link: `/job-search-visa/${createCountryUrl("Malta")}/`,
        },
        {
          title: "Poland",
          link: `/job-search-visa/${createCountryUrl("Poland")}/`,
        },
        {
          title: "Schengen",
          link: `/job-search-visa/${createCountryUrl("Schengen")}/`,
        },
        {
          title: "Singapore",
          link: `/job-search-visa/${createCountryUrl("Singapore")}/`,
        },
        { title: "UAE", link: `/job-search-visa/${createCountryUrl("UAE")}/` },
        { title: "UK", link: `/job-search-visa/${createCountryUrl("UK")}/` },
        {
          title: "Australia",
          link: `/job-search-visa/${createCountryUrl("Australia")}/`,
        },
        {
          title: "Dubai",
          link: `/job-search-visa/${createCountryUrl("Dubai")}/`,
        },
        {
          title: "Germany",
          link: `/job-search-visa/${createCountryUrl("Germany")}/`,
        },
        {
          title: "Gulf",
          link: `/job-search-visa/${createCountryUrl("Gulf")}/`,
        },
      ],
    },
    {
      title: "Resources",
      link: "/resources/",
      subItems: [
        {
          title: "Career",
          link: "/career/",
        },
        {
          title: "Blogs",
          link: "/blog/",
        },
        {
          title: "News",
          link: "/news/",
        },
      ],
    },
    {
      title: "Connect Us",
      link: "/connect-us/",
    },
  ];
  const aboutAchintya = {
    heading: "WHO WE ARE",
    description: `
    <p>Achintya Immigration is a leading immigration consultancy firm based in Central Delhi, India. Since its establishment in 2015, it has earned a stellar reputation as a beacon of excellence in the field of immigration consultancy. This company has played a pivotal role in guiding countless individuals on their path to realizing their dreams of living and working in foreign countries.</p>
    <p>
    Think of Achintya Immigration as a trusted friend and expert advisor for those embarking on the complex journey of immigration. Our services are carefully designed to provide knowledge and insights that empower our clients to make informed decisions with confidence. We understand that navigating the immigration process can be like solving a maze, and our goal is to simplify it.
    </p>
    <p>
    Our team of specialized immigration consultants is the driving force behind our success. They are like skilled artists with brushes in hand, meticulously unraveling the secrets of immigration regulations and requirements. Their expertise ensures that our clients have access to the most accurate and straightforward path to their desired destination.
    </p>
    <p>
    Achintya Immigration has become synonymous with Permanent Residency (PR) Visa consulting. Our track record in this field is exceptional, and we are often regarded as virtuoso's in PR Visa services. We take pride in orchestrating solutions that resonate deeply with our clients, turning their aspirations into reality. Our commitment goes beyond just providing services; we are dedicated to helping individuals achieve their cherished dreams of settling abroad.
    </p>
    <p>
    Over the years, Achintya Immigration has painted a canvas of unparalleled growth. We have expanded our offerings to provide a comprehensive range of immigration consultancy services that resonate not only with our local clientage but also with a global audience. Our hallmark is accuracy, and we strive to craft a masterpiece of success for each of our clients.
    </p>
    <p>
    Our journey has been characterized by unwavering dedication to excellence. It serves as the driving force that propels us forward, keeping us at the forefront of the immigration consultancy industry. Whether you're an individual looking to make a new start in a foreign land or a business seeking international talent, Achintya Immigration is your trusted partner on this transformative journey. We are committed to illuminating the path to your dreams and helping you navigate the intricacies of immigration with confidence and ease.
    </p>`,
  };

  const whyChooseAchintya = {
    heading: "WHY CHOOSE ACHINTYA IMMIGRATION?",
    content: [
      {
        subHeading: "99% Success Rate",
        description:
          "At Achintya Immigration, we take immense pride in our remarkable track record. We have achieved an astounding 99% success rate in helping individuals achieve their immigration goals. This means that almost every client we've assisted has successfully moved to their desired international destination. Your dreams of living abroad are in expert hands.",
      },
      {
        subHeading: "Expertise around the Corner",
        description:
          "Our team of immigration experts is always nearby, ready to provide you with their wealth of knowledge and experience. No matter where you are in India, our local experts are just around the corner. Their deep understanding of immigration laws, regulations, and processes ensures",
      },
      {
        subHeading: "Free Assessment",
        description:
          "To kickstart your journey, we offer a complimentary assessment service. This means you can take the first step toward your immigration dreams without any financial commitment. Our experts will assess your eligibility and provide you with valuable insights into your immigration options. It's our way of making sure you have the information you need to make informed decisions.",
      },
      {
        subHeading: "Knowledge and Accurate Information",
        description:
          "When it comes to immigration, knowledge is power. At Achintya Immigration, we provide you with accurate and up-to-date information. We stay abreast of the ever-evolving immigration landscape to ensure you have access to the most reliable and current information. This empowers you to make well-informed choices throughout your immigration journey.",
      },
      {
        subHeading: "Personalized Guidance:",
        description: `We understand that every individual's immigration journey is unique. That's why we offer personalized guidance customized to your specific circumstances and aspirations. Our experts work closely with you to create a specific roadmap that aligns with your goals. Whether you're seeking a Permanent Residency, a Job Search, or a Tourist Visa, our guidance is designed to address your unique needs.`,
      },
      {
        subHeading: "Credibility:",
        description: `At Achintya Immigration, credibility is the cornerstone of our services. We have earned the trust and confidence of our clients over the years through our unwavering commitment to honesty and integrity. Our credibility is built on a foundation of ethical practices, compliance with
        immigration laws, and a track record of successfully assisting countless individuals in achieving their immigration goals. When you choose Achintya Immigration, you are entrusting your dreams to a reputable and trustworthy partner.`,
      },
      {
        subHeading: "Transparency",
        description: `We understand the importance of transparency in the immigration process. It's our policy to keep our clients fully informed at every stage of their journey. We provide clear and honest information about the requirements, processes, costs, and timelines associated with your immigration application. You can count on us to be upfront and transparent, ensuring that there are no hidden surprises along the way. With Achintya Immigration, you'll have a clear view of your path to success.`,
      },
      {
        subHeading: "Quality",
        description: `Quality is the hallmark of our services. From the moment you engage with us, you'll experience a level of excellence that sets us apart. Our team of experienced immigration experts and lawyers is dedicated to delivering top-notch services. We meticulously review and prepare your application to meet the highest standards of quality. We leave no stone unturned in ensuring that your application stands out for its completeness and accuracy. Quality is not just a goal; it's our commitment to you.`,
      },
    ],
  };

  const enquiryCountries = [
    { label: "Ireland", value: "Ireland" },
    { label: "Japan", value: "Japan" },
    { label: "Australia", value: "Australia" },
    { label: "Bangladesh", value: "Bangladesh" },
    { label: "Canada", value: "Canada" },
    { label: "Dubai", value: "Dubai" },
    { label: "Germany", value: "Germany" },
    { label: "Malaysia", value: "Malaysia" },
    { label: "New Zealand", value: "New Zealand" },
    { label: "Sri Lanka", value: "Sri Lanka" },
    { label: "Schengen", value: "Schegan" },
    { label: "Singapore", value: "Singapore" },
    { label: "South Africa", value: "South Africa" },
    { label: "South Korea", value: "South Kora" },
    { label: "Turkey", value: "Turkey" },
    { label: "Thailand", value: "Thailand" },
    { label: "USA", value: "USA" },
    { label: "UAE", value: "UAE" },
    { label: "UK", value: "UK" },
    { label: "Vietnam", value: "Vietnam" },
    { label: "Malta", value: "Malta" },
    { label: "Poland", value: "POland" },
    { label: "Gulf", value: "Gulf" },
  ];

  const ourServices = {
    heading: "Our Services",
    services: [
      {
        service: "Canadian PR (Permanent Residency): ",
        description: `<ul>
        <li>Secure your pathway to live and work in Canada permanently.</li></ul>`,
      },
      {
        service: "Australian PR (Permanent Residency): ",
        description: `<ul>
        <li>Gain permanent residency in Australia for a vibrant lifestyle.</li></ul>`,
      },
      {
        service: "Provincial Nomination Program (PNP): ",
        description: `<ul>
        <li>Enhance your chances for Canadian PR through province-specific immigration options.
        </li></ul>`,
      },
      {
        service: `Tourist Visa:`,
        description: `<ul>
<li>We facilitate hassle-free tourist visa applications, helping you explore the world's wonders with ease.</li>
<li>Our streamlined process ensures you embark on unforgettable journeys as a tourist visa holder.</li></ul>`,
      },
      {
        service: "IELTS:",
        description: `<ul>
        <li>Our IELTS preparation courses enhance your language skills, boosting your chances of success in English proficiency exams.</li>
        <li>We equip you with the language proficiency needed for your immigration or academic pursuits.</li></ul>`,
      },
      {
        service: "Spouse Dependent Visa: ",
        description: `<ul>
        <li>Reunite your family abroad with a spouse-dependent visa.</li></ul>`,
      },
      {
        service: "Super Visa: ",
        description: `<ul>
        <li>Extend visits for parents or grandparents to Canada.</li></ul>`,
      },
      {
        service: "Business and Investment Visas: ",
        description: `<ul>
        <li>Explore foreign business opportunities through tailored visa programs.</li></ul>`,
      },
      {
        service: "LMIA (Labour Market Impact Assessment): ",
        description: `<ul>
        <li>Simplify Canadian work permit applications with LMIA expertise.</li></ul>`,
      },
      {
        service: "Job Search:",
        description: `<ul>
        <li>We assist in your job search, connecting you with opportunities that match your skills and career goals.</li>
        <li>Our job search services open doors to exciting employment prospects, paving the way for your professional journey.</li></ul>`,
      },
      {
        service: "Resume Marketing:",
        description: `<ul><li>Our resume marketing services showcase your skills and experience to potential employers, elevating your job prospects.</li>
        <li>We craft compelling resumes that make a lasting impression, positioning you for career success.</li></ul>`,
      },
    ],
  };

  return (
    <div>
      <Helmet>
        <link rel="canonical" href={canonicalURL} />
        <title>
          Achintya Immigration| Your Trusted Partner for Visa Services
        </title>
        <meta name="robots" content="index,follow" />
        <meta
          name="description"
          content="Achintya Immigration is a trusted immigration consultancy firm that provides personalized services to clients seeking to study, work, or settle abroad."
        />
        <meta
          name="keywords"
          content="immigration consultants near me, immigration consultancy firms, best immigration agents in india"
        />
      </Helmet>
      <Header item={navigationHeader} />
      <AboutBanner />
      <AboutAchintya
        aboutAchintya={aboutAchintya}
        enquiryCountries={enquiryCountries}
      />
      <WhyChooseAchintya
        whyChooseAchintya={whyChooseAchintya}
        enquiryCountries={enquiryCountries}
      />
      <div className="container_wrapper">
        <div className="monument-image">
          <img src={monumentImage} alt="monuments" />
        </div>
      </div>

      <Services services={ourServices} enquiryCountries={enquiryCountries} />

      <Footer />
      <WhatsApp />
    </div>
  );
};

export default AboutPage;

import React, { useState, useEffect } from 'react'
import './index.css'
import group1 from '../../assets/Asset 1 1.png'

const ImmigrationServices = (immigrationServices) => {
  // console.log("immigrationServices : ", immigrationServices.immigrationServices.section)
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex === immigrationServices.immigrationServices.section.length - 1 ? 0 : prevIndex + 1));
    }, 10000); // Adjust the interval duration (in milliseconds) as desired

    return () => clearInterval(interval);
  }, []);

  return (
    <div className='immigration-outer-container'>
      <div className='container_wrapper'>
        <h2 className='immigration-heading'>{immigrationServices?.immigrationServices?.heading}</h2>
        <div className='immigration-inner-container'>
          <p className='immigration-content'>{immigrationServices?.immigrationServices?.content}</p>
        </div>

        <div className='types-immigration-container'>
          <div className='types-immigration-inner-container'>
            {immigrationServices.immigrationServices.subpoints.map((types, index) => (
              <div key={index} className='immigration-types-box'>
                <img src={types?.imgUrl} alt='types of immigration' />
                <p className='types-immigration-content'>{types?.heading}</p>
              </div>
            ))}
          </div>
        </div>

        <div className='types-immigration-description-outer-container carousel'>
          <div className='carousel-container'     >
            {immigrationServices?.immigrationServices?.section.map((e, i) => (
              <div key={i}
                className={`carousel-item types-description-inner-container ${i === activeIndex ? 'active' : ''}`}
              >
                <h2 className='description-heading'>
                  {e?.sectionHeading}
                </h2>
                <p className='description-content'>
                  {e?.description}
                </p>
              </div>
            ))}
          </div>
          <div className="carousel-dots">
            {immigrationServices.immigrationServices.section.map((_, index) => (
              <span
                key={index}
                className={`dot ${index === activeIndex ? 'active' : ''}`}
                onClick={() => setActiveIndex(index)}
              />
            ))}
          </div>
        </div>

        <div className='immigrations-img-container'>
          <img src={group1} alt='immigration' />
        </div>
      </div>
    </div>
  )
}

export default ImmigrationServices
import React from "react";
import "./index.css";

const TouristBanner = ({ bannerContent }) => {
  return (
    <div className="tourist-visa-banner-outer-container">
      <div className="tourist-visa-banner-inner-container">
        <h2 className="tourist-visa-banner-heading">{bannerContent?.heading}</h2>
        <img src={bannerContent?.banner_image} alt="tourist visa banner image" />
      </div>
    </div>
  );
};

export default TouristBanner;

import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import Header from "../../commonComponents/header";
import WhyPrCountry from "../../pageComponent/prPageComponent/whyCountry";
import PrBenefits from "../../pageComponent/prPageComponent/benefits";
import PrEligibility from "../../pageComponent/prPageComponent/eligibility";
import PrDocuments from "../../pageComponent/prPageComponent/prDocuments";
import TouristVisaRequirement from "../../commonComponents/touristVisaRequirements";
import PrProcess from "../../pageComponent/prPageComponent/prProcess";
import Footer from "../../commonComponents/footer";
import TouristBanner from "../../pageComponent/touristVisaComponent/touristVisaBanner";
import germanyImage from "../../assets/touristVisa/germany.png";
import ukImage from "../../assets/jobSearch/UK.png";
import australiaImage from "../../assets/touristVisa/australia.png";
import gulfImage from "../../assets/jobSearch/gulf.png";
import schengen from "../../assets/touristVisa/schengan.png";
import singapore from "../../assets/jobSearch/singapore.png";
import maltaImage from "../../assets/jobSearch/malta.png";
import polandImage from "../../assets/touristVisa/poland.png";
import { Helmet } from "react-helmet";
import { createCountryUrl } from "../../Utilities/commonFunctions";
const JobSearch = () => {
  const { country } = useParams();
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const [selectedCountry, setSelectedCountry] = useState(country); // Initially selected country

  useEffect(() => {
    if (country) {
      setSelectedCountry(country);
    }
  }, [country]);

  const navigationHeader = [
    {
      title: "Home",
      link: `/`,
    },
    {
      title: "About Us",
      subItems: [
        {
          title: "About",
          link: `/about-us/`,
        },
        {
          title: "Reviews",
          link: ` /reviews/`,
        },
        {
          title: "Gallery",
          link: `/gallery/`,
        },
      ],
    },
    {
      title: "Canada",
      subItems: [
        {
          title: "Canada Express Entry",
          // link: `/${createCountryUrl("canada")}/express-entry/`,
          subDataItems: [
            {
              title: "Canada Express Entry",
              link: `/${createCountryUrl("canada")}/express-entry/`,
            },
            {
              title: "CRS Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/crs-calculator/`,
            },
            {
              title: "CRS Chart",
              link: `/${createCountryUrl("canada")}/express-entry/crs-chart/`,
            },
            {
              title: "67 Points",
              link: `/${createCountryUrl("canada")}/express-entry/67-points/`,
            },
            {
              title: "Latest Express Entry Draw",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/latest-express-entry-draw/`,
            },
            {
              title: "Benefits of the Program",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/benefits-of-the-program/`,
            },
            {
              title: "Occupation Demand",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/occupation-demand/`,
            },
            {
              title: "Provisional Nominee Program",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/provisional-nominee-program/`,
            },
            {
              title: "Federal Skilled Worker Program",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/fedral-skilled-worker-program/`,
            },
            {
              title: "Federal Skilled Trade Program",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/fedral-skilled-trade-program/`,
            },
            {
              title: "Canadian Experience Class",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/canadian-experience-class/`,
            },
            {
              title: "IELTS",
              link: `/${createCountryUrl("canada")}/express-entry/ielts/`,
            },
          ],
        },
        {
          title: "Provincial Nominee Program",
          link: `/${createCountryUrl("Canada")}/provincial-nominee-program/`,
        },
        {
          title: "Point Calculator",
          link: `/${createCountryUrl("Canada")}/point-calculator/`,
        },
        {
          title: "Find your NOC",
          link: `/${createCountryUrl("Canada")}/find-your-noc/`,
        },
        {
          title: "LMIA",
          link: `/${createCountryUrl("Canada")}/lmia/`,
        },
        {
          title: "Canada Spouse Dependent Visa",
          link: `/${createCountryUrl("Canada")}/spouse-dependent-visa/`,
        },
        {
          title: "Super Visa",
          link: `/${createCountryUrl("Canada")}/super-visa/`,
        },
        {
          title: "Quebec Skilled Worker Program",
          link: `/${createCountryUrl("Canada")}/quebec-skilled-worker-program/`,
        },
        {
          title: "Atlantic Immigration Program",
          link: `/${createCountryUrl("Canada")}/atlantic-immigration-program/`,
        },
        {
          title: "Startup Visa",
          link: `/${createCountryUrl("Canada")}/startup-visa/`,
        },
        {
          title: "Entrepreneur Visa",
          link: `/${createCountryUrl("Canada")}/entrepreneur-visa/`,
        },
      ],
    },
    {
      title: "PNP Program",
      link: "/pnp-program/",
      subItems: [
        {
          title: "Manitoba PNP Program ",
          link: `/pnp-program/${createCountryUrl("Manitoba PNP Program")}/`,
        },
        {
          title: "Yukon Nominee Program ",
          link: `/pnp-program/${createCountryUrl("Yukon Nominee Program")}/`,
        },
        {
          title: "Ontario PNP Program ",
          link: `/pnp-program/${createCountryUrl("Ontario PNP Program")}/`,
        },
        {
          title: " Nova Scotia PNP ",
          link: `/pnp-program/${createCountryUrl("Nova Scotia PNP")}/`,
        },
        {
          title: "Alberta PNP Program ",
          link: `/pnp-program/${createCountryUrl("Alberta PNP Program")}/`,
        },
        {
          title: "BC PNP Program ",
          link: `/pnp-program/${createCountryUrl("BC PNP Program")}/`,
        },
        {
          title: "Quebec Skilled Worker Program ",
          link: `/pnp-program/${createCountryUrl(
            "Quebec Skilled Worker Program"
          )}/`,
        },
        {
          title: "Saskatchewan PNP ",
          link: `/pnp-program/${createCountryUrl("Saskatchewan PNP ")}/`,
        },
        {
          title: "New Brunswick PNP Program ",
          link: `/pnp-program/${createCountryUrl(
            "New Brunswick PNP Program "
          )}/`,
        },
        {
          title: "Atlantic Immigration Pilot Program ",
          link: `/pnp-program/${createCountryUrl(
            "Atlantic Immigration Pilot Program "
          )}/`,
        },
        {
          title: "PEI PNP Program ",
          link: `/pnp-program/${createCountryUrl("PEI PNP Program ")}/`,
        },
        {
          title: "NLPNP Program ",
          link: `/pnp-program/${createCountryUrl("NLPNP Program")}/`,
        },
      ],
    },
    {
      title: "Point Calculator",
      link: "/point-calculator/",
    },
    {
      title: "Australia",
      subItems: [
        {
          title: "Australia Skilled Immigration Program",
          link: "/australia/australia-skilled-immigration-program/",
        },
        {
          title: "Spouse Dependent Visa",
          link: `/${createCountryUrl("Australia")}/spouse-dependent-visa/`,
        },
        {
          title: "Parent Visa",
          link: `/${createCountryUrl("Australia")}/parent-visa/`,
        },
        {
          title: "Investment Visa",
          link: `/${createCountryUrl("Australia")}/investment-visa/`,
        },
      ],
    },
    {
      title: "Travel Visa",
      link: "/travel-visa/",
      subItems: [
        {
          title: "Canada",
          link: `/travel-visa/${createCountryUrl("Canada")}/`,
        },
        {
          title: "Australia",
          link: `/travel-visa/${createCountryUrl("Australia")}/`,
        },
        {
          title: "USA",
          link: `/travel-visa/${createCountryUrl("USA")}/`,
        },
        {
          title: "UK",
          link: `/travel-visa/${createCountryUrl("UK")}/`,
        },
        {
          title: "New Zealand",
          link: `/travel-visa/${createCountryUrl("New Zealand")}/`,
        },
        {
          title: "Dubai",
          link: `/travel-visa/${createCountryUrl("Dubai")}/`,
        },
        {
          title: "Germany",
          link: `/travel-visa/${createCountryUrl("Germany")}/`,
        },
        {
          title: "Schengen",
          link: `/travel-visa/${createCountryUrl("Schegan")}/`,
        },
        {
          title: "Turkey",
          link: `/travel-visa/${createCountryUrl("Turkey")}/`,
        },
        {
          title: "Malaysia",
          link: `/travel-visa/${createCountryUrl("Malaysia")}/`,
        },
        {
          title: "Singapore",
          link: `/travel-visa/${createCountryUrl("Singapore")}/`,
        },

        {
          title: "Ireland",
          link: `/travel-visa/${createCountryUrl("Ireland")}/`,
        },
        {
          title: "Japan",
          link: `/travel-visa/${createCountryUrl("Japan")}/`,
        },

        {
          title: "Bangladesh",
          link: `/travel-visa/${createCountryUrl("Bangladesh")}/`,
        },

        {
          title: "Sri Lanka",
          link: `/travel-visa/${createCountryUrl("Sri Lanka")}/`,
        },
        {
          title: "South Africa",
          link: `/travel-visa/${createCountryUrl("South Africa")}/`,
        },
        {
          title: "South Korea",
          link: `/travel-visa/${createCountryUrl("South Korea")}/`,
        },

        {
          title: "Thailand",
          link: `/travel-visa/${createCountryUrl("Thailand")}/`,
        },

        {
          title: "UAE",
          link: `/travel-visa/${createCountryUrl("UAE")}/`,
        },

        {
          title: "Vietnam",
          link: `/travel-visa/${createCountryUrl("Vietnam")}/`,
        },
      ],
    },
    {
      title: "Job Search Program",
      subItems: [
        {
          title: "Malta",
          link: `/job-search-visa/${createCountryUrl("Malta")}/`,
        },
        {
          title: "Poland",
          link: `/job-search-visa/${createCountryUrl("Poland")}/`,
        },
        {
          title: "Schengen",
          link: `/job-search-visa/${createCountryUrl("Schengen")}/`,
        },
        {
          title: "Singapore",
          link: `/job-search-visa/${createCountryUrl("Singapore")}/`,
        },
        { title: "UAE", link: `/job-search-visa/${createCountryUrl("UAE")}/` },
        { title: "UK", link: `/job-search-visa/${createCountryUrl("UK")}/` },
        {
          title: "Australia",
          link: `/job-search-visa/${createCountryUrl("Australia")}/`,
        },
        {
          title: "Dubai",
          link: `/job-search-visa/${createCountryUrl("Dubai")}/`,
        },
        {
          title: "Germany",
          link: `/job-search-visa/${createCountryUrl("Germany")}/`,
        },
        {
          title: "Gulf",
          link: `/job-search-visa/${createCountryUrl("Gulf")}/`,
        },
      ],
    },
    {
      title: "Resources",
      link: "/resources/",
      subItems: [
        {
          title: "Career",
          link: "/career/",
        },
        {
          title: "Blogs",
          link: "/blog/",
        },
        {
          title: "News",
          link: "/news/",
        },
      ],
    },

    {
      title: "Pay Now",
      link: "/pay-now/",
    },
    {
      title: "Connect Us",
      link: "/connect-us/",
    },
  ];

  const countryIntro = {
    germany: {
      metaTitle: "Achintya Immigration: Your Germany Job Search Partner",
      metaDescription:
        "Unlock the doors to German job opportunities with Achintya Immigration. Tailored guidance and resources pave the way for your successful career journey in Germany",
      metaKeywords: `germany work visa for indian,	work visa for germany from india cost`,
      banner: {
        heading: "Search for better opportunities in Germany",
        banner_image: germanyImage,
      },
      introduction: {
        heading: "Introduction about Germany  ",
        content: `
            <p>
            Germany is a country in Central Europe, officially called the Federal Republic of Germany. It 
    has a diverse landscape, with hills, mountains, and rivers in the central and southern regions, 
    and flat plains in the north near the North Sea. Germany has a long and complex history, and 
    it was divided into East and West Germany during the Cold War. </p>
    <p>
    Germany is a powerful country with a strong economy, known for making and exporting 
    machinery, cars, chemicals, and technology. It has had a significant impact on the world's 
    culture, producing famous writers, musicians, philosophers, and scientists. </p>
    <p>The official language is German, and the government is a federal parliamentary republic. 
    Education is highly regarded, and Germany has many top universities and research 
    institutions. </p>
    <p>Tourism is popular in Germany, with many people visiting its historic cities, beautiful 
    landscapes, castles, and cultural sites. Germany is also an important member of the European 
    Union, influencing European politics and economics.</p>`,
      },
      benefits_for_tourist: {
        heading: "Benefits for Job Seekers while going to Germany ",
        description: "In Germany, job seekers can benefit from: ",
        pointers: [
          {
            point: `Help from job centers and employment agencies to find suitable jobs. `,
          },
          {
            point: `Unemployment benefits if they lose their job and meet certain criteria. `,
          },
          {
            point: `Training and education programs to improve skills and qualifications. `,
          },
          {
            point: `Support for foreign job seekers through integration courses and language classes. `,
          },
          {
            point: `Work permits for non-EU job seekers to work legally. `,
          },
          {
            point: "Temporary health insurance coverage during unemployment. ",
          },
          {
            point: `Financial support for families with children. `,
          },
          {
            point: `Job fairs and networking events to meet potential employers. `,
          },
          {
            point: `Recognition of foreign qualifications for easier job opportunities. `,
          },
          {
            point: `Employment support programs for specific groups like young job seekers or 
                    individuals with disabilities. `,
          },
        ],
      },
      eligibility_criteria: {
        heading: "Eligibility Criteria and Requirements ",
        description: "",
        pointers: [
          {
            point: `To get a Work Visa, you need to have the skills that are needed in the country where you want 
                to work. You must also have the right educational qualifications and any necessary 
                certifications for the job. Additionally, having relevant work experience is important. `,
          },
          {
            point: `In some countries, you might have to take a Language Proficiency Test, especially if the 
                official language is English. For example, you may need to take the IELTS test before 
                applying for a Work Visa. `,
          },
        ],
      },
      documents_required: {},
      process_for_visa_application: {
        heading: "",
      },
    },
    uk: {
      metaTitle:
        "Find Your Dream Job in the UK: Achintya Immigration's Guidance",
      metaDescription:
        "Navigate the competitive UK job market effectively with Achintya Immigration support. Uncover diverse roles, connect with employers, and elevate your career prospects",
      metaKeywords: `UK work visa for indians,	work visa for uk from india cost`,
      banner: {
        heading: "Search for better opportunities in UK",
        banner_image: ukImage,
      },
      introduction: {
        heading: "Introduction about the UK ",
        content: `
        <p>
        The UK, known as the United Kingdom, is a fascinating and diverse country in Europe. It is 
made up of four countries – England, Scotland, Wales, and Northern Ireland – each with its own 
unique history and culture. </p>
<p>
The UK has a long and influential history, with ancient castles and palaces, as well as modern 
cities with advanced technology. London, the capital city, is a bustling global hub with famous 
landmarks and top-notch museums. The countryside is filled with beautiful landscapes, charming 
villages, and historic towns. </p>
<p>
The UK is also known for its rich cultural heritage, including famous writers, theaters, and music 
bands. Whether you explore historic sites, enjoy art and culture, savor traditional food, or meet 
friendly locals, the UK offers an unforgettable journey full of charm and excitement. </p>`,
      },
      benefits_for_tourist: {
        heading: "Benefits for Job seekers ",
        description: "Job seekers going to the UK can enjoy these benefits: ",
        pointers: [
          {
            point: `Many job opportunities in various industries. `,
          },
          {
            point: `A strong and stable economy for career growth. `,
          },
          {
            point: `Experiencing and learning from diverse cultures. `,
          },
          {
            point: `Access to top-notch education and research institutions. `,
          },
          {
            point: `Free or low-cost healthcare through the National Health Service (NHS). `,
          },
          {
            point: `Supportive social welfare system during unemployment or financial need. `,
          },
          {
            point: `Work-life balance with flexible hours and leave benefits. `,
          },
          {
            point: `Training programs to improve skills and advance careers. `,
          },
          {
            point: `Networking chances in cosmopolitan cities. `,
          },
          {
            point: `An English-speaking advantage for communication and integration. `,
          },
          {
            point: `Access to EU markets despite leaving the European Union. `,
          },
          {
            point: `Immersing in British culture and history for personal and professional growth. `,
          },
          {
            point: `Job seekers should research visa requirements, job prospects, and living conditions for 
            informed decisions. `,
          },
        ],
      },
      eligibility_criteria: {
        heading: " Eligibility Criteria ",
        description:
          "To qualify for a UK work visa, you need to meet these criteria: ",
        pointers: [
          {
            point: `Score 70 points based on specific skills, qualifications, salaries, and professions. `,
          },
          {
            point: `Have at least a Bachelor's degree and 2 years of skilled work experience from the eligible 
                occupations list. `,
          },
          {
            point: `The job offer must be at the required skill level (RQF 3 or above). `,
          },
          {
            point: `Meet the English Language requirement at B1 level. `,
          },
          {
            point: `Meet the general salary threshold of £26,200 or the specific salary requirement for the 
                occupation. `,
          },
          {
            point: `These criteria determine your eligibility for the UK work visa. `,
          },
        ],
      },
      process_for_visa_application: {
        heading: "",
      },
    },
    australia: {
      metaTitle: "Achintya Immigration: Your Pathway to Australian Job success",
      metaDescription:
        "Achintya Immigration: Your partner for Australian job discovery. Our vast network and insights streamline your search, maximizing your chances to get perfect job.",
      metaKeywords: `australia work visa requirements for indian citizens,	australia work visa processing time for indian`,
      banner: {
        heading: "Search for better opportunities in Australia",
        banner_image: australiaImage,
      },
      introduction: {
        heading: "Introduction about Australia",
        content: `<p>
            Picture a country with endless wonders waiting to be explored. Meet its unique wildlife, like 
kangaroos, koalas, and the quirky platypus. Dive into the world-renowned Great Barrier Reef, 
a magical marine wonderland. But that's not all, Australia's landscapes are a feast for the 
eyes! From stunning beaches and lush rainforests to sprawling deserts and the rugged 
Outback, it's a paradise for nature lovers.</p>
<p>
Step into the rich tapestry of indigenous culture, where the Aboriginal and Torres Strait 
Islander peoples have woven their art, traditions, and history into the nation's heart.
</p>
<p>
Australia is a colorful melting pot! Experience its vibrant multicultural society, where people 
from all corners of the globe come together, creating a harmonious symphony of cultures. 
</p>
<p>
Education here is top-notch too! Discover the world-class education system that lures 
students from far and wide.
</p>
<p>
So, dive into the wonders of Australia, where breathtaking beauty, unique wildlife, and a rich 
cultural blend create a truly unforgettable experience! 
</p>`,
      },
      benefits_for_tourist: {
        heading: "Benefits For Job Seekers ",
        description: "",
        pointers: [
          {
            point: `Stable economy with plenty of job opportunities (around 800,000). `,
          },
          {
            point: `Earn in Australian Dollars (AUD). `,
          },
          {
            point: `Opportunity to apply for permanent residency (PR). `,
          },
          {
            point: `Access to healthcare benefits. `,
          },
          {
            point: `Retirement benefits are provided. `,
          },
        ],
      },
      eligibility_criteria: {
        heading: "Eligibility Criteria  ",
        description: `The General Skilled Migration program (Skillselect) was introduced by the Australian 
        government in 2013 to replace skilled worker visas. It uses a point-based system to select 
        immigrants with the right skills. You earn points based on: `,
        pointers: [
          {
            point: `Age: Between 25 and 32 get the most points, while over 45 get no points. `,
          },
          {
            point: `English language proficiency: IELTS score of 8 bands or more gives 20 points.`,
          },
          {
            point: `Skilled employment: Experience in listed occupations gets points, with a maximum of 
            20. `,
          },
          {
            point: `Educational qualification: Doctorate earns 20 points, while bachelor's or master's 
            degrees get 15. `,
          },
          {
            point: `Community language skills: 5 points if you have translator/interpreter skills in an 
            Australian community language. `,
          },
          {
            point: `Spouse/partner skills and qualifications: 5 points if your partner's skills meet the 
            requirements. `,
          },
          {
            point: `Minimum of 65 points is needed to qualify for the General Skilled Migrant Program 
            visa. s`,
          },
        ],
      },
      process_for_visa_application: {
        heading: "",
      },
    },
    gulf: {
      metaTitle: "Achintya Immigration: Gulf Job Openings ",
      metaDescription:
        "Let Achintya Immigration be your compass in the Gulf job market. Our tailored approach ensures that you're not just another applicant but a standout candidate.",
      metaKeywords: `gulf work visa for indian, 	work visa for gulf countries`,
      banner: {
        heading: "Search for better opportunities in Gulf",
        banner_image: gulfImage,
      },
      introduction: {
        heading: "Introduction about Gulf ",
        content: `<p>
            Dubai is a dazzling city and emirate on the Arabian Peninsula! Prepare to be amazed by its 
    modernity, luxury, and architectural marvels. When the sun sets, Dubai comes alive with a 
    vibrant nightlife, offering upscale bars, clubs, and entertainment galore. Dubai has it all for 
    tourists!  </p>
    <p>
    Experience the perfect mix of modernity, luxury, and cultural diversity that will make your 
    trip truly special. From breathtaking landmarks to incredible entertainment and 
    mouthwatering cuisine, Dubai promises an unforgettable vacation filled with cherished 
    memories. It's a dream destination that will leave you in awe and wanting to come back for 
    more!</p>
    <p>
    Get ready for thrills and excitement at theme parks like Dubai Parks and Resorts and IMG 
    Worlds of Adventure.</p>
    <p>
    Indulge in luxury hospitality, as Dubai's hotels and resorts pamper you with top-notch service 
    and comfort.</p>
    <p>
    Explore a melting pot of cultures, as Dubai welcomes people from all over the globe, making 
    it a diverse and multicultural city. </p>
    <p>
    Dubai's unique blend of modern marvels, luxury, and cultural wonders attracts countless 
    tourists year after year, making it a dream destination for adventurers and luxury seekers 
    alike. </p>`,
      },
      benefits_for_tourist: {
        heading: "Benefits for Job Seekers ",
        description: "Job seekers going to the UAE enjoy many benefits: ",
        pointers: [
          {
            point: `Thriving job market in various sectors like finance, hospitality, and healthcare. `,
          },
          {
            point: `No personal income tax, so they keep more of their earnings. `,
          },
          {
            point: `Attractive salaries and benefits. `,
          },
          {
            point: `Experience diverse cultures with a large expatriate community. `,
          },
          {
            point: `Lots of opportunities for career growth and working on innovative projects. `,
          },
          {
            point: `Modern infrastructure and high-quality healthcare. `,
          },
          {
            point: `Safe and secure environment with a low crime rate. `,
          },
          {
            point: `Work-life balance with flexible hours and leave benefits. `,
          },
          {
            point: `Networking opportunities in the global business hub. `,
          },
          {
            point: `A chance to experience Arabian culture and vibrant social life. `,
          },
          {
            point: `Supportive expat community for easy settling and adapting. `,
          },
          {
            point: `Job seekers should check visa requirements and labor laws before pursuing 
            opportunities in the UAE. 
            `,
          },
        ],
      },
      process_for_visa_application: {
        heading: "",
      },
      eligibility_criteria: {
        heading: "Eligibility Criteria",
        description: "To work in the UAE as a skilled worker: ",
        pointers: [
          {
            point: `You need to be a graduate with 2-3+ years of work experience. `,
          },
          {
            point: `For unskilled jobs, you need trade qualifications. `,
          },
          {
            point: `You must have a job offer from a local employer. `,
          },
          {
            point: `Meet the medical requirements. `,
          },
        ],
      },
    },
    malta: {
      metaTitle: "Find Your Dream Job in Malta: Expert Guidance by Achintya ",
      metaDescription:
        "Achintya Immigration: Your gateway to Malta job exploration. Connect with employer's, apply confidently, and secure your future on this vibrant island.",
      metaKeywords: "malta job vacancy for indian	,malta work visa apply online",
      banner: {
        heading: " Search for better opportunities in Malta",
        banner_image: maltaImage,
      },
      introduction: {
        heading: "Introduction about Malta ",
        content: `<p>
            Malta is a charming and beautiful island nation in the Mediterranean Sea. It has a long history 
with ancient temples, castles, and stunning architecture. Even though it's small, Malta offers 
many things to do, like exploring old cities and relaxing on its lovely beaches. The people are 
friendly and welcoming, and you can experience their lively culture through festivals and 
delicious food.  </p>
<p>
For history lovers, there are ancient temples and a fortified city to explore. Whether you want to 
learn about history or just enjoy the relaxing Mediterranean atmosphere, Malta has something 
special for everyone. </p>`,
      },
      benefits_for_tourist: {
        heading: "Benefits for Job seekers ",
        description: "Working in Malta has many benefits for job seekers: ",
        pointers: [
          {
            point: `Growing Job Market: Many job opportunities in different industries like finance, gaming, 
                and technology.`,
          },
          {
            point: `English Language: English is widely spoken, making communication easy for English 
                speakers. `,
          },
          {
            point: `Multicultural Environment: A diverse workforce from around the world creates an 
            inclusive workplace. `,
          },
          {
            point: `Tax Incentives: Attractive tax benefits for certain job sectors and skilled workers. `,
          },
          {
            point: `Good Work-Life Balance: Pleasant climate and beautiful surroundings contribute to a 
            balanced lifestyle. `,
          },
          {
            point: `Competitive Salaries: Many industries offer competitive pay and benefits. `,
          },
          {
            point: `Career Growth: Plenty of opportunities for professional advancement. `,
          },
          {
            point: `EU Membership: Access to job opportunities across the European Union. `,
          },
          {
            point: `Safety and Security: Malta is a safe country to live and work in. `,
          },
          {
            point: `High Quality of Life: Excellent healthcare, education, and leisure facilities enhance the 
            overall living experience. `,
          },
        ],
      },
      documents_required: {
        heading: "Documents required for Job search Malta Visa Application ",
        description:
          "To apply for a Malta Job Search, you need to submit these documents: ",
        pointers: [
          {
            point: `A valid passport. `,
          },
          {
            point: `Travel medical insurance covers the entire Schengen area. `,
          },
          {
            point: `Proof of your accommodations in Malta for your stay. `,
          },
          {
            point: `Evidence that you have enough money to support yourself during your time in Malta. `,
          },
          {
            point: `Relevant work experience. `,
          },
          {
            point: `An updated resume. `,
          },
          {
            point: `You should currently be employed. `,
          },
          {
            point: `Certification of full COVID-19 vaccination. `,
          },
        ],
      },
      process_for_visa_application: {
        heading: "The application process for Malta Job Search ",
        description: "",
        pointers: [
          {
            stepHeading: "",
            point: `Submit all required documents to the employer in Malta for review. `,
          },
          {
            stepHeading: "",
            point: ` If needed, have a Skype interview. `,
          },
          {
            stepHeading: ``,
            point: `If selected, the employer will offer you the job. `,
          },
          {
            stepHeading: "",
            point: `Accept the job offer and provide additional documents to the employer. `,
          },
          {
            stepHeading: "",
            point: `The employer will apply for your Work Permit in Malta.  `,
          },
          {
            stepHeading: ``,
            point: `Once approved, you will receive a Letter of Approval that is valid for 180 days. `,
          },
        ],
      },
      eligibility_criteria: {},
    },
    poland: {
      metaTitle: "Poland Job Search Made Easy: Trust Achintya for Success",
      metaDescription:
        "Embark on a successful Poland job search with Achintya's Immigration. Access a range of opportunities and expert guidance for your Polish career journey.",
      metaKeywords:
        "poland work visa apply online,	poland work visa requirements for indian",
      banner: {
        heading: "Search for better opportunities in Poland",
        banner_image: polandImage,
      },
      introduction: {
        heading: "Introduction about Poland ",
        content: `
            <p>
            Poland, a captivating country in the heart of Europe, has a rich history and beautiful landscapes. 
From ancient castles and cities to picturesque countryside and lakes, Poland offers diverse 
experiences for travelers. The Polish people are warm and welcoming, and their vibrant culture 
shines through colorful festivals, music, and delicious food. </p>
<p>
Throughout its history, Poland has been influenced by various cultures, as seen in its 
architecture, art, and language. Its central location in Europe makes it an exciting destination for 
both tourists and businesses. </p>
<p>
Whether exploring historical sites, enjoying tasty cuisine, or joining lively festivals, Poland 
promises an unforgettable journey for all. Experience the beauty and spirit of Poland as you 
embark on an enchanting adventure through this captivating European gem. </p>`,
      },
      benefits_for_tourist: {
        heading: "Benefits for Job seekers ",
        description: `When considering a job in Poland, it's essential to know the work benefits you'll receive: `,
        pointers: [
          {
            point: `Working hours: You'll work 40 hours a week and 8 hours a day. Overtime is limited. `,
          },
          {
            point: `Paid time off: You get 20 days of annual leave if employed for less than 10 years, and 26 
                days if employed for 10 years or more. `,
          },
          {
            point: `Social Security benefits: You'll contribute to the social security system, which covers 
                healthcare and other benefits. `,
          },
          {
            point: `Healthcare: Public healthcare is free for workers and their families. Employers often offer 
                private health insurance too. 
                `,
          },
          {
            point: `Sick leave: You'll get paid sick leave for up to 33 days a year. `,
          },
          {
            point: `Life insurance: Some companies offer life insurance for a set time. `,
          },
          {
            point: `Maternity and parental leave: Women get 20 weeks of maternity leave, and both parents 
            can take 32 weeks of parental leave. `,
          },
          {
            point: `Other benefits: Poland's central location in Europe allows easy travel. The standard of 
            living is high, and English is widely spoken. There are diverse job opportunities, 
            especially in the IT industry. Pension and social insurance are mandatory benefits in 
            Poland. 
            `,
          },
        ],
      },
      documents_required: {
        heading: "Documents required for Job search Poland Visa Application ",
        description:
          "To apply for a work permit, you will need to provide the following documents: ",
        pointers: [
          {
            point: `A scanned copy of your valid passport, including all pages. The Ministry of Labor 
                requires this. `,
          },
          {
            point: `If you have a high school diploma, provide a scanned copy. `,
          },
          {
            point: `Submit a complete CV or resume with your current address. `,
          },
          {
            point: `Provide details of your father and mother's names or your birth certificate. `,
          },
        ],
      },
      process_for_visa_application: {
        heading: "To apply for a work permit in Poland, follow these steps: ",
        pointers: [
          {
            stepHeading: "",
            point: `The employer conducts a labor market test to check if any Polish or EU citizens are 
                qualified for the job. If not, they can apply for a work permit for you. `,
          },
          {
            stepHeading: "",
            point: `The employer submits the application with documents proving that employment 
                conditions and wages meet the regulations. `,
          },
          {
            stepHeading: ``,
            point: `The work permit is issued by the local government and is valid for the duration of your 
            employment contract with the employer who applied for it. `,
          },
        ],
      },
      eligibility_criteria: {},
    },
    schengen: {
      metaTitle:
        "Secure Schengen Job Hunt: Achintya Immigration Expert Support",
      metaDescription:
        "Navigate the complexities of easy Schengen job hunting with Achintya Immigration. Discover diverse roles, connect with employers, and secure your dream job.",
      metaKeywords:
        "schengen work visa apply online,	schengen work permit visa from india",
      banner: {
        heading: "Search for better opportunities in Schengen",
        banner_image: schengen,
      },
      introduction: {
        heading: "Introduction about Schengen",
        content: `<p>
        The Schengen Area is a group of European countries that allow border-free travel. It means 
people can move freely between these countries without passport checks at their shared borders. 
There are 26 countries in the Schengen Area, and 22 of them are part of the European Union 
(EU). While there are no border controls inside the Schengen Area, they have strict security 
measures at their external borders. </p>
<p>
If you want to visit multiple Schengen countries, you can apply for a Schengen visa. This visa 
allows you to travel within the Schengen Area for a specific time. Each country may have its 
requirements for the visa application. </p>
<p>
Overall, the Schengen Area promotes unity and cooperation among European countries and 
makes traveling easier for millions of people</p>`,
      },
      benefits_for_tourist: {
        heading: `Benefits for Job seekers `,
        description: "Job seekers in the Schengen Area enjoy many benefits: ",
        pointers: [
          {
            point:
              "<b>Travel Freedom:</b> They can move easily between member countries without extra visas. ",
          },
          {
            point: `<b>Job Diversity:</b> A wide range of industries and jobs are available in the 26 member 
            countries. `,
          },
          {
            point: `<b>One Work Permit:</b> With a work permit in one country, they can work in others too. `,
          },
          {
            point: `<b>Cultural Exposure:</b> Experience diverse cultures and languages, broadening their 
            perspectives. `,
          },
          {
            point: `<b>Convenient Travel: </b>The Schengen visa allows easy travel for interviews and networking. `,
          },
          {
            point: `<b>Worker Protection: </b>Strong labor laws ensure fair treatment and good working conditions. `,
          },
          {
            point: `<b>High Quality of Life: </b>Some countries offer excellent living standards, healthcare, and 
            education. `,
          },
          {
            point: `<b>Career Growth:</b> Opportunities to work for multinational companies and advance their 
            careers. `,
          },
          {
            point: `<b>Language Learning: </b>Chance to learn new languages and improve employability. `,
          },
          {
            point: `<b>International Experience:</b> Working in Schengen boosts their resume and appeal to global 
            employers. `,
          },
        ],
      },
      documents_required: {
        heading: "Documents required for Job search Schengen Visa Application ",
        description: `To get an employment visa in Schengen, you typically need: `,
        pointers: [
          {
            point: `Completed application form (2 copies) `,
          },
          {
            point: `Two identical photos `,
          },
          {
            point: `Valid passport `,
          },
          {
            point: `Travel and medical insurance `,
          },
          {
            point: `Academic or professional qualifications `,
          },
          {
            point: `Language proficiency proof (in some cases) `,
          },
        ],
      },
      process_for_visa_application: {
        heading: "",
      },
      eligibility_criteria: {},
    },
    singapore: {
      metaTitle: "Find Your Perfect Job in Singapore with Achintya's Expertise",
      metaDescription:
        "Embark on a successful career journey in Singapore with Achintya Immigration. Navigate the competitive job market with our expert guidance and secure your dream job",
      metaKeywords:
        "singapore work visa for indians	,singapore work visa price for indian",
      banner: {
        heading: "Search for better opportunities in Singapore",
        banner_image: singapore,
      },
      introduction: {
        heading: "Introduction about Singapore ",
        content: `
        <p>
        Singapore is a small and modern city in Southeast Asia. It's a dynamic country known for its 
success in business and diverse culture. The city has impressive skyscrapers and famous 
landmarks like Marina Bay Sands. Singapore is a mix of different cultures, including Chinese, 
Malay, and Indian. </p>
<p>Visitors can enjoy beautiful parks and gardens in a clean and green environment. There are many 
hawker centers and restaurants with delicious local and international food. Tourists can explore 
historic neighborhoods like Chinatown and Little India, and go shopping on Orchard Road. 
Singapore is also committed to innovation and protecting the environment, with advanced 
buildings and an efficient transportation system. </p>
<p>Overall, it's a welcoming destination for travelers looking to experience a unique blend of 
tradition and modernity in a vibrant city. </p>`,
      },
      benefits_for_tourist: {
        heading: "Benefits for Job seekers ",
        description: `Abundant Job Opportunities: Singapore's thriving economy offers plenty of job options for 
        job seekers with different skills and qualifications. `,
        pointers: [
          {
            point: `<b>Global Business Opportunities:</b> As a major global business hub, Singapore attracts 
            multinational companies, providing job seekers with prestigious career prospects. `,
          },
          {
            point: `<b>Diverse and Inclusive Workplace:</b> Singapore's multicultural environment welcomes 
            expatriates, offering a diverse and inclusive work atmosphere. `,
          },
          {
            point: `<b>Competitive Salaries: </b>Many industries in Singapore offer attractive salaries and benefits to 
            attract and retain skilled professionals. `,
          },
          {
            point: `<b>Entrepreneurial Support:</b> Singapore's pro-business policies and government initiatives 
            encourage entrepreneurship, benefiting job seekers with an entrepreneurial spirit. `,
          },
          {
            point: `<b>Quality of Life:</b> Singapore provides a high standard of living, excellent healthcare, education, 
            and modern amenities, ensuring a comfortable lifestyle for job seekers and their families.`,
          },
          {
            point: `<b>Safety and Stability: </b>With low crime rates and political stability, Singapore offers a safe and 
            secure environment for job seekers. `,
          },
          {
            point: `<b>Career Advancement: </b>Singapore's dynamic economy and focus on talent development offer 
            opportunities for job seekers to grow in their careers. `,
          },
        ],
      },
      eligibility_criteria: {},
      process_for_visa_application: {
        heading: "",
      },
    },
  };
  const canonicalURL = ` https://achintyaconsultant.com/job-search/${country}/`;
  return (
    <div>
      <Helmet>
        <link rel="canonical" href={canonicalURL} />
        <title>{countryIntro[selectedCountry]?.metaTitle}</title>
        <meta name="robots" content="index,follow" />
        <meta
          name="description"
          content={countryIntro[selectedCountry]?.metaDescription}
        />
        <meta
          name="keywords"
          content={countryIntro[selectedCountry]?.metaKeywords}
        />
      </Helmet>

      <Header item={navigationHeader} />

      <TouristBanner
        bannerContent={countryIntro[selectedCountry]?.banner}
        country={country}
      />

      <WhyPrCountry
        whyPRCountry={countryIntro[selectedCountry]?.introduction}
        country={country}
      />
      <PrBenefits
        prBenefits={countryIntro[selectedCountry]?.benefits_for_tourist}
        country={country}
      />
      <PrEligibility
        eligibilityData={countryIntro[selectedCountry]?.eligibility_criteria}
        country={country}
      />
      {/*  <PrDocuments
        documentsData={countryIntro[selectedCountry]?.documents_required}
        country={country}
      />
      <TouristVisaRequirement
        touristRequirement={
          countryIntro[selectedCountry]?.tourist_visa_requirements
        }
      />*/}
      <PrProcess
        prProcess={countryIntro[selectedCountry]?.process_for_visa_application}
        country={country}
      />
      <Footer />
    </div>
  );
};

export default JobSearch;

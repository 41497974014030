import React, { useState, useEffect } from "react";
import "./index.css";
import EnquiryComponent from "../../commonComponents/enquiryComponent";

const Services = ({ services, enquiryCountries }) => {
  // console.log("services : ", services);
  const [currentPageURL, setCurrentPageURL] = useState("");
  const [isFormOpen, setIsFormOpen] = useState(false);

  const handleOpenForm = () => {
    setIsFormOpen(true);
  };

  const handleCloseForm = () => {
    setIsFormOpen(false);
  };

  useEffect(() => {
    // Get the current page URL when the component mounts
    setCurrentPageURL(window.location.pathname);
    // console.log("currentPageURL : ",window.location.pathname)

    // Optional: You can also update the URL whenever it changes using the "hashchange" event
    const handleURLChange = () => {
      setCurrentPageURL(window.location.pathname);
    };

    window.addEventListener("hashchange", handleURLChange);
    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("hashchange", handleURLChange);
    };
  }, []);
  return (
    <div>
      <div className="service-outer-container">
      <h2 className="services-heading">{services.heading}</h2>
        <div className="container_wrapper">
          <div className="service-inner-container">
            <div className="service-left-container">
              <img
                src="https://achintyaconsultant.s3.ap-south-1.amazonaws.com/OBJECTS.png"
                alt="achintya logo"
              />
              <button onClick={handleOpenForm} className="why-enquiry-btn">
                Enquiry
              </button>
              <EnquiryComponent
                enquiryCountries={enquiryCountries}
                isOpen={isFormOpen}
                onClose={handleCloseForm}
              />
            </div>
            <div className="service-vertical-line"></div>
            <div className="service-right-container">
              {services?.services?.map((e, i) => (
                <div key={i}>
                  <p className="type-service-name">{e.service}</p>
                  <p
                    className="type-service-description"
                    dangerouslySetInnerHTML={{ __html: e?.description }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;

import React, { useEffect } from "react";
import Header from "../../commonComponents/header";
import Footer from "../../commonComponents/footer";
import { createCountryUrl } from "../../Utilities/commonFunctions";
import CandaPageComponents from "../../canadExpressPnpPage";
import AbroadForm from "../../commonComponents/abroadForm";
import "./index.css";
import CanadaPNPProcess from "../../canadExpressPnpPage/canadaProcessPNP";
import CanadaExpressPnpBanner from "../../canadExpressPnpPage/canadaExpressPnpBanner";
import { Helmet } from "react-helmet";
import { Link, useLocation } from "react-router-dom";

const CanadaExpressPNP = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  const navigationHeader = [
    {
      title: "Home",
      link: `/`,
    },
    {
      title: "About Us",
      subItems: [
        {
          title: "About",
          link: `/about-us/`,
        },
        {
          title: "Reviews",
          link: ` /reviews/`,
        },
        {
          title: "Gallery",
          link: `/gallery/`,
        },
      ],
    },
    {
      title: "Canada",
      subItems: [
        {
          title: "Canada Express Entry",
          // link: `/${createCountryUrl("canada")}/express-entry/`,
          subDataItems: [
            {
              title: "Canada Express Entry",
              link: `/${createCountryUrl("canada")}/express-entry/`,
            },
            {
              title: "CRS Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/crs-calculator/`,
            },
            {
              title: "CRS Chart",
              link: `/${createCountryUrl("canada")}/express-entry/crs-chart/`,
            },
            {
              title: "67 Points Calculator and charts",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/${createCountryUrl(
                "67 Points Calculator and charts"
              )}`,
            },
            {
              title: "Latest Express Entry Draw",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/latest-express-entry-draw/`,
            },
            {
              title: "Benefits of the Program",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/benefits-of-the-program/`,
            },
            {
              title: "Occupation Demand",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/occupation-demand/`,
            },
            {
              title: "Provisional Nominee Program",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/provisional-nominee-program/`,
            },
            {
              title: "Federal Skilled Worker Program",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/fedral-skilled-worker-program/`,
            },
            {
              title: "Federal Skilled Trade Program",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/fedral-skilled-trade-program/`,
            },
            {
              title: "Canadian Experience Class",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/canadian-experience-class/`,
            },
            {
              title: "Super Visa From India",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/super-visa-from-india/`,
            },
            {
              title: "IELTS",
              link: `/${createCountryUrl("canada")}/express-entry/ielts/`,
            },
          ],
        },
        {
          title: "Provincial Nominee Program",
          subDataItems: [
            {
              title: "Manitoba PNP Program ",
              link: `/${createCountryUrl(
                "canada"
              )}/pnp-program/${createCountryUrl("Manitoba PNP Program")}/`,
            },
            {
              title: "Yukon Nominee Program ",
              link: `/${createCountryUrl(
                "canada"
              )}/pnp-program/${createCountryUrl("Yukon Nominee Program")}/`,
            },
            {
              title: "Ontario PNP Program ",
              link: `/${createCountryUrl(
                "canada"
              )}/pnp-program/${createCountryUrl("Ontario PNP Program")}/`,
            },
            {
              title: " Nova Scotia PNP ",
              link: `/${createCountryUrl(
                "canada"
              )}/pnp-program/${createCountryUrl("Nova Scotia PNP")}/`,
            },
            {
              title: "Alberta PNP Program ",
              link: `/${createCountryUrl(
                "canada"
              )}/pnp-program/${createCountryUrl("Alberta PNP Program")}/`,
            },
            {
              title: "BC PNP Program ",
              link: `/${createCountryUrl(
                "canada"
              )}/pnp-program/${createCountryUrl("BC PNP Program")}/`,
            },
            {
              title: "Quebec Skilled Worker Program ",
              link: `/pnp-program/${createCountryUrl(
                "Quebec Skilled Worker Program"
              )}/`,
            },
            {
              title: "Saskatchewan PNP ",
              link: `/pnp-program/${createCountryUrl("Saskatchewan PNP ")}/`,
            },
            {
              title: "New Brunswick PNP Program ",
              link: `/pnp-program/${createCountryUrl(
                "New Brunswick PNP Program "
              )}/`,
            },
            {
              title: "Atlantic Immigration Pilot Program ",
              link: `/pnp-program/${createCountryUrl(
                "Atlantic Immigration Pilot Program "
              )}/`,
            },
            {
              title: "PEI PNP Program ",
              link: `/pnp-program/${createCountryUrl("PEI PNP Program ")}/`,
            },
            {
              title: "NLPNP Program ",
              link: `/pnp-program/${createCountryUrl("NLPNP Program")}/`,
            },
          ],
        },
        {
          title: "Point Calculator",
          subDataItems: [
            {
              title: "CRS Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl("CRS Calculator")}/`,
            },
            {
              title: "Saskatchewan Points Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl(
                "Saskatchewan Points Calculator"
              )}`,
            },
            {
              title: "Canada 67 points Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl(
                "Canada 67 points Calculator"
              )}`,
            },
            {
              title: "British columbia Points Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl(
                "British columbia Points Calculator"
              )}`,
            },
            {
              title: "Manitoba Points Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl(
                "Manitoba Points Calculator"
              )}`,
            },
            {
              title: "Nova Scotia Points Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl(
                "Nova Scotia Points Calculator"
              )}`,
            },
            {
              title: "Alberta Points Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl(
                "Alberta Points Calculator"
              )}`,
            },
            {
              title: "Ontario Points Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl(
                "Ontario Points Calculator"
              )}`,
            },
            {
              title: " Quebec Points Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl(
                " Quebec Points Calculator"
              )}`,
            },
          ],
        },
        {
          title: "LMIA",
          link: `/${createCountryUrl("Canada")}/lmia/`,
        },
        {
          title: "Canada Spouse Dependent Visa",
          link: `/${createCountryUrl("Canada")}/spouse-dependent-visa/`,
        },
        {
          title: "Super Visa",
          link: `/${createCountryUrl("Canada")}/super-visa/`,
        },
        {
          title: "Quebec Skilled Worker Program",
          link: `/${createCountryUrl("Canada")}/quebec-skilled-worker-program/`,
        },
        {
          title: "Atlantic Immigration Program",
          link: `/${createCountryUrl("Canada")}/atlantic-immigration-program/`,
        },
        {
          title: "Startup Visa",
          link: `/${createCountryUrl("Canada")}/startup-visa/`,
        },
        {
          title: "Entrepreneur Investment Visa",
          link: `/${createCountryUrl("Canada")}/entrepreneur-investment-visa/`,
        },
      ],
    },
    {
      title: "Australia",
      subItems: [
        {
          title: "Australia Skilled Occupation List",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Australia Skilled Occupation List"
          )}`,
        },
        {
          title: "Australia 65 Points Calculator",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Australia 65 Points Calculator"
          )}`,
        },
        {
          title: "Employer Nomination Subclass 186",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Employer Nomination Subclass 186"
          )}`,
        },
        {
          title: "Skilled Independent Subclass 189",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Skilled Independent Subclass 189"
          )}`,
        },
        {
          title: "Skilled Nominated Subclass 190",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Skilled Nominated Subclass 190"
          )}`,
        },
        {
          title: "Australia Subclass 491",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Australia Subclass 491"
          )}`,
        },
        {
          title: "Australia PR Visa From India",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Australia PR Visa From India"
          )}`,
        },
        {
          title: "Australia Spouse Visa",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "AAustralia Spouse Visa"
          )}`,
        },
        {
          title: "Invitation To Apply",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Invitation To Apply"
          )}`,
        },
        {
          title: "Australia Tourist Visa From India",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Australia Tourist Visa From India"
          )}`,
        },
        {
          title: "Australia Subclass 476",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Australia Subclass 476"
          )}`,
        },
      ],
    },
    {
      title: "Travel Visa",
      link: "/travel-visa/",
      subItems: [
        {
          title: "Canada",
          link: `/travel-visa/${createCountryUrl("Canada")}/`,
        },
        {
          title: "Australia",
          link: `/travel-visa/${createCountryUrl("Australia")}/`,
        },
        {
          title: "USA",
          link: `/travel-visa/${createCountryUrl("USA")}/`,
        },
        {
          title: "UK",
          link: `/travel-visa/${createCountryUrl("UK")}/`,
        },
        {
          title: "New Zealand",
          link: `/travel-visa/${createCountryUrl("New Zealand")}/`,
        },
        {
          title: "Dubai",
          link: `/travel-visa/${createCountryUrl("Dubai")}/`,
        },
        {
          title: "Germany",
          link: `/travel-visa/${createCountryUrl("Germany")}/`,
        },
        {
          title: "Schengen",
          link: `/travel-visa/${createCountryUrl("Schegan")}/`,
        },
        {
          title: "Turkey",
          link: `/travel-visa/${createCountryUrl("Turkey")}/`,
        },
        {
          title: "Malaysia",
          link: `/travel-visa/${createCountryUrl("Malaysia")}/`,
        },
        {
          title: "Singapore",
          link: `/travel-visa/${createCountryUrl("Singapore")}/`,
        },

        {
          title: "Ireland",
          link: `/travel-visa/${createCountryUrl("Ireland")}/`,
        },
        {
          title: "Japan",
          link: `/travel-visa/${createCountryUrl("Japan")}/`,
        },

        {
          title: "Bangladesh",
          link: `/travel-visa/${createCountryUrl("Bangladesh")}/`,
        },

        {
          title: "Sri Lanka",
          link: `/travel-visa/${createCountryUrl("Sri Lanka")}/`,
        },
        {
          title: "South Africa",
          link: `/travel-visa/${createCountryUrl("South Africa")}/`,
        },
        {
          title: "South Korea",
          link: `/travel-visa/${createCountryUrl("South Korea")}/`,
        },

        {
          title: "Thailand",
          link: `/travel-visa/${createCountryUrl("Thailand")}/`,
        },

        {
          title: "UAE",
          link: `/travel-visa/${createCountryUrl("UAE")}/`,
        },

        {
          title: "Vietnam",
          link: `/travel-visa/${createCountryUrl("Vietnam")}/`,
        },
      ],
    },
    {
      title: "Job Search Program",
      subItems: [
        {
          title: "Malta",
          link: `/job-search-visa/${createCountryUrl("Malta")}/`,
        },
        {
          title: "Poland",
          link: `/job-search-visa/${createCountryUrl("Poland")}/`,
        },
        {
          title: "Schengen",
          link: `/job-search-visa/${createCountryUrl("Schengen")}/`,
        },
        {
          title: "Singapore",
          link: `/job-search-visa/${createCountryUrl("Singapore")}/`,
        },
        { title: "UAE", link: `/job-search-visa/${createCountryUrl("UAE")}/` },
        { title: "UK", link: `/job-search-visa/${createCountryUrl("UK")}/` },
        {
          title: "Australia",
          link: `/job-search-visa/${createCountryUrl("Australia")}/`,
        },
        {
          title: "Dubai",
          link: `/job-search-visa/${createCountryUrl("Dubai")}/`,
        },
        {
          title: "Germany",
          link: `/job-search-visa/${createCountryUrl("Germany")}/`,
        },
        {
          title: "Gulf",
          link: `/job-search-visa/${createCountryUrl("Gulf")}/`,
        },
      ],
    },
    {
      title: "Resources",
      link: "/resources/",
      subItems: [
        {
          title: "Career",
          link: "/career/",
        },
        {
          title: "Blogs",
          link: "/blog/",
        },
        {
          title: "News",
          link: "/news/",
        },
      ],
    },
    {
      title: "Connect Us",
      link: "/connect-us/",
    },
  ];

  const enquiryCountries = [
    { label: "Ireland", value: "Ireland" },
    { label: "Japan", value: "Japan" },
    { label: "Australia", value: "Australia" },
    { label: "Bangladesh", value: "Bangladesh" },
    { label: "Canada", value: "Canada" },
    { label: "Dubai", value: "Dubai" },
    { label: "Germany", value: "Germany" },
    { label: "Malaysia", value: "Malaysia" },
    { label: "New Zealand", value: "New Zealand" },
    { label: "Sri Lanka", value: "Sri Lanka" },
    { label: "Schengen", value: "Schegan" },
    { label: "Singapore", value: "Singapore" },
    { label: "South Africa", value: "South Africa" },
    { label: "South Korea", value: "South Kora" },
    { label: "Turkey", value: "Turkey" },
    { label: "Thailand", value: "Thailand" },
    { label: "USA", value: "USA" },
    { label: "UAE", value: "UAE" },
    { label: "UK", value: "UK" },
    { label: "Vietnam", value: "Vietnam" },
    { label: "Malta", value: "Malta" },
    { label: "Poland", value: "POland" },
    { label: "Gulf", value: "Gulf" },
  ];

  const candaExpressPnp = {
    introduction: {
      heading: "What is the Provincial Nominee Program?",
      content: `<p>
      The Provincial Nominee Program (PNP) is a way for people to become permanent residents in a specific province or territory in Canada. Each province and territory has its own PNP, which helps them bring in new residents based on their local job market needs.
      </p>
      <p>
      In recent years, the Canadian government has planned to invite more than 200,000 people to become permanent residents through PNPs from 2020 to 2023. This means they want to welcome a lot of newcomers to Canada through this program.
      </p>`,
    },
  };

  const types_of_pnp = {
    introduction: {
      heading: "Types of Provincial Nominee Program",
      content: `<p>
      Canada has many different PNPs, one for each province or territory. These PNPs have various immigration streams or categories to help people become permanent residents. Here are the main PNP programs in Canada:
      </p>
      <p>
      Canada has a lot of different PNPs, and each province or territory has its own. These PNPs have different ways for people to become permanent residents. Let's take a look at one of them, the Alberta Immigrant Nominee Program (AINP):
      </p>`,
      subHeading: "Types of Provincial Nominee Program",
      content2: `
      <p><b>AINP has three streams or categories to nominate foreign applicants:</b></p>
      <ul>
      <li>Alberta Opportunity Stream</b></li>
    
      <li>Alberta Express Entry Stream</li>
      
      <li>Self-Employed Farmer Stream</li>
      </ul>
      <p>To be eligible, applicants need to meet the program's requirements and show that they plan to live in Alberta.</p>`,
    },
  };

  const bc_pnp = {
    introduction: {
      heading: "British Columbia Provincial Nominee Program (BC PNP):",
      content: `
        <p>In British Columbia, Canada, there's a program called the British Columbia Provincial Nominee Program (BC PNP). If you want to move to British Columbia and become a permanent resident, you can apply through different streams:</p>
        <ul>
        <li>Skills Immigration</li>
        <li>Express Entry BC</li>
        <li>Entrepreneur Immigration</li>
        </ul>
  <p>These streams are for different types of people, like skilled workers, students who studied there, and entrepreneurs. They need to have the right qualifications and skills for the program.</p>

        `,
    },
  };

  const manitoba_pnp = {
    introduction: {
      heading: "Manitoba Provincial Nominee Program (MPNP)",
      content: `
          <p>
          The Manitoba Provincial Nominee Program (MPNP) is a way for people who recently graduated, skilled workers, and business folks to come to Canada and become permanent residents in Manitoba. They should have the skills and a plan to live and make a living in Manitoba.
          </p>
          <p>Here are the ways you can apply through MPNP:</p>
          <ul>
          <li>Skilled Workers in Manitoba</li>
          <li>Skilled Workers from Other Countries</li>
          <li>International Education</li>
          <li>Business Investors</li>
          </ul>
          <p>Each way for different types of people with various skills and plans</p>`,
    },
  };

  const ontario_pnp = {
    introduction: {
      heading: "Ontario Immigrant Nominee Program (OINP)",
      content: `<p>
          The Ontario Immigrant Nominee Program (OINP) helps foreign workers, students from other countries, and entrepreneurs to become permanent residents in Ontario, a province in Canada. To qualify, you need the right education, skills, experience, and a plan to live in Ontario.
          </p>
          <p>Here are the ways you can apply through OINP:</p>
          <ul>
          <li><b>If you have a job offer in a high-demand field.</b></li>
          <li><b>If you have a Ph.D. degree.</b></li>
          <li><b>If you speak French and have skills.</b></li>
          <li><b>If you have skills and experience that are needed in Ontario.</b></li>
          <li><b>If you work in skilled trades.</b></li>
          <li><b>If you want to start a business in Ontario.</b></li>
          </ul>
          <p>There are different options depending upon your qualifications and plans</p>`,
    },
  };

  const saskatchewan_pnp = {
    introduction: {
      heading: "Saskatchewan Immigrant Nominee Program (SINP):",
      content: `
        <p>
        The Saskatchewan Immigrant Nominee Program (SINP) lets people from other countries come to Canada and become permanent residents in the Saskatchewan province. They get a permanent resident visa.
        </p>
        <p>Here are the main categories for people who want to apply through SINP:</p>
        <ul>
        <li>
        <b>International Skilled Worker: </b>If you have skills and experience in a job that's needed in Saskatchewan.
        </li>
        <li>
        <b>Saskatchewan Experience: </b>If you already have work experience in Saskatchewan.
        </li>
        <li>
        <b>Entrepreneur and Farm:</b>f you want to start a business or work in farming in Saskatchewan.
        </li>
        </ul>
        <p>These are different ways for people to move to Saskatchewan and become permanent residents.</p>`,
    },
  };

  const prince_edward = {
    introduction: {
      heading: "Prince Edward Island Provincial Nominee Program (PEI PNP)",
      content: `<p>
      The government of Prince Edward Island (PEI) has a program called the PEI Provincial Nominee Program (PNP). This program is designed to select and invite people who have the right skills and work experience to come and live in PEI. If you are chosen, you get a certificate from the province that says you are nominated. With this certificate, you can then apply for a Canadian permanent residence (PR) visa from the Federal Government of Canada.
      </p>
      <p>
      In the PEI PNP, give nominations for different categories of immigration. These categories include:
      </p>
      <ul>
      <li><b>PEI PNP Express Entry</b>    </li>
      <li><b>Labour Impact Category</b></li>
      <li><b>Business Impact Category</b></li>
      </ul>
      <p>So, in simple terms, the PEI PNP is a way for PEI to choose and invite people with the right skills and backgrounds to come and live in PEI, and it offers different categories for different types of immigrants.
      </p>`,
    },
  };

  const new_bronswick = {
    introduction: {
      heading: "New Brunswick Provincial Nominee Program (NBPNP)",
      content: `<p>
      The New Brunswick Provincial Nominee Program (NBPNP) is a program in Canada's New Brunswick province. In New Brunswick, there are two main languages spoken, English and French, and about 30 percent of the people speak French as their first language. New Brunswick has cities like Fredericton, Moncton, and Saint John.
      </p>
      <p>
      The NBPNP is a way for New Brunswick to choose and invite people to come and live in the province if they have the right skills and experience that New Brunswick needs. This program accepts applications for different categories, which are like different ways to qualify for immigration to New Brunswick. These categories include things like having a job skill that New Brunswick needs or having family connections in the province.
      </p>
      <ul>
      <li><b>Skilled Workers with Employer Support</b></li>
      <li><b>Express Entry Labour Market Stream</b></li>
      <li><b>Post-Graduate Entrepreneurial Stream</b></li>
      <li><b>Entrepreneurial Stream</b></li>
      </ul>`,
    },
  };

  const new_found_land = {
    introduction: {
      heading: "Newfoundland and Labrador's Provincial Nominee Program (NLPNP)",
      content: `
      <p>
      Newfoundland and Labrador's Provincial Nominee Program (NLPNP) is a way for people from other countries to move to this province in Canada. Newfoundland and Labrador are made up of two parts: Labrador, which is connected to the mainland, and the island of Newfoundland. This province has been growing its economy by using its energy and natural resources.
      </p>
      <p>
      With the NLPNP, foreigners who want to live in Newfoundland and Labrador can apply to get nominated, which means the provincial government says they are allowed to come and live there.
      </p>
      <p>
      The NLPNP has different ways for people to qualify for immigration. These are different categories you can choose from, depending on your situation. For example, if you have a skill that Newfoundland and Labrador needs, you can apply under a category that matches your skill.
      </p>
      <ul>
      <li><b>Express Entry Skilled Worker</b></li>
      <li><b>Skilled Worker</b></li>
      <li><b>International Graduate</b></li>
      </ul>`,
    },
  };

  const yokon_nominee = {
    introduction: {
      heading: "Yukon Nominee Program (YNP)",
      content: `<p>
      The Yukon Nominee Program (YNP) is a program in the Yukon province of Canada. It assists employers in the Yukon who want to recommend foreign workers and business people with international training for permanent residency in the province.
      </p>
      <p>
      This program has different categories, or "streams," that people can apply through. These streams are like different paths for different types of applicants. So, if you're an employer looking to hire someone from another country or a business person with international training, you can use one of these streams to help them become permanent residents in the Yukon.
      </p>
      <ul>
      <li><b>Skilled Worker</b></li>
      <li><b>Yukon Express Entry (YEE)</b></li>
      <li><b>Business Nominee</b></li>
      <li><b>Critical Impact Worker</b></li>
      </ul>`,
    },
  };

  const canonicalURL =
    "https://achintyaconsultant.com/canada/express-entry/provisional-nominee-program/";

  return (
    <div>
      <Helmet>
        <link rel="canonical" href={canonicalURL} />
        <title>
          Canada Provisional Nominee Program (PNP) | Express Entry Guide
        </title>
        <meta name="robots" content="index,follow" />
        <meta
          name="description"
          content="Discover how the Achintya Provincial Nominee Program (PNP) can accelerate your journey to becoming a Canadian permanent resident through the Express Entry system."
        />
        <meta
          name="keywords"
          content="PNP Express Entry,
          Canadian immigration pathways,
          PNP Canada eligibility"
        />
      </Helmet>
      <Header item={navigationHeader} />
      <CanadaExpressPnpBanner />
      <div className="container_wrapper">
        <div className="canada-page-flex-container">
          <div className="canada-page-flex-left-container">
            <CandaPageComponents props={candaExpressPnp} />
            <div className="get-free-assessment-btn">
              <button>GET FREE ASSESSMENT</button>
            </div>
            <CandaPageComponents props={types_of_pnp} />
            <div className="link-to-page">
              <Link to={"/"}>
                <p>ALBERTA PNP</p>
              </Link>
            </div>

            <CandaPageComponents props={bc_pnp} />
            <div className="link-to-page">
              <Link to={"/"}>
                <p>British Columbia PNP</p>
              </Link>
            </div>

            <CandaPageComponents props={manitoba_pnp} />
            <div className="link-to-page">
              <Link to={"/"}>
                <p>Manitoba PNP</p>
              </Link>
            </div>

            <CandaPageComponents props={ontario_pnp} />
            <div className="link-to-page">
              <Link to={"/"}>
                <p>Ontario PNP</p>
              </Link>
            </div>

            <CandaPageComponents props={saskatchewan_pnp} />
            <div className="link-to-page">
              <Link to={"/"}>
                <p>Saskatchewan Immigrant Nominee Program</p>
              </Link>
            </div>

            <CandaPageComponents props={prince_edward} />
            <div className="link-to-page">
              <Link to={"/"}>
                <p>Prince Edward Island Provincial Nominee Program </p>
              </Link>
            </div>

            <CandaPageComponents props={new_bronswick} />
            <div className="link-to-page">
              <Link to={"/"}>
                <p>New Brunswick Provincial Nominee Program </p>
              </Link>
            </div>

            <div className="canada-pnp-image">
              <img
                src="https://achintyaconsultant.s3.ap-south-1.amazonaws.com/canada-pnp/Group+260.png"
                alt="canada"
              />
            </div>

            <CandaPageComponents props={new_found_land} />
            <div className="link-to-page">
              <Link to={"/"}>
                <p>Newfoundland and Labrador's Provincial Nominee Program</p>
              </Link>
            </div>

            <CandaPageComponents props={yokon_nominee} />
            <div className="link-to-page">
              <Link to={"/"}>
                <p>Yukon Nominee Program</p>
              </Link>
            </div>
          </div>

          <div className="canada-page-right-container">
            <div className="fixed-container">
              <AbroadForm enquiryCountries={enquiryCountries} />
            </div>
          </div>
        </div>
      </div>
      <CanadaPNPProcess />
      <Footer />
    </div>
  );
};

export default CanadaExpressPNP;

import React from "react";
import "./index.css";
import AbroadForm from "../../commonComponents/abroadForm";

const AboutAchintya = ({ aboutAchintya, enquiryCountries }) => {
  // console.log("aboutAchintya : ", aboutAchintya)
  return (
    <>
      <div className="about-outer-container">
        <div className="container_wrapper">
          <h2 className="about-achintya-heading">{aboutAchintya.heading}</h2>
          <div className="about-achintya-description">
            <p
              dangerouslySetInnerHTML={{ __html: aboutAchintya.description }}
            />
            <AbroadForm enquiryCountries={enquiryCountries} />
          </div>
          <div className="monument-image"></div>
        </div>
      </div>
    </>
  );
};

export default AboutAchintya;

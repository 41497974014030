import React, { useState, useEffect } from "react";
import "./index.css";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import headerLogo from "../../assets/achintya logo 1.png";
import HeaderHamburger from "../headerHamburger";
import { Link } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const Header = ({ item }) => {
  // Find the indices of "Australia" and "Tourist Visa" items in the item array
  const australiaIndex = item.findIndex(
    (navItem) => navItem.title === "Australia"
  );
  const touristVisaIndex = item.findIndex(
    (navItem) => navItem.title === "Travel Visa"
  );

  // Create a separate state variable for each navigation item's dropdown
  const [isDropdownOpen, setIsDropdownOpen] = useState(
    Array(item?.length).fill(false)
  );
  const [isSubDataDropdownOpen, setIsSubDataDropdownOpen] = useState(
    Array(item?.length).fill(false)
  );

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Toggle the main dropdown for a specific index
  const toggleDropdown = (index) => {
    const newDropdownState = [...isDropdownOpen];
    newDropdownState[index] = !newDropdownState[index];
    setIsDropdownOpen(newDropdownState);

    // Close subDataDropdown when main dropdown is closed
    if (!newDropdownState[index]) {
      setIsSubDataDropdownOpen(Array(item?.length).fill(false));
    }
  };

  // Toggle the subDataDropdown for a specific index and close others
  const toggleSubDataDropdown = (subIndex) => {
    const newSubDataDropdownState = Array(item?.length).fill(false);
    newSubDataDropdownState[subIndex] = true; // Open the clicked subDataDropdown
    setIsSubDataDropdownOpen(newSubDataDropdownState);
  };

  // Close the subDataDropdown when leaving the entire dropdown menu
  const closeSubDataDropdown = () => {
    setIsSubDataDropdownOpen(Array(item?.length).fill(false));
  };

  return (
    <div className="header-outer-container">
      <div className="header-red-container">
        <div className="header-first-container">
          <div className="header-first-left-container"> </div>
          <div className="header-first-right-container">
            <div className="official-contact">
              <CallIcon />
              <a href="tel:7011302054">
                <p className="official-contact-info">+91 70113 02054</p>
              </a>
            </div>
            <div className="official-contact">
              <EmailIcon />
              <a href="mailto:">
                <p className="official-contact-info">
                  immigration@achintyaconsultant.com
                </p>
              </a>
            </div>
            <div className="official-pay-now-btn">
              <button>PAY NOW</button>
            </div>

            <div className="official-pay-now-btn">
              <button>Enquire Now</button>
            </div>
          </div>
        </div>
      </div>{" "}
      {windowWidth > 768 ? (
        <div className="header-second-container">
          <div className="header-second-left-container">
            <div className="header-logo">
              <Link to={"/"}>
                <img src={headerLogo} alt="logo" />
              </Link>
            </div>
          </div>
          <div className="header-second-right-container">
            {item.map((navItem, index) => (
              <div
                className={`nav-item ${navItem?.subItems ? "dropdown" : ""}`}
                key={index}
                onMouseEnter={() => toggleDropdown(index)}
                onMouseLeave={() => toggleDropdown(index)}
              >
                <Link className="nav-link" to={navItem?.link}>
                  {navItem.title}{" "}
                  {navItem?.subItems &&
                    (isDropdownOpen[index] ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    ))}
                </Link>
                {navItem.subItems && isDropdownOpen[index] && (
                  <div
                    className={`dropdown-menu ${
                      index === australiaIndex || index === touristVisaIndex
                        ? "specific-height-class"
                        : ""
                    }`}
                    onMouseLeave={() => closeSubDataDropdown()}
                  >
                    {navItem?.subItems?.map((subItem, subIndex) => (
                      <div
                        key={subIndex}
                        onMouseEnter={() => toggleSubDataDropdown(subIndex)}
                      >
                        <Link className="dropdown-item" to={subItem?.link}>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            {" "}
                            <p>{subItem.title}</p>
                            {subItem?.subDataItems && (
                              <ArrowForwardIosIcon /> // Render arrow icon if subDataItems exist
                            )}
                          </div>
                          {subItem?.subDataItems &&
                            isSubDataDropdownOpen[subIndex] && (
                              <div className="sub-data-dropdown">
                                {subItem?.subDataItems?.map(
                                  (subDataItem, subDataIndex) => (
                                    <Link
                                      className="sub-data-item"
                                      to={subDataItem?.link}
                                      key={subDataIndex}
                                    >
                                      <p>{subDataItem?.title}</p>
                                    </Link>
                                  )
                                )}
                              </div>
                            )}
                        </Link>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="mobile-hamburger-container">
          <HeaderHamburger item={item} />
        </div>
      )}
    </div>
  );
};

export default Header;

import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import CanadaExpressEntryBanner from "../../canadaPage/canadaExpressEntry/CanadaExpEntryBanner";
import Header from "../../commonComponents/header";
import Footer from "../../commonComponents/footer";
import WhatsApp from "../../commonComponents/whatsapp";
import { createCountryUrl } from "../../Utilities/commonFunctions";
import CanadaExpressProgram from "../../canadaPage/canadaExpressEntry/canadaExpressProgram";

const CanadaExpress = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const navigationHeader = [
    {
      title: "Home",
      link: `/`,
    },
    {
      title: "About Us",
      subItems: [
        {
          title: "About",
          link: `/about-us/`,
        },
        {
          title: "Reviews",
          link: ` /reviews/`,
        },
        {
          title: "Gallery",
          link: `/gallery/`,
        },
      ],
    },
    {
      title: "Canada",
      subItems: [
        {
          title: "Canada Express Entry",
          // link: `/${createCountryUrl("canada")}/express-entry/`,
          subDataItems: [
            {
              title: "Canada Express Entry",
              link: `/${createCountryUrl("canada")}/express-entry/`,
            },
            {
              title: "CRS Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/crs-calculator/`,
            },
            {
              title: "CRS Chart",
              link: `/${createCountryUrl("canada")}/express-entry/crs-chart/`,
            },
            {
              title: "67 Points Calculator and charts",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/${createCountryUrl(
                "67 Points Calculator and charts"
              )}`,
            },
            {
              title: "Latest Express Entry Draw",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/latest-express-entry-draw/`,
            },
            {
              title: "Benefits of the Program",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/benefits-of-the-program/`,
            },
            {
              title: "Occupation Demand",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/occupation-demand/`,
            },
            {
              title: "Provisional Nominee Program",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/provisional-nominee-program/`,
            },
            {
              title: "Federal Skilled Worker Program",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/fedral-skilled-worker-program/`,
            },
            {
              title: "Federal Skilled Trade Program",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/fedral-skilled-trade-program/`,
            },
            {
              title: "Canadian Experience Class",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/canadian-experience-class/`,
            },
            {
              title: "Super Visa From India",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/super-visa-from-india/`,
            },
            {
              title: "IELTS",
              link: `/${createCountryUrl("canada")}/express-entry/ielts/`,
            },
          ],
        },
        {
          title: "Provincial Nominee Program",
          subDataItems: [
            {
              title: "Manitoba PNP Program ",
              link: `/${createCountryUrl(
                "canada"
              )}/pnp-program/${createCountryUrl("Manitoba PNP Program")}/`,
            },
            {
              title: "Yukon Nominee Program ",
              link: `/${createCountryUrl(
                "canada"
              )}/pnp-program/${createCountryUrl("Yukon Nominee Program")}/`,
            },
            {
              title: "Ontario PNP Program ",
              link: `/${createCountryUrl(
                "canada"
              )}/pnp-program/${createCountryUrl("Ontario PNP Program")}/`,
            },
            {
              title: " Nova Scotia PNP ",
              link: `/${createCountryUrl(
                "canada"
              )}/pnp-program/${createCountryUrl("Nova Scotia PNP")}/`,
            },
            {
              title: "Alberta PNP Program ",
              link: `/${createCountryUrl(
                "canada"
              )}/pnp-program/${createCountryUrl("Alberta PNP Program")}/`,
            },
            {
              title: "BC PNP Program ",
              link: `/${createCountryUrl(
                "canada"
              )}/pnp-program/${createCountryUrl("BC PNP Program")}/`,
            },
            {
              title: "Quebec Skilled Worker Program ",
              link: `/pnp-program/${createCountryUrl(
                "Quebec Skilled Worker Program"
              )}/`,
            },
            {
              title: "Saskatchewan PNP ",
              link: `/pnp-program/${createCountryUrl("Saskatchewan PNP ")}/`,
            },
            {
              title: "New Brunswick PNP Program ",
              link: `/pnp-program/${createCountryUrl(
                "New Brunswick PNP Program "
              )}/`,
            },
            {
              title: "Atlantic Immigration Pilot Program ",
              link: `/pnp-program/${createCountryUrl(
                "Atlantic Immigration Pilot Program "
              )}/`,
            },
            {
              title: "PEI PNP Program ",
              link: `/pnp-program/${createCountryUrl("PEI PNP Program ")}/`,
            },
            {
              title: "NLPNP Program ",
              link: `/pnp-program/${createCountryUrl("NLPNP Program")}/`,
            },
          ],
        },
        {
          title: "Point Calculator",
          subDataItems: [
            {
              title: "CRS Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl("CRS Calculator")}/`,
            },
            {
              title: "Saskatchewan Points Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl(
                "Saskatchewan Points Calculator"
              )}`,
            },
            {
              title: "Canada 67 points Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl(
                "Canada 67 points Calculator"
              )}`,
            },
            {
              title: "British columbia Points Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl(
                "British columbia Points Calculator"
              )}`,
            },
            {
              title: "Manitoba Points Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl(
                "Manitoba Points Calculator"
              )}`,
            },
            {
              title: "Nova Scotia Points Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl(
                "Nova Scotia Points Calculator"
              )}`,
            },
            {
              title: "Alberta Points Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl(
                "Alberta Points Calculator"
              )}`,
            },
            {
              title: "Ontario Points Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl(
                "Ontario Points Calculator"
              )}`,
            },
            {
              title: " Quebec Points Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl(
                " Quebec Points Calculator"
              )}`,
            },
          ],
        },
        {
          title: "LMIA",
          link: `/${createCountryUrl("Canada")}/lmia/`,
        },
        {
          title: "Canada Spouse Dependent Visa",
          link: `/${createCountryUrl("Canada")}/spouse-dependent-visa/`,
        },
        {
          title: "Super Visa",
          link: `/${createCountryUrl("Canada")}/super-visa/`,
        },
        {
          title: "Quebec Skilled Worker Program",
          link: `/${createCountryUrl("Canada")}/quebec-skilled-worker-program/`,
        },
        {
          title: "Atlantic Immigration Program",
          link: `/${createCountryUrl("Canada")}/atlantic-immigration-program/`,
        },
        {
          title: "Startup Visa",
          link: `/${createCountryUrl("Canada")}/startup-visa/`,
        },
        {
          title: "Entrepreneur Investment Visa",
          link: `/${createCountryUrl("Canada")}/entrepreneur-investment-visa/`,
        },
      ],
    },
    {
      title: "Australia",
      subItems: [
        {
          title: "Australia Skilled Occupation List",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Australia Skilled Occupation List"
          )}`,
        },
        {
          title: "Australia 65 Points Calculator",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Australia 65 Points Calculator"
          )}`,
        },
        {
          title: "Employer Nomination Subclass 186",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Employer Nomination Subclass 186"
          )}`,
        },
        {
          title: "Skilled Independent Subclass 189",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Skilled Independent Subclass 189"
          )}`,
        },
        {
          title: "Skilled Nominated Subclass 190",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Skilled Nominated Subclass 190"
          )}`,
        },
        {
          title: "Australia Subclass 491",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Australia Subclass 491"
          )}`,
        },
        {
          title: "Australia PR Visa From India",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Australia PR Visa From India"
          )}`,
        },
        {
          title: "Australia Spouse Visa",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "AAustralia Spouse Visa"
          )}`,
        },
        {
          title: "Invitation To Apply",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Invitation To Apply"
          )}`,
        },
        {
          title: "Australia Tourist Visa From India",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Australia Tourist Visa From India"
          )}`,
        },
        {
          title: "Australia Subclass 476",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Australia Subclass 476"
          )}`,
        },
      ],
    },
    {
      title: "Travel Visa",
      link: "/travel-visa/",
      subItems: [
        {
          title: "Canada",
          link: `/travel-visa/${createCountryUrl("Canada")}/`,
        },
        {
          title: "Australia",
          link: `/travel-visa/${createCountryUrl("Australia")}/`,
        },
        {
          title: "USA",
          link: `/travel-visa/${createCountryUrl("USA")}/`,
        },
        {
          title: "UK",
          link: `/travel-visa/${createCountryUrl("UK")}/`,
        },
        {
          title: "New Zealand",
          link: `/travel-visa/${createCountryUrl("New Zealand")}/`,
        },
        {
          title: "Dubai",
          link: `/travel-visa/${createCountryUrl("Dubai")}/`,
        },
        {
          title: "Germany",
          link: `/travel-visa/${createCountryUrl("Germany")}/`,
        },
        {
          title: "Schengen",
          link: `/travel-visa/${createCountryUrl("Schegan")}/`,
        },
        {
          title: "Turkey",
          link: `/travel-visa/${createCountryUrl("Turkey")}/`,
        },
        {
          title: "Malaysia",
          link: `/travel-visa/${createCountryUrl("Malaysia")}/`,
        },
        {
          title: "Singapore",
          link: `/travel-visa/${createCountryUrl("Singapore")}/`,
        },

        {
          title: "Ireland",
          link: `/travel-visa/${createCountryUrl("Ireland")}/`,
        },
        {
          title: "Japan",
          link: `/travel-visa/${createCountryUrl("Japan")}/`,
        },

        {
          title: "Bangladesh",
          link: `/travel-visa/${createCountryUrl("Bangladesh")}/`,
        },

        {
          title: "Sri Lanka",
          link: `/travel-visa/${createCountryUrl("Sri Lanka")}/`,
        },
        {
          title: "South Africa",
          link: `/travel-visa/${createCountryUrl("South Africa")}/`,
        },
        {
          title: "South Korea",
          link: `/travel-visa/${createCountryUrl("South Korea")}/`,
        },

        {
          title: "Thailand",
          link: `/travel-visa/${createCountryUrl("Thailand")}/`,
        },

        {
          title: "UAE",
          link: `/travel-visa/${createCountryUrl("UAE")}/`,
        },

        {
          title: "Vietnam",
          link: `/travel-visa/${createCountryUrl("Vietnam")}/`,
        },
      ],
    },
    {
      title: "Job Search Program",
      subItems: [
        {
          title: "Malta",
          link: `/job-search-visa/${createCountryUrl("Malta")}/`,
        },
        {
          title: "Poland",
          link: `/job-search-visa/${createCountryUrl("Poland")}/`,
        },
        {
          title: "Schengen",
          link: `/job-search-visa/${createCountryUrl("Schengen")}/`,
        },
        {
          title: "Singapore",
          link: `/job-search-visa/${createCountryUrl("Singapore")}/`,
        },
        { title: "UAE", link: `/job-search-visa/${createCountryUrl("UAE")}/` },
        { title: "UK", link: `/job-search-visa/${createCountryUrl("UK")}/` },
        {
          title: "Australia",
          link: `/job-search-visa/${createCountryUrl("Australia")}/`,
        },
        {
          title: "Dubai",
          link: `/job-search-visa/${createCountryUrl("Dubai")}/`,
        },
        {
          title: "Germany",
          link: `/job-search-visa/${createCountryUrl("Germany")}/`,
        },
        {
          title: "Gulf",
          link: `/job-search-visa/${createCountryUrl("Gulf")}/`,
        },
      ],
    },
    {
      title: "Resources",
      link: "/resources/",
      subItems: [
        {
          title: "Career",
          link: "/career/",
        },
        {
          title: "Blogs",
          link: "/blog/",
        },
        {
          title: "News",
          link: "/news/",
        },
      ],
    },
    {
      title: "Connect Us",
      link: "/connect-us/",
    },
  ];

  const canadExpressEntry = {
    introduction: {
      heading: "Canada Express Entry Program",
      content: `<p>
            If your dream is to live and have a successful career in Canada, you should start by understanding the Canada Express Entry Program. This program is for all the people in the economic class who want to move to Canada and get a PR (Permanent Residency) Visa.
The Canada Express Entry Program is an online system created by the Canadian government in January 2015. It's designed to handle PR Visa applications for all economic immigrants. These are skilled individuals who want to come to Canada for work opportunities and a better life. This program is like a big plan to manage the number of immigrants coming to Canada, using different criteria to pick the right ones.</p>`,
    },

    charecterstics: {
      heading: "Characteristics of the Express Entry Program",
      content: `
      <p>
      The Canada Express Entry Program is a special and fast way for skilled workers to become permanent residents in Canada. The government has a unique way of choosing skilled professionals and letting them stay in Canada permanently. They look at things like work experience, language skills, and more to decide.
The more CRS points you have, the better your chances of getting an ITA (Invitation to Apply). Normally, it takes about 6 to 12 months to process PR Visa applications through this program.
      </p>
      <p>Here are the important things to know about the program:</p>
      <b>
      <ul>
      <li>It's online and open all year with no limit on how many people can apply.</li>
      <li>This program applies to only three groups of people: those with Canadian work experience, skilled tradespeople, and skilled workers for federal jobs.</li>
      <li>Send an Expression of Interest (EOI) and apply for any job category listed as Skill Level 0, A, or B.</li>
      <li>Once you submit your EOI (Expression of Interest), your profile will be evaluated based on your CRS score. If you meet the requirements, you will be added to the Express Entry pool.</li>
      <li>Employers and provinces in Canada can pick talented people from this pool.</li>
      <li>The person in the pool with the most points will receive an Invitation to Apply (ITA) when their score meets the CRS (Comprehensive Ranking System) cutoff.</li>
      </ul>
      </b>
      <p>The amount of Invitations to Apply (ITAs) given out depends on the annual immigration plans.</p>`,
    },

    process_canada_express_entry: {
      heading: "Process of Canada Express Entry Program",
      content:
        "Here are the main steps to apply for a Canada PR Visa through the Express Entry Program:",
    },

    online_application_process: {
      heading: "Online application process for Canada Express Entry Program",
      content: `<p>The final step is to provide all the necessary documents to become a Permanent Resident in Canada. When you're selected, you have 60 days to submit your complete application and documents. If you don't, you lose the chance (ITA - Invitation to Apply).
      </p>
      <p>Express Entry accepts applications for these Visa categories:</p>
      <b>
      <ul>
      <li>Federal Skilled Trade Program</li>
      <li>Federal Skilled Worker Program</li>
      <li>Canadian Experience Class Program</li>
      </ul>
      </b>
      <p>
      Also, Provinces and Territories in Canada can invite skilled immigrants from the Express Entry Pool through Provincial Nominee Programs.</p>
      <p>When you join the pool, they give you a score using the Comprehensive Ranking System (CRS), up to 1200 points. Your score depends on things like education, work experience, age, adaptability, language skills, and job offers.</p>
      <p>People with the highest scores get an ITA periodically, and they have 60 days to complete their Canada PR Visa application.</p>
      <p>You can also look for jobs in Canada by creating a Job Bank Account after making a profile in Express Entry. Even after you're in Canada, you can keep searching for jobs through the Job Bank.</p>
      <p>
      So, think of this journey as a thrilling expedition where each step brings you closer to your dream of becoming a Canadian Permanent Resident.</p>`,
    },

    skilled_occupation: {
      heading: "Canada Skilled Occupation List",
      content:
        "Apart from your CRS score, one big thing that affects your Canada PR Visa application is the Skilled Occupation List. The government looks at what jobs are needed in the Canadian job market and sorts them into groups using the NOC (National Occupational Classification).",
    },

    canada_express_entry_points_calculator: {
      heading: "How are Canada Express Entry Program Points calculated?",
      content: `
      <p>
      The Canada Express Entry Points Calculator gives you an estimate of how many points you need to immigrate to Canada. Scores are assigned using a CRS calculator, taking into account factors such as language skills, work experience, age, education, and the influence of a spouse's attributes, among others.
      </p>
      <p>
      Points are determined using a calculator called the CRS calculator. It looks at things like how well you speak a language, your work history, your age, your education, and other factors related to you and your spouse or partner. Every two weeks, the IRCC (Immigration, Refugees and Citizenship Canada) picks candidates with the highest CRS scores in a draw. If you're chosen, they'll send you an ITA (Invitation to Apply) to continue with your PR Visa application.
      </p>`,
      content2: `
      <p>
      Opening the door to your Canadian dream through Express Entry involves a little questionnaire adventure. Think of it as your goal to become a skilled worker in Canada and making it your permanent home.
      </p>
      <p>
Now, imagine this questionnaire as a map with three unique trails, each leading to a different program in the Express Entry system. These programs have their own special requirements, so you'll need to choose the one that suits your journey.
      </p>
      <p>
      To see if you can work and live in Canada as a skilled worker, you need to answer some questions. Remember, there are three programs in this system, and each has its own rules you must meet. The questions will be about things like
      </p>
      <b>
      <ul>
      <li>Approved job offer</li>
      <li>Age</li>
      <li>Language capability </li>
      <li>Education or Skills level </li>
      <li>Work experience </li>
      </ul>
      </b>
      <p>
      Based on your answers, they'll tell you which programs are right for you. But remember, don't give any wrong or false information about yourself.
      </p>
      <p>
      When you meet the Express Entry criteria, they'll give you clear instructions on what to do next. This includes filling out an online profile and giving important information. If you meet all the requirements, your profile goes into a group of applicants for PR Visa processing. Then, you might get an invitation (ITA) to apply for it.
      </p>`,
    },

    invitation_to_apply: {
      heading: "ITA(Invitation To Apply) for PR Visa",
      content: `
      <p>
      The Canadian government does an Express Entry draw about every two weeks. In each draw, they set a minimum score called the CRS cut-off. This score is the lowest one a candidate must have to get an ITA (Invitation to Apply) for a Canada PR Visa in that draw.
      </p>
      <p>
      If you get an ITA, you have 60 days to complete and submit your official PR Visa application online.
      </p>
      <p>
      They pick candidates based on their ranking in the Comprehensive Ranking System (CRS). It's all about points – your education, work experience, age, language skills, and more count. Canada's Immigration Department does these draws regularly to offer opportunities to eligible applicants. They usually have at least two draws a month for the Federal Skilled Worker Program.
      </p>
      <p>
      In 2023, the plan is for a range of 430,000 to 542,000 new permanent residents. For 2024, they aim for a range of 442,500 to 550,000 new permanent residents. This means there will be more ITAs given out, especially through Express Entry draws.
      </p>
      <p>
      To get picked in an Express Entry draw, you need a high CRS score. Your score depends on things like your education, work experience, age, language skills, and more. Basically, the higher your score, the better your chances of getting an ITA through Express Entry.
      </p>`,
    },

    criteria_to_enhance: {
      heading: "Criteria to enhance your CRS points to obtain your ITA",
      content: `
      <p>
      Feeling a bit down because of your Express Entry points? Don't Worry! There are ample ways to boost your CRS (Comprehensive Ranking System) points and elevate your chances of making Canada your new home.</p>
<p>If your Express Entry score is low, don't worry. There are ways to increase it:</p>
<ul>
<li><b>Improve your IELTS score: </b>If your language test score is low, you can retake the test with professional help to boost your CRS points.</li>
<li><b>Take a French (TEF) Language Test:</b> If you can do well in a French language test, you can get 72 extra points in the Express Entry pool.</li>
<li><b>Upgrade your education: </b>If you have a bachelor's degree and want more points, you can pursue a post-graduation diploma for one year or a two-year master's degree. A master's degree adds 40 extra points compared to a bachelor's degree, while a one-year post-graduation diploma can give you 33 extra points.</li>
<li><b>Spousal Factors: </b>If you're not single and have a spouse or common-law partner, include them in your profile. Single applicants can get a maximum of 1200 points, but including your spouse can earn you up to 40 extra points if they have strong credentials. Making your spouse the primary applicant might be a good idea if they have better qualifications.
</li>
<li><b>Education Level: </b>Your spouse can claim up to 10 points for their education, but you'll need to get their credentials assessed by the World Education Services to prove their value in Canada. Having a master's degree or higher in Canada gets you the most points.</li>
<li><b>Job Offer: </b>Having a job offer approved by LMIA (Labour Market Impact Assessment) can increase your CRS score by 50 to 200 points, depending on your NOC (National Occupational Classification) code skill level. Skill levels A, O, B add 50 points, while skill level "OO" adds 200 points. So, getting a job offer in Canada can be a significant step towards getting permanent residency. You can look for jobs through the Job Bank portal in the Express Entry program.</li>
</ul>`,
    },
  };

  const enquiryCountries = [
    { label: "Ireland", value: "Ireland" },
    { label: "Japan", value: "Japan" },
    { label: "Australia", value: "Australia" },
    { label: "Bangladesh", value: "Bangladesh" },
    { label: "Canada", value: "Canada" },
    { label: "Dubai", value: "Dubai" },
    { label: "Germany", value: "Germany" },
    { label: "Malaysia", value: "Malaysia" },
    { label: "New Zealand", value: "New Zealand" },
    { label: "Sri Lanka", value: "Sri Lanka" },
    { label: "Schengen", value: "Schegan" },
    { label: "Singapore", value: "Singapore" },
    { label: "South Africa", value: "South Africa" },
    { label: "South Korea", value: "South Kora" },
    { label: "Turkey", value: "Turkey" },
    { label: "Thailand", value: "Thailand" },
    { label: "USA", value: "USA" },
    { label: "UAE", value: "UAE" },
    { label: "UK", value: "UK" },
    { label: "Vietnam", value: "Vietnam" },
    { label: "Malta", value: "Malta" },
    { label: "Poland", value: "POland" },
    { label: "Gulf", value: "Gulf" },
  ];
  const canonicalURL = "https://achintyaconsultant.com/canada/express-entrys/";
  return (
    <div>
      <Helmet>
        <link rel="canonical" href={canonicalURL} />
        <title>
          Canada Express Entry - Eligibility Requirements, Latest Draw{" "}
        </title>
        <meta name="robots" content="index,follow" />
        <meta
          name="description"
          content="Express Entry is an online system that manages immigration applications of skilled workers, who want to settle in Canada permanently and take part in our economy."
        />
        <meta
          name="keywords"
          content="canada express entry program for skilled workers, requirements for canada express entry"
        />
      </Helmet>
      <Header item={navigationHeader} />
      <CanadaExpressEntryBanner />
      <CanadaExpressProgram
        enquiryCountries={enquiryCountries}
        canadExpressEntry={canadExpressEntry}
      />
      <Footer />
      <WhatsApp />
    </div>
  );
};

export default CanadaExpress;

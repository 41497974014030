import React from "react";
import "./index.css";
import logo from "../../assets/footer logo.png";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footer-outer-container">
      <div className="container_wrapper">
        <div className="footer-inner-container">
          <div className="footer-left-container">
            <img src={logo} alt="achintya logo" />
          </div>
          <div className="footer-right-container">
            <div className="footer-content">
              <div>
                <Link to="/">
                  <p className="footer-div-content">Home</p>
                </Link>
              </div>

              <div>
                <a href="#">
                  <p className="footer-div-content">Blogs</p>
                </a>
              </div>

              <div>
                <Link to="/about-us/">
                  <p className="footer-div-content">About Us</p>
                </Link>
              </div>

              <div>
                <a href="#">
                  <p className="footer-div-content">News</p>
                </a>
              </div>

              <div>
                <Link to="/videos">
                  <p className="footer-div-content">Videos</p>
                </Link>
              </div>

              <div>
                <Link to="/contact-us/">
                  <p className="footer-div-content">Contact Us</p>
                </Link>
              </div>

              <div>
                <Link to={`/partner-with-us/`}>
                  <p className="footer-div-content">Enquire B2b</p>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-below-container">
          <div className="footer-below-left-container">
            <Link to="/terms-conditions/">
              <p className="">Terms & Conditions</p>
            </Link>
            <p>|</p>
            <Link to="/privacy-policy/">
              <p>Privacy Policy</p>
            </Link>
          </div>
          <div className="footer-below-right-container">
            <p> Follow us on</p>
            <div className="footer-social-media">
              <a
                href="https://www.instagram.com/achintya.immigration/"
                target="_blank"
              >
                <InstagramIcon />
              </a>
              <a
                href="https://www.facebook.com/achintya.immigration"
                target="_blank"
              >
                <FacebookIcon />
              </a>
              <a
                href="https://www.youtube.com/@achintyaimmigrationconsultant"
                target="_blank"
              >
                <YouTubeIcon />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;

import React from 'react'
import './index.css'
import aboutBanner from '../../assets/Group 116.png'

const AboutBanner = () => {
    return (
        <div className='about-banner-outer-containers'>
            <img src={aboutBanner} />
            <h2 className='about-heading'>About Us</h2>
        </div>
    )
}

export default AboutBanner
import React from "react";
import "./index.css";
import AbroadForm from "../../../commonComponents/abroadForm";
import CanadaTeerTable from "../canadaTeerTable";

const CanadaExpressProgram = ({ enquiryCountries, canadExpressEntry }) => {
  //   console.log("canadExpressEntry : ", canadExpressEntry);
  return (
    <div className="canada-express-outer-container">
      <div className="container_wrapper">
        <div className="canada-express-flex-container">
          <div className="canada-flex-left-container">
            <div className="canada-express-points-container">
              <div
                style={{
                  backgroundColor: "#2c82c1",
                  borderRadius: "5px",
                  padding: "9px",
                  color: "#fff",
                }}
              >
                <h2>{canadExpressEntry?.introduction?.heading}</h2>
              </div>
              <p
                dangerouslySetInnerHTML={{
                  __html: canadExpressEntry?.introduction?.content,
                }}
              />
            </div>

            <div className="get-free-assessment-btn">
              <button>GET FREE ASSESSMENT</button>
            </div>

            <div className="canada-express-main-container">
              <div className="canada-express-points-container">
                <div
                  style={{
                    backgroundColor: "#2c82c1",
                    borderRadius: "5px",
                    padding: "9px",
                    color: "#fff",
                  }}
                >
                  <h2>{canadExpressEntry?.charecterstics.heading}</h2>
                </div>
                <p
                  dangerouslySetInnerHTML={{
                    __html: canadExpressEntry?.charecterstics?.content,
                  }}
                />
              </div>
            </div>

            <div className="canada-express-main-container">
              <div className="canada-express-points-container">
                <div
                  style={{
                    backgroundColor: "#2c82c1",
                    borderRadius: "5px",
                    padding: "9px",
                    color: "#fff",
                  }}
                >
                  <h2>
                    {canadExpressEntry?.process_canada_express_entry?.heading}
                  </h2>
                </div>
                <p
                  dangerouslySetInnerHTML={{
                    __html:
                      canadExpressEntry?.process_canada_express_entry?.content,
                  }}
                />
                <div className="canada-process-image">
                  <img
                    src="https://achintyaconsultant.s3.ap-south-1.amazonaws.com/canad-expree-entry/Group+250.png"
                    alt="canad express entry process"
                  />
                </div>
              </div>
            </div>

            <div className="canada-express-main-container">
              <div className="canada-express-points-container">
                <div
                  style={{
                    backgroundColor: "#2c82c1",
                    borderRadius: "5px",
                    padding: "9px",
                    color: "#fff",
                  }}
                >
                  <h2>
                    {canadExpressEntry?.online_application_process?.heading}
                  </h2>
                </div>
                <div className="pointer-process">
                  <p
                    dangerouslySetInnerHTML={{
                      __html:
                        canadExpressEntry?.online_application_process?.content,
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="canada-express-main-container">
              <div className="canada-express-points-container">
                <img
                  style={{ width: "100%" }}
                  src="https://achintyaconsultant.s3.ap-south-1.amazonaws.com/canad-expree-entry/Clip+path+group.png"
                />
              </div>
            </div>

            <div className="canada-express-main-container">
              <div className="canada-express-points-container">
                <h2>{canadExpressEntry?.skilled_occupation?.heading}</h2>
                <div className="pointer-process">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: canadExpressEntry?.skilled_occupation?.content,
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="canada-express-main-container">
              <div className="canada-express-points-container">
                <CanadaTeerTable />
              </div>
            </div>
          </div>
          <div className="canada-flex-right-container">
            <div className="fixed-container">
              <AbroadForm enquiryCountries={enquiryCountries} />
            </div>
          </div>
        </div>
      </div>

      <div className="occupation-demand-list-outer-container">
        <div className="container_wrapper">
          <div className="occupation-demand-list-inner-container">
            <div>
              <a href="#">
                <p className="occupation-demand">OCCUPATION DEMAND LIST</p>
              </a>
            </div>
            <p
              className="occupation-text"
              dangerouslySetInnerHTML={{
                __html:
                  canadExpressEntry?.canada_express_entry_points_calculator
                    ?.heading,
              }}
            />
            <div className="pointer-process-canada">
              <p
                dangerouslySetInnerHTML={{
                  __html:
                    canadExpressEntry?.canada_express_entry_points_calculator
                      ?.content,
                }}
              />
            </div>

            <div>
              <a href="#">
                <p className="occupation-demand">Check your CRS SCORE now!</p>
              </a>
            </div>

            <div className="pointer-process-canada">
              <p
                dangerouslySetInnerHTML={{
                  __html:
                    canadExpressEntry?.canada_express_entry_points_calculator
                      ?.content2,
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="canada-ita-outer-container">
        <div className="container_wrapper">
          <div className="canada-ita-inner-container">
            <h2>{canadExpressEntry?.invitation_to_apply?.heading}</h2>
            <div className="pointer-process">
              <p
                dangerouslySetInnerHTML={{
                  __html: canadExpressEntry?.invitation_to_apply?.content,
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="canada-ita-outer-container">
        <div className="container_wrapper">
          <div className="canada-ita-inner-container canada-citeria">
            <h2>{canadExpressEntry?.criteria_to_enhance?.heading}</h2>
            <div className="canada-criteria-flex">
              <div className="pointer-process">
                <p
                  dangerouslySetInnerHTML={{
                    __html: canadExpressEntry?.criteria_to_enhance?.content,
                  }}
                />
              </div>
              <div className="criteria-left-container">
                <img src="https://achintyaconsultant.s3.ap-south-1.amazonaws.com/canad-expree-entry/Character_1.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CanadaExpressProgram;

import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import Header from "../../commonComponents/header";
import Footer from "../../commonComponents/footer";
import PrBanner from "../../pageComponent/prPageComponent/prBanner";
import { useParams } from "react-router-dom";
import bannerImage from "../../assets/PR Visa/australia banner.png";
import canadaBannerImage from "../../assets/PR Visa/canada banner.png";
import WhyPrCountry from "../../pageComponent/prPageComponent/whyCountry";
import PrBenefits from "../../pageComponent/prPageComponent/benefits";
import PrEligibility from "../../pageComponent/prPageComponent/eligibility";
import PrDocuments from "../../pageComponent/prPageComponent/prDocuments";
import PrProcess from "../../pageComponent/prPageComponent/prProcess";

const PermanentResidency = () => {
  const { country } = useParams();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const [selectedCountry, setSelectedCountry] = useState(country); // Initially selected country

  useEffect(() => {
    if (country) {
      setSelectedCountry(country);
    }
  }, [country]);

  const canonicalURL = ` https://achintyaconsultant.com//permanent-residency/${country}/`;

  const metaTitles = {
    canada: "Achintya Immigration Services: Your Best Pathway to Canada PR",
    australia:
      "Achintya Immigration Services: Your Best Pathway to Australia PR",
  };

  const metaDescription = {
    canada:
      "Looking for a trusted immigration consultant to assist you with your Canada PR visa? Look no further than Achintya Immigration Services. ",
    australia:
      "Are you interested in obtaining your permanent residency in Australia? Contact us today about how we can assist you with your PR visa for Australia.",
  };

  const countryMetaDescription = metaDescription[country.toLowerCase()];

  const metaKeywords = [
    {
      country: "canada",
      keywords: [
        "apply for canada pr",
        "canada pr consultant in delhi",
        "canada pr requirements",
        "best consultancy for canada pr",
      ],
    },
    {
      country: "australia",
      keywords: [
        "apply for australian pr",
        "australia pr process",
        "australia pr application in delhi",
        "pr consultant for australia ",
      ],
    },
  ];

  const pageTitle = metaTitles[country.toLowerCase()];

  const countryData = metaKeywords.find(
    (item) => item.country === country.toLowerCase()
  );

  const countryKeywords = countryData?.keywords.join(", ");

  const touristCountries = [
    "Ireland",
    "Japan",
    "Australia",
    "Bangladesh",
    "Canada",
    "Dubai",
    "Germany",
    "Malaysia",
    "New Zealand",
    "Sri Lanka",
    "Schengen",
    "Singapore",
    "South Africa",
    "South Korea",
    "Turkey",
    "Thailand",
    "USA",
    "UAE",
    "UK",
    "Vietnam",
  ];

  const jobSearch = [
    "Malta",
    "Poland",
    "Schengen",
    "Singapore",
    "UAE",
    "UK",
    "Australia",
    "Dubai",
    "Germany",
    "Gulf",
  ];

  const otherServices = ["IELTS", "Resume", "B2B"];

  const countryInfo = {
    canada: {
      introduction: {
        heading: "Why Canada?",
        content: `<p>Canada is a highly attractive destination for people worldwide because it offers numerous 
      opportunities and a welcoming, inclusive society. The country has set ambitious immigration 
      targets to welcome even more newcomers, showing its commitment to building a diverse and 
      thriving nation. </p>
      <p>Canada is on a roll, welcoming people from all over the world with open arms! In January 2023, 
      they invited 250 skilled foreign workers nominated by Canadian provinces to apply for 
      permanent residence through Express Entry. That's a big deal because provincial nominees get 
      extra points, making the competition even more exciting! </p>
      <p>Even during the tough times of the pandemic, Canada made history by inviting a whopping 
      112,750 Express Entry applicants in 2022. Wow, talk about determination! </p>
      <p>And the good news keeps coming! In 2023, Canada plans to welcome a total of 431,000 new 
      immigrants, and 116,000 of them will be using the Express Entry system. That means there are a 
      lot of opportunities up for grabs!</p>
      <p>But wait, there's more! In the next two years, Canada's aiming high with a target of 447,055 and 
      451,000 new immigrants, respectively. Out of those, 118,250 and 121,500 will be hopping onto 
      the Express Entry train. </p>
      <p>Canada is serious about embracing diversity and growth, which is why they've set a budget to 
      welcome 432,000 immigrants in 2022 under their Immigration Levels Plan. So, if you're 
      dreaming of a fresh start in a vibrant and inclusive country, Canada is calling your name! </p>
      <p>Canada's immigration plan for this and upcoming year is as follows: 
      <ul>
      <li>2023: 447,055 permanent residents</li>
      <li>2024: 451,000 permanent residents </li>
      </ul>
      </p>
      <p>This demonstrates Canada's openness and commitment to welcoming and integrating newcomers 
      into its society. </p>`,
      },
      benefits_for_tourist: {
        heading: "Benefits of Canada PR",
        description: "",
        pointers: [
          {
            point:
              "Canada is encouraging PR Visa applications, with around 447,055 individuals set to move there in 2023.",
          },
          {
            point:
              "Most opportunities will be given to those applying through economic programs like Express Entry and PNPs.",
          },
          {
            point:
              "Despite Covid-19's impact on other economies, Canada's economy remains relatively unaffected.",
          },
          {
            imgUrl: "",
            point:
              "The Canadian government believes attracting more immigrants is vital for their economic recovery in 2023.",
          },
          {
            point:
              "In 2022, 56% of newcomers arrived through economic-class channels.",
          },
          {
            point:
              "Around 83,500 newcomers are expected to be welcomed through the Provincial Nominee Program this year.",
          },
          {
            point:
              "Express Entry admissions were reduced this year but are expected to return to normal levels by 2024.",
          },
          {
            point:
              "Applying for a PR Visa through these economic programs can benefit your future and contribute to Canada's economy.",
          },
        ],
      },
      documents_required: {
        heading: "Documents Required",
        subHeading:
          "Here's the list of important documents you need to provide within the given timeframe:",
        description: "",
        pointers: [
          {
            point: ` Educational Documents: 10th and 12th certificates, ECA report, Bachelor's and Master's 
            degrees with mark sheets, and IELTS report (all in a single PDF).`,
          },
          {
            point: `Employment Records: Reference letters, promotion letters, salary slips, and other 
            supporting letters from previous employers. `,
          },
          {
            point: `Language Test Results: IELTS, CELPIP, or TEF scores. `,
          },
          {
            point: `Proof of Funds: Bank certification letter, bank statements, fixed deposits, PPF, and ITRs 
            for the last 2 years (all in a single PDF). `,
          },
          {
            point: `Provincial Nomination Certificate (if applicable).`,
          },
          {
            point: `Marriage Certificate (if married) and Divorce Certificate (if separated). `,
          },
          {
            point: `Passport Copy: All passports, starting from the current one to previous ones (all pages in 
              a single PDF). `,
          },
          {
            point: `Medicals Acknowledgement Receipt. `,
          },
          {
            point: `Any other documents requested by the immigration authorities.`,
          },
        ],
      },
      eligibility_criteria: {
        heading: "Eligibility Criteria for Permanent Visa ",
        description: "",
        pointers: [
          {
            point: "Age Group - 18-45 ",
          },
          {
            point: "Bachelors Required (Any qualification) ",
          },
          {
            point: "English Proficiency",
          },
          {
            point: "Clean Record ",
          },
          {
            point: "2+years experience required ",
          },
        ],
      },
      process_for_visa_application: {
        heading: "Permanent Residency Application Process for Canada",
        description: "",
        pointers: [
          {
            stepHeading: "Express Entry Program ",
            point:
              "Submit an Expression of Interest (EOI) in the Express Entry pool. The EOI is valid for one year. ",
          },
          {
            stepHeading: "Comprehensive Ranking System (CRS) ",
            point: `Points are awarded based on various factors like education, work experience, and other criteria, 
            resulting in a score out of 1200 in the Canada immigration point system. `,
          },
          {
            stepHeading: "Invitation to Apply (ITA) ",
            point: `The highest-ranking candidates receive an Invitation to Apply for permanent residency from 
            Immigration Refugees and Citizenship Canada (IRCC). `,
          },
        ],
      },
    },
    australia: {
      introduction: {
        heading: "Why Australia?",
        content: `
        <p>If you are an Indian looking to move to Australia for a better life, you're not alone! Australia has 
        become a popular choice for many reasons like job opportunities, higher wages, a beautiful 
        environment, great education, and excellent living conditions. </p>
        <p>There are currently around 721,000 Indians living in Australia, and China comes in third place in 
        terms of migration. </p>
        <p>Australia is seen as an incredible country by Indians who want to settle abroad and become 
        global citizens. The excitement for Australian immigration is growing among skilled Indians 
        who wish to move there. </p>
        <p>The Australian immigration system allows skilled foreign workers to apply for permanent 
        residency based on their talents and ability to contribute to the country's economy. There are 
        various visa options for working, studying, and investing in Australia, open to people from any 
        country. </p>
        <p>If you want to move to Australia and are looking for information on the PR process, you've come 
        to the right place! </p>
        `,
      },
      benefits_for_tourist: {
        heading: "Benefits of Australia PR",
        description: "",
        pointers: [
          {
            point:
              "Live, work, and study anywhere in Australia as a permanent resident.",
          },
          {
            point:
              "Sponsor eligible family members to become permanent residents.",
          },
          {
            point:
              "Children of permanent residents can receive free education up to a certain age.",
          },
          {
            point:
              "Access 15 social and healthcare benefits as a permanent resident.",
          },
          {
            point:
              "Eligible to apply for Australian citizenship after meeting the requirements.",
          },
          {
            point:
              "Enjoy most of the rights and entitlements that citizens have in Australia.",
          },
        ],
      },
      documents_required: {
        heading: "Documents Required",
        subHeading:
          "To apply for an Australia PR visa, you need these documents: ",
        description: "",
        pointers: [
          {
            point: `Educational certificates (to show your qualifications).`,
          },
          {
            point: `Work experience certificates (if you have relevant work experience). `,
          },
          {
            point: `Travel document (your passport and permission to migrate).`,
          },
          {
            point: `Medical and police verification certificates (to prove you meet health and character 
            requirements). `,
          },
          {
            point: `Proof of settlement funds (to show you have enough money to support yourself in 
            Australia, like bank statements or cash). `,
          },
        ],
      },
      eligibility_criteria: {
        heading: "Eligibility Criteria for Permanent Visa ",
        description: "",
        pointers: [
          {
            point: "Age Criteria – Below 45 ",
          },
          {
            point: "Registration of Interest (ROI) must be approved  ",
          },
          {
            point: "Minimum Points – 65",
          },
          {
            point: "Holds a valid job offer ",
          },
          {
            point: "English Proficiency ",
          },
          {
            point: "Meet the health and character criteria ",
          },
        ],
      },
      process_for_visa_application: {
        heading: " Permanent Residency Application Process for Australia",
        description: "",
        pointers: [
          {
            stepHeading: "Check Eligibility",
            point: `<p>Visit the Department of Home Affairs website to see if you meet the PR visa 
              requirements. </p>
              <p>Choose the visa pathway that matches your skills, education, and experience. </p>
              `,
          },
          {
            stepHeading: "Apply for Skill Assessment ",
            point: `
            <p>Have your education credentials assessed by the designated authority for your 
            occupation.</p>
            <p>This step ensures your skills meet the standards required by Australian employers.</p>`,
          },
          {
            stepHeading: "Submit an Expression of Interest ",
            point: `
            <p>Use the SkillSelect online system to express your interest in a skilled worker visa for 
            Australia.</p>
            <p>The system evaluates your profile based on factors like age, language skills, education, 
            and work experience.</p>
            <p>You need at least 65 points to qualify for a visa stream. </p>`,
          },
          {
            stepHeading: "Wait for the Invitation and Apply",
            point: `
            <p>If you have enough points, you'll receive an Invitation to Apply (ITA) from DHA 
            Australia.</p>
            <p>Respond within 60 days and submit all necessary documents. </p>`,
          },
          {
            stepHeading: "Processing Time ",
            point: `
            <p>Each step takes time as your application progresses toward permanent residency in 
            Australia.</p>`,
          },
        ],
      },
    },
  };

  const bannerData = {
    canada: {
      imgUrl: canadaBannerImage,
      Heading: "Canada Permanent Residency",
    },
    australia: {
      imgUrl: bannerImage,
      Heading: "Australia Permanent Residency",
    },
  };

  return (
    <div>
      <Helmet>
        <link rel="canonical" href={canonicalURL} />
        <title>{pageTitle}</title>
        <meta name="robots" content="index,follow" />
        <meta name="description" content={countryMetaDescription} />
        <meta name="keywords" content={countryKeywords} />
      </Helmet>
      <Header
        touristCountries={touristCountries}
        jobSearch={jobSearch}
        otherServices={otherServices}
      />
      <PrBanner bannerData={bannerData} country={country} />
      <WhyPrCountry
        whyPRCountry={countryInfo[selectedCountry].introduction}
        country={country}
      />
      <PrBenefits
        prBenefits={countryInfo[selectedCountry].benefits_for_tourist}
        country={country}
      />
      <PrEligibility
        eligibilityData={countryInfo[selectedCountry].eligibility_criteria}
        country={country}
      />
      <PrDocuments
        documentsData={countryInfo[selectedCountry].documents_required}
        country={country}
      />
      <PrProcess
        prProcess={countryInfo[selectedCountry].process_for_visa_application}
        country={country}
      />
      <Footer />
    </div>
  );
};

export default PermanentResidency;

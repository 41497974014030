import React, { useEffect } from "react";
import { createCountryUrl } from "../../Utilities/commonFunctions";
import Header from "../../commonComponents/header";
import AbroadForm from "../../commonComponents/abroadForm";
import { Link, useLocation } from "react-router-dom";
import CandaPageComponents from "../../canadExpressPnpPage";
import CommonTableComponent from "../../tableComponent";
import Footer from "../../commonComponents/footer";
import "./index.css";
import ApplyNow from "../../commonButtonComponent/applyNow";
import { Helmet } from "react-helmet";

const LatestExpressEntry = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const navigationHeader = [
    {
      title: "Home",
      link: `/`,
    },
    {
      title: "About Us",
      subItems: [
        {
          title: "About",
          link: `/about-us/`,
        },
        {
          title: "Reviews",
          link: ` /reviews/`,
        },
        {
          title: "Gallery",
          link: `/gallery/`,
        },
      ],
    },
    {
      title: "Canada",
      subItems: [
        {
          title: "Canada Express Entry",
          // link: `/${createCountryUrl("canada")}/express-entry/`,
          subDataItems: [
            {
              title: "Canada Express Entry",
              link: `/${createCountryUrl("canada")}/express-entry/`,
            },
            {
              title: "CRS Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/crs-calculator/`,
            },
            {
              title: "CRS Chart",
              link: `/${createCountryUrl("canada")}/express-entry/crs-chart/`,
            },
            {
              title: "67 Points Calculator and charts",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/${createCountryUrl(
                "67 Points Calculator and charts"
              )}`,
            },
            {
              title: "Latest Express Entry Draw",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/latest-express-entry-draw/`,
            },
            {
              title: "Benefits of the Program",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/benefits-of-the-program/`,
            },
            {
              title: "Occupation Demand",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/occupation-demand/`,
            },
            {
              title: "Provisional Nominee Program",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/provisional-nominee-program/`,
            },
            {
              title: "Federal Skilled Worker Program",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/fedral-skilled-worker-program/`,
            },
            {
              title: "Federal Skilled Trade Program",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/fedral-skilled-trade-program/`,
            },
            {
              title: "Canadian Experience Class",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/canadian-experience-class/`,
            },
            {
              title: "Super Visa From India",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/super-visa-from-india/`,
            },
            {
              title: "IELTS",
              link: `/${createCountryUrl("canada")}/express-entry/ielts/`,
            },
          ],
        },
        {
          title: "Provincial Nominee Program",
          subDataItems: [
            {
              title: "Manitoba PNP Program ",
              link: `/${createCountryUrl(
                "canada"
              )}/pnp-program/${createCountryUrl("Manitoba PNP Program")}/`,
            },
            {
              title: "Yukon Nominee Program ",
              link: `/${createCountryUrl(
                "canada"
              )}/pnp-program/${createCountryUrl("Yukon Nominee Program")}/`,
            },
            {
              title: "Ontario PNP Program ",
              link: `/${createCountryUrl(
                "canada"
              )}/pnp-program/${createCountryUrl("Ontario PNP Program")}/`,
            },
            {
              title: " Nova Scotia PNP ",
              link: `/${createCountryUrl(
                "canada"
              )}/pnp-program/${createCountryUrl("Nova Scotia PNP")}/`,
            },
            {
              title: "Alberta PNP Program ",
              link: `/${createCountryUrl(
                "canada"
              )}/pnp-program/${createCountryUrl("Alberta PNP Program")}/`,
            },
            {
              title: "BC PNP Program ",
              link: `/${createCountryUrl(
                "canada"
              )}/pnp-program/${createCountryUrl("BC PNP Program")}/`,
            },
            {
              title: "Quebec Skilled Worker Program ",
              link: `/pnp-program/${createCountryUrl(
                "Quebec Skilled Worker Program"
              )}/`,
            },
            {
              title: "Saskatchewan PNP ",
              link: `/pnp-program/${createCountryUrl("Saskatchewan PNP ")}/`,
            },
            {
              title: "New Brunswick PNP Program ",
              link: `/pnp-program/${createCountryUrl(
                "New Brunswick PNP Program "
              )}/`,
            },
            {
              title: "Atlantic Immigration Pilot Program ",
              link: `/pnp-program/${createCountryUrl(
                "Atlantic Immigration Pilot Program "
              )}/`,
            },
            {
              title: "PEI PNP Program ",
              link: `/pnp-program/${createCountryUrl("PEI PNP Program ")}/`,
            },
            {
              title: "NLPNP Program ",
              link: `/pnp-program/${createCountryUrl("NLPNP Program")}/`,
            },
          ],
        },
        {
          title: "Point Calculator",
          subDataItems: [
            {
              title: "CRS Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl("CRS Calculator")}/`,
            },
            {
              title: "Saskatchewan Points Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl(
                "Saskatchewan Points Calculator"
              )}`,
            },
            {
              title: "Canada 67 points Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl(
                "Canada 67 points Calculator"
              )}`,
            },
            {
              title: "British columbia Points Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl(
                "British columbia Points Calculator"
              )}`,
            },
            {
              title: "Manitoba Points Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl(
                "Manitoba Points Calculator"
              )}`,
            },
            {
              title: "Nova Scotia Points Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl(
                "Nova Scotia Points Calculator"
              )}`,
            },
            {
              title: "Alberta Points Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl(
                "Alberta Points Calculator"
              )}`,
            },
            {
              title: "Ontario Points Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl(
                "Ontario Points Calculator"
              )}`,
            },
            {
              title: " Quebec Points Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl(
                " Quebec Points Calculator"
              )}`,
            },
          ],
        },
        {
          title: "LMIA",
          link: `/${createCountryUrl("Canada")}/lmia/`,
        },
        {
          title: "Canada Spouse Dependent Visa",
          link: `/${createCountryUrl("Canada")}/spouse-dependent-visa/`,
        },
        {
          title: "Super Visa",
          link: `/${createCountryUrl("Canada")}/super-visa/`,
        },
        {
          title: "Quebec Skilled Worker Program",
          link: `/${createCountryUrl("Canada")}/quebec-skilled-worker-program/`,
        },
        {
          title: "Atlantic Immigration Program",
          link: `/${createCountryUrl("Canada")}/atlantic-immigration-program/`,
        },
        {
          title: "Startup Visa",
          link: `/${createCountryUrl("Canada")}/startup-visa/`,
        },
        {
          title: "Entrepreneur Investment Visa",
          link: `/${createCountryUrl("Canada")}/entrepreneur-investment-visa/`,
        },
      ],
    },
    {
      title: "Australia",
      subItems: [
        {
          title: "Australia Skilled Occupation List",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Australia Skilled Occupation List"
          )}`,
        },
        {
          title: "Australia 65 Points Calculator",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Australia 65 Points Calculator"
          )}`,
        },
        {
          title: "Employer Nomination Subclass 186",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Employer Nomination Subclass 186"
          )}`,
        },
        {
          title: "Skilled Independent Subclass 189",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Skilled Independent Subclass 189"
          )}`,
        },
        {
          title: "Skilled Nominated Subclass 190",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Skilled Nominated Subclass 190"
          )}`,
        },
        {
          title: "Australia Subclass 491",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Australia Subclass 491"
          )}`,
        },
        {
          title: "Australia PR Visa From India",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Australia PR Visa From India"
          )}`,
        },
        {
          title: "Australia Spouse Visa",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "AAustralia Spouse Visa"
          )}`,
        },
        {
          title: "Invitation To Apply",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Invitation To Apply"
          )}`,
        },
        {
          title: "Australia Tourist Visa From India",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Australia Tourist Visa From India"
          )}`,
        },
        {
          title: "Australia Subclass 476",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Australia Subclass 476"
          )}`,
        },
      ],
    },
    {
      title: "Travel Visa",
      link: "/travel-visa/",
      subItems: [
        {
          title: "Canada",
          link: `/travel-visa/${createCountryUrl("Canada")}/`,
        },
        {
          title: "Australia",
          link: `/travel-visa/${createCountryUrl("Australia")}/`,
        },
        {
          title: "USA",
          link: `/travel-visa/${createCountryUrl("USA")}/`,
        },
        {
          title: "UK",
          link: `/travel-visa/${createCountryUrl("UK")}/`,
        },
        {
          title: "New Zealand",
          link: `/travel-visa/${createCountryUrl("New Zealand")}/`,
        },
        {
          title: "Dubai",
          link: `/travel-visa/${createCountryUrl("Dubai")}/`,
        },
        {
          title: "Germany",
          link: `/travel-visa/${createCountryUrl("Germany")}/`,
        },
        {
          title: "Schengen",
          link: `/travel-visa/${createCountryUrl("Schegan")}/`,
        },
        {
          title: "Turkey",
          link: `/travel-visa/${createCountryUrl("Turkey")}/`,
        },
        {
          title: "Malaysia",
          link: `/travel-visa/${createCountryUrl("Malaysia")}/`,
        },
        {
          title: "Singapore",
          link: `/travel-visa/${createCountryUrl("Singapore")}/`,
        },

        {
          title: "Ireland",
          link: `/travel-visa/${createCountryUrl("Ireland")}/`,
        },
        {
          title: "Japan",
          link: `/travel-visa/${createCountryUrl("Japan")}/`,
        },

        {
          title: "Bangladesh",
          link: `/travel-visa/${createCountryUrl("Bangladesh")}/`,
        },

        {
          title: "Sri Lanka",
          link: `/travel-visa/${createCountryUrl("Sri Lanka")}/`,
        },
        {
          title: "South Africa",
          link: `/travel-visa/${createCountryUrl("South Africa")}/`,
        },
        {
          title: "South Korea",
          link: `/travel-visa/${createCountryUrl("South Korea")}/`,
        },

        {
          title: "Thailand",
          link: `/travel-visa/${createCountryUrl("Thailand")}/`,
        },

        {
          title: "UAE",
          link: `/travel-visa/${createCountryUrl("UAE")}/`,
        },

        {
          title: "Vietnam",
          link: `/travel-visa/${createCountryUrl("Vietnam")}/`,
        },
      ],
    },
    {
      title: "Job Search Program",
      subItems: [
        {
          title: "Malta",
          link: `/job-search-visa/${createCountryUrl("Malta")}/`,
        },
        {
          title: "Poland",
          link: `/job-search-visa/${createCountryUrl("Poland")}/`,
        },
        {
          title: "Schengen",
          link: `/job-search-visa/${createCountryUrl("Schengen")}/`,
        },
        {
          title: "Singapore",
          link: `/job-search-visa/${createCountryUrl("Singapore")}/`,
        },
        { title: "UAE", link: `/job-search-visa/${createCountryUrl("UAE")}/` },
        { title: "UK", link: `/job-search-visa/${createCountryUrl("UK")}/` },
        {
          title: "Australia",
          link: `/job-search-visa/${createCountryUrl("Australia")}/`,
        },
        {
          title: "Dubai",
          link: `/job-search-visa/${createCountryUrl("Dubai")}/`,
        },
        {
          title: "Germany",
          link: `/job-search-visa/${createCountryUrl("Germany")}/`,
        },
        {
          title: "Gulf",
          link: `/job-search-visa/${createCountryUrl("Gulf")}/`,
        },
      ],
    },
    {
      title: "Resources",
      link: "/resources/",
      subItems: [
        {
          title: "Career",
          link: "/career/",
        },
        {
          title: "Blogs",
          link: "/blog/",
        },
        {
          title: "News",
          link: "/news/",
        },
      ],
    },
    {
      title: "Connect Us",
      link: "/connect-us/",
    },
  ];

  const enquiryCountries = [
    { label: "Ireland", value: "Ireland" },
    { label: "Japan", value: "Japan" },
    { label: "Australia", value: "Australia" },
    { label: "Bangladesh", value: "Bangladesh" },
    { label: "Canada", value: "Canada" },
    { label: "Dubai", value: "Dubai" },
    { label: "Germany", value: "Germany" },
    { label: "Malaysia", value: "Malaysia" },
    { label: "New Zealand", value: "New Zealand" },
    { label: "Sri Lanka", value: "Sri Lanka" },
    { label: "Schengen", value: "Schegan" },
    { label: "Singapore", value: "Singapore" },
    { label: "South Africa", value: "South Africa" },
    { label: "South Korea", value: "South Kora" },
    { label: "Turkey", value: "Turkey" },
    { label: "Thailand", value: "Thailand" },
    { label: "USA", value: "USA" },
    { label: "UAE", value: "UAE" },
    { label: "UK", value: "UK" },
    { label: "Vietnam", value: "Vietnam" },
    { label: "Malta", value: "Malta" },
    { label: "Poland", value: "POland" },
    { label: "Gulf", value: "Gulf" },
  ];

  const latest_aug_table = {
    row1: {
      column1: {
        heading: "Invitation Round",
      },
      column2: {
        heading: "262",
      },
    },
    row2: {
      column1: "Date and Time of Round",
      column2: "August 15, 2023 at 14:23:51 UTC",
    },
    row3: {
      column1: "Type of Draw",
      column2: "No Program Specified",
    },
    row4: {
      column1: "Number of ITAs Issued ",
      column2: "4,300 ",
    },
    row5: {
      column1: "CRS Score of Lowest-Ranked Candidate Invited",
      column2: "496",
    },
    row6: {
      column1: "Tie-Breaking Rule",
      column2: "August 01, 2023 at 12:59:52 UTC  ",
    },
  };

  const latestIntro = {
    introduction: {
      content:
        "<p><b>The Canadian government usually holds an Express Entry draw about every two weeks. The most recent one took place on August 15, 2023.</b></p>",
    },
  };

  const latest_entry_content = {
    introduction: {
      content: `
        <p>
        In 2023, the lowest CRS score required for all program draws was 481 points, and for PNP-specific draws, it was 691 points. The highest CRS score required for all program draws was 511 points, and for PNP-specific draws, it was 791 points.
        </p>
        <p>
        In 2022, the lowest CRS score required for all program draws was 491 points, and for PNP-specific draws, it was 674 points. The highest CRS score required for all program draws was 557 points, and for PNP-specific draws, it was 808 points.
        </p>
        `,
    },
  };

  const latest_draw_list = {
    introduction: {
      heading: "Canada Express Entry Draw List: 2023",
    },
  };

  const latest_draw_table = {
    row1: {
      column1: "Draw",
      column2: "Date",
      column3: "Number of Invitations",
      column4: "Minimum CRS points",
    },
    row2: {
      column1: "262",
      column2: "Aug-15",
      column3: "4300",
      column4: `<p>496</p>
        <p>*French Language Proficiency</p>
        <p>(2023-1)</p>`,
    },
    row3: {
      column1: "261",
      column2: "Aug-03",
      column3: "1500",
      column4: `<p>388</p>
        <p>*Trade occupations (2023-1)</p>`,
    },
    row4: {
      column1: "260",
      column2: "Aug-02 ",
      column3: "800",
      column4: `<p>435</p>
        <p>*French language proficiency (2023-1)
        </p>`,
    },
    row5: {
      column1: "259",
      column2: "Aug-01",
      column3: "2000",
      column4: `<p>517</p>
<p>*No Program Specified(2023-1)</p>`,
    },
    row6: {
      column1: "258",
      column2: "Jul-12",
      column3: "3800",
      column4: `<p>375</p>
        <p>*French language proficiency(2023-1) </p>
       `,
    },
    row7: {
      column1: "257",
      column2: "Jul-11   ",
      column3: "800",
      column4: `<p>505</p>
      <p>*No Program Specified</p>`,
    },
    row8: {
      column1: "256",
      column2: "Jul-07",
      column3: "2300",
      column4: `<p>439</p>
        <p>*French language proficiency (2023-1)</p>`,
    },
    row9: {
      column1: "255",
      column2: "Jul-06",
      column3: "1500",
      column4: `<p>463</p>
        <p>*Healthcare occupations (2023-1)
        </p>`,
    },
    row10: {
      column1: "254",
      column2: "Jul-05",
      column3: "500",
      column4: `<p>486</p>
      <p>*STEM occupations (2023-1)
      </p>`,
    },

    row11: {
      column1: "244",
      column2: "Mar-23",
      column3: "7000",
      column4: `<p>484</p>
          <p>*No Program Specified (2023-1)</p>`,
    },
    row12: {
      column1: "243",
      column2: "Mar-15",
      column3: "7000",
      column4: `<p>490</p>
          <p>*No Program Specified (2023-1)</p>`,
    },
    row13: {
      column1: "242",
      column2: "Mar-01",
      column3: "667",
      column4: `<p>748</p>
          <p>*Provincial Nominee Program (2023-1)</p>`,
    },
    row14: {
      column1: "241",
      column2: "Feb-15",
      column3: "699",
      column4: `<p>791</p>
          <p>*Provincial Nominee Program (2023-1)</p>`,
    },
    row15: {
      column1: "240",
      column2: "Feb-02",
      column3: "3300",
      column4: `<p>489</p>
          <p>*Federal Skilled Worker (2023-1)</p>`,
    },
    row16: {
      column1: "239",
      column2: "Feb-01",
      column3: "893",
      column4: `<p>733</p>
          <p>*Provincial Nominee Program (2023-1)</p>`,
    },
    row17: {
      column1: "238",
      column2: "Jan-18",
      column3: "5,500",
      column4: `<p>490</p>
          <p>*All Program Considered (2023-1)</p>`,
    },
    row18: {
      column1: "237",
      column2: "Jan-11",
      column3: "5,500",
      column4: `<p>507</p>
          <p>*All Programs Considered (2023-1)</p>`,
    },
  };

  const lates_express = {
    introduction: {
      content: `<p>In the Express Entry process, Canada's Immigration, Refugees, and Citizenship Canada (IRCC) uses a system called the CRS score calculator to give points to candidates. When they decide to invite people, they:</p>
<b>
<ul>
<li>Pick the type of draw they want to do.</li>
<li>Decide how many candidates they want to invite.</li>
<li>Find the candidates with the highest scores.</li>
<li>Invite those high-scoring candidates to apply for permanent residency in Canada.</li>
</ul>
</b>`,
    },
  };

  const types_invitation = {
    introduction: {
      heading: "Types of Invitation Rounds",
      content: `
      <p>There are different types of invitation rounds conducted by IRCC:</p>
      <p><b>
      General Rounds of Invitations:</b>
      In these rounds, IRCC invites the candidates with the highest scores in the pool who are eligible for one of the three programs managed through Express Entry, which are the Federal Skilled Worker Program, Federal Skilled Trades Program, and Canadian Experience Class.
      </p>
      <p>
      <b>Program-Specific Rounds of Invitations:</b>
      n these rounds, IRCC invites candidates with the highest scores who are eligible for a specific Express Entry program connected to a Provincial Nominee Program.
      </p>
      <p>
      <b>Category-Based Rounds of Invitations: </b>
      In these rounds, Canada's Immigration Minister, Sean Fraser, defines a specific category to achieve a particular economic goal. IRCC then invites the highest-scoring candidates in the pool who are eligible for that targeted category.
      </p>
      `,
    },
  };

  const category_based_content = {
    introduction: {
      heading: "Category Based Express Entry Draws",
      content: `
      <p>Canada's Immigration Minister, Sean Fraser, shared on May 31 that Immigration, Refugees, and Citizenship Canada (IRCC) has decided on new groups or types for picking candidates in Express Entry draws. They made these choices by looking at predictions about the job market and talking to different partners, stakeholders, and governments at the provincial and territorial levels. The categories they picked for selection are:</p>
      <b>
      <ul>
      <li>French-language proficiency</li>
      <li>Transport occupations</li>
      <li>Trade occupations</li>
      <li>Science, Technology, Engineering and Math (STEM) occupations</li>
      <li>Agriculture and Agri-Food occupations</li>
      <li>Healthcare occupations</li>
      </ul>
      </b>
      <p>
      People have been anticipating category-based selection draws since June 2022. This anticipation started when the Canadian government changed the Immigration and Refugee Protection Act. These changes made it possible to choose immigrants based on certain qualities that match Canada's economic goals. For example, they might select people with the right work experience to meet job market needs or those who know the French language well.
      </p>
      `,
      content2: `<p><b>The table below shows how many Express Entry profiles are currently in the pool, grouped by the range of their CRS scores.</b></p>`,
    },
  };

  const express_entry_list = {
    introduction: {
      heading: "Express Entry Draw List for the year 2022",
      content: `
      <p>
      Canada's Immigration Minister, Sean Fraser, shared on May 31 that Immigration, Refugees, and Citizenship Canada (IRCC) has decided on new groups or types for picking candidates in Express Entry draws. They made these choices by looking at predictions about the job market and talking to different partners, stakeholders, and governments at the provincial and territorial levels. The categories they picked for selection are:
      </p>
      <b>
      <ul>
      <li>French-language proficiency</li>
      <li>Transport occupations</li>
      <li>Trade occupations</li>
      <li>Science, Technology, Engineering and Math (STEM) occupations</li>
      <li>Agriculture and Agri-Food occupations</li>
      <li>Healthcare occupations</li>
      </ul>
      </b>
      <p>
      People have been anticipating category-based selection draws since June 2022. This anticipation started when the Canadian government changed the Immigration and Refugee Protection Act. These changes made it possible to choose immigrants based on certain qualities that match Canada's economic goals. For example, they might select people with the right work experience to meet job market needs or those who know the French language well.</p>
      `,
    },
  };

  const count_data = {
    row2: {
      column1: "601-1200",
      column2: "984",
    },
    row3: {
      column1: "501-600",
      column2: "2,172",
    },
    row4: {
      column1: "451-500",
      column2: "55,924",
    },
    row5: {
      column1: "491-500",
      column2: "2,709",
    },
    row6: {
      column1: "481-490",
      column2: "6,721",
    },
    row7: {
      column1: "471-480",
      column2: "19,351",
    },
    row8: {
      column1: "461-470",
      column2: "14,976",
    },
    row9: {
      column1: "451-460",
      column2: "12,167",
    },
    row10: {
      column1: "401-450",
      column2: "53,709",
    },
    row11: {
      column1: "441-450",
      column2: "11,184",
    },
    row12: {
      column1: "431-440",
      column2: "11,596",
    },
    row13: {
      column1: "421-430",
      column2: "9,573",
    },
    row14: {
      column1: "411-420",
      column2: "10,282",
    },
    row15: {
      column1: "401-410",
      column2: "11,074",
    },
    row16: {
      column1: "351-400",
      column2: "61,289",
    },
    row17: {
      column1: "301-350",
      column2: "31,980",
    },
    row18: {
      column1: "0-300",
      column2: "5,298",
    },
    row19: {
      column1: "Total",
      column2: "211,356",
    },
  };

  const draw_list_year_table = {
    row1: {
      column1: "Draw",
      column2: "Date",
      column3: "Number of Invitations",
      column4: "Minimum CRS points",
    },
    row2: {
      column1: "253",
      column2: "Jul-04",
      column3: "700",
      column4: `<p>511</p>
            <p>*No Program Specified</p>`,
    },
    row3: {
      column1: "252",
      column2: "Jun-28",
      column3: "500",
      column4: `<p>476</p>
            <p>*Healthcare occupations (2023-1)</p>`,
    },
    row4: {
      column1: "251",
      column2: "Jun-27",
      column3: "4300",
      column4: `<p>486</p>
            <p>*No Program Specified</p>`,
    },
    row5: {
      column1: "250",
      column2: "Jun-08",
      column3: "4800",
      column4: `<p>486</p>
            <p>*No Program Specified</p>`,
    },
    row6: {
      column1: "249",
      column2: "May-24",
      column3: "4800",
      column4: `<p>488</p>
            <p>*No Program Specified</p>`,
    },
    row7: {
      column1: "248",
      column2: "May-10",
      column3: "589",
      column4: `<p>691</p>
            <p>*Provincial Nominee Program</p>`,
    },
    row8: {
      column1: "247",
      column2: "Apr-26",
      column3: "3500",
      column4: `<p>483</p>
            <p>*No Program Specified</p>`,
    },
    row9: {
      column1: "246",
      column2: "Apr-12",
      column3: "3500",
      column4: `<p>486</p>
            <p>*No Program Specified</p>`,
    },
    row10: {
      column1: "245",
      column2: "Mar-29",
      column3: "7000",
      column4: `<p>481</p>
            <p>*No Program Specified</p>`,
    },
    row11: {
      column1: "244",
      column2: "Mar-23",
      column3: "7000",
      column4: `<p>484</p>
            <p>*No Program Specified</p>`,
    },
    row12: {
      column1: "243",
      column2: "Mar-15",
      column3: "7000",
      column4: `<p>490</p>
            <p>*No Program Specified</p>`,
    },
    row13: {
      column1: "242",
      column2: "Mar-01",
      column3: "667",
      column4: `<p>748</p>
            <p>*Provincial Nominee Program</p>`,
    },
    row14: {
      column1: "241",
      column2: "Feb-15",
      column3: "699",
      column4: `<p>791</p>
            <p>*Provincial Nominee Program</p>`,
    },
    row15: {
      column1: "240",
      column2: "Feb-02",
      column3: "3300",
      column4: `<p>489</p>
            <p>*Federal Skilled Worker</p>`,
    },
    row16: {
      column1: "239",
      column2: "Feb-01",
      column3: "893",
      column4: `<p>733</p>
            <p>*Provincial Nominee Program</p>`,
    },
    row17: {
      column1: "238",
      column2: "Jan-18",
      column3: "5,500",
      column4: `<p>490</p>
            <p>*All Program Considered</p>`,
    },
    row18: {
      column1: "237",
      column2: "Jan-11",
      column3: "5,500",
      column4: `<p>507</p>
            <p>*All Programs Considered</p>`,
    },
    // Additional rows with the new data:
    row19: {
      column1: "236",
      column2: "Nov-23",
      column3: "4,750",
      column4: `<p>491</p>
            <p>*All programs considered</p>`,
    },
    row20: {
      column1: "235",
      column2: "Nov-09",
      column3: "4,750",
      column4: `<p>494</p>
            <p>*All programs considered</p>`,
    },
    row21: {
      column1: "234",
      column2: "Oct-26",
      column3: "4,750",
      column4: `<p>496</p>
            <p>*All programs considered</p>`,
    },
    row22: {
      column1: "233",
      column2: "Oct-12",
      column3: "4,250",
      column4: `<p>500</p>
            <p>*All programs considered</p>`,
    },
    row23: {
      column1: "232",
      column2: "Sep-28",
      column3: "3,750",
      column4: `<p>504</p>
            <p>*All programs considered</p>`,
    },
    row24: {
      column1: "231",
      column2: "Sep-14",
      column3: "3,250",
      column4: `<p>510</p>
            <p>*All programs considered</p>`,
    },
    row25: {
      column1: "230",
      column2: "Aug-31",
      column3: "2,750",
      column4: `<p>516</p>
            <p>*All programs considered</p>`,
    },
    row26: {
      column1: "229",
      column2: "Aug-17",
      column3: "2,250",
      column4: `<p>525</p>
            <p>*All programs considered</p>`,
    },
    row27: {
      column1: "228",
      column2: "Aug-03",
      column3: "2,000",
      column4: `<p>533</p>
            <p>*All programs considered</p>`,
    },
    row28: {
      column1: "227",
      column2: "Jul-20",
      column3: "1,750",
      column4: `<p>542</p>
            <p>*All programs considered</p>`,
    },
    row29: {
      column1: "226",
      column2: "Jul-06",
      column3: "1,500",
      column4: `<p>557</p>
            <p>*All programs considered</p>`,
    },
    row30: {
      column1: "225",
      column2: "Jun-22",
      column3: "636",
      column4: `<p>752</p>
            <p>*PNP-specific draw</p>`,
    },
    row31: {
      column1: "224",
      column2: "Jun-08",
      column3: "932",
      column4: `<p>796</p>
            <p>*PNP-specific draw</p>`,
    },
    row32: {
      column1: "223",
      column2: "May-25",
      column3: "589",
      column4: `<p>741</p>
            <p>*PNP-specific draw</p>`,
    },
    row33: {
      column1: "222",
      column2: "May-11",
      column3: "545",
      column4: `<p>753</p>
            <p>*PNP-specific draw</p>`,
    },
    row34: {
      column1: "221",
      column2: "Apr-27",
      column3: "829",
      column4: `<p>772</p>
            <p>*PNP-specific draw</p>`,
    },
    row35: {
      column1: "220",
      column2: "Apr-13",
      column3: "787",
      column4: `<p>782</p>
            <p>*PNP-specific draw</p>`,
    },
    row36: {
      column1: "219",
      column2: "Mar-30",
      column3: "919",
      column4: `<p>785</p>
            <p>*PNP-specific draw</p>`,
    },
    row37: {
      column1: "218",
      column2: "Mar-16",
      column3: "924",
      column4: `<p>754</p>
            <p>*PNP-specific draw</p>`,
    },
    row38: {
      column1: "217",
      column2: "Mar-02",
      column3: "1,047",
      column4: `<p>761</p>
            <p>*PNP-specific draw</p>`,
    },
    row39: {
      column1: "216",
      column2: "Feb-16",
      column3: "1,082",
      column4: `<p>710</p>
            <p>*PNP-specific draw</p>`,
    },
    row40: {
      column1: "215",
      column2: "Feb-02",
      column3: "1,070",
      column4: `<p>674</p>
            <p>*PNP-specific draw</p>`,
    },
    row41: {
      column1: "214",
      column2: "Jan-19",
      column3: "1,036",
      column4: `<p>745</p>
            <p>*PNP-specific draw</p>`,
    },
    row42: {
      column1: "213",
      column2: "Jan-05",
      column3: "392",
      column4: `<p>808</p>
            <p>*PNP-specific draw</p>`,
    },
  };

  const canonicalURL =
    "https://achintyaconsultant.com/canada/express-entry/latest-express-entry-draw/";

  return (
    <div>
      <Helmet>
        <link rel="canonical" href={canonicalURL} />
        <title>
          Achintya Immigration's Express Entry Draw Page: Stay Updated
        </title>
        <meta name="robots" content="index,follow" />
        <meta
          name="description"
          content="Get the latest Express Entry draw information for 2023 with Achintya. Learn about the latest CRS score cutoffs and your eligibility for Canadian immigration."
        />
        <meta name="keywords" content="Express Entry Draw " />
      </Helmet>
      <Header item={navigationHeader} />
      <div className="canada-latest-banner-outer-container">
        <div className="container_wrapper">
          <div className="canada-latest-banner-inner-container">
            <div className="latest-banner-lest-container">
              <div className="vertical-text">#Since 2015</div>
              <h2>Latest Express Entry Draw</h2>
              <p>Canada's 2023</p>
              <div className="apply-now-btn-container-banner">
                <ApplyNow />
              </div>
            </div>
            <div className="latest-banner-right-container">
              <img
                src="https://achintyaconsultant.s3.ap-south-1.amazonaws.com/latest-express-entry/dfdsfsdfsd+1.png"
                alt="latest express entry banner"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container_wrapper">
        <div className="canada-page-flex-container">
          <div className="canada-page-flex-left-container">
            <CandaPageComponents props={latestIntro} />
            <CommonTableComponent props={Object.values(latest_aug_table)} />
            <div className="link-to-page">
              <Link to={"/"}>
                <p>Calculate your CRS Score Now!</p>
              </Link>
            </div>
            <CandaPageComponents props={latest_entry_content} />
            <CandaPageComponents props={latest_draw_list} />
            <CommonTableComponent props={Object.values(latest_draw_table)} />
            <CandaPageComponents props={lates_express} />
            <div className="latest-img-container">
              <img src="https://achintyaconsultant.s3.ap-south-1.amazonaws.com/image+2.png" />
            </div>
            <CandaPageComponents props={types_invitation} />
            <CandaPageComponents props={category_based_content} />
            <CommonTableComponent props={Object.values(count_data)} />
            <CandaPageComponents props={express_entry_list} />
            <CommonTableComponent props={Object.values(draw_list_year_table)} />
          </div>
          <div className="canada-page-right-container">
            <div className="fixed-container">
              <AbroadForm enquiryCountries={enquiryCountries} />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default LatestExpressEntry;

import React from "react";
import './index.css'

const ContactInfo = ({ contactInfo }) => {
  return (
    <div className="contact-info-outer-container">
      <div className="container_wrapper">
        <div className="contact-info-inner-container">
          <p className="contact-info-heading">{contactInfo.heading}</p>
          <p className="contact-info-subheading">{contactInfo.subHeading}</p>
          <p className="contact-info-description">{contactInfo.description}</p>
        </div>
      </div>
    </div>
  );
};

export default ContactInfo;

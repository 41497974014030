import React from "react";
import { createCountryUrl } from "../../Utilities/commonFunctions";
import Header from "../../commonComponents/header";
import Footer from "../../commonComponents/footer";
import ApplyNow from "../../commonButtonComponent/applyNow";
import "./index.css";
import AbroadForm from "../../commonComponents/abroadForm";
import CandaPageComponents from "../../canadExpressPnpPage";
import { Helmet } from "react-helmet";

const CrsChart = () => {
  const navigationHeader = [
    {
      title: "Home",
      link: `/`,
    },
    {
      title: "About Us",
      subItems: [
        {
          title: "About",
          link: `/about-us/`,
        },
        {
          title: "Reviews",
          link: ` /reviews/`,
        },
        {
          title: "Gallery",
          link: `/gallery/`,
        },
      ],
    },
    {
      title: "Canada",
      subItems: [
        {
          title: "Canada Express Entry",
          // link: `/${createCountryUrl("canada")}/express-entry/`,
          subDataItems: [
            {
              title: "Canada Express Entry",
              link: `/${createCountryUrl("canada")}/express-entry/`,
            },
            {
              title: "CRS Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/crs-calculator/`,
            },
            {
              title: "CRS Chart",
              link: `/${createCountryUrl("canada")}/express-entry/crs-chart/`,
            },
            {
              title: "67 Points Calculator and charts",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/${createCountryUrl(
                "67 Points Calculator and charts"
              )}`,
            },
            {
              title: "Latest Express Entry Draw",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/latest-express-entry-draw/`,
            },
            {
              title: "Benefits of the Program",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/benefits-of-the-program/`,
            },
            {
              title: "Occupation Demand",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/occupation-demand/`,
            },
            {
              title: "Provisional Nominee Program",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/provisional-nominee-program/`,
            },
            {
              title: "Federal Skilled Worker Program",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/fedral-skilled-worker-program/`,
            },
            {
              title: "Federal Skilled Trade Program",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/fedral-skilled-trade-program/`,
            },
            {
              title: "Canadian Experience Class",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/canadian-experience-class/`,
            },
            {
              title: "Super Visa From India",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/super-visa-from-india/`,
            },
            {
              title: "IELTS",
              link: `/${createCountryUrl("canada")}/express-entry/ielts/`,
            },
          ],
        },
        {
          title: "Provincial Nominee Program",
          subDataItems: [
            {
              title: "Manitoba PNP Program ",
              link: `/${createCountryUrl(
                "canada"
              )}/pnp-program/${createCountryUrl("Manitoba PNP Program")}/`,
            },
            {
              title: "Yukon Nominee Program ",
              link: `/${createCountryUrl(
                "canada"
              )}/pnp-program/${createCountryUrl("Yukon Nominee Program")}/`,
            },
            {
              title: "Ontario PNP Program ",
              link: `/${createCountryUrl(
                "canada"
              )}/pnp-program/${createCountryUrl("Ontario PNP Program")}/`,
            },
            {
              title: " Nova Scotia PNP ",
              link: `/${createCountryUrl(
                "canada"
              )}/pnp-program/${createCountryUrl("Nova Scotia PNP")}/`,
            },
            {
              title: "Alberta PNP Program ",
              link: `/${createCountryUrl(
                "canada"
              )}/pnp-program/${createCountryUrl("Alberta PNP Program")}/`,
            },
            {
              title: "BC PNP Program ",
              link: `/${createCountryUrl(
                "canada"
              )}/pnp-program/${createCountryUrl("BC PNP Program")}/`,
            },
            {
              title: "Quebec Skilled Worker Program ",
              link: `/pnp-program/${createCountryUrl(
                "Quebec Skilled Worker Program"
              )}/`,
            },
            {
              title: "Saskatchewan PNP ",
              link: `/pnp-program/${createCountryUrl("Saskatchewan PNP ")}/`,
            },
            {
              title: "New Brunswick PNP Program ",
              link: `/pnp-program/${createCountryUrl(
                "New Brunswick PNP Program "
              )}/`,
            },
            {
              title: "Atlantic Immigration Pilot Program ",
              link: `/pnp-program/${createCountryUrl(
                "Atlantic Immigration Pilot Program "
              )}/`,
            },
            {
              title: "PEI PNP Program ",
              link: `/pnp-program/${createCountryUrl("PEI PNP Program ")}/`,
            },
            {
              title: "NLPNP Program ",
              link: `/pnp-program/${createCountryUrl("NLPNP Program")}/`,
            },
          ],
        },
        {
          title: "Point Calculator",
          subDataItems: [
            {
              title: "CRS Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl("CRS Calculator")}/`,
            },
            {
              title: "Saskatchewan Points Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl(
                "Saskatchewan Points Calculator"
              )}`,
            },
            {
              title: "Canada 67 points Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl(
                "Canada 67 points Calculator"
              )}`,
            },
            {
              title: "British columbia Points Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl(
                "British columbia Points Calculator"
              )}`,
            },
            {
              title: "Manitoba Points Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl(
                "Manitoba Points Calculator"
              )}`,
            },
            {
              title: "Nova Scotia Points Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl(
                "Nova Scotia Points Calculator"
              )}`,
            },
            {
              title: "Alberta Points Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl(
                "Alberta Points Calculator"
              )}`,
            },
            {
              title: "Ontario Points Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl(
                "Ontario Points Calculator"
              )}`,
            },
            {
              title: " Quebec Points Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl(
                " Quebec Points Calculator"
              )}`,
            },
          ],
        },
        {
          title: "LMIA",
          link: `/${createCountryUrl("Canada")}/lmia/`,
        },
        {
          title: "Canada Spouse Dependent Visa",
          link: `/${createCountryUrl("Canada")}/spouse-dependent-visa/`,
        },
        {
          title: "Super Visa",
          link: `/${createCountryUrl("Canada")}/super-visa/`,
        },
        {
          title: "Quebec Skilled Worker Program",
          link: `/${createCountryUrl("Canada")}/quebec-skilled-worker-program/`,
        },
        {
          title: "Atlantic Immigration Program",
          link: `/${createCountryUrl("Canada")}/atlantic-immigration-program/`,
        },
        {
          title: "Startup Visa",
          link: `/${createCountryUrl("Canada")}/startup-visa/`,
        },
        {
          title: "Entrepreneur Investment Visa",
          link: `/${createCountryUrl("Canada")}/entrepreneur-investment-visa/`,
        },
      ],
    },
    {
      title: "Australia",
      subItems: [
        {
          title: "Australia Skilled Occupation List",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Australia Skilled Occupation List"
          )}`,
        },
        {
          title: "Australia 65 Points Calculator",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Australia 65 Points Calculator"
          )}`,
        },
        {
          title: "Employer Nomination Subclass 186",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Employer Nomination Subclass 186"
          )}`,
        },
        {
          title: "Skilled Independent Subclass 189",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Skilled Independent Subclass 189"
          )}`,
        },
        {
          title: "Skilled Nominated Subclass 190",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Skilled Nominated Subclass 190"
          )}`,
        },
        {
          title: "Australia Subclass 491",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Australia Subclass 491"
          )}`,
        },
        {
          title: "Australia PR Visa From India",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Australia PR Visa From India"
          )}`,
        },
        {
          title: "Australia Spouse Visa",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "AAustralia Spouse Visa"
          )}`,
        },
        {
          title: "Invitation To Apply",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Invitation To Apply"
          )}`,
        },
        {
          title: "Australia Tourist Visa From India",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Australia Tourist Visa From India"
          )}`,
        },
        {
          title: "Australia Subclass 476",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Australia Subclass 476"
          )}`,
        },
      ],
    },
    {
      title: "Travel Visa",
      link: "/travel-visa/",
      subItems: [
        {
          title: "Canada",
          link: `/travel-visa/${createCountryUrl("Canada")}/`,
        },
        {
          title: "Australia",
          link: `/travel-visa/${createCountryUrl("Australia")}/`,
        },
        {
          title: "USA",
          link: `/travel-visa/${createCountryUrl("USA")}/`,
        },
        {
          title: "UK",
          link: `/travel-visa/${createCountryUrl("UK")}/`,
        },
        {
          title: "New Zealand",
          link: `/travel-visa/${createCountryUrl("New Zealand")}/`,
        },
        {
          title: "Dubai",
          link: `/travel-visa/${createCountryUrl("Dubai")}/`,
        },
        {
          title: "Germany",
          link: `/travel-visa/${createCountryUrl("Germany")}/`,
        },
        {
          title: "Schengen",
          link: `/travel-visa/${createCountryUrl("Schegan")}/`,
        },
        {
          title: "Turkey",
          link: `/travel-visa/${createCountryUrl("Turkey")}/`,
        },
        {
          title: "Malaysia",
          link: `/travel-visa/${createCountryUrl("Malaysia")}/`,
        },
        {
          title: "Singapore",
          link: `/travel-visa/${createCountryUrl("Singapore")}/`,
        },

        {
          title: "Ireland",
          link: `/travel-visa/${createCountryUrl("Ireland")}/`,
        },
        {
          title: "Japan",
          link: `/travel-visa/${createCountryUrl("Japan")}/`,
        },

        {
          title: "Bangladesh",
          link: `/travel-visa/${createCountryUrl("Bangladesh")}/`,
        },

        {
          title: "Sri Lanka",
          link: `/travel-visa/${createCountryUrl("Sri Lanka")}/`,
        },
        {
          title: "South Africa",
          link: `/travel-visa/${createCountryUrl("South Africa")}/`,
        },
        {
          title: "South Korea",
          link: `/travel-visa/${createCountryUrl("South Korea")}/`,
        },

        {
          title: "Thailand",
          link: `/travel-visa/${createCountryUrl("Thailand")}/`,
        },

        {
          title: "UAE",
          link: `/travel-visa/${createCountryUrl("UAE")}/`,
        },

        {
          title: "Vietnam",
          link: `/travel-visa/${createCountryUrl("Vietnam")}/`,
        },
      ],
    },
    {
      title: "Job Search Program",
      subItems: [
        {
          title: "Malta",
          link: `/job-search-visa/${createCountryUrl("Malta")}/`,
        },
        {
          title: "Poland",
          link: `/job-search-visa/${createCountryUrl("Poland")}/`,
        },
        {
          title: "Schengen",
          link: `/job-search-visa/${createCountryUrl("Schengen")}/`,
        },
        {
          title: "Singapore",
          link: `/job-search-visa/${createCountryUrl("Singapore")}/`,
        },
        { title: "UAE", link: `/job-search-visa/${createCountryUrl("UAE")}/` },
        { title: "UK", link: `/job-search-visa/${createCountryUrl("UK")}/` },
        {
          title: "Australia",
          link: `/job-search-visa/${createCountryUrl("Australia")}/`,
        },
        {
          title: "Dubai",
          link: `/job-search-visa/${createCountryUrl("Dubai")}/`,
        },
        {
          title: "Germany",
          link: `/job-search-visa/${createCountryUrl("Germany")}/`,
        },
        {
          title: "Gulf",
          link: `/job-search-visa/${createCountryUrl("Gulf")}/`,
        },
      ],
    },
    {
      title: "Resources",
      link: "/resources/",
      subItems: [
        {
          title: "Career",
          link: "/career/",
        },
        {
          title: "Blogs",
          link: "/blog/",
        },
        {
          title: "News",
          link: "/news/",
        },
      ],
    },
    {
      title: "Connect Us",
      link: "/connect-us/",
    },
  ];

  const enquiryCountries = [
    { label: "Ireland", value: "Ireland" },
    { label: "Japan", value: "Japan" },
    { label: "Australia", value: "Australia" },
    { label: "Bangladesh", value: "Bangladesh" },
    { label: "Canada", value: "Canada" },
    { label: "Dubai", value: "Dubai" },
    { label: "Germany", value: "Germany" },
    { label: "Malaysia", value: "Malaysia" },
    { label: "New Zealand", value: "New Zealand" },
    { label: "Sri Lanka", value: "Sri Lanka" },
    { label: "Schengen", value: "Schegan" },
    { label: "Singapore", value: "Singapore" },
    { label: "South Africa", value: "South Africa" },
    { label: "South Korea", value: "South Kora" },
    { label: "Turkey", value: "Turkey" },
    { label: "Thailand", value: "Thailand" },
    { label: "USA", value: "USA" },
    { label: "UAE", value: "UAE" },
    { label: "UK", value: "UK" },
    { label: "Vietnam", value: "Vietnam" },
    { label: "Malta", value: "Malta" },
    { label: "Poland", value: "POland" },
    { label: "Gulf", value: "Gulf" },
  ];

  const crs_chart_intro = {
    introduction: {
      content: `<p>
       <b>NOTE: </b>If your husband, wife, or partner is not joining you in Canada, or if they are already a Canadian citizen or permanent resident, the immigration system will treat you as if you are applying as an individual. This means you won't get any extra points or benefits based on having a spouse or partner in your application.
        </p>`,
    },
  };

  const point_summary = {
    introduction: {
      heading: `Express Entry Points Summary: Each factor has a maximum point value`,
    },
  };

  const data1 = [
    {
      id: "Factors",
      name: "Points per factor - With  a spouse or common-law partner",
      age: "Points per factor - Without a spouse or common-law partner",
    },
    { id: "Age", name: 100, age: 110 },
    { id: "Level of education", name: 140, age: 150 },
    { id: "Official languages proficiency", name: 150, age: 160 },
    { id: "Canadian work experience", name: 70, age: 80 },
  ];

  const tableData = [
    { id: "(Maximum 100 points)", name: "(Maximum 100 points)", value: "" },
    { id: "17 years of age or less   ", name: 0, value: 0 },
    { id: "18 years of age  ", name: 90, value: 99 },
    { id: "19 years of age    ", name: 95, value: 105 },
    { id: "20 to 29 years of age   ", name: 100, value: 110 },
    { id: "30 years of age", name: 95, value: 105 },
    { id: "31 years of age", name: 90, value: 99 },
    { id: "32 years of age", name: 85, value: 94 },
    { id: "33 years of age ", name: 80, value: 88 },
    { id: "34 years of age ", name: 75, value: 83 },
    { id: "35 years of age ", name: 70, value: 77 },
    { id: "40 years of age    ", name: 45, value: 70 },
    { id: "41 years of age   ", name: 35, value: 39 },
    { id: "42 years of age ", name: 25, value: 28 },
    { id: "43 years of age ", name: 15, value: 17 },
    { id: "44 years of age   ", name: 5, value: 6 },
    { id: "45 years of age or more ", name: 0, value: 0 },
  ];

  const tableData2 = [
    { id: "", name: "(Maximum 100 points)", value: "(Maximum 100 points)" },
    { id: "17 years of age or less   ", name: 0, value: 0 },
    { id: "18 years of age  ", name: 90, value: 99 },
    { id: "19 years of age    ", name: 95, value: 105 },
    { id: "20 to 29 years of age   ", name: 100, value: 110 },
    { id: "30 years of age", name: 95, value: 105 },
    { id: "31 years of age", name: 90, value: 99 },
    { id: "32 years of age", name: 85, value: 94 },
    { id: "33 years of age ", name: 80, value: 88 },
    { id: "34 years of age ", name: 75, value: 83 },
    { id: "35 years of age ", name: 70, value: 77 },
    { id: "40 years of age    ", name: 45, value: 70 },
    { id: "41 years of age   ", name: 35, value: 39 },
    { id: "42 years of age ", name: 25, value: 28 },
    { id: "43 years of age ", name: 15, value: 17 },
    { id: "44 years of age   ", name: 5, value: 6 },
    { id: "45 years of age or more ", name: 0, value: 0 },
  ];

  const tableData3 = [
    { id: "", name: "(Maximum 100 points)", value: "(Maximum 100 points)" },
    { id: "Less than secondary school (high school)", name: "00", value: "00" },
    { id: "Secondary diploma (high school graduation)", name: 28, value: 30 },
    {
      id: "One-year degree, diploma or certificate from a university, college, trade or technical school, or other institute",
      name: 84,
      value: 90,
    },
    {
      id: "Two-year program at a university, college, trade or technical school, or other institute",
      name: 91,
      value: 98,
    },
    {
      id: "Bachelor's degree OR a three or more year program at a university, college, trade or technical school, or other institute",
      name: 112,
      value: 120,
    },
    {
      id: "Two or more certificates, diplomas, or degrees. One must be for a program of three or more years",
      name: 119,
      value: 128,
    },
    {
      id: "Master's degree, OR professional degree needed to practice in a licensed profession (For “professional degree,” the degree program must have been in: medicine, veterinary medicine, dentistry, optometry, law, chiropractic medicine, or pharmacy.)",
      name: 126,
      value: 135,
    },
    { id: "Doctoral level university degree (Ph.D.) ", name: 140, value: 150 },
  ];

  const tableData4 = [
    { id: "", name: "(Maximum 128 points)", value: "(Maximum 136 points)" },
    { id: "Less than CLB 4", name: 0, value: 0 },
    {
      id: "CLB 4 or 5",
      name: 6,
      value: 6,
    },
    {
      id: "CLB 6",
      name: 8,
      value: 9,
    },
    {
      id: "CLB 7",
      name: 16,
      value: 17,
    },
    {
      id: "CLB 8",
      name: 22,
      value: 23,
    },
    {
      id: "CLB 9",
      name: 29,
      value: 31,
    },
    { id: "CLB 10 or more", name: 32, value: 34 },
  ];

  const tableData5 = [
    { id: "", name: "Maximum 22 points)", value: "(Maximum 24 points)" },
    { id: "CLB 4 or less", name: 0, value: 0 },
    { id: "CLB 5 or 6", name: 1, value: 1 },
    {
      id: "CLB 7 or 8",
      name: 3,
      value: 3,
    },
    {
      id: "CLB 9 or more",
      name: 6,
      value: 6,
    },
  ];

  const tableData6 = [
    { id: "", name: "Maximum 70 points)", value: "(Maximum 80 points)" },
    { id: "None or less than a year", name: 0, value: 0 },
    { id: "1 year", name: 35, value: 40 },
    {
      id: "2 year",
      name: 46,
      value: 53,
    },
    {
      id: "3 year",
      name: 56,
      value: 64,
    },
    {
      id: "4 year",
      name: 63,
      value: 72,
    },
    {
      id: "5 years or more",
      name: 70,
      value: 80,
    },
  ];

  const tableData7 = [
    { id: "", name: "(Maximum 150 points)", value: "(Maximum 150 points)" },
    { id: "Less than secondary school (high school", name: 0, value: "N/A" },
    { id: "Secondary diploma (high school graduation)", name: 2, value: "N/A" },
    {
      id: "One-year degree, diploma or certificate from a university, college, trade or technical school, or other institute",
      name: 6,
      value: "N/A",
    },
    {
      id: "Two-year program at a university, college, trade or technical school, or other institute",
      name: 6,
      value: "N/A",
    },
    {
      id: "Bachelor's degree OR a three or more year program at a university, college, trade or technical school, or other institute",
      name: 8,
      value: "N/A",
    },
    {
      id: "Two or more certificates, diplomas, or degrees. One must be for a program of three or more years",
      name: 9,
      value: "N/A",
    },
    {
      id: "Master's degree, OR professional degree needed to practice in a licensed profession (For “professional degree,” the degree program must have been in: medicine, veterinary medicine, dentistry, optometry, law, chiropractic medicine, or pharmacy.)",
      name: 10,
      value: "N/A",
    },
    {
      id: "Doctoral level university degree (Ph.D.)",
      name: 10,
      value: "N/A",
    },
  ];

  const tableData8 = [
    {
      id: "",
      name: "Maximum 5 points per ability",
      value: "(Does not apply)",
    },
    { id: "CLB 4 or less", name: 0, value: "N/A" },
    { id: "CLB 5 or 6", name: 1, value: "N/A" },
    {
      id: "CLB 7 or 8",
      name: 3,
      value: "N/A",
    },
    {
      id: "CLB 9 or more",
      name: 5,
      value: "N/A",
    },
  ];

  const tableData9 = [
    {
      id: "",
      name: "Maximum 10 points",
      value: "(Does not apply)",
    },
    {
      id: "None or less than a year",
      name: 0,
      value: "N/A",
    },
    {
      id: "1 year",
      name: 5,
      value: "N/A",
    },
    {
      id: " 2 year",
      name: 7,
      value: "N/A",
    },
    {
      id: " 3 year",
      name: 8,
      value: "N/A",
    },
    {
      id: " 4 year",
      name: 9,
      value: "N/A",
    },
    {
      id: " 5 year or more",
      name: 10,
      value: "N/A",
    },
  ];

  const tableData10 = [
    {
      id: "",
      name: "Maximum 25 points",
      value: "Maximum 50 points",
    },
    {
      id: "Secondary school (high school) credential or less",
      name: 0,
      value: 0,
    },
    {
      id: "Post-secondary program credential of one year or longer",
      name: 13,
      value: 25,
    },
    {
      id: "Two or more post-secondary program credentials AND at least one of these credentials was issued on completion of a post-secondary program of three years or longer",
      name: 25,
      value: 50,
    },
    {
      id: "A university-level credential at the master’s level or at the level of an entry-to-practice professional degree for an occupation listed in the National Occupational Classification matrix at Skill Level A for which licensing by a provincial regulatory body is required",
      name: 25,
      value: 50,
    },
    {
      id: "A university-level credential at the doctoral level",
      name: 25,
      value: 50,
    },
  ];

  const tableData11 = [
    {
      id: "",
      name: "Maximum 25 points",
      value: "Maximum 50 points",
    },
    {
      id: "Secondary school (high school) credential or less",
      name: 0,
      value: 0,
    },
    {
      id: "Post-secondary program credential of one year or longer",
      name: 13,
      value: 25,
    },
    {
      id: "Two or more post-secondary program credentials AND at least one of these credentials was issued on completion of a post-secondary program of three years or longer",
      name: 25,
      value: 50,
    },
    {
      id: "A university-level credential at the master’s level or at the level of an entry-to-practice professional degree for an occupation listed in the National Occupational Classification matrix at Skill Level A for which licensing by a provincial regulatory body is required",
      name: 25,
      value: 50,
    },
    {
      id: "A university-level credential at the doctoral level",
      name: 25,
      value: 50,
    },
  ];

  const tableData12 = [
    {
      id: "",
      name: "Maximum 25 points",
      value: "Maximum 50 points",
    },
    {
      id: "No foreign work experience",
      name: 0,
      value: 0,
    },
    {
      id: "1 or 2 years of foreign work experience",
      name: 13,
      value: 25,
    },
    {
      id: "3 years or more of foreign work experience",
      name: 25,
      value: 50,
    },
  ];

  const tableData13 = [
    {
      id: "",
      name: "Maximum 25 points",
      value: "Maximum 50 points",
    },
    {
      id: "No foreign work experience",
      name: 0,
      value: 0,
    },
    {
      id: "1 or 2 years of foreign work experience",
      name: 13,
      value: 25,
    },
    {
      id: "3 years or more of foreign work experience",
      name: 25,
      value: 50,
    },
  ];

  const tableData14 = [
    {
      id: "",
      name: "Maximum 25 points",
      value: "Maximum 50 points",
    },
    {
      id: "With a certificate of qualification",
      name: 25,
      value: 50,
    },
  ];

  const tableData15 = [
    {
      id: "Brother or sister living in Canada who is a citizen or permanent resident of Canada",
      name: 15,
    },
    {
      id: "Scored NCLC 7 or higher on all four French language skills and scored CLB 4 or lower in English (or didn’t take an English test)",
      name: 25,
    },
    {
      id: "Scored NCLC 7 or higher on all four French language skills and scored CLB 5 or higher on all four English skills",
      name: 50,
    },
    {
      id: "Post-secondary education in Canada - credential of one or two years",
      name: 15,
    },
    {
      id: "Post-secondary education in Canada - credential three years or longer",
      name: 30,
    },
    {
      id: "Arranged employment – NOC TEER 0 Major group 00",
      name: 200,
    },
    {
      id: "Arranged employment – NOC TEER 1, 2 or 3, or any TEER 0 other than Major group 00",
      name: 50,
    },
    {
      id: "Provincial or territorial nomination 600",
      name: 300,
    },
  ];

  const canonicalURL = "https://achintyaconsultant.com/canada/express-entry/crs-chart/"
  return (
    <div>
      <Helmet>
        <link rel="canonical" href={canonicalURL} />
        <title>Express Entry CRS Score Calculator | Achintya Immigration</title>
        <meta name="robots" content="index,follow" />
        <meta
          name="description"
          content="Explore our comprehensive CRS chart to calculate your Express Entry CRS score accurately. Find out how we can help you achieve your Canadian immigration goals."
        />
        <meta
          name="keywords"
          content="crs points calculator canada | online crs points calculator"
        />
      </Helmet>
      <Header item={navigationHeader} />
      <div className="ielts-page-banner-outer-container">
        <div className="container_wrapper">
          <div className="vertical-text-crs-chart">#Since 2015</div>
          <div className="ielts-banner-inner-container">
            <div className="ielts-banner-heading-container">
              <h2>CRS CHART (Canada)</h2>
              <ApplyNow />
            </div>
            <div className="crs-chart-banner-img">
              <img
                src="https://achintyaconsultant.s3.ap-south-1.amazonaws.com/Frame+(1).png"
                alt="crs chart banner image"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container_wrapper">
        <div className="canada-page-flex-container">
          <div className="canada-page-flex-left-container">
            <CandaPageComponents props={crs_chart_intro} />
            <CandaPageComponents props={point_summary} />

            <div>
              <table className="custom-table">
                <thead>
                  <tr colSpan="3">
                    <th style={{ textAlign: "center" }}>
                      A. Core / human capital factors
                    </th>
                  </tr>
                  <tbody>
                    {data1.map((item, index) => (
                      <tr
                        style={index === 0 ? { fontWeight: "bold" } : {}}
                        key={item.id}
                      >
                        <td style={{ textAlign: "center" }}>{item.id}</td>
                        <td style={{ textAlign: "center" }}>{item.name}</td>
                        <td style={{ textAlign: "center" }}>{item.age}</td>
                      </tr>
                    ))}
                  </tbody>
                </thead>
              </table>
            </div>

            <br />
            <br />

            <div>
              <table>
                <thead>
                  <tr>
                    <th colSpan="2" style={{ textAlign: "center" }}>
                      B. Spouse or common-law partner factors
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td
                      style={{ fontWeight: 600, textAlign: "center" }}
                      rowSpan="2"
                    >
                      Factors
                    </td>
                    <td style={{ fontWeight: 600, textAlign: "center" }}>
                      Points per factor
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: 600, textAlign: "center" }}>
                      (Maximum 40 points)
                    </td>
                  </tr>

                  <tr>
                    <td style={{ textAlign: "center" }}>Level of education</td>
                    <td style={{ textAlign: "center" }}>10</td>
                  </tr>

                  <tr>
                    <td style={{ textAlign: "center" }}>
                      Official languages proficiency
                    </td>
                    <td style={{ textAlign: "center" }}>20</td>
                  </tr>

                  <tr>
                    <td style={{ textAlign: "center" }}>
                      Canadian work experience
                    </td>
                    <td style={{ textAlign: "center" }}>10</td>
                  </tr>

                  <tr>
                    <th colSpan="2">
                      A. Core/human capital + B. Spouse or common-law partner
                      factors = Maximum 500 points (with OR without a spouse or
                      common-law partner)
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>

            <br />
            <br />

            <div>
              <table>
                <thead>
                  <tr>
                    <th colSpan="2" style={{ textAlign: "center" }}>
                      C. Skill Transferability factors (Maximum 100 points){" "}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td
                      style={{ fontWeight: 600, textAlign: "center" }}
                      rowSpan="2"
                      className="table-td-width"
                    >
                      Education{" "}
                    </td>
                    <td style={{ fontWeight: 600, textAlign: "center" }}>
                      Points per factor
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: 600, textAlign: "center" }}>
                      (Maximum 50 points)
                    </td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "center" }}>
                      With good/strong official languages proficiency and a
                      post-secondary degree
                    </td>
                    <td style={{ textAlign: "center" }}>50</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "center" }}>
                      With Canadian work experience and a post- secondary degree
                    </td>
                    <td style={{ textAlign: "center" }}>50</td>
                  </tr>
                  <tr>
                    <td
                      style={{ fontWeight: 600, textAlign: "center" }}
                      rowSpan="2"
                    >
                      Foreign work experience
                    </td>
                    <td style={{ fontWeight: 600, textAlign: "center" }}>
                      Points per factor
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: 600, textAlign: "center" }}>
                      (Maximum 50 points)
                    </td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "center" }}>
                      With good/strong official languages proficiency (Canadian
                      Language Benchmark [CLB] level 7 or higher) and foreign
                      work experience{" "}
                    </td>
                    <td style={{ textAlign: "center" }}>50</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "center" }}>
                      With Canadian work experience and foreign work experience
                    </td>
                    <td style={{ textAlign: "center" }}>50</td>
                  </tr>

                  <tr>
                    <td
                      style={{ fontWeight: 600, textAlign: "center" }}
                      rowSpan="2"
                    >
                      Certificate of qualification (for people in trade
                      occupations){" "}
                    </td>
                    <td style={{ fontWeight: 600, textAlign: "center" }}>
                      Points per factor
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: 600, textAlign: "center" }}>
                      (Maximum 50 points)
                    </td>
                  </tr>

                  <tr>
                    <td style={{ textAlign: "center" }}>
                      With good/strong official languages proficiency and a
                      certificate of qualification{" "}
                    </td>
                    <td style={{ textAlign: "center" }}>50</td>
                  </tr>

                  <tr>
                    <th colSpan="2">
                      A. Core/human capital + B. Spouse or common-law partner +
                      C. Transferability factors = Maximum 600 points{" "}
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>

            <br />
            <br />

            <div>
              <table>
                <thead>
                  <tr>
                    <th colSpan="2" style={{ textAlign: "center" }}>
                      D. Additional points (Maximum 600 points){" "}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td
                      className="table-td-width"
                      style={{ fontWeight: 600, textAlign: "center" }}
                    >
                      Factors
                    </td>
                    <td style={{ fontWeight: 600, textAlign: "center" }}>
                      Maximum points per factor
                    </td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "center" }}>
                      Brother or sister living in Canada (citizen or permanent
                      resident)
                    </td>
                    <td style={{ textAlign: "center" }}>15</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "center" }}>
                      French language skills
                    </td>
                    <td style={{ textAlign: "center" }}>50</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "center" }}>
                      Post-secondary education in Canada
                    </td>
                    <td style={{ textAlign: "center" }}>30</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "center" }}>
                      Arranged employment - TEER 0 Major group 00
                    </td>
                    <td style={{ textAlign: "center" }}>200</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "center" }}>
                      Arranged employment –NOC TEER 1, 2, or 3, or any TEER 0
                      other than Major Group 00
                    </td>
                    <td style={{ textAlign: "center" }}>50</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "center" }}>PN nomination</td>
                    <td style={{ textAlign: "center" }}>600</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="chart-image">
              <img src="https://achintyaconsultant.s3.ap-south-1.amazonaws.com/Untitled-1+1.png" />
            </div>

            <div className="total-express-points-container">
              <p>
                <b>Total Express Entry Points:</b>
              </p>
              <ul>
                <li>Your Skills </li>
                <li>Partner Factors </li>
                <li>Transferability</li>
                <li> Extra Points</li>
              </ul>
              <p>
                <b>Grand Total: Max 1,200 Points</b>
              </p>

              <p>
                <b>Points for You and Your Partner:</b>
              </p>
              <ul>
                <li>With Partner: Max 460 points </li>
                <li>Without Partner: Max 500 points </li>
              </ul>
            </div>

            <br />
            <br />
            <div>
              <table>
                <thead>
                  <tr>
                    <th className="crs-spouse-heading">
                      With a spouse or common -law partner
                    </th>
                    <th className="crs-spouse-heading">
                      Without a spouse or common-law partner
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.map((row) => (
                    <tr key={row.id}>
                      <td style={{ textAlign: "center" }}>{row.id}</td>
                      <td style={{ textAlign: "center" }}>{row.name}</td>
                      <td style={{ textAlign: "center" }}>{row.value}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <br />
            <br />
            <div style={{ marginTop: "5%" }}>
              <table>
                <thead>
                  <tr>
                    <th></th>
                    <th className="crs-spouse-heading">
                      Without a spouse or common-law partner
                    </th>
                    <th className="crs-spouse-heading">
                      With a spouse or common -law partner
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {tableData2.map((row) => (
                    <tr key={row.id}>
                      <td style={{ textAlign: "center" }}>{row.id}</td>
                      <td style={{ textAlign: "center" }}>{row.name}</td>
                      <td style={{ textAlign: "center" }}>{row.value}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <br />
            <br />
            <div style={{ marginTop: "5%" }}>
              <table>
                <thead>
                  <tr>
                    <th className="crs-spouse-heading">Level of Education</th>
                    <th className="crs-spouse-heading">
                      With a spouse or common-law partner
                    </th>
                    <th className="crs-spouse-heading">
                      Without a spouse or common-law partner
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {tableData3.map((row) => (
                    <tr key={row.id}>
                      <td style={{ textAlign: "center" }}>{row.id}</td>
                      <td style={{ textAlign: "center" }}>{row.name}</td>
                      <td style={{ textAlign: "center" }}>{row.value}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <br />
            <br />
          </div>

          <div className="canada-page-right-container">
            <div className="fixed-container">
              <AbroadForm enquiryCountries={enquiryCountries} />
            </div>
          </div>
        </div>
      </div>

      <div className="crs-chart-grey-container">
        <div className="container_wrapper">
          <div className="crs-chart-grey-flex-contaienr">
            <div className="grey-left-container">
              <div>
                <p className="grey-text">Points for Language Skills:</p>
                <p className="grey-text">
                  With Partner:<span> Up to 32 per skill</span>
                </p>
                <p className="grey-text">
                  Without Partner:<span> Up to 34 per skill</span>
                </p>
              </div>

              <div style={{ marginTop: "5%" }}>
                <table className="crs-table-color">
                  <thead>
                    <tr>
                      <th className="crs-spouse-heading">
                        Canadian Language Benchmark (CLB) level per abilityn
                      </th>
                      <th className="crs-spouse-heading">
                        With a spouse or common-law partner
                      </th>
                      <th className="crs-spouse-heading">
                        Without a spouse or common-law partner
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData4.map((row) => (
                      <tr key={row.id}>
                        <td style={{ textAlign: "center" }}>{row.id}</td>
                        <td style={{ textAlign: "center" }}>{row.name}</td>
                        <td style={{ textAlign: "center" }}>{row.value}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <br />
              <br />
              <div className="crs-canada-img-container">
                <img
                  src="https://achintyaconsultant.s3.ap-south-1.amazonaws.com/Group+260.png"
                  alt="canada"
                />
              </div>
            </div>
            <div className="grey-right-container">
              <div>
                <p className="grey-text">Points for Second Language Skills:</p>
                <p className="grey-text">
                  With Partner:
                  <span> Up to 6 per skill (up to 22 points in total)</span>
                </p>
                <p className="grey-text">
                  Without Partner:
                  <span> Up to 6 per skill (up to 24 points in total)</span>
                </p>
              </div>

              <div>
                <div style={{ marginTop: "5%" }}>
                  <table className="crs-table-color">
                    <thead>
                      <tr>
                        <th className="crs-spouse-heading">
                          Canadian Language Benchmark (CLB) level per ability
                        </th>
                        <th className="crs-spouse-heading">
                          With a spouse or common-law partner
                        </th>
                        <th className="crs-spouse-heading">
                          Without a spouse or common-law partner
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableData5.map((row) => (
                        <tr key={row.id}>
                          <td style={{ textAlign: "center" }}>{row.id}</td>
                          <td style={{ textAlign: "center" }}>{row.name}</td>
                          <td style={{ textAlign: "center" }}>{row.value}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>

              <div>
                <div style={{ marginTop: "5%" }}>
                  <table className="crs-table-color">
                    <thead>
                      <tr>
                        <th className="crs-spouse-heading">
                          Canadian work experience{" "}
                        </th>
                        <th className="crs-spouse-heading">
                          With a spouse or common-law partner
                        </th>
                        <th className="crs-spouse-heading">
                          Without a spouse or common-law partner
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableData6.map((row) => (
                        <tr key={row.id}>
                          <td style={{ textAlign: "center" }}>{row.id}</td>
                          <td style={{ textAlign: "center" }}>{row.name}</td>
                          <td style={{ textAlign: "center" }}>{row.value}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <br />
      <br />

      <div className="container_wrapper">
        <div>
          <p className="grey-text">Total Points for Personal Skills:</p>
          <p className="grey-text">
            With Partner:
            <span> Up to 460 points</span>
          </p>
          <p className="grey-text">
            Without Partner:
            <span> Up to 500 points</span>
          </p>
        </div>
        <div>
          <div>
            <div style={{ marginTop: "5%" }}>
              <table className="crs-table-color">
                <thead>
                  <tr>
                    <th className="crs-spouse-heading">Level of Education </th>
                    <th
                      style={{ width: "150px" }}
                      className="crs-spouse-heading"
                    >
                      With a spouse or common-law partner
                    </th>
                    <th
                      style={{ width: "150px" }}
                      className="crs-spouse-heading"
                    >
                      Without a spouse or common-law partner
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {tableData7.map((row) => (
                    <tr key={row.id}>
                      <td style={{ textAlign: "center" }}>{row.id}</td>
                      <td style={{ textAlign: "center" }}>{row.name}</td>
                      <td style={{ textAlign: "center" }}>{row.value}</td>
                    </tr>
                  ))}
                  <tr>
                    <td
                      style={{ textAlign: "center", fontWeight: "bold" }}
                      colSpan="3"
                    >
                      NOTE: (n/a) means it is not applicable in this situation.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div>
          <div className="crs-chart-total-points-container">
            <div className="total-points-left-container">
              <div style={{ marginTop: "5%" }}>
                <table className="crs-table-color">
                  <thead>
                    <tr>
                      <td
                        style={{ textAlign: "center", fontWeight: "bold" }}
                        colSpan="3"
                      >
                        Spouse's or common-law partner's official language
                        proficiency - first official language{" "}
                      </td>
                    </tr>
                    <tr>
                      <th className="crs-spouse-heading">
                        Canadian Language Benchmark (CLB) level per ability
                        (reading, writing, speaking and listening )
                      </th>
                      <th
                        style={{ width: "150px" }}
                        className="crs-spouse-heading"
                      >
                        Maximum 20 points for section{" "}
                      </th>
                      <th
                        style={{ width: "150px" }}
                        className="crs-spouse-heading"
                      >
                        With a spouse or common-law partner{" "}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData8.map((row) => (
                      <tr key={row.id}>
                        <td style={{ textAlign: "center" }}>{row.id}</td>
                        <td style={{ textAlign: "center" }}>{row.name}</td>
                        <td style={{ textAlign: "center" }}>{row.value}</td>
                      </tr>
                    ))}
                    <tr>
                      <td
                        style={{ textAlign: "center", fontWeight: "bold" }}
                        colSpan="3"
                      >
                        NOTE: (n/a) means it is not applicable in this
                        situation.
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="total-points-right-container">
              <div style={{ marginTop: "5%" }}>
                <table className="crs-table-color">
                  <thead>
                    <tr>
                      <th className="crs-spouse-heading">
                        Spouse's Canadian work experience
                      </th>
                      <th
                        style={{ width: "150px" }}
                        className="crs-spouse-heading"
                      >
                        Without a spouse or common-law partner
                      </th>
                      <th
                        style={{ width: "150px" }}
                        className="crs-spouse-heading"
                      >
                        With a spouse or common-law partner{" "}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData9.map((row) => (
                      <tr key={row.id}>
                        <td style={{ textAlign: "center" }}>{row.id}</td>
                        <td style={{ textAlign: "center" }}>{row.name}</td>
                        <td style={{ textAlign: "center" }}>{row.value}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <p
            style={{ textAlign: "center", fontWeight: "normal" }}
            className="grey-text"
          >
            Total Points for Personal Skills and Partner:
            <span style={{ fontWeight: "bold" }}> Max 500 points</span>
          </p>
        </div>
      </div>
      <div className="crs-chart-grey-container">
        <div className="container_wrapper">
          <p className="crs-c-heading">
            CRS – C. Skill transferability factors: Max 100 points in this
            section
          </p>
          <div style={{ marginTop: "5%" }}>
            <table className="crs-table-color">
              <thead>
                <tr>
                  <td
                    style={{ textAlign: "center", fontWeight: "bold" }}
                    colSpan="3"
                  >
                    Education
                  </td>
                </tr>
                <tr>
                  <th className="crs-spouse-heading">
                    With good official language proficiency (Canadian Language
                    Benchmark Level [CLB] 7 or higher) and a post-secondary
                    degree
                  </th>
                  <th style={{ width: "150px" }} className="crs-spouse-heading">
                    Points for CLB 7 or more on all first official language
                    abilities, with one or more under CLB 9{" "}
                  </th>
                  <th style={{ width: "150px" }} className="crs-spouse-heading">
                    Points for CLB 9 or more on all four first official language
                    abilities{" "}
                  </th>
                </tr>
              </thead>
              <tbody>
                {tableData10.map((row) => (
                  <tr key={row.id}>
                    <td style={{ textAlign: "center" }}>{row.id}</td>
                    <td style={{ textAlign: "center" }}>{row.name}</td>
                    <td style={{ textAlign: "center" }}>{row.value}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div>
        <div className="container_wrapper">
          <div style={{ marginTop: "5%" }}>
            <table className="crs-table-color">
              <thead>
                <tr>
                  <th className="crs-spouse-heading">
                    With Canadian work experience and a post-secondary degree
                  </th>
                  <th style={{ width: "150px" }} className="crs-spouse-heading">
                    Points for education + 1 year of Canadian work experience
                  </th>
                  <th style={{ width: "150px" }} className="crs-spouse-heading">
                    Points for education + 2 years or more of Canadian work
                    experience
                  </th>
                </tr>
              </thead>
              <tbody>
                {tableData11.map((row) => (
                  <tr key={row.id}>
                    <td style={{ textAlign: "center" }}>{row.id}</td>
                    <td style={{ textAlign: "center" }}>{row.name}</td>
                    <td style={{ textAlign: "center" }}>{row.value}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <p style={{ textAlign: "center" }} className="grey-text">
            Work experience abroad - With strong language skills in English or
            French (CLB 7 or higher) Years of experience{" "}
          </p>

          <div style={{ marginTop: "5%" }}>
            <table className="crs-table-color">
              <thead>
                <tr>
                  <th className="crs-spouse-heading">Years of experience </th>
                  <th style={{ width: "150px" }} className="crs-spouse-heading">
                    Points for foreign work experience + CLB 7 or more on all
                    first official language abilities, one or more under 9{" "}
                  </th>
                  <th style={{ width: "150px" }} className="crs-spouse-heading">
                    Points for foreign work experience + CLB 9 or more on all
                    four first official anguage abilities
                  </th>
                </tr>
              </thead>
              <tbody>
                {tableData12.map((row) => (
                  <tr key={row.id}>
                    <td style={{ textAlign: "center" }}>{row.id}</td>
                    <td style={{ textAlign: "center" }}>{row.name}</td>
                    <td style={{ textAlign: "center" }}>{row.value}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div style={{ marginTop: "5%" }}>
            <table className="crs-table-color">
              <thead>
                <tr>
                  <td
                    style={{ textAlign: "center", fontWeight: "bold" }}
                    colSpan="3"
                  >
                    Foreign work experience – With Canadian work experience{" "}
                  </td>
                </tr>
                <tr>
                  <th className="crs-spouse-heading">Years of experience</th>
                  <th style={{ width: "150px" }} className="crs-spouse-heading">
                    Points for foreign work experience + 1 year of Canadian work
                    experience
                  </th>
                  <th style={{ width: "150px" }} className="crs-spouse-heading">
                    Points for foreign work experience + 2 years or more of
                    Canadian work experience
                  </th>
                </tr>
              </thead>
              <tbody>
                {tableData13.map((row) => (
                  <tr key={row.id}>
                    <td style={{ textAlign: "center" }}>{row.id}</td>
                    <td style={{ textAlign: "center" }}>{row.name}</td>
                    <td style={{ textAlign: "center" }}>{row.value}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div style={{ marginTop: "5%" }}>
            <table className="crs-table-color">
              <thead>
                <tr>
                  <th className="crs-spouse-heading">
                    Certificate of qualification (trade occupations) – With good
                    official language proficiency (Canadian Language Benchmark
                    Level [CLB] 5 or higher)
                  </th>
                  <th style={{ width: "150px" }} className="crs-spouse-heading">
                    Points for certificate of qualification + CLB 5 or more on
                    all first official language abilities, one or more under 7
                  </th>
                  <th style={{ width: "150px" }} className="crs-spouse-heading">
                    Points for certificate of qualification + CLB 7 or more on
                    all four first official language abilities
                  </th>
                </tr>
              </thead>
              <tbody>
                {tableData14.map((row) => (
                  <tr key={row.id}>
                    <td style={{ textAlign: "center" }}>{row.id}</td>
                    <td style={{ textAlign: "center" }}>{row.name}</td>
                    <td style={{ textAlign: "center" }}>{row.value}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="crs-chart-flex-contaienr">
            <div className="crs-chart-left-cont">
              <div style={{ marginTop: "5%" }}>
                <table className="crs-table-color">
                  <thead>
                    <tr>
                      <td
                        style={{ textAlign: "center", fontWeight: "bold" }}
                        colSpan="3"
                      >
                        CRS – D. Additional points (Maximum 600 points){" "}
                      </td>
                    </tr>
                    <tr>
                      <th className="crs-spouse-heading">Additional points</th>
                      <th
                        style={{ width: "150px" }}
                        className="crs-spouse-heading"
                      >
                        Maximum 600 points
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData15.map((row) => (
                      <tr key={row.id}>
                        <td style={{ textAlign: "center" }}>{row.id}</td>
                        <td style={{ textAlign: "center" }}>{row.name}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="crs-chart-right-cont">
              <p style={{ textAlign: "left" }} className="grey-text">
                Subtotal: A. Core / human capital + B. Spouse or common- law
                partner + C. Skill transferability factors - Maximum 600 points
              </p>
              <div className="crs-img-c">
                <img
                  src="https://achintyaconsultant.s3.ap-south-1.amazonaws.com/Frame.png"
                  alt="crs-chart-image"
                />
              </div>
            </div>
          </div>
          <p style={{ textAlign: "center" }} className="grey-text">
            Subtotal: D. Additional points – Maximum 600 points
          </p>
          <p style={{ textAlign: "center" }} className="grey-text">
            Grand total: A. Core / human capital + B. Spouse or common-law
            partner + C. Skill transferability factors + D. Additional points =
            Maximum 1,200 points{" "}
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CrsChart;

import React, { useEffect, useState } from "react";
import EnquiryComponent from "../enquiryComponent";

const OpenScrollComponent = () => {
  const [showForm, setShowForm] = useState(false);


  useEffect(() => {
    let timer;

    const handleScroll = () => {
        console.log(showForm)
      if (timer) {
        clearTimeout(timer);
      }

      timer = setTimeout(() => {
        setShowForm(true);
      }, 3000); // 3000 milliseconds = 3 seconds
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      clearTimeout(timer);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return <div>{showForm ? <EnquiryComponent /> : ""}</div>;
};

export default OpenScrollComponent;

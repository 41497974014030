import React from "react";
import "./index.css";
import CloseIcon from "@mui/icons-material/Close";
import immigrationImage from "../../assets/immigration.png";
import FormRightSide from "../enquiryRightSide";

const EnquiryComponent = ({ isOpen, onClose, enquiryCountries }) => {
  if (!isOpen) return null;

  return (
    <div className="enquiry-outer-container">
      <div className="formWrapper">
        <div className="formWrapper_header">
          <p className="form-heading">Submit Your Enquiry</p>
          <div className="close-icon" onClick={onClose}>
            <CloseIcon />
          </div>
        </div>
        <div className="formWrapper_body">
          <div className="form-body-wrapper">
            <div className="sections form-left-section">
              <img src={immigrationImage} alt="form image" />
            </div>

            <div className="form-divider-line"></div>

            <div className="sections form-right-section">
              <FormRightSide enquiryCountries={enquiryCountries} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnquiryComponent;

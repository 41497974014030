import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import Header from "../../commonComponents/header";
import PrBenefits from "../../pageComponent/prPageComponent/benefits";
import PrDocuments from "../../pageComponent/prPageComponent/prDocuments";
import WhyPrCountry from "../../pageComponent/prPageComponent/whyCountry";
import PrEligibility from "../../pageComponent/prPageComponent/eligibility";
import PrProcess from "../../pageComponent/prPageComponent/prProcess";
import Footer from "../../commonComponents/footer";
import TouristVisaRequirement from "../../commonComponents/touristVisaRequirements";
import TouristBanner from "../../pageComponent/touristVisaComponent/touristVisaBanner";
import australiaImage from "../../assets/touristVisa/australia.png";
import canadaImage from "../../assets/touristVisa/canada.png";
import irelandImage from "../../assets/touristVisa/ireland.png";
import germanyImage from "../../assets/touristVisa/germany.png";
import newZealandImage from "../../assets/touristVisa/NewZealand.png";
import japan from "../../assets/touristVisa/japan.png";
import singapore from "../../assets/touristVisa/singapore.png";
import bangladesh from "../../assets/touristVisa/bangladesh.png";
import gulf from "../../assets/touristVisa/gulf.png";
import malaysia from "../../assets/touristVisa/malaysia.png";
import srilanka from "../../assets/touristVisa/srilanka.png";
import schegan from "../../assets/touristVisa/schengan.png";
import southAfrica from "../../assets/touristVisa/South africa.png";
import thailand from "../../assets/touristVisa/Thailand.png";
import turkey from "../../assets/touristVisa/turkey.png";
import vietnam from "../../assets/touristVisa/vietnam.png";
import uk from "../../assets/touristVisa/UK.png";
import usa from "../../assets/touristVisa/USa.png";
import southKorea from "../../assets/touristVisa/Soth Korea.png";
import { createCountryUrl } from "../../Utilities/commonFunctions";

const TouristVisa = () => {
  const { country } = useParams();
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const [selectedCountry, setSelectedCountry] = useState(country); // Initially selected country
  // const formattedCountry = country.replace("-", "_");
  useEffect(() => {
    if (country) {
      setSelectedCountry(country);
    }
  }, [country]);

  const canonicalURL = ` https://achintyaconsultant.com/travel-visa/${country}/`;
  const navigationHeader = [
    {
      title: "Home",
      link: `/`,
    },
    {
      title: "About Us",
      subItems: [
        {
          title: "About",
          link: `/about-us/`,
        },
        {
          title: "Reviews",
          link: ` /reviews/`,
        },
        {
          title: "Gallery",
          link: `/gallery/`,
        },
      ],
    },
    {
      title: "Canada",
      subItems: [
        {
          title: "Canada Express Entry",
          // link: `/${createCountryUrl("canada")}/express-entry/`,
          subDataItems: [
            {
              title: "Canada Express Entry",
              link: `/${createCountryUrl("canada")}/express-entry/`,
            },
            {
              title: "CRS Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/crs-calculator/`,
            },
            {
              title: "CRS Chart",
              link: `/${createCountryUrl("canada")}/express-entry/crs-chart/`,
            },
            {
              title: "67 Points",
              link: `/${createCountryUrl("canada")}/express-entry/67-points/`,
            },
            {
              title: "Latest Express Entry Draw",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/latest-express-entry-draw/`,
            },
            {
              title: "Benefits of the Program",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/benefits-of-the-program/`,
            },
            {
              title: "Occupation Demand",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/occupation-demand/`,
            },
            {
              title: "Provisional Nominee Program",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/provisional-nominee-program/`,
            },
            {
              title: "Federal Skilled Worker Program",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/fedral-skilled-worker-program/`,
            },
            {
              title: "Federal Skilled Trade Program",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/fedral-skilled-trade-program/`,
            },
            {
              title: "Canadian Experience Class",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/canadian-experience-class/`,
            },
          ],
        },
        {
          title: "Provincial Nominee Program",
          link: `/${createCountryUrl("Canada")}/provincial-nominee-program/`,
        },
        {
          title: "Point Calculator",
          link: `/${createCountryUrl("Canada")}/point-calculator/`,
        },
        {
          title: "Find your NOC",
          link: `/${createCountryUrl("Canada")}/find-your-noc/`,
        },
        {
          title: "LMIA",
          link: `/${createCountryUrl("Canada")}/lmia/`,
        },
        {
          title: "Canada Spouse Dependent Visa",
          link: `/${createCountryUrl("Canada")}/spouse-dependent-visa/`,
        },
        {
          title: "Super Visa",
          link: `/${createCountryUrl("Canada")}/super-visa/`,
        },
        {
          title: "Quebec Skilled Worker Program",
          link: `/${createCountryUrl("Canada")}/quebec-skilled-worker-program/`,
        },
        {
          title: "Atlantic Immigration Program",
          link: `/${createCountryUrl("Canada")}/atlantic-immigration-program/`,
        },
        {
          title: "Startup Visa",
          link: `/${createCountryUrl("Canada")}/startup-visa/`,
        },
        {
          title: "Entrepreneur Visa",
          link: `/${createCountryUrl("Canada")}/entrepreneur-visa/`,
        },
      ],
    },
    {
      title: "PNP Program",
      link: "/pnp-program/",
      subItems: [
        {
          title: "Manitoba PNP Program ",
          link: `/pnp-program/${createCountryUrl("Manitoba PNP Program")}/`,
        },
        {
          title: "Yukon Nominee Program ",
          link: `/pnp-program/${createCountryUrl("Yukon Nominee Program")}/`,
        },
        {
          title: "Ontario PNP Program ",
          link: `/pnp-program/${createCountryUrl("Ontario PNP Program")}/`,
        },
        {
          title: " Nova Scotia PNP ",
          link: `/pnp-program/${createCountryUrl("Nova Scotia PNP")}/`,
        },
        {
          title: "Alberta PNP Program ",
          link: `/pnp-program/${createCountryUrl("Alberta PNP Program")}/`,
        },
        {
          title: "BC PNP Program ",
          link: `/pnp-program/${createCountryUrl("BC PNP Program")}/`,
        },
        {
          title: "Quebec Skilled Worker Program ",
          link: `/pnp-program/${createCountryUrl(
            "Quebec Skilled Worker Program"
          )}/`,
        },
        {
          title: "Saskatchewan PNP ",
          link: `/pnp-program/${createCountryUrl("Saskatchewan PNP ")}/`,
        },
        {
          title: "New Brunswick PNP Program ",
          link: `/pnp-program/${createCountryUrl(
            "New Brunswick PNP Program "
          )}/`,
        },
        {
          title: "Atlantic Immigration Pilot Program ",
          link: `/pnp-program/${createCountryUrl(
            "Atlantic Immigration Pilot Program "
          )}/`,
        },
        {
          title: "PEI PNP Program ",
          link: `/pnp-program/${createCountryUrl("PEI PNP Program ")}/`,
        },
        {
          title: "NLPNP Program ",
          link: `/pnp-program/${createCountryUrl("NLPNP Program")}/`,
        },
      ],
    },
    {
      title: "Point Calculator",
      link: "/point-calculator/",
    },
    {
      title: "Australia",
      subItems: [
        {
          title: "Australia Skilled Immigration Program",
          link: "/australia/australia-skilled-immigration-program/",
        },
        {
          title: "Spouse Dependent Visa",
          link: `/${createCountryUrl("Australia")}/spouse-dependent-visa/`,
        },
        {
          title: "Parent Visa",
          link: `/${createCountryUrl("Australia")}/parent-visa/`,
        },
        {
          title: "Investment Visa",
          link: `/${createCountryUrl("Australia")}/investment-visa/`,
        },
      ],
    },
    {
      title: "Travel Visa",
      link: "/travel-visa/",
      subItems: [
        {
          title: "Canada",
          link: `/travel-visa/${createCountryUrl("Canada")}/`,
        },
        {
          title: "Australia",
          link: `/travel-visa/${createCountryUrl("Australia")}/`,
        },
        {
          title: "USA",
          link: `/travel-visa/${createCountryUrl("USA")}/`,
        },
        {
          title: "UK",
          link: `/travel-visa/${createCountryUrl("UK")}/`,
        },
        {
          title: "New Zealand",
          link: `/travel-visa/${createCountryUrl("New Zealand")}/`,
        },
        {
          title: "Dubai",
          link: `/travel-visa/${createCountryUrl("Dubai")}/`,
        },
        {
          title: "Germany",
          link: `/travel-visa/${createCountryUrl("Germany")}/`,
        },
        {
          title: "Schengen",
          link: `/travel-visa/${createCountryUrl("Schegan")}/`,
        },
        {
          title: "Turkey",
          link: `/travel-visa/${createCountryUrl("Turkey")}/`,
        },
        {
          title: "Malaysia",
          link: `/travel-visa/${createCountryUrl("Malaysia")}/`,
        },
        {
          title: "Singapore",
          link: `/travel-visa/${createCountryUrl("Singapore")}/`,
        },

        {
          title: "Ireland",
          link: `/travel-visa/${createCountryUrl("Ireland")}/`,
        },
        {
          title: "Japan",
          link: `/travel-visa/${createCountryUrl("Japan")}/`,
        },

        {
          title: "Bangladesh",
          link: `/travel-visa/${createCountryUrl("Bangladesh")}/`,
        },

        {
          title: "Sri Lanka",
          link: `/travel-visa/${createCountryUrl("Sri Lanka")}/`,
        },
        {
          title: "South Africa",
          link: `/travel-visa/${createCountryUrl("South Africa")}/`,
        },
        {
          title: "South Korea",
          link: `/travel-visa/${createCountryUrl("South Korea")}/`,
        },

        {
          title: "Thailand",
          link: `/travel-visa/${createCountryUrl("Thailand")}/`,
        },

        {
          title: "UAE",
          link: `/travel-visa/${createCountryUrl("UAE")}/`,
        },

        {
          title: "Vietnam",
          link: `/travel-visa/${createCountryUrl("Vietnam")}/`,
        },
      ],
    },
    {
      title: "Job Search Program",
      subItems: [
        {
          title: "Malta",
          link: `/job-search-visa/${createCountryUrl("Malta")}/`,
        },
        {
          title: "Poland",
          link: `/job-search-visa/${createCountryUrl("Poland")}/`,
        },
        {
          title: "Schengen",
          link: `/job-search-visa/${createCountryUrl("Schengen")}/`,
        },
        {
          title: "Singapore",
          link: `/job-search-visa/${createCountryUrl("Singapore")}/`,
        },
        { title: "UAE", link: `/job-search-visa/${createCountryUrl("UAE")}/` },
        { title: "UK", link: `/job-search-visa/${createCountryUrl("UK")}/` },
        {
          title: "Australia",
          link: `/job-search-visa/${createCountryUrl("Australia")}/`,
        },
        {
          title: "Dubai",
          link: `/job-search-visa/${createCountryUrl("Dubai")}/`,
        },
        {
          title: "Germany",
          link: `/job-search-visa/${createCountryUrl("Germany")}/`,
        },
        {
          title: "Gulf",
          link: `/job-search-visa/${createCountryUrl("Gulf")}/`,
        },
      ],
    },
    {
      title: "Resources",
      link: "/resources/",
      subItems: [
        {
          title: "Career",
          link: "/career/",
        },
        {
          title: "Blogs",
          link: "/blog/",
        },
        {
          title: "News",
          link: "/news/",
        },
      ],
    },

    {
      title: "Pay Now",
      link: "/pay-now/",
    },
    {
      title: "Connect Us",
      link: "/connect-us/",
    },
  ];

  const countryIntro = {
    australia: {
      metaTitle: "Achintya Immigration Services: Your Gateway to Australia",
      metaDescription:
        "Trust Achintya Immigration to help make your dream trip to Australia a reality. Start your journey to the Land Down Under with our expert guidance and support.",
      metaKeywords:
        "Apply for Australia tourist visa,Best tourist visa agency for Australia, Australia tourist visa online free",
      banner: {
        heading: "Australia Tourist Visa",
        banner_image: australiaImage,
      },
      introduction: {
        heading: "Introduction about Australia",
        content: `<p>
            Picture a country with endless wonders waiting to be explored. Meet its unique wildlife, like 
kangaroos, koalas, and the quirky platypus. Dive into the world-renowned Great Barrier Reef, 
a magical marine wonderland. But that's not all, Australia's landscapes are a feast for the 
eyes! From stunning beaches and lush rainforests to sprawling deserts and the rugged 
Outback, it's a paradise for nature lovers.</p>
<p>
Step into the rich tapestry of indigenous culture, where the Aboriginal and Torres Strait 
Islander peoples have woven their art, traditions, and history into the nation's heart.
</p>
<p>
Australia is a colorful melting pot! Experience its vibrant multicultural society, where people 
from all corners of the globe come together, creating a harmonious symphony of cultures. 
</p>
<p>
Education here is top-notch too! Discover the world-class education system that lures 
students from far and wide.
</p>
<p>
So, dive into the wonders of Australia, where breathtaking beauty, unique wildlife, and a rich 
cultural blend create a truly unforgettable experience! 
</p>`,
      },
      benefits_for_tourist: {
        heading: "Benefits for a tourist while going to Australia ",
        description: `Get ready for a journey to Australia, where world-class attractions await! From the iconic 
        Sydney Opera House to the majestic Uluru (Ayers Rock) and the breathtaking Great Ocean 
        Road, prepare for unforgettable experiences.`,
        pointers: [
          {
            point: `Immerse yourself in the unique Aboriginal culture! Learn about the rich history and 
                traditions of the Aboriginal and Torres Strait Islander peoples, adding a special depth 
                to your adventure. `,
          },
          {
            point: `Safety is a top priority here, making Australia a worry-free destination for travelers.`,
          },
          {
            point: `∙Get ready for a multicultural feast! Sample diverse cuisines, join in vibrant festivals, 
                and embrace the traditions from around the world in this melting pot of cultures. `,
          },
          {
            point: `And the best part? Australia's weather is simply fantastic! Whether you're exploring 
                vibrant cities or stunning landscapes, the pleasant climate will make your journey 
                even more delightful. `,
          },
          {
            point: `So, pack your bags and get ready to explore the wonders of Australia – a land of 
                world-class experiences, fascinating cultures, and beautiful weather. Your adventure 
                awaits! `,
          },
        ],
        description2: "",
      },
      documents_required: {},
      eligibility_criteria: {},
      tourist_visa_requirements: {
        heading: "Tourist Visa Requirements ",
        description: `To get an Australian tourist visa, you need to: `,
        pointers: [
          {
            point: `Truly intend to visit Australia for a short period. `,
          },
          {
            point: `Have sufficient funds to cover your expenses while you're there and for your return 
            trip. `,
          },
          {
            point: `Meet Australia's health and character requirements. `,
          },
        ],
      },
      process_for_visa_application: {
        heading: "Process for Tourist Visa Application ",
        description:
          "To apply for an Australia tourist visa from India, follow these steps: ",
        pointers: [
          {
            stepHeading: "",
            point: `Collect all the necessary documents, such as a valid passport, health report, identity 
                papers, proof of being a genuine visitor, and character documents.`,
          },
          {
            stepHeading: "",
            point: `Create an ImmiAccount and submit your visa application online. Pay the required 
                application fee. `,
          },
          {
            stepHeading: "",
            point: `The Department of Home Affairs (DHA) will review your application, which usually 
                takes about 40 days.`,
          },
          {
            stepHeading: "",
            point: `You will receive a written response from DHA, informing you of the visa decision. If 
                approved, they will provide you with a visa grant number, the visa start date, and the 
                conditions of your visa. `,
          },
        ],
      },
    },
    bangladesh: {
      metaTitle:
        "Your Gateway to Bangladesh: Explore with Achintya Immigration",
      metaDescription:
        "Planning your dream getaway to Bangladesh? Experience the wonders of Bangladesh with ease through Achintya Immigration's exceptional tourist visa services. ",
      metaKeywords:
        "bangladesh tourist visa requirements for indian citizens ,bangladesh tourist visa documents required ,best tourist visa for bangladesh",
      banner: {
        heading: "Bangladesh Tourist Visa",
        banner_image: bangladesh,
      },
      introduction: {
        heading: "Introduction about Bangladesh ",
        content: `
            <p>
            Discover Bangladesh, a vibrant South Asian gem nestled along the Bay of Bengal's 
northeastern shore. With its friendly borders shared with India and Myanmar, this country is 
home to over 160 million people, making it a bustling and densely populated wonderland. 
Let's dive into some of the exciting highlights of Bangladesh: 
</p>
<p>
<b>Geography:</b> Picture a land shaped by the meeting of powerful rivers like the Ganges, 
Brahmaputra, and Meghna, creating a unique delta landscape. But beware, during the 
monsoon season, it can get a bit watery! </p>
<p>
<b>Capital and Major Cities:</b> The heart of Bangladesh beats in Dhaka, a bustling metropolis 
and one of the fastest-growing cities worldwide. Other urban treasures include Chittagong, 
Khulna, and Rajshahi. </p>
<p>
<b>Language and Culture:</b> Embrace the beauty of Bengali (Bangla), the official language of 
Bangladesh, as it weaves a cultural tapestry enriched with traditional music, dance, literature, 
and the grand celebration of Pohela Boishakh, the Bengali New Year. </p>
<p>
<b>Religion:</b> Islam takes center stage in Bangladesh, and its warm embrace of religious diversity 
makes it a harmonious home for various communities. </p>
<p>
<b>Economy:</b> Agriculture thrives in this land, with rice leading the way as the staple crop. 
Moreover, Bangladesh is a garment export powerhouse, making waves in the fashion 
industry. </p>
<p>So, immerse yourself in the wonders of Bangladesh – a land of rivers, cultural wonders, and a 
resilient spirit. It's a place where captivating adventures await at every turn! </p>`,
      },
      benefits_for_tourist: {
        heading: "Benefits for a tourist while going to Bangladesh ",
        description: `Venture into the hidden gem of Bangladesh, a land less explored but filled with incredible 
        rewards for intrepid travelers! Here's why Bangladesh should be on your travel radar: `,
        pointers: [
          {
            point: `<b>Immerse in Culture:</b> Get ready for an authentic cultural treat! Witness vibrant music, 
                mesmerizing dances, and colorful festivals that showcase the country's rich heritage. `,
          },
          {
            point: `<b>Warm Welcome:</b> Bangladeshi people are like sunshine – their warmth and hospitality will 
                make you feel right at home, even in a distant land. `,
          },
          {
            point: `<b>Uncharted Wonders:</b> For those who crave adventure, Bangladesh offers off-the-beaten-path 
                destinations where you can discover the unspoiled beauty of a genuine, untouched world. `,
          },
          {
            point: `<b>History Unveiled:</b> Delve into the past with Bangladesh's historical sites and ancient ruins. 
                History enthusiasts will be captivated by the heritage this land holds. `,
          },
          {
            point: `<b>Sundarbans Magic:</b> Enter the enchanting Sundarbans, a UNESCO World Heritage Site. 
                Here, the world's largest mangrove forest unveils Bengal tigers and an array of wildlife in their natural haven. `,
          },
        ],
        description2: `So, gear up for a unique and memorable journey in Bangladesh! It might require a bit more 
        planning, but the authentic experiences, cultural treasures, and warm embrace of this country 
        will leave you with cherished memories to last a lifetime. `,
      },
      documents_required: {
        heading: "Documents for Bangladesh Tourist Visa ",
        description:
          "To apply for a visa to Bangladesh, you'll need the following documents: ",
        pointers: [
          {
            point: "Valid passport ",
          },
          {
            point: "Passport-sized photos",
          },
          {
            point: "Completed and signed visa application form ",
          },
          {
            point: "Identity proof ",
          },
          {
            point: "Itinerary details ",
          },
          {
            point: "Proof of hotel and flight bookings ",
          },
          {
            point: "Copy of your tour ticket ",
          },
          {
            point: "Invitation letter (if applicable) ",
          },
          {
            point:
              "Bank statement for the last six months to show enough funds for your trip.",
          },
        ],
      },
      eligibility_criteria: {},
      tourist_visa_requirements: {},
      process_for_visa_application: {
        heading: "",
      },
    },
    canada: {
      metaTitle:
        "Achintya Immigration: Your Partner for Memorable Canada Visit",
      metaDescription:
        "Discover the beauty of Canada through Achintya Immigration's dedicated tourist visa solutions. Explore Canada's iconic landmarks and experiences with confidence.",
      metaKeywords:
        "canada tourist visa application online , best bangladesh tourist visa for indian ",
      banner: {
        heading: "Canada Tourist Visa",
        banner_image: canadaImage,
      },
      introduction: {
        heading: "Introduction about Canada  ",
        content: `
            <p>
            Welcome to Canada, a vast and enchanting land nestled between the Atlantic and Pacific 
Oceans. Picture a country so big it seems to stretch on forever, adorned with breathtaking 
landscapes like majestic mountains, lush forests, vast prairies, and even Arctic tundra. 
            </p>
            <p>
            In this wonderful country, you'll find its heart and soul in the capital city, Ottawa, and 
bustling metropolises like Toronto, Vancouver, Montreal, Calgary, and Edmonton. Canada is 
not just a single melody; it's a harmonious symphony of languages, with both English and 
French dancing together as official languages. This blend celebrates the richness of its diverse 
cultural tapestry. </p>
<p>
Imagine a place where people from all corners of the globe come together to create a colorful 
mosaic. Canada's open arms embrace immigrants, making it a vibrant and multicultural 
society. Prepare to be mesmerized by Mother Nature's masterpieces! Stand in awe before the 
grandeur of the Rocky Mountains, experience the exhilarating rush of Niagara Falls, and find 
tranquility in the untouched beauty of Banff National Park. And when the night sky lights up 
with the mystical dance of the Northern Lights, it's pure magic. 
</p>
<p>
But that's not all – Canada's wilderness is home to an incredible cast of creatures. Meet bears, 
moose, and beavers, while countless bird species add their melodies to nature's symphony. It's 
a wildlife lover's paradise! Canada, is a country that captivates the soul, where natural 
wonders and cultural treasures blend in perfect harmony. Whether you're a traveller seeking 
adventure or someone looking for a place to call home, Canada's charm and warmth will 
make you fall in love at first sight.
</p>
            `,
      },
      benefits_for_tourist: {
        heading: "Benefits for a tourist while going to Canada ",
        description: `A land that beckons travelers with its jaw-dropping beauty, diverse cultures, and friendly 
        locals. It's a place where you can experience unforgettable adventures and create cherished 
        memories. Here are some great reasons to visit Canada: `,
        pointers: [
          {
            point: `<b>Stay Worry-Free:</b> Canada offers top-notch healthcare, so you can relax knowing you're in 
                good hands if you need medical help. `,
          },
          {
            point: `<b>Easy Exploration:</b> Getting around Canada is a breeze! With well-connected flights, trains, 
                and buses, you can effortlessly explore its various regions and attractions. `,
          },
          {
            point: `<b>Thrilling Outdoor Fun:</b> Embrace the thrill of skiing, snowboarding, hiking, camping, and 
                water sports – Canada is a playground for outdoor enthusiasts all year round.`,
          },
          {
            point: `<b>Wildlife Wonder:</b> Get ready for incredible wildlife encounters! Watch bears, whales, moose, 
                and beautiful bird species in their natural habitats.`,
          },
          {
            point: `<b>Cultural Delights:</b> Immerse yourself in Canada's rich heritage through its exciting cultural 
                events, festivals, and arts celebrations that happen throughout the year. `,
          },
          {
            point: `<b>City Buzz:</b> Dive into the vibrant life of cities like Toronto, Vancouver, Montreal, and 
            Calgary, where modernity blends seamlessly with cultural treasures, fantastic shopping, 
            delectable dining, and exciting nightlife. `,
          },
          {
            point: `<b>Safe and Sound:</b> As a tourist in Canada, you're covered by the country's universal healthcare 
            system, ensuring peace of mind during your visit. `,
          },
          {
            point: `<b>Northern Magic:</b> Witness the enchanting Northern Lights in certain regions, a celestial 
            spectacle that will leave you spellbound. `,
          },
        ],
        description2: `Escape to Canada and be enchanted by its breathtaking beauty, vibrant customs, and friendly 
        welcomes – a journey you'll treasure forever! `,
      },
      documents_required: {
        heading: "Documents Required ",
        description:
          "To apply for a Canada tourist visa, you'll need the following documents: ",
        pointers: [
          {
            point: `A valid passport with an expiry date beyond your visa duration. `,
          },
          {
            point: `Papers showing your connections to your home country, like a job or family ties, to 
                demonstrate that you intend to return after your visit. `,
          },
          {
            point: `Financial documents to prove you have enough money to support yourself during 
                your stay in Canada. `,
          },
          {
            point: `If you plan to stay with relatives, a letter of invitation from them can be helpful. `,
          },
          {
            point: `In some cases, you may need to undergo a medical exam before your trip and provide 
                a Medical Clearance Certificate (MCC).`,
          },
        ],
      },
      eligibility_criteria: {
        heading: "Eligibility Criteria for Tourist Visa ",
        description: "To be eligible for a Canada tourist visa, you need to: ",
        pointers: [
          {
            point: `Have a valid passport that covers your entire stay in Canada. `,
          },
          {
            point: `Meet health requirements, which may involve a medical exam and certificate. `,
          },
          {
            point:
              "Have enough funds to support yourself during your time in Canada. ",
          },
          {
            point: `Show ties to your home country, like a job, family, or property, to prove you will 
                return home after your visit. `,
          },
          {
            point: `Be admissible to Canada, meaning you must not have a criminal record or pose 
                security or health risks to the country. `,
          },
          {
            point: `If you plan to stay with relatives or friends, provide a letter of invitation from them.`,
          },
          {
            point: `Depending on your nationality, you may require a temporary resident visa for Canada. `,
          },
        ],
      },
      tourist_visa_requirements: {},
      process_for_visa_application: {
        heading: "",
      },
    },
    ireland: {
      metaTitle: "Ireland Tourist Visa - Achintya Immigration Services",
      metaDescription:
        "Experience the charm and beauty of Ireland with Achintya Immigration Services. Our expert team can assist you in obtaining your tourist visa to Ireland.",
      metaKeywords:
        "Best Ireland tourist visa consultants	,Top Ireland tourist visa agents,	Ireland tourist visa requirements",
      banner: {
        heading: "Ireland Tourist Visa",
        banner_image: irelandImage,
      },
      introduction: {
        heading: "Introduction about Ireland ",
        content: `<p>Explore Ireland's famous and iconic places, a tourist's dream come true! Delight in its rich 
      history, warm hospitality, vibrant cities, and diverse festivals. Easily travel around, enjoy 
      outdoor adventures, and savor unique experiences. With its safety, UNESCO sites, and 
      culinary delights, Ireland welcomes you with open arms! A magical land in Western Europe, 
      where history, nature, and culture dance together! Let's explore some of the enchanting things 
      that make Ireland so beloved: </p>
      <p>
      Step into a fairytale with Ireland's stunning landscapes – green hills, wild coastlines, serene 
lakes, and dramatic cliffs that have graced the screens of famous films and TV shows. 
</p>
<p>
Feel the heartbeat of Celtic heritage, echoing through traditional music, lively dances, 
mesmerizing folklore, and ancient wonders like Stonehenge and Newgrange. 
</p>
<p>
Join the vibrant festivities of St. Patrick's Day on March 17th, a cultural extravaganza 
celebrated globally with parades, green outfits, and Irish-themed joy! </p>
<p>
Let the spirit of Irish music and dance sweep you off your feet! The lively jigs and reels of 
traditional music and the iconic Riverdance are a celebration of Irish culture.
</p>
<p>
Travel back in time as you explore historic castles, churches, and ruins, unraveling the 
fascinating stories of Ireland's past. 
</p>
<p>
Embrace the shamrock's charm, a three-leafed clover symbolizing Ireland and its connection 
to St. Patrick's Day. And keep an eye out for mischievous leprechauns from Irish folklore, 
adding a touch of magic to this delightful country. </p>`,
      },
      benefits_for_tourist: {
        heading: "Benefits for a tourist while going to Ireland ",
        description: `Ireland, a place where dreams come alive, awaits you with open arms – a world of wonder, 
        history, and natural beauty to captivate your heart! Explore Ireland's charm and allure with a 
        treasure trove of benefits: `,
        pointers: [
          {
            point: `Step into the past with rich history and culture, where ancient castles whisper tales of 
          old. `,
          },
          {
            point:
              "Embrace the warmth of Irish hospitality, as friendly locals welcome you with open arms. ",
          },
          {
            point:
              "In vibrant cities like Dublin, savor a blend of history, modernity, and artistic delights, with museums, theatres, galleries, and mouthwatering cuisine. ",
          },
          {
            point: `Join the festivities throughout the year, celebrating music, arts, and literature, 
            immersing yourself in the true Irish spirit. `,
          },
          {
            point: `Travel with ease, as Ireland's compact size and efficient transportation systems pave 
            the way for effortless exploration. `,
          },
          {
            point: `Feel safe and secure in this welcoming destination, perfect for solo travelers, families, 
            and groups alike. `,
          },
          {
            point: `Unearth unique experiences, from ancient ruins to quaint villages and scenic coastal 
            walks, leaving you with cherished memories of Ireland's essence. `,
          },
          {
            point: `Indulge in culinary delights, savoring traditional dishes and modern twists, like Irish 
            stews, soda bread, and delectable seafood. `,
          },
          {
            point: `Prepare for an enchanting adventure in Ireland – a land of history, beauty, and warm 
            hearts, where unforgettable moments await! `,
          },
        ],
      },
      documents_required: {
        heading: "Documents Required ",
        description: "To apply for an Ireland Tourist visa, you'll need: ",
        pointers: [
          {
            point: "Passport with at least six months validity. ",
          },
          {
            point: "Letter explaining your reasons for visiting Ireland. ",
          },
          {
            point: "Detailed plan of your activities during the visit. ",
          },
          {
            point:
              "Reservation confirmation for your accommodation (hotels, guesthouses, etc.). ",
          },
          {
            point: "Travel and medical insurance proof. ",
          },
          {
            point: "Proof of paid visa application fees.",
          },
          {
            point:
              "Evidence of your ties to your home country, such as employment and family commitments. ",
          },
          {
            point:
              "Bank statements for the last six months show sufficient funds to support yourself during your stay. ",
          },
        ],
      },
      eligibility_criteria: {},
      process_for_visa_application: {
        heading: "",
      },
    },
    japan: {
      metaTitle: "Discover the Beauty of Japan with Achintya Immigration",
      metaDescription:
        "Have you always dreamed of visiting Japan? Let Achintya Immigration Services help make that dream a reality. Start planning your adventure today with Achintya immigration",
      metaKeywords:
        "Tourist visa application for Japan,	Japan tourist visa requirements,	Best consultants for japan tourist visa",
      banner: {
        heading: "Japan Tourist Visa",
        banner_image: japan,
      },
      introduction: {
        heading: "Introduction about Japan",
        content: `
        <p>
        A captivating island nation in East Asia! Picture a place where ancient traditions dance with 
modern technology, creating a mesmerizing blend. With over 125 million people, Japan is a 
prosperous and developed country, bustling with life and innovation. </p>
<p>
Embrace its rich cultural heritage, where traditional arts, music, and festivals come alive, 
adding a touch of magic to your journey. </p>
<p>
The Japanese language and writing system are like beautiful puzzles, with kanji characters, 
hiragana, and katakana forming a unique tapestry of communication. </p>
<p>
But that's not all – Japan is a powerhouse of technology and innovation! Discover cutting-
edge electronics and automotive wonders from world-leading companies. And when it comes 
to getting around, Japan's transportation systems are legendary. Hop aboard the famous 
Shinkansen, the bullet train known for its speed and punctuality.</p>
<p>
Get ready for an unforgettable adventure in Japan – a land where tradition meets the future, 
offering a world of wonders to explore! </p>
`,
      },
      benefits_for_tourist: {
        heading: "Benefits for a tourist while going to Japan ",
        description: `Japan's captivating blend of ancient traditions, modern technology, beautiful landscapes, and 
        unique experiences make it a captivating and unforgettable destination for travellers seeking a 
        diverse and enriching journey. Major benefits while travelling to Japan that one can 
        experience are:  `,
        pointers: [
          {
            point: `Discover the magic of modern technology and innovation! Japan is a global leader in 
  robotics, electronics, and futuristic transportation systems. `,
          },
          {
            point: `Experience the breathtaking Sakura season! When cherry blossoms bloom, the entire country 
            turns into a pink paradise – a sight that will leave you in awe. `,
          },
          {
            point: `Step back in time with ancient temples and shrines, standing tall for centuries, revealing 
            Japan's spiritual and architectural heritage. `,
          },
          {
            point: `Indulge in relaxation with natural hot springs, known as onsen, offering therapeutic benefits 
            for both body and soul. `,
          },
          {
            point: `Zoom between cities on the legendary Shinkansen, the famous bullet train known for its 
            speed, efficiency, and punctuality. `,
          },
          {
            point: `Immerse yourself in the colorful world of anime and pop culture, where Japan's creativity 
            sparks joy worldwide. `,
          },
          {
            point: `Climb to new heights and witness the majestic Mount Fuji, a symbol of cultural significance 
            and a magnet for nature lovers. `,
          },
          {
            point: `Experience a society built on respect and etiquette, where politeness and consideration prevail. `,
          },
        ],
      },
      documents_required: {
        heading: " Documents Required ",
        description: `To apply for a Japan Tourist visa, you'll need: `,
        pointers: [
          {
            point: "Valid passport with at least 6 months validity. ",
          },
          {
            point: "Completed visa application form. ",
          },
          {
            point: "Two photos meet the specifications. ",
          },
          {
            point: "Cover letter. ",
          },
          {
            point: "Proof of confirmed travel dates and air tickets. ",
          },
          {
            point: "Sufficient funds proof for your stay in Japan. ",
          },
          {
            point: "Travel Requisition from the Employer (if sponsored). ",
          },
          {
            point: "Latest Income Tax Returns and Bank Statements. ",
          },
          {
            point: "Proof of accommodation and hotel reservation. ",
          },
          {
            point: "Original invitation letter (if applicable). ",
          },
        ],
      },
      eligibility_criteria: {},
      process_for_visa_application: {
        heading: "",
      },
    },
    germany: {
      metaTitle:
        "Plan Your German Escape: Achintya Immigration Takes You There",
      metaDescription:
        "Planning a German vacation? Our seamless process ensures you can explore charming villages, historic cities, and stunning landscapes without any visa-related worries.",
      metaKeywords:
        "germany tourist visa requirements ,	top consultant for germany tourist visa in delhi, 	online apply for germany tourist visa ",

      banner: {
        heading: "Germany Tourist Visa",
        banner_image: germanyImage,
      },
      introduction: {
        heading: "Introduction about Germany  ",
        content: `
        <p>
        Germany is a country in Central Europe, officially called the Federal Republic of Germany. It 
has a diverse landscape, with hills, mountains, and rivers in the central and southern regions, 
and flat plains in the north near the North Sea. Germany has a long and complex history, and 
it was divided into East and West Germany during the Cold War. </p>
<p>
Germany is a powerful country with a strong economy, known for making and exporting 
machinery, cars, chemicals, and technology. It has had a significant impact on the world's 
culture, producing famous writers, musicians, philosophers, and scientists. </p>
<p>The official language is German, and the government is a federal parliamentary republic. 
Education is highly regarded, and Germany has many top universities and research 
institutions. </p>
<p>Tourism is popular in Germany, with many people visiting its historic cities, beautiful 
landscapes, castles, and cultural sites. Germany is also an important member of the European 
Union, influencing European politics and economics.</p>`,
      },
      benefits_for_tourist: {
        heading: "Benefits for a tourist while going to Germany  ",
        description: `Remember that each traveler's interests and preferences may vary, but Germany's diverse 
        offerings ensure that there is something for everyone, making it an exciting and rewarding 
        destination for tourists. `,
        pointers: [
          {
            point:
              "History and Culture: Explore historic cities, castles, and UNESCO sites. ",
          },
          {
            point:
              "Beautiful Landscapes: Enjoy the stunning Alps and scenic river valleys. ",
          },
          {
            point:
              "Vibrant Cities: Experience modernity, arts, and entertainment in Berlin, Munich, and more. ",
          },
          {
            point:
              "Easy Transportation: Efficient trains, buses, and flights for exploring. ",
          },
          {
            point:
              "Safe Destination: Low crime rates ensure a comfortable vacation. ",
          },
          {
            point:
              "Festivals: Join lively events like Oktoberfest and Christmas markets. ",
          },
          {
            point: "Tasty Cuisine: Try hearty dishes and delicious pastries. ",
          },
          {
            point: "Museums and Art: Discover world-class exhibits. ",
          },
          {
            point: "Shopping: Find unique souvenirs and fashionable items. ",
          },
          {
            point:
              "Friendly Locals: Germans are welcoming, and English is widely spoken. ",
          },
        ],
      },
      documents_required: {
        heading: "Documents Required ",
        description: `To apply for a Germany Tourist visa, you'll need: `,
        pointers: [
          {
            point: "Valid passport (issued within the last ten years). ",
          },
          {
            point: "Passport-sized photos. ",
          },
          {
            point: "Completed and signed application form. ",
          },
          {
            point:
              "Proof of hotel and flight bookings, and a detailed plan of your activities during your stay. ",
          },
          {
            point: "Copy of tour ticket. ",
          },
          {
            point:
              "Proof of sufficient finances to support your travel and stay, like a recent bank statement. ",
          },
          {
            point:
              "Valid medical insurance with a minimum coverage of 30,000 Euros. ",
          },
          {
            point:
              "No-objection certificate from your university or employer (if applicable). ",
          },
          {
            point:
              "A Cover letter explaining your reason for visiting Germany. ",
          },
          {
            point:
              "Proof of civil status (e.g., marriage certificate, birth/death certificates if applicable). ",
          },
        ],
      },
      eligibility_criteria: {},
      tourist_visa_requirements: {},
      process_for_visa_application: {
        heading: "",
      },
    },
    malaysia: {
      metaTitle: "Achintya Immigration: Your Partner for Malaysia Tourist Visa",
      metaDescription:
        "Planning a Malaysian getaway? Our tailored services ensure a stress-free application process, allowing you to explore ancient temples and bustling cities.",
      metaKeywords:
        "malaysia tourist visa apply online, 	best malaysia tourist visa in delhi ,	malaysia tourist visa requirements ",
      banner: {
        heading: "Malaysia Tourist Visa ",
        banner_image: malaysia,
      },
      introduction: {
        heading: "Introduction about Malaysia  ",
        content: `
        <p>
        Malaysia is a country in Southeast Asia, known for its diverse culture, beautiful landscapes, and 
rich history. It is located on both the Malay Peninsula and the island of Borneo. Malaysia shares 
borders with Thailand, Indonesia, and Brunei, and has maritime borders with Singapore, 
Vietnam, and the Philippines. 
        </p>
        <p>
The country has a mix of different ethnic groups, including Malays, Chinese, Indians, and 
indigenous peoples. The official language is Bahasa Malaysia (Malay), but many people also 
speak English. Kuala Lumpur is the capital and largest city, where most of the economic, 
cultural, and administrative activities take place. Malaysia's economy includes modern 
industries, technology, agriculture, and natural resources. </p>
<p>
Tourists visit Malaysia for its famous attractions, like the Petronas Twin Towers, historical sites, 
beautiful beaches, lush rainforests, and wildlife sanctuaries. Additionally, Malaysia is known for 
its delicious cuisine, influenced by its diverse cultural heritage. </p>`,
      },
      benefits_for_tourist: {
        heading: "Benefits for a tourist while going to Malaysia ",
        description: `Malaysia provides a well-rounded and enriching experience for tourists, combining cultural 
        diversity, natural beauty, modern amenities, and warm hospitality. `,
        pointers: [
          {
            point: `<b>Diverse Culture and Heritage: </b>Malaysia's multicultural society offers a unique experience for 
  tourists to immerse themselves in various traditions, festivals, and cuisines of different ethnic 
  groups, including Malay, Chinese, Indian, and indigenous cultures. `,
          },
          {
            point: `<b>Beautiful Landscapes:</b>Malaysia boasts stunning landscapes, from pristine beaches with crystal-
            clear waters to lush rainforests teeming with wildlife. Tourists can explore nature reserves, 
            national parks, and picturesque islands.  `,
          },
          {
            point: `<b>Affordable Travel:</b> Compared to many other destinations, Malaysia offers relatively affordable 
            travel options, including accommodation, food, and activities, making it attractive for budget-
            conscious travelers. `,
          },
          {
            point: `<b>Iconic Landmarks: </b> Malaysia is home to famous landmarks like the Petronas Twin Towers, 
            Kuala Lumpur Tower, and historical sites that offer great sightseeing and photo opportunities.`,
          },
        ],
      },
      eligibility_criteria: {},
      documents_required: {
        heading: "Documents Required ",
        description: `To apply for a Malaysia Tourist visa, you'll need: `,
        pointers: [
          {
            point: `Valid passport (with at least six months validity) `,
          },
          {
            point: `Passport-sized photos `,
          },
          {
            point: `Completed and signed visa application form `,
          },
          {
            point: `Travel Insurance `,
          },
          {
            point: `Itinerary details `,
          },
          {
            point: `Proof of hotel and flight bookings `,
          },
          {
            point: `Copy of your tour ticket `,
          },
          {
            point: `A cover letter containing all required itinerary details. `,
          },
        ],
      },
      process_for_visa_application: {
        heading: "",
      },
    },
    gulf: {
      metaTitle: "Achintya Immigration: Your Pathway to Experiencing Gulf",
      metaDescription:
        "Planning your Dubai getaway? Look no further than Achintya Immigration for your tourist visa needs. Discover our seamless process to obtain your Dubai tourist visa",
      metaKeywords:
        "dubai tourist visa for indian ,	dubai tourist visa requirements .	best dubai tourist visa online ",
      banner: {
        heading: "Gulf Tourist Visa",
        banner_image: gulf,
      },
      introduction: {
        heading: "Introduction about Gulf ",
        content: `<p>
        Dubai is a dazzling city and emirate on the Arabian Peninsula! Prepare to be amazed by its 
modernity, luxury, and architectural marvels. When the sun sets, Dubai comes alive with a 
vibrant nightlife, offering upscale bars, clubs, and entertainment galore. Dubai has it all for 
tourists!  </p>
<p>
Experience the perfect mix of modernity, luxury, and cultural diversity that will make your 
trip truly special. From breathtaking landmarks to incredible entertainment and 
mouthwatering cuisine, Dubai promises an unforgettable vacation filled with cherished 
memories. It's a dream destination that will leave you in awe and wanting to come back for 
more!</p>
<p>
Get ready for thrills and excitement at theme parks like Dubai Parks and Resorts and IMG 
Worlds of Adventure.</p>
<p>
Indulge in luxury hospitality, as Dubai's hotels and resorts pamper you with top-notch service 
and comfort.</p>
<p>
Explore a melting pot of cultures, as Dubai welcomes people from all over the globe, making 
it a diverse and multicultural city. </p>
<p>
Dubai's unique blend of modern marvels, luxury, and cultural wonders attracts countless 
tourists year after year, making it a dream destination for adventurers and luxury seekers 
alike. </p>`,
      },
      benefits_for_tourist: {
        heading: "Benefits for a tourist while going to Dubai ",
        description: `Traveling to Dubai as a tourist offers a plethora of enticing benefits that make it an exceptional destination for an unforgettable experience. In Dubai, prepare to be amazed by 
        modern marvels like futuristic skyscrapers and the famous Burj Khalifa! `,
        pointers: [
          {
            point:
              "Experience the lap of luxury with lavish accommodations and top-notch hospitality at Dubai's finest hotels and resorts. ",
          },
          {
            point: `Immerse yourself in a vibrant blend of cultures, from traditional Emirati heritage to exciting international influences. `,
          },
          {
            point: `Embark on thrilling desert adventures with exciting safaris and feel the magic of the Arabian Desert. `,
          },
          {
            point: `For shopaholics, Dubai is a paradise with countless malls and lively souks offering unique treasures to discover! `,
          },
        ],
      },
      eligibility_criteria: {},
      documents_required: {
        heading: "Documents Required ",
        description: "To apply for a Dubai Tourist visa, you'll need: ",
        pointers: [
          {
            point: `Valid passport (with at least six months validity). `,
          },
          {
            point: `Passport-sized photos. `,
          },
          {
            point: `Completed and signed the Dubai visa application form. `,
          },
          {
            point: `Itinerary details. `,
          },
          {
            point: `Proof of hotel and flight bookings. `,
          },
          {
            point: `Copy of your tour ticket. `,
          },
          {
            point: `Cover letter with all required itinerary details. `,
          },
          {
            point: `Bank statement for the last six months to show sufficient funds for your trip. `,
          },
          {
            point: `Address proof. `,
          },
          {
            point: `Letter from your sponsor (friend or relative) residing in Dubai. `,
          },
        ],
      },
      process_for_visa_application: {
        heading: "",
      },
    },
    usa: {
      metaTitle: "USA Tourist Visa - Achintya Immigration Services.",
      metaDescription:
        "Experience USA Wonders with Achintya Immigration. Simplifying your USA tourist visa process for a memorable journey. Expert guidance, and hassle-free applications.",
      metaKeywords:
        "online usa visa application form ,	Tourist visa for USA	,usa visa application online ",
      banner: {
        heading: "USA Tourist Visa",
        banner_image: usa,
      },
      introduction: {
        heading: "Introduction about the USA",
        content: `<p>
        The USA is short for the United States of America, a federal republic in North America with 50 states and capital in Washington, D.C. It borders Canada to the north, Mexico to the south, the 
Atlantic Ocean to the east, and the Pacific Ocean to the west. </p>
<p>
It is a highly influential and diverse country, known for its rich history since declaring independence from Great Britain on July 4, 1776. The USA played significant roles in global 
events, including both World Wars, becoming a superpower after World War II.</p>
<p>
The country operates under a democratic system with three branches of government: executive, legislative, and judicial. The President is the head of state and government, elected by the people. </p>
<p>
The USA is famous for its multicultural society, innovation, and technology. It houses renowned universities, research institutions, and global companies. Its economy is one of the largest and 
most impactful worldwide, with various industries such as technology, finance, healthcare, and entertainment. </p>
<p>
Overall, the USA has a prominent position globally, influencing politics, economics, culture, and technology on the international stage.</p>`,
      },
      benefits_for_tourist: {
        heading: "Benefits for a tourist while going to the USA ",
        description:
          "The USA is a fantastic destination for tourists with something for everyone: ",
        pointers: [
          {
            point: `<b>Beautiful Nature: </b>From breathtaking national parks to sunny beaches, nature lovers will 
            be amazed. `,
          },
          {
            point: `Famous Cities: Iconic cities like New York, Los Angeles, and Las Vegas have unique 
            attractions and entertainment. `,
          },
          {
            point: `<b>Rich Culture: </b>Experience diverse cuisines, festivals, music, and art from different 
            cultures. `,
          },
          {
            point: `<b>Landmarks Galore: </b>Visit famous landmarks like the Statue of Liberty and the Golden 
            Gate Bridge. `,
          },
          {
            point: `<b>Great Shopping: </b>Shop till you drop with luxury boutiques and outlet malls. Fun Theme Parks: Enjoy Disneyland and Universal Studios for thrilling experiences.`,
          },
          {
            point: `<b>Easy Travel: </b>Convenient airports, highways, and public transport make exploring easy.Friendly Locals: Americans are known for their warm hospitality. `,
          },
          {
            point: `<b>Learn and Explore: </b>Visit museums and historic sites for an educational journey. `,
          },
          {
            point: `<b>Safety and Communication: </b>The USA offers a safe environment, and English is widely spoken. `,
          },
        ],
      },
      eligibility_criteria: {},
      documents_required: {},
      process_for_visa_application: {
        heading: "Process for USA Tourist Visa Application  ",
        description: `To apply for a US tourist visa (B1/B2), follow these steps: `,
        pointers: [
          {
            stepHeading: "Check if you need a visa",
            point: `Some countries are part of the Visa Waiver Program (VWP), which allows travel to the US without a visa for short visits. `,
          },
          {
            stepHeading: "Complete the DS-160 form",
            point: `Fill out the online application form accurately and submit it.`,
          },
          {
            stepHeading: "Schedule an interview",
            point:
              "After paying the fee, book an appointment for an interview at the US embassy. ",
          },
          {
            stepHeading: "Prepare for the interview",
            point:
              "Gather all necessary documents, like your passport, visa application confirmation, travel plans, and reasons for visiting the US. ",
          },
          {
            stepHeading: "Attend the interview",
            point:
              "Dress appropriately and be honest during the interview. Provide any extra documents if requested. ",
          },
          {
            stepHeading: "Wait for a decision",
            point: `After the interview, the immigration officer will decide on your visa application. If approved, you'll receive the visa in your passport. If denied, you'll be given 
            a reason for the refusal. `,
          },
        ],
      },
    },
    "new-zealand": {
      metaTitle: "Discover the Best Pathways to New Zealand with Achintya",
      metaDescription:
        "Explore the breathtaking landscapes of New Zealand with Achintya Immigration's seamless tourist visa services. Your dream vacation awaits, and back with our expertise.",
      metaKeywords:
        "how to get new zealand tourist visa ,	new zealand tourist visa for indians ,	New Zealand online visa application",
      banner: {
        heading: "New Zealand Tourist Visa",
        banner_image: newZealandImage,
      },
      introduction: {
        heading: "Introduction about New Zealand ",
        content: `<p>
              New Zealand is a captivating country with stunning nature, fun adventures, and a rich culture. It
    has two main islands - the North and South Islands, surrounded by the Pacific Ocean. You'll find
    majestic mountains, beautiful lakes, forests, and beaches, perfect for hiking, skiing, and water
    sports.</p>
    <p>
    The Maori people are the native inhabitants, and their culture is an essential part of New
    Zealand's identity. The country has unique wildlife, like the kiwi bird and intelligent kea parrot.
    </p>
    <p>
    Adventure lovers will enjoy bungee jumping, skydiving, and white-water rafting. Movie fans can
    visit famous "Lord of the Rings" filming locations. Wine enthusiasts can taste delicious
    Sauvignon Blanc wines from the country's vineyards. </p>
    <p>
    You can explore geothermal areas, and caves with glowworms, and enjoy various outdoor
    activities. The main languages spoken are English and Maori. Rugby is a popular sport, and New
    Zealand is known for being environmentally conscious, keeping the country clean and green. </p>`,
      },
      benefits_for_tourist: {
        heading: "Benefits for a tourist while going to New Zealand ",
        description: ``,
        pointers: [
          {
            point: `New Zealand is an amazing place for tourists from all over the world. It has stunning landscapes
                with mountains, beaches, and clear lakes, perfect for nature lovers and outdoor activities like
                hiking and water sports. Adventure seekers can enjoy bungee jumping, skydiving, and rafting. `,
          },
          {
            point: `You'll see unique wildlife like the kiwi bird and the kea parrot. You can also learn about the
                Maori culture, the indigenous people of New Zealand, and experience their traditions. `,
          },
          {
            point: `
                The country is known for its safety and friendly locals. Fans of "The Lord of the Rings" movies
                can visit filming locations. Wine enthusiasts will love the famous Sauvignon Blanc, and foodies
                can enjoy diverse culinary delights. `,
          },
          {
            point: `New Zealand is a paradise for outdoor activities, including camping and exploring geothermal
      areas and glowworm caves. Most people speak English, making communication easy for tourists. `,
          },
          {
            point: `Lastly, New Zealand is environmentally conscious and known for its clean and green image,
                preserving its natural beauty. `,
          },
        ],
      },
      eligibility_criteria: {
        heading: "Eligibility Criteria for New Zealand ",
        description: `To visit New Zealand, you need to: `,
        pointers: [
          {
            point: "Show the purpose of your visit.",
          },
          {
            point: "Meet health and character requirements. ",
          },
          {
            point:
              "Have a passport valid for at least three months past your departure date or one month after your intended departure.",
          },
          {
            point:
              "Convince the visa officer that you will leave the country before your visa expires.",
          },
          {
            point: `Demonstrate sufficient funds for your stay: NZ$1000 per person per month or NZ$400 per person per month if accommodation is pre-paid. You'll need proof like hotel prepaid vouchers.`,
          },
        ],
      },
      documents_required: {},
      process_for_visa_application: {},
    },
    schengen: {
      metaTitle: "Achintya Immigration: Your Pathway to Experiencing Schengen",
      metaDescription:
        "Unlock the treasures of Europe through Achintya Immigration's Schengen tourist visa support. Your gateway to hassle-free and unforgettable experiences awaits.",
      metaKeywords:
        "Schengen visa requirements,	schengen visa application online ",
      banner: {
        heading: "Schengen Tourist Visa",
        banner_image: schegan,
      },
      introduction: {
        heading: "Introduction about Schengen",
        content: `<p>
        The Schengen Area is a group of European countries that allow border-free travel. It means 
people can move freely between these countries without passport checks at their shared borders. 
There are 26 countries in the Schengen Area, and 22 of them are part of the European Union 
(EU). While there are no border controls inside the Schengen Area, they have strict security 
measures at their external borders. </p>
<p>
If you want to visit multiple Schengen countries, you can apply for a Schengen visa. This visa 
allows you to travel within the Schengen Area for a specific time. Each country may have its 
requirements for the visa application. </p>
<p>
Overall, the Schengen Area promotes unity and cooperation among European countries and 
makes traveling easier for millions of people</p>`,
      },
      benefits_for_tourist: {
        heading: "Benefits for a tourist while going to Schengen ",
        description:
          "The Schengen Area is a great place for tourists because: ",
        pointers: [
          {
            point: `<b>No Borders:</b> You can travel easily between Schengen countries without passport checks. 
            Explore Many Countries: With 26 countries to visit, you can experience diverse cultures 
            and landscapes. `,
          },
          {
            point: `<b>Easy Visa Process:</b> Apply for a Schengen visa to visit multiple countries with one 
            application.`,
          },
          {
            point: `<b>Good Infrastructure: </b>There are efficient transportation and quality accommodations for 
            tourists.`,
          },
          {
            point: `<b>Rich Culture and History:</b> Explore ancient landmarks and museums in the Schengen 
            countries. `,
          },
          {
            point: `<b>Beautiful Nature: </b>Enjoy stunning landscapes, mountains, and lakes. `,
          },
          {
            point: `<b>Delicious Food:</b> Each country has unique culinary delights to try. `,
          },
          {
            point: `<b>Cultural Exchange:</b> Interact with locals and learn about their customs. `,
          },
          {
            point: `<b>Adventure Opportunities: </b>Engage in exciting activities like skiing and hiking. `,
          },
          {
            point: `<b>Business Opportunities:</b> The Schengen Area attracts professionals for business and 
            networking events. `,
          },
        ],
      },
      documents_required: {
        heading: "Documents Required",
        description: "To apply for a Schengen visit visa, you'll need: ",
        pointers: [
          {
            point: "A valid passport. ",
          },
          {
            point: "A filled-out Schengen visit visa application form. ",
          },
          {
            point:
              "Your travel plans, including countries you'll visit, where you'll stay, and flight details. ",
          },
          {
            point:
              "Proof that you have enough money to cover your expenses during your trip. ",
          },
          {
            point: `Documents showing your employment or student status to prove you won't overstay your 
            visa. `,
          },
          {
            point: "Proof of adequate health insurance for your trip. ",
          },
        ],
      },
      eligibility_criteria: {
        heading: "Eligibility Criteria for Schengen Tourist Visa ",
        description: "",
        pointers: [
          {
            point: `To apply for a European visa, you must be a citizen of a non-European country outside 
            the European Union. `,
          },
          {
            point: `You need to justify the reason for your travel and the length of your 
            stay. `,
          },
          {
            point: `You must also have medical insurance with a minimum coverage of €30,000. `,
          },
        ],
      },
      process_for_visa_application: {
        heading: "",
      },
    },
    singapore: {
      metaTitle: "Singapore Tourist Visa - Achintya Immigration Services",
      metaDescription:
        "Plan your Singapore getaway effortlessly with Achintya's dedicated tourist visa support. Trust in our expertise to navigate the visa application process.",
      metaKeywords:
        "Singapore tourist visa online application,	Singapore tourist visa requirements, 	How to apply for Singapore tourist visa ",
      banner: {
        heading: "Singapore Tourist Visa",
        banner_image: singapore,
      },
      introduction: {
        heading: "Introduction about Singapore ",
        content: `
        <p>
        Singapore is a small and modern city in Southeast Asia. It's a dynamic country known for its 
success in business and diverse culture. The city has impressive skyscrapers and famous 
landmarks like Marina Bay Sands. Singapore is a mix of different cultures, including Chinese, 
Malay, and Indian. </p>
<p>Visitors can enjoy beautiful parks and gardens in a clean and green environment. There are many 
hawker centers and restaurants with delicious local and international food. Tourists can explore 
historic neighborhoods like Chinatown and Little India, and go shopping on Orchard Road. 
Singapore is also committed to innovation and protecting the environment, with advanced 
buildings and an efficient transportation system. </p>
<p>Overall, it's a welcoming destination for travelers looking to experience a unique blend of 
tradition and modernity in a vibrant city. </p>`,
      },
      benefits_for_tourist: {
        heading: "Benefits for a tourist while going to Singapore ",
        description:
          "Singapore is a fantastic choice for tourists looking for a dynamic and enriching vacation: ",
        pointers: [
          {
            point: `It's a modern and vibrant city with a diverse culture. `,
          },
          {
            point: `Getting around is easy with efficient public transportation. `,
          },
          {
            point: `You can enjoy delicious food from hawker centers and restaurants. `,
          },
          {
            point: `The city is known for its clean and green environment with beautiful parks. `,
          },
          {
            point: `Explore iconic landmarks like Marina Bay Sands and the Merlion statue. `,
          },
          {
            point: `Discover cultural heritage in historic neighborhoods like Chinatown and Little India. `,
          },
          {
            point: `Shopaholics will love the variety of malls and street markets. `,
          },
          {
            point: `Enjoy a vibrant nightlife with bars and clubs for entertainment. `,
          },
          {
            point: `Safety and cleanliness are top priorities for a pleasant experience. `,
          },
          {
            point: `English is widely spoken, making communication hassle-free. `,
          },
        ],
      },
      documents_required: {
        heading: "Documents Required  ",
        description: "",
        pointers: [
          {
            point: `A valid passport with at least six months of validity. `,
          },
          {
            point: `Passport-size photos`,
          },
          {
            point: `A completed and signed visa application form. `,
          },
          {
            point: `Your travel itinerary details.`,
          },
          {
            point: `Proof of hotel and flight bookings. `,
          },
          {
            point: `A copy of your return flight ticket. `,
          },
          {
            point: `Bank statements for the last six months show you have enough money for your trip. `,
          },
        ],
      },
      eligibility_criteria: {},
      process_for_visa_application: {
        heading: "",
      },
    },
    "south-africa": {
      metaTitle: "Discover the Best Pathways to South Korea with Achintya",
      metaDescription:
        "Unlock your dream vacation in South Africa with Achintya's seamless tourist visa services. Discover the allure of this remarkable destination with ease.",
      metaKeywords:
        "South Africa tourist visa requirements,	online apply for south africa tourist visa",
      banner: {
        heading: "South Africa",
        banner_image: southAfrica,
      },
      introduction: {
        heading: "Introduction about South Africa ",
        content: `
        <p>
        South Africa is a fascinating country in Africa known for its rich history, stunning landscapes, 
diverse wildlife, and vibrant cultures. It has a diverse population of over 60 million people with 
various languages and traditions. </p>
<p>
The country has a complex past, including colonization and apartheid, but it achieved democracy 
in 1994, promoting inclusivity and reconciliation. South Africa's natural beauty is breathtaking, 
from Table Mountain to Kruger National Park, offering wildlife safaris and beautiful scenery. </p>
<p>
Its culture is diverse, with unique art, music, and dance representing different ethnic groups like 
Zulu, Xhosa, and Afrikaans. The cities, like Johannesburg and Cape Town, blend modernity and 
history, attracting tourists. </p>
<p>
Visitors can enjoy wine regions, especially in the Western Cape, famous for producing fine 
wines. Despite facing challenges, South Africa's resilience, beauty, and cultural richness make it 
an exciting destination for travelers seeking an unforgettable African experience. </p>`,
      },
      benefits_for_tourist: {
        heading: "Benefits for a tourist while going to South Africa ",
        description: "Visit South Africa for: ",
        pointers: [
          {
            point: `<b>Breathtaking landscapes: </b>Table Mountain, savannas, beaches, and mountains for 
            outdoor adventures. `,
          },
          {
            point: `<b>Wildlife and safaris:</b> Spot the famous "Big Five" in national parks and private 
            reserves. `,
          },
          {
            point: `<b>Diverse culture:</b> Experience art, music, dance, and delicious cuisine from various 
            ethnic groups. `,
          },
          {
            point: `<b>History and heritage:</b> Learn about the struggle against apartheid at museums and 
            historic sites. `,
          },
          {
            point: `<b>Vibrant cities: </b>Cape Town, Johannesburg, and Durban offer attractions and 
            entertainment. `,
          },
          {
            point: `<b>Thrilling activities: </b>Surfing, hiking, shark cage diving, and more for adventure 
            seekers. `,
          },
          {
            point: `<b>Scenic drives:</b> Garden Route and Panorama Route offer mesmerizing views. Wine tasting: World-class vineyards in the Western Cape for wine enthusiasts. `,
          },
          {
            point: `<b>Budget-friendly:</b> Enjoy affordable travel with a favorable exchange rate. 
            Warm hospitality: Friendly locals create a welcoming atmosphere for your 
            memorable trip. `,
          },
        ],
      },
      eligibility_criteria: {},
      process_for_visa_application: {
        heading: "",
      },
      documents_required: {
        heading: "Documents Required  ",
        description: "",
        pointers: [
          {
            point: `A valid passport with at least six months left before expiration. `,
          },
          {
            point: `Two passport-size photos. `,
          },
          {
            point: `Previous passports and visas, if applicable.`,
          },
          {
            point: `A completed and signed visa application form. `,
          },
          {
            point: `Details of your travel plans. `,
          },
          {
            point: `Proof of hotel and flight bookings. `,
          },
          {
            point: `A copy of your return ticket. `,
          },
          {
            point: `A cover letter with your itinerary details. `,
          },
          {
            point: `Proof of sufficient finances for your trip, such as bank statements for the last 6 
            months and income tax returns for the past 3 years. `,
          },
          {
            point: `Yellow fever vaccination certificate, if required. `,
          },
        ],
      },
    },
    "south-korea": {
      metaTitle: "Discover the Best Pathways to South Korea with Achintya",
      metaDescription:
        "Discover the Rich Culture and Stunning Landscapes of South Korea with Ease. Trust Achintya Immigration to guide you through the visa application process smoothly.",
      metaKeywords:
        "south korea tourist visa application form,	south korea tourist visa for indian	,online apply for south korea visa	 ",
      banner: {
        heading: "South Korea Tourist Visa",
        banner_image: southKorea,
      },
      introduction: {
        heading: "Introduction to South Korea ",
        content: `
        <p>
        South Korea is a lively and advanced country in East Asia, bordered by North Korea and 
surrounded by the Yellow Sea and the Sea of Japan. It combines rich history with modern 
technology and cities. </p>
<p>
Seoul, the capital, is a bustling city with tall buildings and historic palaces, representing the 
country's progress. South Korea is famous for K-pop music and Korean dramas, loved 
worldwide. Its cuisine, like kimchi and Korean barbecue, is popular globally. The country has 
beautiful landscapes, including mountains, forests, and coastal areas, with traditional architecture 
and temples. </p>
<p>
South Korea's strong focus on education has contributed to its economic growth and innovation. 
Overall, South Korea is a captivating destination where ancient traditions and modern 
achievements come together, offering a unique experience in East Asia. </p>`,
      },
      benefits_for_tourist: {
        heading: "  Benefits for a tourist while going to South Korea ",
        description: "",
        pointers: [
          {
            point: `<b>Cultural Marvels:</b> Explore ancient temples, art, dance, and historic sites. `,
          },
          {
            point: `<b>Hi-Tech Wonder:</b> Experience cutting-edge technology and modern cities. `,
          },
          {
            point: `<b>Exciting City Life: </b>Enjoy lively cities with shopping, entertainment, and nightlife. `,
          },
          {
            point: `<b>Tasty Delights: </b>Savor diverse and delicious Korean cuisine. `,
          },
          {
            point: `<b>K-Pop Fever: </b>Immerse in the vibrant K-pop and Korean drama culture. `,
          },
          {
            point: `<b>Stunning Scenery:</b> Marvel at beautiful mountains, beaches, and islands.`,
          },
          {
            point: `<b>Festive Fun:</b> Join lively festivals and celebrate Korean traditions. `,
          },
          {
            point: `<b>Friendly Locals:</b> Experience warm hospitality from South Koreans.`,
          },
          {
            point: `<b>Easy Travel: </b>Convenient transportation to explore the country.`,
          },
        ],
      },
      documents_required: {
        heading: "Documents Required  ",
        description: `To apply for the visa, you'll need: `,
        pointers: [
          {
            point: `A valid passport `,
          },
          {
            point: `Passport-size photo`,
          },
          {
            point: `Previous passports and visas (if applicable) `,
          },
          {
            point: `Completed and signed visa application form `,
          },
          {
            point: `Itinerary details `,
          },
          {
            point: `Proof of hotel and flight bookings `,
          },
          {
            point: `Copy of return ticket `,
          },
          {
            point: `Cover letter with itinerary details `,
          },
          {
            point: `Proof of sufficient funds for your visit `,
          },
          {
            point: `Salary slips for the last 6 months `,
          },
          {
            point: `Income tax returns for the last 3 years `,
          },
          {
            point: `Travel Insurance`,
          },
        ],
      },
      eligibility_criteria: {},
      process_for_visa_application: {
        heading: "",
      },
    },
    "sri-lanka": {
      metaTitle: "Discover the Best Pathways to Sri Lanka with Achintya",
      metaDescription:
        "Planning your dream getaway to Sri Lanka? Let Achintya Immigration handle your tourist visa process, ensuring a seamless journey to this tropical paradise.",
      metaKeywords:
        "Sri Lanka tourist visa application,	Sri Lanka visa online	,Sri Lanka visa application process	 	 ",
      banner: {
        heading: "Sri Lanka Tourist Visa",
        banner_image: srilanka,
      },
      introduction: {
        heading: "Introduction about Sri Lanka  ",
        content: `
        <p>
        Sri Lanka is a beautiful island nation in South Asia, located in the Indian Ocean. It has diverse 
landscapes, a rich history, and a vibrant culture. The largest city and major port is Colombo. The 
country has around 22 million people with different ethnic groups and languages: Sinhalese, 
Tamil, and English. Sri Lanka's history goes back thousands of years, with ancient civilizations 
like Anuradhapura and Polonnaruwa. The culture is deeply influenced by Buddhism. The 
country is famous for its stunning scenery, like beaches, tea plantations, and mountains. </p>
<p>
Sri Lanka has a tropical climate, allowing for a variety of wildlife, including elephants, leopards, 
and various bird species protected in national parks. The economy relies on agriculture (tea, 
rubber, and coconut), textiles, and tourism. Recently, Sri Lanka has been developing its 
infrastructure and technology sectors to support economic growth. Overall, it's a unique and 
captivating destination, attracting visitors from all over the world to explore its beauty and 
experience its welcoming culture. </p>`,
      },
      benefits_for_tourist: {
        heading: "Benefits for a tourist while going to Sri Lanka ",
        description: "",
        pointers: [
          {
            point: `<b>Beautiful Landscapes: </b>From stunning beaches to lush tea plantations and mountains. 
            Rich Culture: Thousands of years of history, with ancient cities and temples to explore. 
            Wildlife and Nature: Amazing biodiversity and national parks with elephants and 
            leopards. `,
          },
          {
            point: `<b>Warm Hospitality:</b> Locals are friendly and welcoming to visitors. `,
          },
          {
            point: `<b>Tasty Cuisine: </b>Enjoy a variety of flavorful dishes, including spicy curries and fresh 
            seafood. 
            `,
          },
          {
            point: `<b>Affordable Travel:</b> It's a budget-friendly destination with many options for 
accommodations and activities. `,
          },
          {
            point: `<b>Adventure Activities: </b>Surfing, diving, hiking, and wildlife safaris for thrill-seekers. `,
          },
          {
            point: `<b>Cultural Festivals: </b>Experience colorful and vibrant traditions and celebrations. `,
          },
          {
            point: `<b>Tropical Climate:</b> Perfect weather for enjoying the sun, sand, and sea. `,
          },
          {
            point: `<b>Easy Accessibility:</b> Well-connected airports and transportation make getting around easy. `,
          },
          {
            point: `<b>English Proficiency:</b> Communicate easily with locals as English is widely spoken. `,
          },
        ],
      },
      documents_required: {
        heading: "Documents required  ",
        description: "",
        pointers: [
          {
            point: `When traveling to Sri Lanka, make sure your passport is valid for at least six more 
            months.  `,
          },
          {
            point: `Show that you have enough money to support yourself during your stay.  `,
          },
          {
            point: `Have a return flight ticket to prove you'll leave Sri Lanka on time.  `,
          },
          {
            point: `If your trip is for business, bring a letter from the company inviting you. `,
          },
        ],
      },
      eligibility_criteria: {},
      process_for_visa_application: {
        heading: "",
      },
    },
    thailand: {
      metaTitle: "Discover the Beauty of Thailand with Achintya Immigration",
      metaDescription:
        "Experience Thailand's beauty hassle-free with Achintya Immigration's expert assistance. Secure your tourist visa effortlessly. Start your journey now!",
      metaKeywords:
        "best thailand tourist visa for indian, 	Thailand tourist visa requirements ,	online thailand visa application	 ",
      banner: {
        heading: "Thailand Tourist Visa",
        banner_image: thailand,
      },
      introduction: {
        heading: "Introduction to Thailand ",
        content: `
        <p>
        Thailand, known as the "Land of Smiles," is a captivating country in Southeast Asia. It's famous 
for stunning landscapes, rich culture, and warm hospitality, attracting travelers worldwide. </p>
<p>
From lively cities like Bangkok with impressive temples to peaceful beaches and lush islands, 
Thailand offers diverse experiences for all travelers. Its blend of ancient traditions and modern 
influences creates a fascinating and dynamic atmosphere that captivates visitors. </p>
<p>
Thailand's mouthwatering cuisine, bustling markets, and colorful festivals delight the senses. The 
friendly locals add to the charm, making visitors feel welcome and embraced by the culture. </p>
<p>
Whether you seek adventure in jungles, relaxation on beaches, spiritual experiences in temples, 
or vibrant city life, Thailand promises an unforgettable journey. Embrace the beauty and magic 
of Thailand as you explore this enchanting destination that leaves a lasting impression on every 
traveler's heart. </p>`,
      },
      benefits_for_tourist: {
        heading: "Benefits for a tourist while going to Thailand",
        description:
          "Thailand is a fantastic place to visit with lots of benefits for tourists: ",
        pointers: [
          {
            point: `<b> Beautiful Nature: </b> Thailand has stunning beaches, jungles, and mountains, 
        making it perfect for nature lovers and adventurers. `,
          },
          {
            point: `<b> Rich Culture:</b> You can explore ancient temples, palaces, and enjoy lively 
        festivals, giving you a taste of Thailand's vibrant culture. `,
          },
          {
            point: `<b> Friendly People:</b> Thais are known for their warm and welcoming nature, 
        ensuring you have a pleasant and memorable trip. `,
          },
          {
            point: `<b> Tasty Food:</b> Thai cuisine is famous worldwide for its delicious flavors and 
        diverse dishes, a treat for food enthusiasts. `,
          },
          {
            point: `<b> Affordable: </b> Traveling in Thailand is budget-friendly, with various options 
        for accommodation, food, and transportation. `,
          },
          {
            point: `<b> Exciting Cities: </b> Cities like Bangkok and Chiang Mai offer a mix of modern 
        and traditional experiences, with vibrant streets and great shopping. `,
          },

          {
            point: `<b> Adventurous Activities:</b> You can enjoy thrilling activities like jungle 
        trekking, cave exploration, and water sports. `,
          },
          {
            point: `<b> Amazing Marine Life:</b> Diving and snorkeling in Thailand's coastal areas let you discover its rich underwater world. `,
          },
          {
            point: `<b> Relaxing Wellness:</b> Traditional Thai massages and wellness practices help 
you unwind and feel rejuvenated. `,
          },
          {
            point: `<b> Cultural Immersion: </b> Participate in Thai festivals, learn traditional arts, and 
interact with locals for a deeper cultural experience. `,
          },
        ],
      },
      eligibility_criteria: {},
      process_for_visa_application: {
        heading: "Documents Required  ",
        description:
          "When applying for a Thailand Visa, you need the following documents:",
        pointers: [
          {
            point: ` Valid passport with at least six months validity and two blank pages. `,
          },
          {
            point: ` For a one-year visa, your passport should be valid for at least 18 months. `,
          },
          {
            point: ` Completed and signed the Thailand Visa application form.`,
          },
          {
            point: ` Passport-sized photo with specific requirements. `,
          },
          {
            point: ` Size: 3.5 cm x 4.5 cm. `,
          },
          {
            point: ` White background. `,
          },
          {
            point: ` Taken within the last six months. `,
          },
          {
            point: ` Neutral facial expression, looking straight ahead. `,
          },
          {
            point: ` Full visibility of your face. `,
          },
          {
            point: ` Headgear allowed only for religious purposes, not covering the face. `,
          },
          {
            point: ` Glasses are allowed if they don't cover the eyes and have no heavy frames. `,
          },
        ],
      },
      documents_required: {},
    },
    uk: {
      banner: {
        heading: "UK Tourist Visa",
        banner_image: uk,
      },
      introduction: {
        heading: "Introduction about the UK ",
        content: `
        <p>
        The UK, known as the United Kingdom, is a fascinating and diverse country in Europe. It is 
made up of four countries – England, Scotland, Wales, and Northern Ireland – each with its own 
unique history and culture. </p>
<p>
The UK has a long and influential history, with ancient castles and palaces, as well as modern 
cities with advanced technology. London, the capital city, is a bustling global hub with famous 
landmarks and top-notch museums. The countryside is filled with beautiful landscapes, charming 
villages, and historic towns. </p>
<p>
The UK is also known for its rich cultural heritage, including famous writers, theaters, and music 
bands. Whether you explore historic sites, enjoy art and culture, savor traditional food, or meet 
friendly locals, the UK offers an unforgettable journey full of charm and excitement. </p>`,
      },
      benefits_for_tourist: {
        heading: "Benefits for a tourist while going to the UK ",
        description: "Traveling to the UK has many benefits for tourists: ",
        pointers: [
          {
            point: `<b>Rich History and Culture: </b>The UK has historic landmarks and famous museums that 
            show its interesting past. `,
          },
          {
            point: `<b>Exciting Cities: </b>From London to Edinburgh, there are lively cities with lots of things to 
            do. `,
          },
          {
            point: `<b>Beautiful Nature: </b>The UK has pretty countryside, lovely coasts, and mountains for 
            outdoor activities. `,
          },
          {
            point: `<b>Great Museums and Art:</b> You can see famous art and artifacts in top museums. `,
          },
          {
            point: `<b>Fun Festivals: </b>Throughout the year, there are cultural events and music festivals to enjoy.`,
          },
          {
            point: `<b>Literary Legacy: </b>You can explore places related to famous writers. `,
          },
          {
            point: `<b>Top Education: </b>The UK has excellent universities that attract students from all over the 
            world. `,
          },
          {
            point: `<b>Easy Transportation: </b>The UK has a good public transportation system for convenient and 
            safe travel. `,
          },
        ],
      },
      documents_required: {
        heading: "Documents Required ",
        description:
          "When applying for a visa to the UK, you need to provide the following information: ",
        pointers: [
          {
            point: `<b>Personal Information:</b> Your basic personal details, such as name, date of birth, and 
            passport information. `,
          },
          {
            point: `<b>Financial Information:</b> Details about your salary and finances to show that you can 
            support yourself during your stay. `,
          },
          {
            point: `<b>Travel Itinerary and History:</b> Information about your travel plans and any previous travel 
 
            history you might have. `,
          },
          {
            point: `<b>Proof of Adequate Funding:</b> Evidence that you have enough money to cover your 
            expenses while in the UK. `,
          },
        ],
      },
      eligibility_criteria: {},
      process_for_visa_application: {
        heading: "",
      },
    },
    turkey: {
      metaTitle:
        "Plan Your Turkey Escape: Achintya Immigration Takes You There",
      metaDescription:
        "Explore Turkey's beauty hassle-free with Achintya Immigration's expert tourist visa assistance. Simplifying your journey to captivating destinations.",
      metaKeywords:
        "turkey visa requirements ,	turkey visa online apply ,	Best turkey tourist visa consultants	 	 ",
      banner: {
        heading: "Turkey Tourist Visa",
        banner_image: turkey,
      },
      introduction: {
        heading: "Introduction to Turkey",
        content: ` 
            <p> 
            Turkey, a captivating country, sits between Europe and Asia, blending ancient history and 
    vibrant cultures. From Istanbul's mix of old and new to the surreal landscapes of Cappadocia, 
    Turkey offers diverse experiences. 
    </p> 
    <p> 
    Discover ancient ruins like Troy and Ephesus, or unwind on the stunning Mediterranean and 
    Aegean coastlines. The warm hospitality and delicious cuisine welcome you, making Turkey a land 
    of wonder and magic. 
    </p> 
    <p> 
    Whether seeking adventure, history, relaxation, or great food, Turkey promises a memorable 
    journey. 
    </p> `,
      },
      benefits_for_tourist: {
        heading: "Benefits for a tourist while going to Turkey",
        description: " Turkey has so much to offer for tourists: ",
        pointers: [
          {
            point: `<b> Rich History: </b> You can explore ancient ruins and historical sites that reveal 
Turkey's fascinating past. `,
          },
          {
            point: `<b> Beautiful Nature:</b> Enjoy stunning landscapes, from sandy beaches to unique 
Cappadocia. `,
          },
          {
            point: `<b> Vibrant Cities:</b> Experience a blend of modern and traditional life in cities 
like Istanbul and Ankara. `,
          },
          {
            point: `<b> Delicious Food:</b> Try mouthwatering Turkish cuisine, including famous 
kebabs and baklava. `,
          },
          {
            point: `<b> Friendly People: </b> Turkish people are warm and welcoming to tourists. `,
          },
          {
            point: `<b> UNESCO Sites: </b> Discover impressive UNESCO World Heritage Sites, like 
Hagia Sophia and Cappadocia's rock formations. `,
          },
          {
            point: `<b> Affordable Travel:</b> Turkey offers budget-friendly accommodations and 
activities. `,
          },
          {
            point: `<b> Ancient Ruins:</b> History lovers can explore well-preserved ancient ruins. `,
          },
          {
            point: `<b> Relaxing Hammams:</b> Indulge in traditional Turkish baths for a relaxing spa 
experience. `,
          },
          {
            point: `<b> Diverse Activities: </b> From hot air ballooning to Bosphorus cruises, there's 
something for everyone in Turkey. `,
          },
        ],
      },
      eligibility_criteria: {},
      process_for_visa_application: {
        heading: "Documents Required  ",
        description: "To apply for the visa, you'll need:",
        pointers: [
          {
            point: ` A valid passport with at least six months of validity beyond your intended stay. `,
          },
          {
            point: ` Passport-size photos.. `,
          },
          {
            point: ` A completed and signed application form.`,
          },
          {
            point: ` Your travel itinerary, including hotel and flight bookings. `,
          },
          {
            point: ` Proof of sufficient finances to cover your expenses during your trip. `,
          },
          {
            point: ` A cover letter with all the necessary details about your travel plans. `,
          },
          {
            point: ` Taken within the last six months. `,
          },
          {
            point: ` Neutral facial expression, looking straight ahead. `,
          },
          {
            point: ` A letter from your employer (if applicable) confirming your employment. `,
          },
          {
            point: ` Recent bank statements and income tax documents. `,
          },
          {
            point: ` Travel insurance provides coverage for major injuries or accidents. `,
          },
        ],
      },
      documents_required: {},
    },
    vietnam: {
      metaTitle:
        "Achintya Immigration: Your Partner for Memorable Vietnam Visit",
      metaDescription:
        "Discover Vietnam's beauty hassle-free with Achintya Immigration's expert tourist visa services. Simplifying your journey to Vietnam. Apply now!",
      metaKeywords: `Apply for veitnam tourist visa	,best veitnam tourist visa in delhi ,	best tourist visa for veitnam	 `,
      banner: {
        heading: "Introduction to Vietnam",
        banner_image: vietnam,
      },
      introduction: {
        heading: "Introduction about Vietnam",
        content: ` 
            <p> 
            Vietnam is a captivating and vibrant country in Southeast Asia, famous for its rich history, 
    stunning landscapes, and diverse culture. Located on the eastern coast of the Indochinese Peninsula, 
    Vietnam blends ancient traditions with modern developments. The country has a long history, 
    shaped by different dynasties and colonial influences. Today, Vietnam embraces its past while 
    looking forward to the future. 
    </p> 
    <p> 
    In Vietnam, you can explore bustling cities like Hanoi and Ho Chi Minh City, filled with historical 
    landmarks. Or you can enjoy the tranquil beauty of places like Ha Long Bay and Sapa's terraced 
    rice fields. Vietnamese cuisine offers mouthwatering dishes like pho and banh mi. The friendly 
    locals make you feel welcome and at home. 
    </p> 
    <p> 
    For history lovers, Vietnam's ancient temples and historic sites offer fascinating insights into its 
    past. The vibrant markets and busy streets give you a taste of everyday life. Whether you want to 
    admire Vietnam's natural beauty, learn about its history and culture, or enjoy its delicious food, 
    Vietnam promises an unforgettable journey. Experience the charm, diversity, and warmth of 
    Vietnam as you immerse yourself in its captivating experiences. 
    </p> `,
      },
      benefits_for_tourist: {
        heading: "Benefits for a tourist while going to Vietnam",
        description:
          " Traveling to Vietnam is an exciting and enriching experience with many benefits: ",
        pointers: [
          {
            point: `<b> Stunning Scenery: </b> From Ha Long Bay's limestone formations to Sapa's 
              rice fields and beautiful beaches, Vietnam offers breathtaking landscapes for adventure and 
              relaxation `,
          },
          {
            point: `<b> Delicious Food:</b> Enjoy tasty and healthy Vietnamese cuisine, including 
            famous dishes like pho and banh mi. `,
          },
          {
            point: `<b> Friendly People:</b> Vietnamese people are warm and welcoming, making 
            tourists feel at home. `,
          },
          {
            point: `<b> Affordable:</b> Vietnam is budget-friendly, with affordable accommodations, 
food, and activities. `,
          },
          {
            point: `<b> History and Landmarks: </b> Discover historical sites and landmarks, gaining 
insights into Vietnam's past. `,
          },
          {
            point: `<b> Unique Experiences: </b> Join local traditions and festivals, immersing in 
            Vietnamese culture. `,
          },
          {
            point: `<b> Affordable Travel:</b> Turkey offers budget-friendly accommodations and 
            activities. `,
          },
          {
            point: `<b> Diverse Activities:</b> Vietnam offers various activities like trekking, water 
            sports, and exploring caves. `,
          },
          {
            point: `<b> Shopping Delights:</b> Enjoy vibrant markets and shopping for unique 
            souvenirs. `,
          },
          {
            point: `<b> Easy Travel: </b> Vietnam has a well-connected transportation system for 
            convenient travel. `,
          },
        ],
      },
      eligibility_criteria: {},
      process_for_visa_application: {
        heading: "Documents Required  ",
        description: " To travel, you need:",
        pointers: [
          {
            point: ` Passport with at least six months left before it expires. `,
          },
          {
            point: ` A filled-out visa application form with your signature. `,
          },
          {
            point: ` Your travel plans, including places you'll visit. `,
          },
          {
            point: ` Proof of hotel and flight bookings. `,
          },
          {
            point: ` A return ticket. `,
          },
          {
            point: ` Bank statements from the last three months to show you have enough money for 
        your trip. `,
          },
        ],
      },
      documents_required: {
        heading: "",
      },
    },
  };
  return (
    <div>
      <Helmet>
        <link rel="canonical" href={canonicalURL} />
        <title>{countryIntro[selectedCountry]?.metaTitle}</title>
        <meta name="robots" content="index,follow" />
        <meta
          name="description"
          content={countryIntro[selectedCountry]?.metaDescription}
        />
        <meta
          name="keywords"
          content={countryIntro[selectedCountry]?.metaKeywords}
        />
      </Helmet>
      <Header item={navigationHeader} />
      <TouristBanner
        bannerContent={countryIntro[selectedCountry]?.banner}
        country={country}
      />
      <WhyPrCountry
        whyPRCountry={countryIntro[selectedCountry]?.introduction}
        country={country}
      />
      <PrBenefits
        prBenefits={countryIntro[selectedCountry]?.benefits_for_tourist}
        country={country}
      />
      <PrEligibility
        eligibilityData={countryIntro[selectedCountry]?.eligibility_criteria}
        country={country}
      />
      <PrDocuments
        documentsData={countryIntro[selectedCountry]?.documents_required}
        country={country}
      />
      <TouristVisaRequirement
        touristRequirement={
          countryIntro[selectedCountry]?.tourist_visa_requirements
        }
      />
      <PrProcess
        prProcess={countryIntro[selectedCountry]?.process_for_visa_application}
        country={country}
      />
      <Footer />
    </div>
  );
};

export default TouristVisa;

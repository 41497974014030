import React from "react";
import "./index.css";
import prEligibility from "../../../assets/PR Visa/eligibility.png";
import VerifiedIcon from "@mui/icons-material/Verified";

const PrEligibility = ({ eligibilityData, country }) => {
  return eligibilityData.heading ? (
    <div className="pr-eligibility-outer-container">
      <div className="container_wrapper">
        <div className="pr-eligibility-inner-container">
          <h2 className="pr-eligibility-heading">{eligibilityData?.heading}</h2>
          <p className="eligibilty-description">
            {eligibilityData?.description}
          </p>
          <div className="pr-eligibility-flex-container">
            <div className="pr-eligibility-image">
              <img src={prEligibility} alt="eligibility image" />
            </div>
            <div className="pr-eligibility-pointers-container">
              {eligibilityData.pointers.map((item, index) => (
                <div key={index} className="pr-eligibility-pointer">
                  <VerifiedIcon />
                  <p>{item?.point}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default PrEligibility;

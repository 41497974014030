import React, { useEffect } from "react";
import "./index.css";
import { Link, useLocation } from "react-router-dom";
import Header from "../../commonComponents/header";
import Footer from "../../commonComponents/footer";
import { Helmet } from "react-helmet";

const Page404 = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const touristCountries = [
    "Ireland",
    "Japan",
    "Australia",
    "Bangladesh",
    "Canada",
    "Dubai",
    "Germany",
    "Malaysia",
    "New Zealand",
    "Sri Lanka",
    "Schengen",
    "Singapore",
    "South Africa",
    "South Korea",
    "Turkey",
    "Thailand",
    "USA",
    "UAE",
    "UK",
    "Vietnam",
  ];

  const jobSearch = [
    "Malta",
    "Poland",
    "Schengen",
    "Singapore",
    "UAE",
    "UK",
    "Australia",
    "Dubai",
    "Germany",
    "Gulf",
  ];

  const otherServices = ["IELTS", "Resume"];

  return (
    <div>
      <Helmet>
        <title>Page Not Found</title>
      </Helmet>
      <Header
        touristCountries={touristCountries}
        jobSearch={jobSearch}
        otherServices={otherServices}
      />
      <div className="oops-outer-container">
        <div className="container_wrapper">
          <div className="oops-inner-container">
            <div className="oops-image-container">
              <img
                src="https://achintyaconsultant.s3.ap-south-1.amazonaws.com/oops.png"
                alt="oops image"
              />
            </div>
            <div className="oops-content-container">
              <p className="oops-heading">OOPS!</p>
              <p className="page-not-found-msg">Page Not Found</p>
              <p className="oops-sorry-msg">
                We're sorry, the page you requested could not be found please go
                back to the homepage
              </p>
            </div>
            <div className="homepage-btn">
              <Link to={"/"}>
                <button>Go Back</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Page404;

import React, { useEffect } from "react";
import "./index.css";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import Header from "../../commonComponents/header";
import Footer from "../../commonComponents/footer";
import { createCountryUrl } from "../../Utilities/commonFunctions";

const TermsNCondition = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);


  const navigationHeader = [
    {
      title: "Home",
      link: `/`,
    },
    {
      title: "About Us",
      subItems: [
        {
          title: "About",
          link: `/about-us/`,
        },
        {
          title: "Reviews",
          link: ` /reviews/`,
        },
        {
          title: "Gallery",
          link: `/gallery/`,
        },
      ],
    },
    {
      title: "Canada",
      subItems: [
        {
          title: "Canada Express Entry",
          // link: `/${createCountryUrl("canada")}/express-entry/`,
          subDataItems: [
            {
              title: "Canada Express Entry",
              link: `/${createCountryUrl("canada")}/express-entry/`,
            },
            {
              title: "CRS Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/crs-calculator/`,
            },
            {
              title: "CRS Chart",
              link: `/${createCountryUrl("canada")}/express-entry/crs-chart/`,
            },
            {
              title: "67 Points Calculator and charts",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/${createCountryUrl(
                "67 Points Calculator and charts"
              )}`,
            },
            {
              title: "Latest Express Entry Draw",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/latest-express-entry-draw/`,
            },
            {
              title: "Benefits of the Program",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/benefits-of-the-program/`,
            },
            {
              title: "Occupation Demand",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/occupation-demand/`,
            },
            {
              title: "Provisional Nominee Program",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/provisional-nominee-program/`,
            },
            {
              title: "Federal Skilled Worker Program",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/fedral-skilled-worker-program/`,
            },
            {
              title: "Federal Skilled Trade Program",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/fedral-skilled-trade-program/`,
            },
            {
              title: "Canadian Experience Class",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/canadian-experience-class/`,
            },
            {
              title: "Super Visa From India",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/super-visa-from-india/`,
            },
            {
              title: "IELTS",
              link: `/${createCountryUrl("canada")}/express-entry/ielts/`,
            },
          ],
        },
        {
          title: "Provincial Nominee Program",
          subDataItems: [
            {
              title: "Manitoba PNP Program ",
              link: `/${createCountryUrl(
                "canada"
              )}/pnp-program/${createCountryUrl("Manitoba PNP Program")}/`,
            },
            {
              title: "Yukon Nominee Program ",
              link: `/${createCountryUrl(
                "canada"
              )}/pnp-program/${createCountryUrl("Yukon Nominee Program")}/`,
            },
            {
              title: "Ontario PNP Program ",
              link: `/${createCountryUrl(
                "canada"
              )}/pnp-program/${createCountryUrl("Ontario PNP Program")}/`,
            },
            {
              title: " Nova Scotia PNP ",
              link: `/${createCountryUrl(
                "canada"
              )}/pnp-program/${createCountryUrl("Nova Scotia PNP")}/`,
            },
            {
              title: "Alberta PNP Program ",
              link: `/${createCountryUrl(
                "canada"
              )}/pnp-program/${createCountryUrl("Alberta PNP Program")}/`,
            },
            {
              title: "BC PNP Program ",
              link: `/${createCountryUrl(
                "canada"
              )}/pnp-program/${createCountryUrl("BC PNP Program")}/`,
            },
            {
              title: "Quebec Skilled Worker Program ",
              link: `/pnp-program/${createCountryUrl(
                "Quebec Skilled Worker Program"
              )}/`,
            },
            {
              title: "Saskatchewan PNP ",
              link: `/pnp-program/${createCountryUrl("Saskatchewan PNP ")}/`,
            },
            {
              title: "New Brunswick PNP Program ",
              link: `/pnp-program/${createCountryUrl(
                "New Brunswick PNP Program "
              )}/`,
            },
            {
              title: "Atlantic Immigration Pilot Program ",
              link: `/pnp-program/${createCountryUrl(
                "Atlantic Immigration Pilot Program "
              )}/`,
            },
            {
              title: "PEI PNP Program ",
              link: `/pnp-program/${createCountryUrl("PEI PNP Program ")}/`,
            },
            {
              title: "NLPNP Program ",
              link: `/pnp-program/${createCountryUrl("NLPNP Program")}/`,
            },
          ],
        },
        {
          title: "Point Calculator",
          subDataItems: [
            {
              title: "CRS Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl("CRS Calculator")}/`,
            },
            {
              title: "Saskatchewan Points Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl(
                "Saskatchewan Points Calculator"
              )}`,
            },
            {
              title: "Canada 67 points Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl(
                "Canada 67 points Calculator"
              )}`,
            },
            {
              title: "British columbia Points Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl(
                "British columbia Points Calculator"
              )}`,
            },
            {
              title: "Manitoba Points Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl(
                "Manitoba Points Calculator"
              )}`,
            },
            {
              title: "Nova Scotia Points Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl(
                "Nova Scotia Points Calculator"
              )}`,
            },
            {
              title: "Alberta Points Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl(
                "Alberta Points Calculator"
              )}`,
            },
            {
              title: "Ontario Points Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl(
                "Ontario Points Calculator"
              )}`,
            },
            {
              title: " Quebec Points Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl(
                " Quebec Points Calculator"
              )}`,
            },
          ],
        },
        {
          title: "LMIA",
          link: `/${createCountryUrl("Canada")}/lmia/`,
        },
        {
          title: "Canada Spouse Dependent Visa",
          link: `/${createCountryUrl("Canada")}/spouse-dependent-visa/`,
        },
        {
          title: "Super Visa",
          link: `/${createCountryUrl("Canada")}/super-visa/`,
        },
        {
          title: "Quebec Skilled Worker Program",
          link: `/${createCountryUrl("Canada")}/quebec-skilled-worker-program/`,
        },
        {
          title: "Atlantic Immigration Program",
          link: `/${createCountryUrl("Canada")}/atlantic-immigration-program/`,
        },
        {
          title: "Startup Visa",
          link: `/${createCountryUrl("Canada")}/startup-visa/`,
        },
        {
          title: "Entrepreneur Investment Visa",
          link: `/${createCountryUrl("Canada")}/entrepreneur-investment-visa/`,
        },
      ],
    },
    {
      title: "Australia",
      subItems: [
        {
          title: "Australia Skilled Occupation List",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Australia Skilled Occupation List"
          )}`,
        },
        {
          title: "Australia 65 Points Calculator",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Australia 65 Points Calculator"
          )}`,
        },
        {
          title: "Employer Nomination Subclass 186",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Employer Nomination Subclass 186"
          )}`,
        },
        {
          title: "Skilled Independent Subclass 189",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Skilled Independent Subclass 189"
          )}`,
        },
        {
          title: "Skilled Nominated Subclass 190",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Skilled Nominated Subclass 190"
          )}`,
        },
        {
          title: "Australia Subclass 491",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Australia Subclass 491"
          )}`,
        },
        {
          title: "Australia PR Visa From India",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Australia PR Visa From India"
          )}`,
        },
        {
          title: "Australia Spouse Visa",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "AAustralia Spouse Visa"
          )}`,
        },
        {
          title: "Invitation To Apply",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Invitation To Apply"
          )}`,
        },
        {
          title: "Australia Tourist Visa From India",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Australia Tourist Visa From India"
          )}`,
        },
        {
          title: "Australia Subclass 476",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Australia Subclass 476"
          )}`,
        },
      ],
    },
    {
      title: "Travel Visa",
      link: "/travel-visa/",
      subItems: [
        {
          title: "Canada",
          link: `/travel-visa/${createCountryUrl("Canada")}/`,
        },
        {
          title: "Australia",
          link: `/travel-visa/${createCountryUrl("Australia")}/`,
        },
        {
          title: "USA",
          link: `/travel-visa/${createCountryUrl("USA")}/`,
        },
        {
          title: "UK",
          link: `/travel-visa/${createCountryUrl("UK")}/`,
        },
        {
          title: "New Zealand",
          link: `/travel-visa/${createCountryUrl("New Zealand")}/`,
        },
        {
          title: "Dubai",
          link: `/travel-visa/${createCountryUrl("Dubai")}/`,
        },
        {
          title: "Germany",
          link: `/travel-visa/${createCountryUrl("Germany")}/`,
        },
        {
          title: "Schengen",
          link: `/travel-visa/${createCountryUrl("Schegan")}/`,
        },
        {
          title: "Turkey",
          link: `/travel-visa/${createCountryUrl("Turkey")}/`,
        },
        {
          title: "Malaysia",
          link: `/travel-visa/${createCountryUrl("Malaysia")}/`,
        },
        {
          title: "Singapore",
          link: `/travel-visa/${createCountryUrl("Singapore")}/`,
        },

        {
          title: "Ireland",
          link: `/travel-visa/${createCountryUrl("Ireland")}/`,
        },
        {
          title: "Japan",
          link: `/travel-visa/${createCountryUrl("Japan")}/`,
        },

        {
          title: "Bangladesh",
          link: `/travel-visa/${createCountryUrl("Bangladesh")}/`,
        },

        {
          title: "Sri Lanka",
          link: `/travel-visa/${createCountryUrl("Sri Lanka")}/`,
        },
        {
          title: "South Africa",
          link: `/travel-visa/${createCountryUrl("South Africa")}/`,
        },
        {
          title: "South Korea",
          link: `/travel-visa/${createCountryUrl("South Korea")}/`,
        },

        {
          title: "Thailand",
          link: `/travel-visa/${createCountryUrl("Thailand")}/`,
        },

        {
          title: "UAE",
          link: `/travel-visa/${createCountryUrl("UAE")}/`,
        },

        {
          title: "Vietnam",
          link: `/travel-visa/${createCountryUrl("Vietnam")}/`,
        },
      ],
    },
    {
      title: "Job Search Program",
      subItems: [
        {
          title: "Malta",
          link: `/job-search-visa/${createCountryUrl("Malta")}/`,
        },
        {
          title: "Poland",
          link: `/job-search-visa/${createCountryUrl("Poland")}/`,
        },
        {
          title: "Schengen",
          link: `/job-search-visa/${createCountryUrl("Schengen")}/`,
        },
        {
          title: "Singapore",
          link: `/job-search-visa/${createCountryUrl("Singapore")}/`,
        },
        { title: "UAE", link: `/job-search-visa/${createCountryUrl("UAE")}/` },
        { title: "UK", link: `/job-search-visa/${createCountryUrl("UK")}/` },
        {
          title: "Australia",
          link: `/job-search-visa/${createCountryUrl("Australia")}/`,
        },
        {
          title: "Dubai",
          link: `/job-search-visa/${createCountryUrl("Dubai")}/`,
        },
        {
          title: "Germany",
          link: `/job-search-visa/${createCountryUrl("Germany")}/`,
        },
        {
          title: "Gulf",
          link: `/job-search-visa/${createCountryUrl("Gulf")}/`,
        },
      ],
    },
    {
      title: "Resources",
      link: "/resources/",
      subItems: [
        {
          title: "Career",
          link: "/career/",
        },
        {
          title: "Blogs",
          link: "/blog/",
        },
        {
          title: "News",
          link: "/news/",
        },
      ],
    },
    {
      title: "Connect Us",
      link: "/connect-us/",
    },
  ];
  const canonicalURL = "https://achintyaconsultant.com/terms-conditions/";
  return (
    <div>
      <Helmet>
        <link rel="canonical" href={canonicalURL} />
        <title>
          Terms and Conditions: Your Agreement with Achintya Immigration{" "}
        </title>
        <meta name="robots" content="index,follow" />
        <meta
          name="description"
          content="Trust and Transparency: Achintya Immigration's Terms and Conditions -Your aspirations, our commitment.Get acquainted with our terms to script your own triumph."
        />
        <meta
          name="keywords"
          content="Immigration terms and conditions, Legal requirements for immigration, Visa application guidelines"
        />
      </Helmet>
      <Header item={navigationHeader} />

      <div className="terms-outer-container">
        <div className="terms-outer-heading-container">
          <div className="container_wrapper">
            <div className="terms-heading-container">
              <h2>Terms and Conditions</h2>
            </div>
          </div>
        </div>
        <div className="terms-inner-container">
          <div className="container_wrapper">
            <div className="terms-content-container">
              <div className="terms-content1">
                <p>
                  Welcome to our website! By continuing to browse and use this
                  website you agree to comply with and be bound by the following
                  terms and conditions of use, which together with our privacy
                  policy govern Achintya Immigration Consultant Private
                  Limited’s relationship with you in relation to this website.
                  In case you differ or do not agree with any part of the terms
                  and conditions of this website, please desist from using our
                  website.
                </p>
                <p>
                  The Term “Achintya Immigration Consultant Private Limited” or
                  “AICPL” or “Website” or “Website Owner” or “us” or “we” refers
                  to the owner of this website including subsidiaries and
                  affiliates having registered corporate office at 1204-05, 12th
                  Floor, Surya Kiran Building, 19 KG Marg, New Delhi – 110001,
                  India. The term “you” or “your” refers to the end user or
                  viewer or visitor of the website. We provide the information
                  contained on the website or any of the pages comprising the
                  website (“Website”) to you subject to the terms and conditions
                  set out on this website, other relevant terms and conditions,
                  polices and notices which may be applicable to a specific
                  section or module of the website.
                </p>
                <p>
                  The Headings contained herein is for ease of reference only
                  and shall not affect the interpretation of these Terms &
                  Conditions.
                </p>
              </div>
              <div className="terms-sub-sub-haeding margin-bottom-change">
                <p>ALLIANCE </p>
              </div>
              <div className="terms-content1">
                <p>
                  AICPL is not affiliated with any government or government
                  agency for any kind of permits from any organisation or
                  department. It provides consultation, assistance in
                  documentation, application filing for immigration/visas and
                  charges a service fee for the same. It offers general
                  information on issues related to immigration services on its
                  website
                  <a href="www.achintyaconsultant.com">
                    www.achintyaconsultant.com
                  </a>
                  . AICPL does not offer any kind of legal advice to its users
                  through its website. Any service taken through the information
                  provided on its website shall be subject to the terms and
                  conditions to which, by using this site or availing any
                  service, you are agreeing to be bound.
                </p>
              </div>
              <div className="terms-sub-sub-haeding margin-bottom-change">
                <p>CREDIBILITY </p>
              </div>
              <div className="terms-content1">
                <p>
                  AICPL is a valid and legitimate business which has acquired
                  validation through benchmark checks.
                </p>
              </div>
              <div className="terms-sub-sub-haeding margin-bottom-change">
                <p>LIMITATION OF LIABILITY </p>
              </div>
              <div className="terms-content1">
                <p>
                  AICPL shall not be liable for any direct, indirect,
                  incidental, special, consequential damage that may result from
                  the use or the inability to use, the materials on this
                  website, or the performance of the products, any services
                  availed or obtained or transactions entered into through the
                  website.
                </p>
                <p>
                  Applicable law may not allow the limitation of exclusion of
                  liability for incidental or consequential damages; thereby the
                  above limitation or exclusion may not apply to you.
                </p>
              </div>
              <div className="terms-sub-sub-haeding margin-bottom-change">
                <p>INTELLECTUAL PROPERTY RIGHTS </p>
              </div>
              <div className="terms-content1">
                <p>
                  Except as stated otherwise, AICPL is the copyright holder of
                  the entire content, layout, graphics, design, data, trademarks
                  and logos under this domain as per the Copyright Act, 1957. We
                  shall not hesitate to take legal action to protect these
                  rights in case of infringement. The forms provided on our
                  website which are published by various overseas government
                  agencies shall not be subject to any copyrights. However, for
                  the forms provided by AICPL, the Company shall claim
                  copyrights.
                </p>
                <p>
                  AICPL hereby grants you a limited, personal, non-transferable,
                  non- sublicensable, revocable licence to (a) access and use
                  only the Web Site, Content and Services only in the manner
                  presented herein, and (b) access and use the computer and
                  network services offered within the Web Site only in the
                  manner expressly permitted by us. Except to the extent
                  required by law or as expressly provided herein, none of the
                  Content and/or Information may be reverse-engineered,
                  modified, amended, reproduced, republished, translated into
                  any language or computer language, re-transmitted in any form
                  or by any means, resold or redistributed without the prior
                  written consent of AICPL. You may not make, sell, offer for
                  sale, modify, amend, reproduce, display, publicly perform,
                  import, distribute, retransmit or otherwise use the Content in
                  any way unless expressly permitted to do so by AICPL.
                </p>
              </div>
              <div className="terms-sub-sub-haeding margin-bottom-change">
                <p>PERSONAL USE </p>
              </div>
              <div className="terms-content1">
                <p>
                  All the information including but not limited to articles,
                  emails and services provided by us are for your personal and
                  non-commercial use. You shall not modify, copy, distribute,
                  transmit, display, perform, reproduce, publish, licence,
                  transfer or sell the contents or services obtained from us
                  without express written permission.
                </p>
              </div>
              <div className="terms-sub-sub-haeding margin-bottom-change">
                <p>USER BEHAVIOUR RULES </p>
              </div>
              <div className="terms-content1">
                <p>
                  You are not allowed to perform certain actions including but
                  not limited to using the website inappropriately which may
                  slow down, disable or harm the free flow of the website;
                  recording, publishing , telecasting or using the content of
                  our website for any commercial, non-commercial and individual
                  purposes; intimidating or harassing any person of our team
                  either directly or indirectly; using inappropriate language
                  while posting comments on different parts of our website .If
                  we observe such behavioural violation, AICPL shall reserve the
                  rights to take legal action including termination or
                  suspension of your permission to use our platform or the
                  services at any time, with or without notice.
                </p>
              </div>
              <div className="terms-sub-sub-haeding margin-bottom-change">
                <p>DATA PROTECTION PRINCIPLES:</p>
              </div>
              <div className="terms-content1">
                <p>
                  We comply with all the data protection laws and recognise the
                  right to privacy of each user. The personal information and
                  data we hold about you is used in a secured and transparent
                  manner. AICPL does not use, store or transmit any information
                  or data in any manner that is in contradiction to the purposes
                  explained to you while collection of the data unless it is
                  reasonably considered to use it for another reason.
                </p>
              </div>
              <div className="terms-sub-sub-haeding margin-bottom-change">
                <p>DATA SECURITY: </p>
              </div>
              <div className="terms-content1">
                <p>
                  We have put in place measures to protect the security of your
                  personal information from being accidentally lost, used, or
                  accessed in an unauthorized way, altered, or disclosed. In
                  addition, we limit access to your personal information to
                  those employees, and agents who require it on a "need-to-know
                  basis". However, we have put in place procedures to deal with
                  any suspected data security breach and shall notify you and
                  any applicable regulator of a suspected breach where we are
                  legally required to do so.
                </p>
              </div>
              <div className="terms-sub-sub-haeding margin-bottom-change">
                <p>COOKIES </p>
              </div>
              <div className="terms-content1">
                <p>
                  This website makes use of cookies to keep an eye on the
                  browsing choices of its users and visitors. In case you permit
                  the usage of these cookies, we can store private data for
                  usage of third parties.
                </p>
              </div>
              <div className="terms-sub-sub-haeding margin-bottom-change">
                <p>CHANGES TO OUR TERMS AND CONDITIONS</p>
              </div>
              <div className="terms-content1">
                <p>
                  We reserve the right to amend these terms and conditions at
                  our discretion at any time. You are responsible for
                  periodically visiting the website for any changes. By availing
                  our services here in the website, you hereby agree to all the
                  terms and conditions mentioned here. All disputes arising in
                  this respect are subject to the exclusive jurisdiction of
                  Delhi Courts only. By using our website and availing our
                  services, you have entered into a contract with us and in case
                  of litigation, you agree that the prevailing party shall be
                  awarded reasonable legal expenses.
                </p>
                <p>
                  Any feedback you provide at this website shall be deemed to be
                  non- confidential. We shall be free to use such information on
                  an unrestricted basis.
                </p>
                <p>
                  The information contained in this web site is subject to
                  change without notice. All rights reserved.
                </p>
                <p>
                  For further details, please, get in touch or you can e-mail us
                  at{" "}
                  <a href="mailto:Support@achintyaconsultant.com">
                    Support@achintyaconsultant.com
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TermsNCondition;

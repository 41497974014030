import React, { useEffect } from "react";
import { createCountryUrl } from "../../Utilities/commonFunctions";
import { Link, useLocation } from "react-router-dom";
import Footer from "../../commonComponents/footer";
import Header from "../../commonComponents/header";
import AbroadForm from "../../commonComponents/abroadForm";
import CandaPageComponents from "../../canadExpressPnpPage";
import ApplyNow from "../../commonButtonComponent/applyNow";
import { Helmet } from "react-helmet";

const FedralSkilledTradeProgram = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const navigationHeader = [
    {
      title: "Home",
      link: `/`,
    },
    {
      title: "About Us",
      subItems: [
        {
          title: "About",
          link: `/about-us/`,
        },
        {
          title: "Reviews",
          link: ` /reviews/`,
        },
        {
          title: "Gallery",
          link: `/gallery/`,
        },
      ],
    },
    {
      title: "Canada",
      subItems: [
        {
          title: "Canada Express Entry",
          // link: `/${createCountryUrl("canada")}/express-entry/`,
          subDataItems: [
            {
              title: "Canada Express Entry",
              link: `/${createCountryUrl("canada")}/express-entry/`,
            },
            {
              title: "CRS Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/crs-calculator/`,
            },
            {
              title: "CRS Chart",
              link: `/${createCountryUrl("canada")}/express-entry/crs-chart/`,
            },
            {
              title: "67 Points Calculator and charts",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/${createCountryUrl(
                "67 Points Calculator and charts"
              )}`,
            },
            {
              title: "Latest Express Entry Draw",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/latest-express-entry-draw/`,
            },
            {
              title: "Benefits of the Program",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/benefits-of-the-program/`,
            },
            {
              title: "Occupation Demand",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/occupation-demand/`,
            },
            {
              title: "Provisional Nominee Program",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/provisional-nominee-program/`,
            },
            {
              title: "Federal Skilled Worker Program",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/fedral-skilled-worker-program/`,
            },
            {
              title: "Federal Skilled Trade Program",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/fedral-skilled-trade-program/`,
            },
            {
              title: "Canadian Experience Class",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/canadian-experience-class/`,
            },
            {
              title: "Super Visa From India",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/super-visa-from-india/`,
            },
            {
              title: "IELTS",
              link: `/${createCountryUrl("canada")}/express-entry/ielts/`,
            },
          ],
        },
        {
          title: "Provincial Nominee Program",
          subDataItems: [
            {
              title: "Manitoba PNP Program ",
              link: `/${createCountryUrl(
                "canada"
              )}/pnp-program/${createCountryUrl("Manitoba PNP Program")}/`,
            },
            {
              title: "Yukon Nominee Program ",
              link: `/${createCountryUrl(
                "canada"
              )}/pnp-program/${createCountryUrl("Yukon Nominee Program")}/`,
            },
            {
              title: "Ontario PNP Program ",
              link: `/${createCountryUrl(
                "canada"
              )}/pnp-program/${createCountryUrl("Ontario PNP Program")}/`,
            },
            {
              title: " Nova Scotia PNP ",
              link: `/${createCountryUrl(
                "canada"
              )}/pnp-program/${createCountryUrl("Nova Scotia PNP")}/`,
            },
            {
              title: "Alberta PNP Program ",
              link: `/${createCountryUrl(
                "canada"
              )}/pnp-program/${createCountryUrl("Alberta PNP Program")}/`,
            },
            {
              title: "BC PNP Program ",
              link: `/${createCountryUrl(
                "canada"
              )}/pnp-program/${createCountryUrl("BC PNP Program")}/`,
            },
            {
              title: "Quebec Skilled Worker Program ",
              link: `/pnp-program/${createCountryUrl(
                "Quebec Skilled Worker Program"
              )}/`,
            },
            {
              title: "Saskatchewan PNP ",
              link: `/pnp-program/${createCountryUrl("Saskatchewan PNP ")}/`,
            },
            {
              title: "New Brunswick PNP Program ",
              link: `/pnp-program/${createCountryUrl(
                "New Brunswick PNP Program "
              )}/`,
            },
            {
              title: "Atlantic Immigration Pilot Program ",
              link: `/pnp-program/${createCountryUrl(
                "Atlantic Immigration Pilot Program "
              )}/`,
            },
            {
              title: "PEI PNP Program ",
              link: `/pnp-program/${createCountryUrl("PEI PNP Program ")}/`,
            },
            {
              title: "NLPNP Program ",
              link: `/pnp-program/${createCountryUrl("NLPNP Program")}/`,
            },
          ],
        },
        {
          title: "Point Calculator",
          subDataItems: [
            {
              title: "CRS Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl("CRS Calculator")}/`,
            },
            {
              title: "Saskatchewan Points Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl(
                "Saskatchewan Points Calculator"
              )}`,
            },
            {
              title: "Canada 67 points Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl(
                "Canada 67 points Calculator"
              )}`,
            },
            {
              title: "British columbia Points Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl(
                "British columbia Points Calculator"
              )}`,
            },
            {
              title: "Manitoba Points Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl(
                "Manitoba Points Calculator"
              )}`,
            },
            {
              title: "Nova Scotia Points Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl(
                "Nova Scotia Points Calculator"
              )}`,
            },
            {
              title: "Alberta Points Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl(
                "Alberta Points Calculator"
              )}`,
            },
            {
              title: "Ontario Points Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl(
                "Ontario Points Calculator"
              )}`,
            },
            {
              title: " Quebec Points Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl(
                " Quebec Points Calculator"
              )}`,
            },
          ],
        },
        {
          title: "LMIA",
          link: `/${createCountryUrl("Canada")}/lmia/`,
        },
        {
          title: "Canada Spouse Dependent Visa",
          link: `/${createCountryUrl("Canada")}/spouse-dependent-visa/`,
        },
        {
          title: "Super Visa",
          link: `/${createCountryUrl("Canada")}/super-visa/`,
        },
        {
          title: "Quebec Skilled Worker Program",
          link: `/${createCountryUrl("Canada")}/quebec-skilled-worker-program/`,
        },
        {
          title: "Atlantic Immigration Program",
          link: `/${createCountryUrl("Canada")}/atlantic-immigration-program/`,
        },
        {
          title: "Startup Visa",
          link: `/${createCountryUrl("Canada")}/startup-visa/`,
        },
        {
          title: "Entrepreneur Investment Visa",
          link: `/${createCountryUrl("Canada")}/entrepreneur-investment-visa/`,
        },
      ],
    },
    {
      title: "Australia",
      subItems: [
        {
          title: "Australia Skilled Occupation List",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Australia Skilled Occupation List"
          )}`,
        },
        {
          title: "Australia 65 Points Calculator",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Australia 65 Points Calculator"
          )}`,
        },
        {
          title: "Employer Nomination Subclass 186",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Employer Nomination Subclass 186"
          )}`,
        },
        {
          title: "Skilled Independent Subclass 189",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Skilled Independent Subclass 189"
          )}`,
        },
        {
          title: "Skilled Nominated Subclass 190",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Skilled Nominated Subclass 190"
          )}`,
        },
        {
          title: "Australia Subclass 491",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Australia Subclass 491"
          )}`,
        },
        {
          title: "Australia PR Visa From India",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Australia PR Visa From India"
          )}`,
        },
        {
          title: "Australia Spouse Visa",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "AAustralia Spouse Visa"
          )}`,
        },
        {
          title: "Invitation To Apply",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Invitation To Apply"
          )}`,
        },
        {
          title: "Australia Tourist Visa From India",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Australia Tourist Visa From India"
          )}`,
        },
        {
          title: "Australia Subclass 476",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Australia Subclass 476"
          )}`,
        },
      ],
    },
    {
      title: "Travel Visa",
      link: "/travel-visa/",
      subItems: [
        {
          title: "Canada",
          link: `/travel-visa/${createCountryUrl("Canada")}/`,
        },
        {
          title: "Australia",
          link: `/travel-visa/${createCountryUrl("Australia")}/`,
        },
        {
          title: "USA",
          link: `/travel-visa/${createCountryUrl("USA")}/`,
        },
        {
          title: "UK",
          link: `/travel-visa/${createCountryUrl("UK")}/`,
        },
        {
          title: "New Zealand",
          link: `/travel-visa/${createCountryUrl("New Zealand")}/`,
        },
        {
          title: "Dubai",
          link: `/travel-visa/${createCountryUrl("Dubai")}/`,
        },
        {
          title: "Germany",
          link: `/travel-visa/${createCountryUrl("Germany")}/`,
        },
        {
          title: "Schengen",
          link: `/travel-visa/${createCountryUrl("Schegan")}/`,
        },
        {
          title: "Turkey",
          link: `/travel-visa/${createCountryUrl("Turkey")}/`,
        },
        {
          title: "Malaysia",
          link: `/travel-visa/${createCountryUrl("Malaysia")}/`,
        },
        {
          title: "Singapore",
          link: `/travel-visa/${createCountryUrl("Singapore")}/`,
        },

        {
          title: "Ireland",
          link: `/travel-visa/${createCountryUrl("Ireland")}/`,
        },
        {
          title: "Japan",
          link: `/travel-visa/${createCountryUrl("Japan")}/`,
        },

        {
          title: "Bangladesh",
          link: `/travel-visa/${createCountryUrl("Bangladesh")}/`,
        },

        {
          title: "Sri Lanka",
          link: `/travel-visa/${createCountryUrl("Sri Lanka")}/`,
        },
        {
          title: "South Africa",
          link: `/travel-visa/${createCountryUrl("South Africa")}/`,
        },
        {
          title: "South Korea",
          link: `/travel-visa/${createCountryUrl("South Korea")}/`,
        },

        {
          title: "Thailand",
          link: `/travel-visa/${createCountryUrl("Thailand")}/`,
        },

        {
          title: "UAE",
          link: `/travel-visa/${createCountryUrl("UAE")}/`,
        },

        {
          title: "Vietnam",
          link: `/travel-visa/${createCountryUrl("Vietnam")}/`,
        },
      ],
    },
    {
      title: "Job Search Program",
      subItems: [
        {
          title: "Malta",
          link: `/job-search-visa/${createCountryUrl("Malta")}/`,
        },
        {
          title: "Poland",
          link: `/job-search-visa/${createCountryUrl("Poland")}/`,
        },
        {
          title: "Schengen",
          link: `/job-search-visa/${createCountryUrl("Schengen")}/`,
        },
        {
          title: "Singapore",
          link: `/job-search-visa/${createCountryUrl("Singapore")}/`,
        },
        { title: "UAE", link: `/job-search-visa/${createCountryUrl("UAE")}/` },
        { title: "UK", link: `/job-search-visa/${createCountryUrl("UK")}/` },
        {
          title: "Australia",
          link: `/job-search-visa/${createCountryUrl("Australia")}/`,
        },
        {
          title: "Dubai",
          link: `/job-search-visa/${createCountryUrl("Dubai")}/`,
        },
        {
          title: "Germany",
          link: `/job-search-visa/${createCountryUrl("Germany")}/`,
        },
        {
          title: "Gulf",
          link: `/job-search-visa/${createCountryUrl("Gulf")}/`,
        },
      ],
    },
    {
      title: "Resources",
      link: "/resources/",
      subItems: [
        {
          title: "Career",
          link: "/career/",
        },
        {
          title: "Blogs",
          link: "/blog/",
        },
        {
          title: "News",
          link: "/news/",
        },
      ],
    },
    {
      title: "Connect Us",
      link: "/connect-us/",
    },
  ];

  const enquiryCountries = [
    { label: "Ireland", value: "Ireland" },
    { label: "Japan", value: "Japan" },
    { label: "Australia", value: "Australia" },
    { label: "Bangladesh", value: "Bangladesh" },
    { label: "Canada", value: "Canada" },
    { label: "Dubai", value: "Dubai" },
    { label: "Germany", value: "Germany" },
    { label: "Malaysia", value: "Malaysia" },
    { label: "New Zealand", value: "New Zealand" },
    { label: "Sri Lanka", value: "Sri Lanka" },
    { label: "Schengen", value: "Schegan" },
    { label: "Singapore", value: "Singapore" },
    { label: "South Africa", value: "South Africa" },
    { label: "South Korea", value: "South Kora" },
    { label: "Turkey", value: "Turkey" },
    { label: "Thailand", value: "Thailand" },
    { label: "USA", value: "USA" },
    { label: "UAE", value: "UAE" },
    { label: "UK", value: "UK" },
    { label: "Vietnam", value: "Vietnam" },
    { label: "Malta", value: "Malta" },
    { label: "Poland", value: "POland" },
    { label: "Gulf", value: "Gulf" },
  ];

  const trade_intro = {
    introduction: {
      content: `<p>The Federal Skilled Trades Program is for skilled workers who want to become permanent
        residents because they are experts in a specific trade or job.</p>`,
    },
  };

  const requirement_intro = {
    introduction: {
      heading: "Requirements",
      content: `
      <p>
     <b>To qualify, you need to meet these basic requirements:</b>
      </p>
  
      <ul>
      <li>Work experience in a skilled trade.</li>
      <li>Job offer or a qualification certificate.</li>
      <li>Well communication in English or French.</li>
      </ul>
    
      `,
    },
  };

  const skilled_trade_work_experience = {
    introduction: {
      heading: "Skilled Trades Work Experience",
      content: `
      <p><b>To qualify, you must:</b></p>
     
        <ul>
        <li>Have the Work Experience</li>
        <li>Meet Job Requirements</li>
        <li>Perform Specific Job Duties</li>
        </ul>
        
        `,
    },
  };

  const skilled_trade_noc_group = {
    introduction: {
      heading: "Skilled trades NOC groups",
      content: `
      <p><b>Your work experience must be in one of the following NOC groups:</b></p>
     <ul>
      <li>Major Group 72, technical trades and transportation officers and controllers </li>
      <li>excluding Sub-Major Group 726, transportation officers and controllers</li>
      <li>Major Group 73, general trades</li>
      <li>Major Group 82, supervisors in natural resources, agriculture and related production</li>
      <li>Major Group 83, occupations in natural resources and related production</li>
      <li>Major Group 92, processing, manufacturing and utilities supervisors, and utilities operators and controllers</li>
      <li>Major Group 93, central control and process operators and aircraft assembly assemblers and inspectors, excluding Sub-Major Group 932, aircraft assemblers and aircraft assembly inspectors</li>
      <li>Minor Group 6320, cooks, butchers and bakers</li>
      <li>Unit Group 62200, chefs</li>
      </ul>
     
      `,
    },
  };

  const job_offer = {
    introduction: {
      heading: "Job Offer or Certificate of Qualification",
      content: `
      <p>
      <b>To be eligible, you need either:</b>
      </p>
   
      <ul>
      <li>A real job offer, a full-time job that lasts at least 1 year</li>
      <li>A qualification certificate issued by a Canadian authority, like a province or territory, in your skilled trade.</li>
      </ul>    

      <p><b>A certificate of qualification:</b></p>
   
      <ul>
      <li>Proves you are qualified for a specific trade in Canada.</li>
      <li>This means you passed a certification test.</li>
      <li>Shows you meet all the requirements to work in that trade in the province or territory where you received the certificate.</li>
      </ul>
     
      <p><b>Issuance of certificate</b></p>
 <p><b>This certificate is provided by: </b></p>
    <ul>
    <li>The group in charge of trade in a province or territory </li>
    <li>Or, sometimes, by a national (federal) authority. </li>
    </ul>
    
    <p>This certificate might be named either a "certificate of qualification" or a "qualification certificate" depending on the issuance authority.</p>
    <p>
    To get this certificate, the province, territory, or federal authority will check your training, trade experience, and skills to see if you can take a certification exam.
    </p>
    <p></p>
    <p><b></b>Analyzed by province or territory</b></p>
   
    <ul>
    <li>
    Every province and territory in Canada has its own specific requirements for obtaining a certificate of qualification.
    </li>
    <li>
    They also have their own unique process for certification.
    </li>
    </ul>
 
    <p><b>One must</b></p>

    <ul>
    <li>Visit the specific province or territory.</li>
    <li>Find a Canadian employer who can provide you with experience and training.</li>
    </ul>
 
    <p>To find out more about the assessment process, go to the website of the group that manages trades in the province or territory where you plan to live and work.</p>
    <b>
    <ul>
    <li>Alberta</li>
    <li>British Columbia</li>
    <li>Manitoba</li>
    <li>New Brunswick</li>
    <li>Newfoundland and Labrador</li>
    <li>Northwest Territories</li>
    <li>Nova Scotia</li>
    <li>Nunavut</li>
    <li>Ontario</li>
    <li>Prince Edward Island</li>
    <li>Saskatchewan</li>
   <li> Yukon</li>
    </ul>
    </b>
    <p>If the province or territory you want to move to doesn't issue certificates for your trade, you'll need a job offer in that trade to qualify for this program.</p>
    <p>
    If your trade is regulated by the federal government (like airplane mechanics), you can find out who oversees it and how to get assessed through the Canadian Information Centre for International Credentials.
    </p> 
    <p><b>Language Qualification</b></p>
    <p><b>For language ability: </b>Take approved tests for Writing, Reading, Listening, And Speaking.</p>
    <p><b>Language Certification: </b>Test results are valid for 2 years from the test date.</p>
    `,
    },
  };

  const cont_job_offer = {
    introduction: {
      content: `
    <p>
    <b>Education</b>
    </p>
    <p>The Federal Skilled Trades Program doesn't require a specific level of education.</p>
    <p>
    <b>But if you want to boost your ranking in the Express Entry pool, you have two options:</b>
    </p>
    <p>If you have studied in Canada, you can earn points for having a certificate, diploma, or degree from a Canadian high school or college/university.</p>
   <p>If you have a foreign education, you can earn points if you meet these two conditions:</p>
   <b>
   <ul>
   <li>You hold a completed educational credential.</li>
   <li>You have an educational credential assessment report from a designated organization for immigration purposes, which confirms that your education is equivalent to a completed certificate, diploma, or degree from a Canadian high school or college/university.</li>
   </ul>
   </b>
   <p><b>Proof of Funds</b></p>
   <p>To be eligible, you need to demonstrate that you have sufficient funds to support yourself and your family when you move to Canada unless you meet both of these conditions:</p>
   <b>
   <ul>
   <li>You are allowed to work in Canada legally right now.</li>
   <li>You have a valid job offer from a Canadian employer.</li>
   </ul>
   </b>
   <p><b>Admissibility</b></p>
   <p>You need to meet the requirements to enter Canada.</p>
   <p><b>A place to reside in Canada</b></p>
   <b>
   <ul>
   <li>You should intend to live outside of Quebec because Quebec has its own selection process for skilled workers. When you complete your profile, we'll inquire about your intended Canadian location. However, you're not obligated to settle in that specific province or territory.</li>
   <li>If you're nominated by a province or territory as a Provincial Nominee, you do need to settle in the province or territory that nominated you.</li>
   </ul>
   </b>
   <p><b>Further Steps</b></p>
   <p>Combine all your documents</p>
   <p>Submit your profile</p>

    `,
    },
  };
  const canonicalURL =
    "https://achintyaconsultant.com/canada/express-entry/fedral-skilled-trade-program/";
  return (
    <div>
      <Helmet>
        <link rel="canonical" href={canonicalURL} />
        <title>Maximize Your Opportunities with Federal Skill Trade</title>
        <meta name="robots" content="index,follow" />
        <meta
          name="description"
          content="Achintya Immigration is your trusted partner for navigating the Federal Skill Trade Program in Canada. Start your journey towards a prosperous future today."
        />
        <meta
          name="keywords"
          content="federal skilled trades program points calculator | federal skilled trades program draw"
        />
      </Helmet>
      <Header item={navigationHeader} />
      <div className="canada-latest-banner-outer-container change-back">
        <div className="container_wrapper">
          <div className="canada-latest-banner-inner-container">
            <div className="latest-banner-lest-container">
              <div className="vertical-text">#Since 2015</div>

              <h2>Federal Skilled Trade Program</h2>
              <p>Canada Express Entry</p>
              <div className="apply-now-btn-container-banner">
                <ApplyNow />
              </div>
            </div>
            <div className="latest-banner-right-container">
              <img
                style={{ marginTop: "4%" }}
                className="fedral-image-width"
                src="https://achintyaconsultant.s3.ap-south-1.amazonaws.com/fedral-skilled-trade/trade+(1).png"
                alt="Federal Skilled Trade banner"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container_wrapper">
        <div className="canada-page-flex-container">
          <div className="canada-page-flex-left-container">
            <CandaPageComponents props={trade_intro} />
            <CandaPageComponents props={requirement_intro} />
            <CandaPageComponents props={skilled_trade_work_experience} />
            <CandaPageComponents props={skilled_trade_noc_group} />
            <div className="link-to-page">
              <Link to={"/"}>
                <p>NOC LIST</p>
              </Link>
            </div>
            <CandaPageComponents props={job_offer} />
            <img
              className="page-image"
              src="https://achintyaconsultant.s3.ap-south-1.amazonaws.com/fedral-skilled-trade/acaca+1+(1).png"
              alt="Federal Skilled Trade worker"
            />
            <CandaPageComponents props={cont_job_offer} />
            <div className="link-to-page">
              <Link to={"/"}>
                <p>DOCUMENT LIST</p>
              </Link>
            </div>
            <div className="link-to-page">
              <Link to={"/"}>
                <p>SUBMIT YOUR PROFILE</p>
              </Link>
            </div>
          </div>
          <div className="canada-page-right-container">
            <div className="fixed-container">
              <AbroadForm enquiryCountries={enquiryCountries} />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default FedralSkilledTradeProgram;

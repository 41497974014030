import React from "react";
import "./index.css";
import touristReqImage from "../../assets/tourist-requiremt.png";

const TouristVisaRequirement = ({ touristRequirement }) => {
  return (
    <div className="tourist-visa-outer-container">
      {touristRequirement && (
        <div className="container_wrapper">
          <h2 className="tourist-visa-require-heading">
            {touristRequirement?.heading}
          </h2>
          <p className="tourist-visa-require-subheading">
            {touristRequirement?.description}
          </p>
          <div className="tourist-visa-require-inner-container">
            <div className="tourist-requirement-image">
              <img src={touristReqImage} alt="tourist-requirement-image" />
            </div>
            <div className="tourist-requirement-content">
              {touristRequirement?.pointers?.map((pointer, index) => (
                <p key={index}>{pointer?.point}</p>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TouristVisaRequirement;

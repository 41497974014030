import React from "react";
import "./index.css";
import { titleCaseWord } from "../../../Utilities/commonFunctions";

const PrBenefits = ({ prBenefits, country }) => {

  return (
    <div className="prbenefits-outer-container">
      <div className="container_wrapper">
        <div className="prbenefits-inner-container">
          <h2 className="prbenefits-heading">
            {prBenefits?.heading}
          </h2>
          <p className="benefits-sub-heading">{prBenefits?.description}</p>
          <div className="pr-benfits-box-container">
            {prBenefits?.pointers?.map((item, index) => (
              <div key={index} className="pr-benefits-box">
                <p dangerouslySetInnerHTML={{ __html: item?.point }}/>

              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrBenefits;

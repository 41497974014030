import React from "react";
import "./index.css";

const B2bBanner = ({ b2bContent }) => {
  return (
    <div className="partner-banner-outer-container">
      <div className="container_wrapper">
        <div className="partner-banner-inner-container">
          <div className="partner-left-container">
            <h2 className="partner-banner-heading">{b2bContent.bannerHeading}</h2>
          </div>
          <div className="partner-right-conatiner">
            <img
              src="https://achintyaconsultant.s3.ap-south-1.amazonaws.com/partner-Us/partner-banner.png"
              alt="partner banner image"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default B2bBanner;

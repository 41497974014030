import React, { useEffect, useState } from "react";
import "./index.css";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";

const WhyChooseAchintya = ({ whyChooseAchintya, enquiryCountries }) => {
  // console.log("whyChooseAchintya : ", whyChooseAchintya);

  return (
    <div className="why-outer-container">
      <div className="container_wrapper">
        <div className="why-inner-container">
          <h2 className="why-achintya-heading">{whyChooseAchintya.heading}</h2>
          <div className="why-choose-desktop">
            <Timeline position="alternate-reverse">
              {whyChooseAchintya?.content?.map((e, i) => (
                <TimelineItem key={i}>
                  <TimelineSeparator>
                    <TimelineDot />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <p className="why-subHeading">{e?.subHeading}</p>
                    <p className="why-description">{e?.description}</p>
                  </TimelineContent>
                </TimelineItem>
              ))}
            </Timeline>
          </div>

          <div className="why-choose-mobile">
            <Timeline>
              {whyChooseAchintya?.content.map((e, i) => (
                <TimelineItem key={i}>
                  <TimelineSeparator>
                    <TimelineDot />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <p className="why-subHeading">{e?.subHeading}</p>
                    <p className="why-description">{e?.description}</p>
                  </TimelineContent>
                </TimelineItem>
              ))}
            </Timeline>
          </div>
        </div>
      
      </div>
    </div>
  );
};

export default WhyChooseAchintya;

import React, { useEffect } from "react";
import { createCountryUrl } from "../../Utilities/commonFunctions";
import Header from "../../commonComponents/header";
import Footer from "../../commonComponents/footer";
import { Link, useLocation } from "react-router-dom";
import CandaPageComponents from "../../canadExpressPnpPage";
import AbroadForm from "../../commonComponents/abroadForm";
import "./index.css";
import ApplyNow from "../../commonButtonComponent/applyNow";
import { Helmet } from "react-helmet";

const CanadaIelts = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const canonicalURL =
    "https://achintyaconsultant.com/canada/express-entry/ielts/";

  const navigationHeader = [
    {
      title: "Home",
      link: `/`,
    },
    {
      title: "About Us",
      subItems: [
        {
          title: "About",
          link: `/about-us/`,
        },
        {
          title: "Reviews",
          link: ` /reviews/`,
        },
        {
          title: "Gallery",
          link: `/gallery/`,
        },
      ],
    },
    {
      title: "Canada",
      subItems: [
        {
          title: "Canada Express Entry",
          // link: `/${createCountryUrl("canada")}/express-entry/`,
          subDataItems: [
            {
              title: "Canada Express Entry",
              link: `/${createCountryUrl("canada")}/express-entry/`,
            },
            {
              title: "CRS Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/crs-calculator/`,
            },
            {
              title: "CRS Chart",
              link: `/${createCountryUrl("canada")}/express-entry/crs-chart/`,
            },
            {
              title: "67 Points Calculator and charts",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/${createCountryUrl(
                "67 Points Calculator and charts"
              )}`,
            },
            {
              title: "Latest Express Entry Draw",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/latest-express-entry-draw/`,
            },
            {
              title: "Benefits of the Program",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/benefits-of-the-program/`,
            },
            {
              title: "Occupation Demand",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/occupation-demand/`,
            },
            {
              title: "Provisional Nominee Program",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/provisional-nominee-program/`,
            },
            {
              title: "Federal Skilled Worker Program",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/fedral-skilled-worker-program/`,
            },
            {
              title: "Federal Skilled Trade Program",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/fedral-skilled-trade-program/`,
            },
            {
              title: "Canadian Experience Class",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/canadian-experience-class/`,
            },
            {
              title: "Super Visa From India",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/super-visa-from-india/`,
            },
            {
              title: "IELTS",
              link: `/${createCountryUrl("canada")}/express-entry/ielts/`,
            },
          ],
        },
        {
          title: "Provincial Nominee Program",
          subDataItems: [
            {
              title: "Manitoba PNP Program ",
              link: `/${createCountryUrl(
                "canada"
              )}/pnp-program/${createCountryUrl("Manitoba PNP Program")}/`,
            },
            {
              title: "Yukon Nominee Program ",
              link: `/${createCountryUrl(
                "canada"
              )}/pnp-program/${createCountryUrl("Yukon Nominee Program")}/`,
            },
            {
              title: "Ontario PNP Program ",
              link: `/${createCountryUrl(
                "canada"
              )}/pnp-program/${createCountryUrl("Ontario PNP Program")}/`,
            },
            {
              title: " Nova Scotia PNP ",
              link: `/${createCountryUrl(
                "canada"
              )}/pnp-program/${createCountryUrl("Nova Scotia PNP")}/`,
            },
            {
              title: "Alberta PNP Program ",
              link: `/${createCountryUrl(
                "canada"
              )}/pnp-program/${createCountryUrl("Alberta PNP Program")}/`,
            },
            {
              title: "BC PNP Program ",
              link: `/${createCountryUrl(
                "canada"
              )}/pnp-program/${createCountryUrl("BC PNP Program")}/`,
            },
            {
              title: "Quebec Skilled Worker Program ",
              link: `/pnp-program/${createCountryUrl(
                "Quebec Skilled Worker Program"
              )}/`,
            },
            {
              title: "Saskatchewan PNP ",
              link: `/pnp-program/${createCountryUrl("Saskatchewan PNP ")}/`,
            },
            {
              title: "New Brunswick PNP Program ",
              link: `/pnp-program/${createCountryUrl(
                "New Brunswick PNP Program "
              )}/`,
            },
            {
              title: "Atlantic Immigration Pilot Program ",
              link: `/pnp-program/${createCountryUrl(
                "Atlantic Immigration Pilot Program "
              )}/`,
            },
            {
              title: "PEI PNP Program ",
              link: `/pnp-program/${createCountryUrl("PEI PNP Program ")}/`,
            },
            {
              title: "NLPNP Program ",
              link: `/pnp-program/${createCountryUrl("NLPNP Program")}/`,
            },
          ],
        },
        {
          title: "Point Calculator",
          subDataItems: [
            {
              title: "CRS Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl("CRS Calculator")}/`,
            },
            {
              title: "Saskatchewan Points Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl(
                "Saskatchewan Points Calculator"
              )}`,
            },
            {
              title: "Canada 67 points Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl(
                "Canada 67 points Calculator"
              )}`,
            },
            {
              title: "British columbia Points Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl(
                "British columbia Points Calculator"
              )}`,
            },
            {
              title: "Manitoba Points Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl(
                "Manitoba Points Calculator"
              )}`,
            },
            {
              title: "Nova Scotia Points Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl(
                "Nova Scotia Points Calculator"
              )}`,
            },
            {
              title: "Alberta Points Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl(
                "Alberta Points Calculator"
              )}`,
            },
            {
              title: "Ontario Points Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl(
                "Ontario Points Calculator"
              )}`,
            },
            {
              title: " Quebec Points Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl(
                " Quebec Points Calculator"
              )}`,
            },
          ],
        },
        {
          title: "LMIA",
          link: `/${createCountryUrl("Canada")}/lmia/`,
        },
        {
          title: "Canada Spouse Dependent Visa",
          link: `/${createCountryUrl("Canada")}/spouse-dependent-visa/`,
        },
        {
          title: "Super Visa",
          link: `/${createCountryUrl("Canada")}/super-visa/`,
        },
        {
          title: "Quebec Skilled Worker Program",
          link: `/${createCountryUrl("Canada")}/quebec-skilled-worker-program/`,
        },
        {
          title: "Atlantic Immigration Program",
          link: `/${createCountryUrl("Canada")}/atlantic-immigration-program/`,
        },
        {
          title: "Startup Visa",
          link: `/${createCountryUrl("Canada")}/startup-visa/`,
        },
        {
          title: "Entrepreneur Investment Visa",
          link: `/${createCountryUrl("Canada")}/entrepreneur-investment-visa/`,
        },
      ],
    },
    {
      title: "Australia",
      subItems: [
        {
          title: "Australia Skilled Occupation List",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Australia Skilled Occupation List"
          )}`,
        },
        {
          title: "Australia 65 Points Calculator",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Australia 65 Points Calculator"
          )}`,
        },
        {
          title: "Employer Nomination Subclass 186",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Employer Nomination Subclass 186"
          )}`,
        },
        {
          title: "Skilled Independent Subclass 189",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Skilled Independent Subclass 189"
          )}`,
        },
        {
          title: "Skilled Nominated Subclass 190",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Skilled Nominated Subclass 190"
          )}`,
        },
        {
          title: "Australia Subclass 491",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Australia Subclass 491"
          )}`,
        },
        {
          title: "Australia PR Visa From India",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Australia PR Visa From India"
          )}`,
        },
        {
          title: "Australia Spouse Visa",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "AAustralia Spouse Visa"
          )}`,
        },
        {
          title: "Invitation To Apply",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Invitation To Apply"
          )}`,
        },
        {
          title: "Australia Tourist Visa From India",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Australia Tourist Visa From India"
          )}`,
        },
        {
          title: "Australia Subclass 476",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Australia Subclass 476"
          )}`,
        },
      ],
    },
    {
      title: "Travel Visa",
      link: "/travel-visa/",
      subItems: [
        {
          title: "Canada",
          link: `/travel-visa/${createCountryUrl("Canada")}/`,
        },
        {
          title: "Australia",
          link: `/travel-visa/${createCountryUrl("Australia")}/`,
        },
        {
          title: "USA",
          link: `/travel-visa/${createCountryUrl("USA")}/`,
        },
        {
          title: "UK",
          link: `/travel-visa/${createCountryUrl("UK")}/`,
        },
        {
          title: "New Zealand",
          link: `/travel-visa/${createCountryUrl("New Zealand")}/`,
        },
        {
          title: "Dubai",
          link: `/travel-visa/${createCountryUrl("Dubai")}/`,
        },
        {
          title: "Germany",
          link: `/travel-visa/${createCountryUrl("Germany")}/`,
        },
        {
          title: "Schengen",
          link: `/travel-visa/${createCountryUrl("Schegan")}/`,
        },
        {
          title: "Turkey",
          link: `/travel-visa/${createCountryUrl("Turkey")}/`,
        },
        {
          title: "Malaysia",
          link: `/travel-visa/${createCountryUrl("Malaysia")}/`,
        },
        {
          title: "Singapore",
          link: `/travel-visa/${createCountryUrl("Singapore")}/`,
        },

        {
          title: "Ireland",
          link: `/travel-visa/${createCountryUrl("Ireland")}/`,
        },
        {
          title: "Japan",
          link: `/travel-visa/${createCountryUrl("Japan")}/`,
        },

        {
          title: "Bangladesh",
          link: `/travel-visa/${createCountryUrl("Bangladesh")}/`,
        },

        {
          title: "Sri Lanka",
          link: `/travel-visa/${createCountryUrl("Sri Lanka")}/`,
        },
        {
          title: "South Africa",
          link: `/travel-visa/${createCountryUrl("South Africa")}/`,
        },
        {
          title: "South Korea",
          link: `/travel-visa/${createCountryUrl("South Korea")}/`,
        },

        {
          title: "Thailand",
          link: `/travel-visa/${createCountryUrl("Thailand")}/`,
        },

        {
          title: "UAE",
          link: `/travel-visa/${createCountryUrl("UAE")}/`,
        },

        {
          title: "Vietnam",
          link: `/travel-visa/${createCountryUrl("Vietnam")}/`,
        },
      ],
    },
    {
      title: "Job Search Program",
      subItems: [
        {
          title: "Malta",
          link: `/job-search-visa/${createCountryUrl("Malta")}/`,
        },
        {
          title: "Poland",
          link: `/job-search-visa/${createCountryUrl("Poland")}/`,
        },
        {
          title: "Schengen",
          link: `/job-search-visa/${createCountryUrl("Schengen")}/`,
        },
        {
          title: "Singapore",
          link: `/job-search-visa/${createCountryUrl("Singapore")}/`,
        },
        { title: "UAE", link: `/job-search-visa/${createCountryUrl("UAE")}/` },
        { title: "UK", link: `/job-search-visa/${createCountryUrl("UK")}/` },
        {
          title: "Australia",
          link: `/job-search-visa/${createCountryUrl("Australia")}/`,
        },
        {
          title: "Dubai",
          link: `/job-search-visa/${createCountryUrl("Dubai")}/`,
        },
        {
          title: "Germany",
          link: `/job-search-visa/${createCountryUrl("Germany")}/`,
        },
        {
          title: "Gulf",
          link: `/job-search-visa/${createCountryUrl("Gulf")}/`,
        },
      ],
    },
    {
      title: "Resources",
      link: "/resources/",
      subItems: [
        {
          title: "Career",
          link: "/career/",
        },
        {
          title: "Blogs",
          link: "/blog/",
        },
        {
          title: "News",
          link: "/news/",
        },
      ],
    },
    {
      title: "Connect Us",
      link: "/connect-us/",
    },
  ];

  const enquiryCountries = [
    { label: "Ireland", value: "Ireland" },
    { label: "Japan", value: "Japan" },
    { label: "Australia", value: "Australia" },
    { label: "Bangladesh", value: "Bangladesh" },
    { label: "Canada", value: "Canada" },
    { label: "Dubai", value: "Dubai" },
    { label: "Germany", value: "Germany" },
    { label: "Malaysia", value: "Malaysia" },
    { label: "New Zealand", value: "New Zealand" },
    { label: "Sri Lanka", value: "Sri Lanka" },
    { label: "Schengen", value: "Schegan" },
    { label: "Singapore", value: "Singapore" },
    { label: "South Africa", value: "South Africa" },
    { label: "South Korea", value: "South Kora" },
    { label: "Turkey", value: "Turkey" },
    { label: "Thailand", value: "Thailand" },
    { label: "USA", value: "USA" },
    { label: "UAE", value: "UAE" },
    { label: "UK", value: "UK" },
    { label: "Vietnam", value: "Vietnam" },
    { label: "Malta", value: "Malta" },
    { label: "Poland", value: "POland" },
    { label: "Gulf", value: "Gulf" },
  ];

  const ielts_intro = {
    introduction: {
      content: `<p>The <b>IELTS or International English Language Testing System</b>, is a popular test
        used to check a person's English language skills. It's recognized and accepted by many
        universities and institutions in different countries, including Canada, Australia, the UK, New
        Zealand, and the USA.</p>`,
    },
  };

  const ielts_score_heading = {
    introduction: {
      heading: "IELTS SCORE",
    },
  };

  const ielts_score_content = {
    introduction: {
      content: `
        <p>
        In the IELTS exam, scores are given on a scale from 0 to 9. A score of 0 is the lowest, and 9 is
the highest, indicating expertise in the English language.
        </p>
        <p>
The passing or minimum score needed can vary depending on the university or immigration
program you're applying to. However, in most cases, a score of 6 is considered competent and is he minimum requirement at many places.
        </p>
        <p>
        The test covers four categories, and you need to pass in all of them to achieve your desired overall score.
        </p>
        <p>
        <b>The IELTS test consists of four parts, and it takes 2 hours and 45 minutes to finish.</b>
        </p>
        `,
    },
  };

  const ielts_reading = {
    introduction: {
      heading: "READING",
      content: `
      <p>he IELTS Reading test has 40 questions, and each correct answer is worth 1 mark. So, the
      highest score you can get is 40. Based on this score, you receive a band score ranging from Band 1 to Band 9.</p>
      <p><b>Duration: 60 minutes</b></p>
      `,
    },
  };

  const ielts_writing = {
    introduction: {
      heading: "WRITING",
      content: `
      <p>
      In the Writing section, examiners use specific criteria to assess your performance in each of the 9 IELTS bands. They evaluate four areas: Task Achievement (for Task 1), Task Response (for Task 2), Coherence and Cohesion, Lexical Resource, and Grammatical Range and Accuracy. Each of these areas carries equal weight in your assessment.
      </p>
      <p>
      The Writing component has two parts: Academic and General. Most students choose the Academic format, which is a bit more challenging. In the General IELTS, candidates have to write a letter. Task 2 is more important for your final band score than Task 1. It's important to write legibly, as illegible handwriting can lower your score.
      </p>
      <p><b>Duration: 60 minutes</b></p>
      `,
    },
  };

  const ielts_speaking = {
    introduction: {
      heading: "SPEAKING",
      content: `
      <p>
      In the Speaking section, examiners assess you based on four criteria: how smoothly you speak and stay on topic, your vocabulary and word choice, your use of grammar, and your
pronunciation. Each of these four areas is equally important.
      </p>
      <p>
      The Speaking test has three parts, and it's like having a friendly conversation with the examiner. They will ask you about your past experiences, your current situation, and your future plans.
      </p>
      <p><b>Duration: 11 to 14 minutes</b></p>
      `,
    },
  };

  const ielts_listening = {
    introduction: {
      heading: "LISTENING",
      content: `
      <p>
      In the Listening section, there are 40 questions, and you'll listen to recordings on a cassette tape, CD, or DVD. You only get to hear the recordings once. After they finish playing, you have just 10 minutes to write your answers on the answer sheet.
      </p>
      <p><b>Duration: 30 minutes</b></p>
      `,
    },
  };

  const why_ielts = {
    introduction: {
      content: `
        <p>
        <b>Specially Designed IELTS Course:</b> We have developed a comprehensive IELTS course tailored for students at both intermediate and advanced levels. This means that regardless of your current proficiency in English, we have a program designed to help you excel in the IELTS exam.
        </p>
        <p>
       <b>Classroom & Online Learning Options:</b> We understand that students have diverse needs and preferences when it comes to learning. That's why we offer both classroom and online sessions. Our classroom sessions are held on Saturdays, making it convenient for those who prefer face-to- face interaction. For those with busy schedules or who prefer the flexibility of online learning, we offer virtual classes from Monday to Friday.
        </p>
        <p>
       <b>Customized Instruction: </b>We recognize that each student is unique, with their own strengths and areas for improvement. Our approach to teaching is highly personalized. We take the time to understand your specific needs and adapt our instruction accordingly. Whether you need to focus on a particular skill or have specific goals in mind, our instructors are here to help.
        </p>
        <p>
        <b>Interactive Learning Materials:</b> Learning should be engaging and enjoyable. To enhance your understanding of the course materials, we use a variety of interesting and interactive resources. These materials are designed to make your learning experience both effective and enjoyable.
        </p>
        <p>
        <b>Structured Weekly Lesson Plans:</b> We believe in a systematic approach to learning. To ensure you stay on track and cover all the necessary content, we provide structured weekly lesson plans. These plans help you organize your study schedule and make steady progress toward your goals. Individual Progress Reports: We believe in transparency and accountability. That's why we offer progress reports for each student. These reports provide valuable insights into your strengths and areas that may require additional focus. You'll always know where you stand and how much effort is needed to achieve your desired results.
        </p>
        <p>
        <b>Three Sessions Every Day:</b> We are committed to your success. To support your IELTS preparation journey, we have a dedicated and certified IELTS trainer who will work with you consistently for three months. This level of guidance and support is aimed at maximizing your chances of success in the IELTS exam. Expert Training: Our instructors are experts in IELTS preparation, with many years of experience helping students and visa applicants achieve high scores. You can trust that you're receiving top-notch training from professionals who understand the intricacies of the IELTS exam.
        </p>
        <p>
        <b>Excellent Learning Environment:</b> At Achintya Immigration, we provide an outstanding learning environment. Our state-of-the-art infrastructure includes dedicated classrooms and online resources. This ensures that you have access to the best tools and facilities to excel in your studies.
        </p>
        <p>
        <b>Certified Trainers:</b> All our trainers are 100% certified and possess the necessary credentials to prepare students and visa applicants effectively for the IELTS exam. You can have confidence in the expertise of our trainers.
        </p>
        <p>
       <b>Counseling Support:</b> We understand that preparing for the IELTS exam can be a significant undertaking. That's why we offer counseling and support to our students and visa candidates. We're here to inspire and guide you, ensuring that you're well-prepared and confident when it's time to take the exam.
        </p>
        <p>
        <b>Remarkable Progress: </b>Many individuals who receive IELTS coaching from us experience remarkable improvement in their language skills and test performance. They not only achieve higher scores but also gain a boost in confidence. Our goal is to help you succeed and obtain the IELTS bands you need for your future endeavors. 
        </p>
        <p>
In summary, our IELTS program is designed to provide comprehensive, personalized, and expert preparation for the exam. We offer a range of learning options, individualized instruction, engaging materials, and dedicated support to help you achieve your IELTS goals. Our track record of success and commitment to your progress make us a top choice for IELTS preparation.
        </p>

        `,
    },
  };

  const ielts_program = {
    introduction: {
      content: `
        <p>
       <b>Language Background Not Required:</b> You don't need to be an expert in English to excel in the IELTS test. Even if your language background is not strong, you can still perform well with consistent practice and the right approach. We share valuable tips and tricks with our students to
help them succeed and achieve high scores.
        </p>
        <p>
       <b>Speaking Skills:</b> Doing well in the speaking section is crucial, and our instructor ensures that our students become reasonably proficient in this aspect. We provide guidance and practice to enhance your speaking skills
        </p>
        <p>
       <b>Listening Skills:</b> Understanding and interpreting what you hear is essential. We equip our students with the necessary coaching and training to excel in the listening section. You'll learn how to decode and comprehend audio materials effectively.
        </p>
        <p>
       <b>Reading Skills:</b> The reading section is another critical component of the IELTS exam. We cover this aspect thoroughly, ensuring that you are well-prepared and can perform well in this part of  the test to achieve high bands.
        </p>
        <p>
       <b>Writing Skills:</b> Many students find the writing section challenging. We offer detailed coverage of this aspect, providing you with the skills and techniques to perform well. Our goal is to help you score high bands and achieve a high overall IELTS score.
        </p>
        <p>
        <b>Doubt Clearing Sessions:</b> We are committed to ensuring that all your doubts related to the IELTS test are addressed and resolved. We provide comprehensive doubt-clearing sessions to answer your questions and clarify any uncertainties you may have about the language test.
        </p>
        <p>
        <b>Number of Sessions & Class Duration:</b> We offer three sessions daily at our state-of-the-art center. This means you have multiple opportunities to receive instruction and practice to enhance your skills.
        </p>
        <p>
        <b>Flexible Timings: </b>We understand that our students have different schedules. Therefore, we provide flexible coaching timings to accommodate your convenience and availability. We want to ensure that you can receive training at a time that suits you.
        </p>
        <p>
        <b>Customized Coaching:</b> Our coaching is tailored to pay attention to the smallest details and is adjusted based on your specific language background and learning abilities. We understand that each student is unique, and our coaching reflects that.
        </p>
       
        `,
    },
  };

  const last_ielts_intro = {
    introduction: {
      content: ` <p>
        <b>In summary, you don't need a strong language background to succeed in the IELTS exam. We offer comprehensive coaching and support to help you improve your speaking, listening, reading, and writing skills. Our doubt-clearing sessions, flexible timings, and customized coaching ensure
        that you receive the guidance you need to excel in the IELTS test. Our goal is to help you achieve high bands and succeed in your language proficiency goals.</b>
        </p>`,
    },
  };

  return (
    <div>
      <Helmet>
        <link rel="canonical" href={canonicalURL} />
        <title>
          Your Key to Successful Immigration | Achintya Immigration{" "}
        </title>
        <meta name="robots" content="index,follow" />
        <meta
          name="description"
          content="Achintya Immigration - your one-stop solution for all immigration-related queries. From visa applications to job search assistance, we've got you covered."
        />
        <meta
          name="keywords"
          content="immigration consultancy firms near Delhi |  top 10 immigration consultants in delhi | govt approved immigration consultants in delhi"
        />
      </Helmet>
      <Header item={navigationHeader} />

      <div className="ielts-page-banner-outer-container">
        <div className="container_wrapper">
          <div className="vertical-text-ielts">#Since 2015</div>
          <div className="ielts-banner-inner-container">
            <div className="ielts-banner-heading-container">
              <h2>International English Language Testing System (IELTS)</h2>
              <ApplyNow />
            </div>
            <div className="ielts-banner-img">
              <img
                src="https://achintyaconsultant.s3.ap-south-1.amazonaws.com/Frame+22.png"
                alt="ielts banner image"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container_wrapper">
        <div className="canada-page-flex-container">
          <div className="canada-page-flex-left-container">
            <CandaPageComponents props={ielts_intro} />
            <CandaPageComponents props={ielts_score_heading} />
            <div className="link-to-page">
              <Link to={"/"}>
                <p style={{ fontSize: "18px" }}>Check your eligibility</p>
              </Link>
            </div>
            <CandaPageComponents props={ielts_score_content} />
            <CandaPageComponents props={ielts_reading} />
            <CandaPageComponents props={ielts_writing} />
            <CandaPageComponents props={ielts_speaking} />
            <CandaPageComponents props={ielts_listening} />
          </div>
          <div className="canada-page-right-container">
            <div className="fixed-container">
              <AbroadForm enquiryCountries={enquiryCountries} />
            </div>
          </div>
        </div>
      </div>

      <div className="why-ielts-container">
        <div className="why-ielts-inner-container">
          <div className="container_wrapper">
            <h2 className="why-choose-ielts-heading">
              Why Choose Achintya Immigration for IELTS?
            </h2>
            <CandaPageComponents props={why_ielts} />
          </div>
        </div>
      </div>

      <div className="ielts-program-outer-container">
        <div className="container_wrapper">
          <h2 className="why-choose-ielts-heading">
            IELTS Program at Achintya Immigration
          </h2>
          <div className="ielts-program-inner-container">
            <div className="ielts-program-left-container">
              <CandaPageComponents props={ielts_program} />
            </div>
            <div className="ielts-program-right-container">
              <img
                src="https://achintyaconsultant.s3.ap-south-1.amazonaws.com/Rectangle+126.png"
                alt="ielts program"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container_wrapper">
        <CandaPageComponents props={last_ielts_intro} />
      </div>
      <Footer />
    </div>
  );
};

export default CanadaIelts;

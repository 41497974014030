import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import Header from "../../commonComponents/header";
import Footer from "../../commonComponents/footer";
import B2bBanner from "../../pageComponent/B2BEnquiryPageComponent/B2bBanner";
import B2bContent from "../../pageComponent/B2BEnquiryPageComponent/B2bContent";

const EnquireB2B = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const canonicalURL = "https://achintyaconsultant.com/partner-with-us/";

  const touristCountries = [
    "Ireland",
    "Japan",
    "Australia",
    "Bangladesh",
    "Canada",
    "Dubai",
    "Germany",
    "Malaysia",
    "New Zealand",
    "Sri Lanka",
    "Schengen",
    "Singapore",
    "South Africa",
    "South Korea",
    "Turkey",
    "Thailand",
    "USA",
    "UAE",
    "UK",
    "Vietnam",
  ];

  const jobSearch = [
    "Malta",
    "Poland",
    "Schengen",
    "Singapore",
    "UAE",
    "UK",
    "Australia",
    "Dubai",
    "Germany",
    "Gulf",
  ];

  const otherServices = ["IELTS", "Resume"];

  const enquiryCountries = [
    { value: "Ireland", label: "Ireland" },
    { value: "Japan", label: "Japan" },
    { value: "Australia", label: "Australia" },
    { value: "Bangladesh", label: "Bangladesh" },
    { value: "Canada", label: "Canada" },
    { value: "Dubai", label: "Dubai" },
    { value: "Germany", label: "Germany" },
    { value: "Malaysia", label: "Malaysia" },
    { value: "New Zealand", label: "New Zealand" },
    { value: "Sri Lanka", label: "Sri Lanka" },
    { value: "Schengen", label: "Schengen" },
    { value: "Singapore", label: "Singapore" },
    { value: "South Africa", label: "South Africa" },
    { value: "South Korea", label: "South Korea" },
    { value: "Turkey", label: "Turkey" },
    { value: "Thailand", label: "Thailand" },
    { value: "USA", label: "USA" },
    { value: "UAE", label: "UAE" },
    { value: "UK", label: "UK" },
    { value: "Vietnam", label: "Vietnam" },
    { value: "Malta", label: "Malta" },
    { value: "Poland", label: "Poland" },
    { value: "Gulf", label: "Gulf" },
  ];

  const b2bContent = {
    bannerHeading: "Partner with Us",
    bannerSubHeading: "We are Independent Experts, but we're Better Together",
    globalImmigration: {
      heading: "End-to-end global immigration services and support",
      content:
        "Achintya Immigration consultants provide immigration services and related support to individuals and companies all across different states in India. Each Partner is a well-established, respected entity in their home market. Working together with other Partners, we provide full-spectrum immigration service on a case-by-case basis",
    },
    pointers: [
      {
        heading: "The right fit",
        subContent:
          "We’re not the biggest, but we strive to be the best. We offer innovative, creative solutions to today’s immigration and mobility challenges – whether you’re looking for support on five files or 500.",
      },
      {
        heading: "Trusted",
        subContent:
          "Trust is a cornerstone of Partners Immigration. Partners are integrated, yet independent – and we hold each other accountable. It’s the value of our personal relationships that makes Partners better.",
      },
    ],
    partner_with_us: [
      {
        heading: "Partner with us – Join Our Immigration Network",
        content: `<p>
      Are you searching for a dependable partner who knows all about immigration and can help your clients make their dreams of living, working, or studying in another country come true? Are you thinking about expanding your business and offering more services to your valued customers? If this sounds like what you're looking for, then you're in the right place to start a partnership journey with us!
      </p>
      <p>
      Think about teaming up with us – we're experts in immigration and have helped many people fulfill their dreams of moving to new places. We offer lots of services, like helping with visa applications, getting documents ready, giving advice about immigration, teaching languages, and guiding people as they start their new lives in a different country.
      </p>`,
        content2: `
      <p>
      We're looking for partners who have similar visions and values, and who want to provide really good and honest services to their clients. If you believe in being fair and excellent in what you do, then joining forces with us means you get some cool benefits:</p>`,
        pointers: [
          {
            point: "Access to lots of immigration experts and helpful staff.",
          },
          {
            point: "Good deals on money for every service, plus nice rewards.",
          },
          {
            point: "Learning and help from our friendly team.",
          },
          {
            point: "Cool things to help you tell people about what we do. ",
          },
          {
            point:
              "Making your customers super happy and loyal to your business. ",
          },
        ],
        content3: `
        <p>This is your chance to be part of something special! If you're excited about teaming up with us, 
        just fill in the form below and tell us what you think. Every time you write something down, 
         
        you're taking a step toward an exciting partnership. We're really looking forward to starting this 
        adventure together! </p>`,
      },
    ],
  };

  return (
    <div>
      <Helmet>
        <link rel="canonical" href={canonicalURL} />
        <title>
          Expert B2B Immigration Services| Achintya Immigration Enquiry
        </title>
        <meta name="robots" content="index,follow" />
        <meta
          name="description"
          content="Unlock new opportunities abroad with Achintya Immigration's B2B enquiry platform. Discover efficient pathways for your company's global talent mobility."
        />
        <meta
          name="keywords"
          content="immigration partner ,enquiry b2b immigration services ,b2b immigration services"
        />
      </Helmet>
      <Header
        touristCountries={touristCountries}
        jobSearch={jobSearch}
        otherServices={otherServices}
      />
      <B2bBanner b2bContent={b2bContent} />
      <B2bContent b2bContent={b2bContent} />
      <Footer />
    </div>
  );
};

export default EnquireB2B;

const createCountryUrl = (country) => {
  const words = country.split(/\s+/); // Split the text into words based on whitespace

  // Convert each word to lowercase andjoin with -
  const joinedString = words.join("-").toLowerCase();

  return joinedString;
};

const titleCaseWord = (country) => {
  const titleWord = country
    .toLowerCase() // Convert the input to lowercase
    .replace(/(?:^|\s)\w/g, (match) => match.toUpperCase());
    return titleWord
};

module.exports = {
  createCountryUrl,
  titleCaseWord
};

import React, { useState, useRef } from "react";
import "./index.css";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Select from "react-select";
import VerifiedIcon from "@mui/icons-material/Verified";

const FormRightSide = ({ enquiryCountries }) => {
  // console.log("enquiryCountries : ", enquiryCountries);
  const anchorRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false); // Track form submission

  const handleCountryChange = (e, newValue) => {
    setSelectedCountry(newValue?.label || null);
  };

  // State variables for form fields and their respective errors
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [desiredCountry, setDesiredCountry] = useState("");

  // State variables for error messages
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [mobileNoError, setMobileNoError] = useState("");
  const [desiredCountryError, setDesiredCountryError] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    // Resetting the error messages before validation
    setNameError("");
    setEmailError("");
    setMobileNoError("");
    setDesiredCountryError("");

    // Perform form validation here
    let isValid = true;

    if (!name.trim()) {
      setNameError("Name is required!");
      isValid = false;
    } else if (/\d/.test(name)) {
      setNameError("Name should not contain numbers!");
      isValid = false;
    } else if (/[^\w\s]/.test(name)) {
      setNameError("Special characters are not allowed in the name!");
      isValid = false;
    }

    if (!email.trim()) {
      setEmailError("Email is required!");
      isValid = false;
    } else if (!/^\S+@\S+\.\S+$/.test(email)) {
      setEmailError("Invalid email format!");
      isValid = false;
    }

    if (!mobileNo.trim()) {
      setMobileNoError("Mobile No. is required!");
      isValid = false;
    } else if (/\D/.test(mobileNo) || mobileNo.length !== 10) {
      setMobileNoError("Mobile No. should be a 10-digit number!");
      isValid = false;
    }

    if (!selectedCountry) {
      setDesiredCountryError("Desired Country is required!");
      isValid = false;
    }

    // If all validations pass, you can proceed with form submission or further processing
    if (isValid) {
      // Your form submission logic here...
      setIsSubmitted(true); // Mark the form as submitted
    }
  };

  // Event handler to clear error messages when the user interacts with the form fields again
  const handleInputFocus = () => {
    if (isSubmitted) {
      // Clear error messages when the form has been submitted and user interacts with fields again
      setNameError("");
      setEmailError("");
      setMobileNoError("");
      setDesiredCountryError("");
      setIsSubmitted(false); // Reset the form submission status
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };

  const handleButtonClick = () => {
    // Programmatically trigger the file input click event
    document.getElementById("fileInput").click();
  };

  return (
    <div className="enquiry-right-side-outer-container">
      <form onSubmit={handleSubmit}>
        {!isSubmitted ? (
          <>
            <div className="enquiry-right-inner-container">
              <div className="input-field-space">
                <TextField
                  id="filled-basic"
                  label="Name*"
                  variant="filled"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  onBlur={handleInputFocus} // Clear error message when the field loses focus
                  error={Boolean(nameError)}
                  helperText={nameError}
                  sx={{
                    borderRadius: "8px",
                    "& .MuiInputBase-root.Mui-focused": {
                      "&:after": {
                        borderBottom: "none",
                      },
                    },
                    "& .Mui-focused .MuiInputLabel-filled": {
                      color: "#D42D2D",
                    },
                  }}
                />
              </div>

              <div className="input-field-space">
                <TextField
                  id="filled-basic"
                  label="Email Id*"
                  variant="filled"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  onBlur={handleInputFocus} // Clear error message when the field loses focus
                  error={Boolean(emailError)}
                  helperText={emailError}
                  sx={{
                    borderRadius: "8px",
                    "& .MuiInputBase-root.Mui-focused": {
                      "&:after": {
                        borderBottom: "none",
                      },
                    },
                    "& .Mui-focused .MuiInputLabel-filled": {
                      color: "#D42D2D",
                    },
                  }}
                />
              </div>

              <div className="input-field-space">
                <TextField
                  id="filled-basic"
                  label="Mobile No.*"
                  variant="filled"
                  inputProps={{ maxLength: 10 }}
                  value={mobileNo}
                  onChange={(e) => setMobileNo(e.target.value)}
                  onBlur={handleInputFocus} // Clear error message when the field loses focus
                  error={Boolean(mobileNoError)}
                  helperText={mobileNoError}
                  sx={{
                    borderRadius: "8px",
                    "& .MuiInputBase-root.Mui-focused": {
                      "&:after": {
                        borderBottom: "none",
                      },
                    },
                    "& .Mui-focused .MuiInputLabel-filled": {
                      color: "#D42D2D",
                    },
                  }}
                />
              </div>

              <div className="input-field-space" ref={anchorRef}>
                <Autocomplete
                  options={enquiryCountries}
                  // getOptionLabel={(option) =>
                  //   option && option.label ? option.label : ""
                  // }
                  value={selectedCountry}
                  onChange={handleCountryChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Desired Country*"
                      variant="filled"
                      error={Boolean(desiredCountryError)}
                      onBlur={handleInputFocus} // Clear error message when the field loses focus
                      helperText={desiredCountryError}
                      value={selectedCountry === "" ? "" : selectedCountry}
                      onChange={handleCountryChange}
                      sx={{
                        borderRadius: "8px",
                        "& .MuiInputBase-root.Mui-focused": {
                          "&:after": {
                            borderBottom: "none",
                          },
                        },
                        "& .Mui-focused .MuiInputLabel-filled": {
                          color: "#D42D2D",
                        },
                      }}
                    />
                  )}
                />
              </div>

              <div className="input-field-space">
                <input
                  type="file"
                  id="fileInput"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                  disabled={isSubmitted} // Disable the file input when the form is submitted
                />
                <TextField
                  id="filled-basic"
                  label="Upload Documents"
                  variant="filled"
                  value={selectedFile ? selectedFile.name : ""}
                  // onBlur={handleInputFocus} // Clear error message when the field loses focus
                  onClick={handleButtonClick}
                  // error={Boolean(uploadError)}
                  // helperText={uploadError}
                  sx={{
                    borderRadius: "8px",
                    cursor: "pointer",
                    "& .MuiInputBase-root.Mui-focused": {
                      "&:after": {
                        borderBottom: "none",
                      },
                    },
                    "& .Mui-focused .MuiInputLabel-filled": {
                      color: "#D42D2D",
                    },
                  }}
                />
              </div>
            </div>

            <button type="submit" className="submit-form-btn">
              Submit
            </button>
          </>
        ) : (
          <div className="thank-you-outer-section">
            <div className="thank-you-inner-section">
              <div>
                <VerifiedIcon />
              </div>
              <p className="thankyou-first-heading">
                Thank You for submitting your query!
              </p>
              <p className="thankyou-second-heading">
                Our Team will contact you soon!!
              </p>
            </div>
          </div>
        )}
      </form>
    </div>
  );
};

export default FormRightSide;

import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import Header from "../../commonComponents/header";
import HomeBanner from "../../componenets/homeBanner";
import WhatsApp from "../../commonComponents/whatsapp";
import ImmigrationServices from "../../homePageComponent/immigrationServices";
import agent from "../../assets/operator 1.png";
import aeroplane from "../../assets/airplane 1.png";
import competence from "../../assets/competence 1.png";
import document from "../../assets/document 1.png";
import Offerings from "../../homePageComponent/offerings";
import appointment from "../../assets/appointment.png";
import cv from "../../assets/CV.png";
import ielts from "../../assets/ielts.png";
import job from "../../assets/Job.png";
import Pr from "../../assets/PR.png";
import tvisa from "../../assets/tourist.png";
import Footer from "../../commonComponents/footer";
import WhyChooseAchintya from "../../commonComponents/whyChoose";
import OpenScrollComponent from "../../commonComponents/openScrollComponenet";
import { createCountryUrl } from "../../Utilities/commonFunctions";

const HomePage = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const canonicalURL = "https://achintyaconsultant.com/";
  const navigationHeader = [
    {
      title: "Home",
      link: "/",
    },
    {
      title: "About Us",
      subItems: [
        {
          title: "About",
          link: `/about-us/`,
        },
        {
          title: "Reviews",
          link: ` /reviews/`,
        },
        {
          title: "Gallery",
          link: `/gallery/`,
        },
      ],
    },
    {
      title: "Canada",
      subItems: [
        {
          title: "Canada Express Entry",
          // link: `/${createCountryUrl("canada")}/express-entry/`,
          subDataItems: [
            {
              title: "Canada Express Entry",
              link: `/${createCountryUrl("canada")}/express-entry/`,
            },
            {
              title: "CRS Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/crs-calculator/`,
            },
            {
              title: "CRS Chart",
              link: `/${createCountryUrl("canada")}/express-entry/crs-chart/`,
            },
            {
              title: "67 Points Calculator and charts",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/${createCountryUrl(
                "67 Points Calculator and charts"
              )}`,
            },
            {
              title: "Latest Express Entry Draw",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/latest-express-entry-draw/`,
            },
            {
              title: "Benefits of the Program",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/benefits-of-the-program/`,
            },
            {
              title: "Occupation Demand",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/occupation-demand/`,
            },
            {
              title: "Provisional Nominee Program",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/provisional-nominee-program/`,
            },
            {
              title: "Federal Skilled Worker Program",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/fedral-skilled-worker-program/`,
            },
            {
              title: "Federal Skilled Trade Program",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/fedral-skilled-trade-program/`,
            },
            {
              title: "Canadian Experience Class",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/canadian-experience-class/`,
            },
            {
              title: "Super Visa From India",
              link: `/${createCountryUrl(
                "canada"
              )}/express-entry/super-visa-from-india/`,
            },
            {
              title: "IELTS",
              link: `/${createCountryUrl("canada")}/express-entry/ielts/`,
            },
          ],
        },
        {
          title: "Provincial Nominee Program",
          subDataItems: [
            {
              title: "Manitoba PNP Program ",
              link: `/${createCountryUrl(
                "canada"
              )}/pnp-program/${createCountryUrl("Manitoba PNP Program")}/`,
            },
            {
              title: "Yukon Nominee Program ",
              link: `/${createCountryUrl(
                "canada"
              )}/pnp-program/${createCountryUrl("Yukon Nominee Program")}/`,
            },
            {
              title: "Ontario PNP Program ",
              link: `/${createCountryUrl(
                "canada"
              )}/pnp-program/${createCountryUrl("Ontario PNP Program")}/`,
            },
            {
              title: " Nova Scotia PNP ",
              link: `/${createCountryUrl(
                "canada"
              )}/pnp-program/${createCountryUrl("Nova Scotia PNP")}/`,
            },
            {
              title: "Alberta PNP Program ",
              link: `/${createCountryUrl(
                "canada"
              )}/pnp-program/${createCountryUrl("Alberta PNP Program")}/`,
            },
            {
              title: "BC PNP Program ",
              link: `/${createCountryUrl(
                "canada"
              )}/pnp-program/${createCountryUrl("BC PNP Program")}/`,
            },
            {
              title: "Quebec Skilled Worker Program ",
              link: `/pnp-program/${createCountryUrl(
                "Quebec Skilled Worker Program"
              )}/`,
            },
            {
              title: "Saskatchewan PNP ",
              link: `/pnp-program/${createCountryUrl("Saskatchewan PNP ")}/`,
            },
            {
              title: "New Brunswick PNP Program ",
              link: `/pnp-program/${createCountryUrl(
                "New Brunswick PNP Program "
              )}/`,
            },
            {
              title: "Atlantic Immigration Pilot Program ",
              link: `/pnp-program/${createCountryUrl(
                "Atlantic Immigration Pilot Program "
              )}/`,
            },
            {
              title: "PEI PNP Program ",
              link: `/pnp-program/${createCountryUrl("PEI PNP Program ")}/`,
            },
            {
              title: "NLPNP Program ",
              link: `/pnp-program/${createCountryUrl("NLPNP Program")}/`,
            },
          ],
        },
        {
          title: "Point Calculator",
          subDataItems: [
            {
              title: "CRS Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl("CRS Calculator")}/`,
            },
            {
              title: "Saskatchewan Points Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl(
                "Saskatchewan Points Calculator"
              )}`,
            },
            {
              title: "Canada 67 points Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl(
                "Canada 67 points Calculator"
              )}`,
            },
            {
              title: "British columbia Points Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl(
                "British columbia Points Calculator"
              )}`,
            },
            {
              title: "Manitoba Points Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl(
                "Manitoba Points Calculator"
              )}`,
            },
            {
              title: "Nova Scotia Points Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl(
                "Nova Scotia Points Calculator"
              )}`,
            },
            {
              title: "Alberta Points Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl(
                "Alberta Points Calculator"
              )}`,
            },
            {
              title: "Ontario Points Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl(
                "Ontario Points Calculator"
              )}`,
            },
            {
              title: " Quebec Points Calculator",
              link: `/${createCountryUrl(
                "canada"
              )}/point-calculator/${createCountryUrl(
                " Quebec Points Calculator"
              )}`,
            },
          ],
        },
        {
          title: "LMIA",
          link: `/${createCountryUrl("Canada")}/lmia/`,
        },
        {
          title: "Canada Spouse Dependent Visa",
          link: `/${createCountryUrl("Canada")}/spouse-dependent-visa/`,
        },
        {
          title: "Super Visa",
          link: `/${createCountryUrl("Canada")}/super-visa/`,
        },
        {
          title: "Quebec Skilled Worker Program",
          link: `/${createCountryUrl("Canada")}/quebec-skilled-worker-program/`,
        },
        {
          title: "Atlantic Immigration Program",
          link: `/${createCountryUrl("Canada")}/atlantic-immigration-program/`,
        },
        {
          title: "Startup Visa",
          link: `/${createCountryUrl("Canada")}/startup-visa/`,
        },
        {
          title: "Entrepreneur Investment Visa",
          link: `/${createCountryUrl("Canada")}/entrepreneur-investment-visa/`,
        },
      ],
    },
    {
      title: "Australia",
      subItems: [
        {
          title: "Australia Skilled Occupation List",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Australia Skilled Occupation List"
          )}`,
        },
        {
          title: "Australia 65 Points Calculator",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Australia 65 Points Calculator"
          )}`,
        },
        {
          title: "Employer Nomination Subclass 186",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Employer Nomination Subclass 186"
          )}`,
        },
        {
          title: "Skilled Independent Subclass 189",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Skilled Independent Subclass 189"
          )}`,
        },
        {
          title: "Skilled Nominated Subclass 190",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Skilled Nominated Subclass 190"
          )}`,
        },
        {
          title: "Australia Subclass 491",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Australia Subclass 491"
          )}`,
        },
        {
          title: "Australia PR Visa From India",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Australia PR Visa From India"
          )}`,
        },
        {
          title: "Australia Spouse Visa",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "AAustralia Spouse Visa"
          )}`,
        },
        {
          title: "Invitation To Apply",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Invitation To Apply"
          )}`,
        },
        {
          title: "Australia Tourist Visa From India",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Australia Tourist Visa From India"
          )}`,
        },
        {
          title: "Australia Subclass 476",
          link: `/${createCountryUrl("Australia")}/${createCountryUrl(
            "Australia Subclass 476"
          )}`,
        },
      ],
    },
    {
      title: "Travel Visa",
      link: "/travel-visa/",
      subItems: [
        {
          title: "Canada",
          link: `/travel-visa/${createCountryUrl("Canada")}/`,
        },
        {
          title: "Australia",
          link: `/travel-visa/${createCountryUrl("Australia")}/`,
        },
        {
          title: "USA",
          link: `/travel-visa/${createCountryUrl("USA")}/`,
        },
        {
          title: "UK",
          link: `/travel-visa/${createCountryUrl("UK")}/`,
        },
        {
          title: "New Zealand",
          link: `/travel-visa/${createCountryUrl("New Zealand")}/`,
        },
        {
          title: "Dubai",
          link: `/travel-visa/${createCountryUrl("Dubai")}/`,
        },
        {
          title: "Germany",
          link: `/travel-visa/${createCountryUrl("Germany")}/`,
        },
        {
          title: "Schengen",
          link: `/travel-visa/${createCountryUrl("Schegan")}/`,
        },
        {
          title: "Turkey",
          link: `/travel-visa/${createCountryUrl("Turkey")}/`,
        },
        {
          title: "Malaysia",
          link: `/travel-visa/${createCountryUrl("Malaysia")}/`,
        },
        {
          title: "Singapore",
          link: `/travel-visa/${createCountryUrl("Singapore")}/`,
        },

        {
          title: "Ireland",
          link: `/travel-visa/${createCountryUrl("Ireland")}/`,
        },
        {
          title: "Japan",
          link: `/travel-visa/${createCountryUrl("Japan")}/`,
        },

        {
          title: "Bangladesh",
          link: `/travel-visa/${createCountryUrl("Bangladesh")}/`,
        },

        {
          title: "Sri Lanka",
          link: `/travel-visa/${createCountryUrl("Sri Lanka")}/`,
        },
        {
          title: "South Africa",
          link: `/travel-visa/${createCountryUrl("South Africa")}/`,
        },
        {
          title: "South Korea",
          link: `/travel-visa/${createCountryUrl("South Korea")}/`,
        },

        {
          title: "Thailand",
          link: `/travel-visa/${createCountryUrl("Thailand")}/`,
        },

        {
          title: "UAE",
          link: `/travel-visa/${createCountryUrl("UAE")}/`,
        },

        {
          title: "Vietnam",
          link: `/travel-visa/${createCountryUrl("Vietnam")}/`,
        },
      ],
    },
    {
      title: "Job Search Program",
      subItems: [
        {
          title: "Malta",
          link: `/job-search-visa/${createCountryUrl("Malta")}/`,
        },
        {
          title: "Poland",
          link: `/job-search-visa/${createCountryUrl("Poland")}/`,
        },
        {
          title: "Schengen",
          link: `/job-search-visa/${createCountryUrl("Schengen")}/`,
        },
        {
          title: "Singapore",
          link: `/job-search-visa/${createCountryUrl("Singapore")}/`,
        },
        { title: "UAE", link: `/job-search-visa/${createCountryUrl("UAE")}/` },
        { title: "UK", link: `/job-search-visa/${createCountryUrl("UK")}/` },
        {
          title: "Australia",
          link: `/job-search-visa/${createCountryUrl("Australia")}/`,
        },
        {
          title: "Dubai",
          link: `/job-search-visa/${createCountryUrl("Dubai")}/`,
        },
        {
          title: "Germany",
          link: `/job-search-visa/${createCountryUrl("Germany")}/`,
        },
        {
          title: "Gulf",
          link: `/job-search-visa/${createCountryUrl("Gulf")}/`,
        },
      ],
    },
    {
      title: "Resources",
      link: "/resources/",
      subItems: [
        {
          title: "Career",
          link: "/career/",
        },
        {
          title: "Blogs",
          link: "/blog/",
        },
        {
          title: "News",
          link: "/news/",
        },
      ],
    },
    {
      title: "Connect Us",
      link: "/connect-us/",
    },
  ];
  const immigrationServices = {
    heading: "Immigration Services from Experienced Agents",
    content:
      "Let one of India's most reputable immigration consultancies take care of your Visa needs. Our team is dedicated to ensuring your Visa is approved, making your travel dreams a reality. With our expert assistance, you can board your flight with confidence and ease.",
    subpoints: [
      {
        heading: "Immigration Services from Experienced Agents",
        imgUrl: aeroplane,
      },
      {
        heading: "Visa Eligibility Assistance",
        imgUrl: agent,
      },
      {
        heading: "Importance of Documentation",
        imgUrl: document,
      },
      {
        heading: "Processing Assistance",
        imgUrl: competence,
      },
    ],
    section: [
      {
        sectionHeading: "Immigration Consultation ",
        description:
          "Immigration consultation involves seeking expert advice from professionals on matters related to immigration. These professionals help individuals or families navigate the complex immigration process, including obtaining visas, citizenship, and permanent residency. Immigration consultants provide personalized guidance and support, helping clients understand their options and make informed decisions. Their services may also include assistance with documentation, filing applications, and preparing for interviews or exams. In short, immigration consultation can be invaluable for anyone seeking to move to a new country.",
      },
      {
        sectionHeading: "Processing Assistance",
        description:
          "Immigration processing assistance involves providing professional guidance and support to individuals or families throughout the immigration process. These services include assistance with visa applications, permanent residency applications, and citizenship applications. Immigration processing assistance providers help clients gather and organize the necessary documents, fill out forms, and prepare for interviews or exams. They also keep clients updated on the status of their applications and provide advice on how to address any issues that may arise. In short, immigration processing assistance can be critical for anyone seeking to navigate the complex and often confusing immigration process",
      },
      {
        sectionHeading: "Importance of Documentation",
        description:
          "Documentation is crucial in many aspects of life, from legal matters to business operations. It provides a record of important information, ensuring accuracy, transparency, and accountability. Proper documentation also helps in organizing and managing information efficiently, making it easily accessible when needed. In short, documentation plays a vital role in maintaining order and reliability in various fields.",
      },
      {
        sectionHeading: "Visa Eligibility Assistance",
        description:
          "Visa eligibility assistance involves providing individuals or businesses with guidance and support on their eligibility for various types of visas. Visa eligibility assistance providers help clients assess their qualifications and requirements for different types of visas, including work visas, student visas, Permanent Residency, and travel visas. They also assist clients in determining which visas may be best suited to their needs and goals. Additionally, visa eligibility assistance providers may offer advice on how to improve eligibility, such as by obtaining additional qualifications or work experience. In short, visa eligibility assistance can help simplify the visa application process and increase the chances of approval.",
      },
    ],
  };

  const offeringContent = {
    heading:
      "Offering One-Of-A-Kind Visa and Immigration Services to Aspiring Candidates",
    subOffering: [
      {
        imgUrl: appointment,
        heading: "Appointment Services",
        description: `Appointment services for immigration involve assisting individuals or families in scheduling appointments for visa or immigration-related interviews, exams, or other required appointments. These services aim to streamline the process and save clients valuable time and effort in navigating the appointment scheduling process. Appointment services providers work closely with clients to ensure that they are prepared for their appointments and have all the necessary documentation and information to ensure a successful outcome. In short, appointment services for immigration help clients manage the appointment scheduling process and ensure that they are well-prepared for their interviews or exams.`,
      },
      {
        imgUrl: ielts,
        heading: "IELTS Preparation",
        description: `Start your language learning journey by evaluating your current proficiency level through a proficiency test. Then, work with a qualified instructor to identify areas where you need improvement and develop a customized study plan. Use IELTS study materials and practice tests to improve your skills, and consider taking classes or private tutoring to enhance your learning. Additionally, be sure to familiarize yourself with the specific requirements of the IELTS exam, such as test format and timing, to ensure that you are fully prepared on exam day. With focused preparation and support from immigration services, you can boost your IELTS score and increase your chances of a successful immigration application.`,
      },
      {
        imgUrl: cv,
        heading: "CV Modification",
        description: `It's crucial to revamp your CV. Emphasize relevant skills, experiences, and qualifications that match the job requirements. Customize it by incorporating specific keywords and highlighting language proficiency. Provide an accurate overview of your educational background, work history, and accomplishments to leave a lasting impression on immigration officers and potential employers.`,
      },
      {
        imgUrl: job,
        heading: "Job Research Assistance",
        description: `When it comes to job research for immigration, it's important to focus on industries and companies that align with your skills and qualifications. Use online resources such as job boards, company websites, and professional networking platforms to research potential opportunities. Forge connections with experts in your preferred industry and location. Don't hesitate to us.`,
      },
      {
        imgUrl: Pr,
        heading: "Permanent Residency Visa",
        description: `A permanent residency visa is a type of immigration program that allows individuals to live and work in a foreign country indefinitely. It grants the holder the right to access most of the same benefits as citizens, such as healthcare, education, and employment opportunities. Permanent residency visas may be obtained through various routes, such as skilled worker programs, family sponsorship, or investment-based programs. The specific requirements and application process may vary depending on the destination country's immigration policies. Once granted, a permanent residency visa holder may be eligible to apply for citizenship after a certain period.`,
      },
      {
        imgUrl: tvisa,
        heading: "Tourist Visa",
        description: `A tourist visa allows foreign travelers to visit a country for a limited period for leisure, tourism, or visiting friends and family. The requirements and procedures for obtaining a tourist visa may vary depending on the country and the traveller’s nationality. Obtaining a tourist visa requires meeting specific eligibility criteria, such as demonstrating the intention to return to the home country after the visit and providing evidence of sufficient funds to cover the cost of the trip. It's important to note that a tourist visa usually does not permit the visa holder to engage in any type of employment or business activity in a foreign country. `,
      },
    ],
  };

  const whyChooseAchintya = {
    heading: "WHY CHOOSE ACHINTYA IMMIGRATION?",
    content: [
      {
        subHeading: "99% Success Rate",
        description:
          "At Achintya Immigration, we take immense pride in our remarkable track record. We have achieved an astounding 99% success rate in helping individuals achieve their immigration goals. This means that almost every client we've assisted has successfully moved to their desired international destination. Your dreams of living abroad are in expert hands.",
      },
      {
        subHeading: "Expertise around the Corner",
        description:
          "Our team of immigration experts is always nearby, ready to provide you with their wealth of knowledge and experience. No matter where you are in India, our local experts are just around the corner. Their deep understanding of immigration laws, regulations, and processes ensures",
      },
      {
        subHeading: "Free Assessment",
        description:
          "To kickstart your journey, we offer a complimentary assessment service. This means you can take the first step toward your immigration dreams without any financial commitment. Our experts will assess your eligibility and provide you with valuable insights into your immigration options. It's our way of making sure you have the information you need to make informed decisions.",
      },
      {
        subHeading: "Knowledge and Accurate Information",
        description:
          "When it comes to immigration, knowledge is power. At Achintya Immigration, we provide you with accurate and up-to-date information. We stay abreast of the ever-evolving immigration landscape to ensure you have access to the most reliable and current information. This empowers you to make well-informed choices throughout your immigration journey.",
      },
      {
        subHeading: "Personalized Guidance:",
        description: `We understand that every individual's immigration journey is unique. That's why we offer personalized guidance customized to your specific circumstances and aspirations. Our experts work closely with you to create a specific roadmap that aligns with your goals. Whether you're seeking a Permanent Residency, a Job Search, or a Tourist Visa, our guidance is designed to address your unique needs.`,
      },
      {
        subHeading: "Credibility:",
        description: `At Achintya Immigration, credibility is the cornerstone of our services. We have earned the trust and confidence of our clients over the years through our unwavering commitment to honesty and integrity. Our credibility is built on a foundation of ethical practices, compliance with
        immigration laws, and a track record of successfully assisting countless individuals in achieving their immigration goals. When you choose Achintya Immigration, you are entrusting your dreams to a reputable and trustworthy partner.`,
      },
      {
        subHeading: "Transparency",
        description: `We understand the importance of transparency in the immigration process. It's our policy to keep our clients fully informed at every stage of their journey. We provide clear and honest information about the requirements, processes, costs, and timelines associated with your immigration application. You can count on us to be upfront and transparent, ensuring that there are no hidden surprises along the way. With Achintya Immigration, you'll have a clear view of your path to success.`,
      },
      {
        subHeading: "Quality",
        description: `Quality is the hallmark of our services. From the moment you engage with us, you'll experience a level of excellence that sets us apart. Our team of experienced immigration experts and lawyers is dedicated to delivering top-notch services. We meticulously review and prepare your application to meet the highest standards of quality. We leave no stone unturned in ensuring that your application stands out for its completeness and accuracy. Quality is not just a goal; it's our commitment to you.`,
      },
    ],
  };

  const enquiryCountries = [
    { value: "Ireland", label: "Ireland" },
    { value: "Japan", label: "Japan" },
    { value: "Australia", label: "Australia" },
    { value: "Bangladesh", label: "Bangladesh" },
    { value: "Canada", label: "Canada" },
    { value: "Dubai", label: "Dubai" },
    { value: "Germany", label: "Germany" },
    { value: "Malaysia", label: "Malaysia" },
    { value: "New Zealand", label: "New Zealand" },
    { value: "Sri Lanka", label: "Sri Lanka" },
    { value: "Schengen", label: "Schengen" },
    { value: "Singapore", label: "Singapore" },
    { value: "South Africa", label: "South Africa" },
    { value: "South Korea", label: "South Korea" },
    { value: "Turkey", label: "Turkey" },
    { value: "Thailand", label: "Thailand" },
    { value: "USA", label: "USA" },
    { value: "UAE", label: "UAE" },
    { value: "UK", label: "UK" },
    { value: "Vietnam", label: "Vietnam" },
    { value: "Malta", label: "Malta" },
    { value: "Poland", label: "Poland" },
    { value: "Gulf", label: "Gulf" },
  ];

  return (
    <div>
      <Helmet>
        <link rel="canonical" href={canonicalURL} />
        <title>Your Key to Successful Immigration | Achintya Immigration</title>
        <meta name="robots" content="index,follow" />
        <meta
          name="description"
          content="Achintya Immigration - your one-stop solution for all immigration-related queries. From visa applications to job search assistance, we've got you covered."
        />
        <meta
          name="keywords"
          content="immigration consultancy firms near Delhi, top 10 immigration consultants in delhi, govt approved immigration consultants in delhi"
        />
      </Helmet>
      <Header item={navigationHeader} />
      <HomeBanner />
      <ImmigrationServices immigrationServices={immigrationServices} />
      <Offerings offeringContent={offeringContent} />
      <WhyChooseAchintya
        whyChooseAchintya={whyChooseAchintya}
        enquiryCountries={enquiryCountries}
      />
      <OpenScrollComponent />
      <Footer />
      <WhatsApp />
    </div>
  );
};

export default HomePage;

import React from 'react'
import './index.css'
import whatsappImage from '../../assets/WhatsApp.png'

const WhatsApp = () => {

    const handleClick = () => {
        // Replace the phone number and message with your desired values
        const phoneNumber = '7011302054';
        // const encodedIcon = encodeURIComponent(companyIcon);
        // const encodedName = encodeURIComponent("One Tap Loan");

        // Construct the WhatsApp URL with the phone number and message
        const url = `https://wa.me/${phoneNumber}`;

        // Open the WhatsApp URL in a new tab
        window.open(url, '_blank');
    };

    return (
        <div>
            <div className='whats-outer-container'>
                <a target='_blank' aria-label="Chat on WhatsApp" href="https://wa.me/7011302054" rel="noopener noreferrer">
                    <div className='whatsapp-container' >
                        <img src={whatsappImage} alt='Whatsapp' />
                    </div>
                </a>
            </div>
        </div>
    )
}

export default WhatsApp
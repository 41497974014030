import React from "react";
import "./index.css";
import SimpleTable from "../simpletable";

const LocateNoc = ({ props }) => {
  const experiencePointsTable = {
    row1: {
      column1: "Experience",
      column2: "Points",
    },
    row2: {
      column1: "1 year",
      column2: "9",
    },
    row3: {
      column1: "2-3 years",
      column2: "11",
    },
    row4: {
      column1: "4-5 years",
      column2: "13",
    },
    row5: {
      column1: "6 or more years",
      column2: "15",
    },
  };

  const agePointsTable = {
    row1: {},
    row2: {
      column1: "Age",
      column2: "Points",
    },
    row3: {
      column1: "Under 18",
      column2: "0",
    },
    row4: {
      column1: "18-35",
      column2: "12",
    },
    row5: {
      column1: "36",
      column2: "11",
    },
    row6: {
      column1: "37",
      column2: "10",
    },
    row7: {
      column1: "38",
      column2: "9",
    },
    row8: {
      column1: "39",
      column2: "8",
    },
    row9: {
      column1: "40",
      column2: "7",
    },
    row10: {
      column1: "41",
      column2: "6",
    },
    row11: {
      column1: "42",
      column2: "5",
    },
    row12: {
      column1: "43",
      column2: "4",
    },
    row13: {
      column1: "44",
      column2: "3",
    },
    row14: {
      column1: "45",
      column2: "2",
    },
    row15: {
      column1: "46",
      column2: "1",
    },
    row16: {
      column1: "47 and older",
      column2: "0",
    },
  };

  return (
    <div className="locate-noc-outer-container">
      <div className="container_wrapper">
        <div className="locate-noc-inner-container">
          <h2 className="locate_noc_heading">{props.introduction.heading}</h2>
          <div>
            <p
              className="locate_noc_content"
              dangerouslySetInnerHTML={{ __html: props.introduction.content }}
            />
          </div>
          <div className="loc-flex-container">
            <div className="loc-left-flex-container">
              <SimpleTable data={experiencePointsTable} />
              <img src="https://achintyaconsultant.s3.ap-south-1.amazonaws.com/Frame.png" />
            </div>
            <div className="loc-right-flex-container">
              <SimpleTable data={agePointsTable} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocateNoc;

// HeaderHamburger.js

import React, { useState } from "react";
import { Link } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const HeaderHamburger = ({ item }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [openSubMenus, setOpenSubMenus] = useState([]);
  const [openSubDataMenus, setOpenSubDataMenus] = useState([]);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const toggleSubMenu = (index) => {
    if (openSubMenus.includes(index)) {
      setOpenSubMenus(openSubMenus.filter((item) => item !== index));
    } else {
      setOpenSubMenus([...openSubMenus, index]);
    }
  };

  const toggleSubDataMenu = (index) => {
    if (openSubDataMenus.includes(index)) {
      setOpenSubDataMenus(openSubDataMenus.filter((item) => item !== index));
    } else {
      setOpenSubDataMenus([...openSubDataMenus, index]);
    }
  };

  return (
    <>
      <IconButton onClick={toggleDrawer} color="inherit">
        {isDrawerOpen ? <ChevronLeftIcon /> : <MenuIcon />}
      </IconButton>
      <Drawer
        anchor="right" // Set anchor to "right" for the drawer to open from the right side
        open={isDrawerOpen}
        onClose={toggleDrawer}
        className="drawer-width"
      >
        <div style={{ width: "100%" }}>
          <IconButton onClick={toggleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <List>
          {item.map((navItem, index) => (
            <div key={index}>
              <ListItem
                button
                onClick={() => toggleSubMenu(index)}
                // disableRipple
              >
                <Link
                  to={navItem?.link}
                  style={{ textDecoration: "none", color: "#000" }} // Set the color to black
                >
                  <ListItemText primary={navItem.title} />
                </Link>
                {navItem.subItems && (
                  <span className="sub-item-arrow">
                    {openSubMenus.includes(index) ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )}
                  </span>
                )}
              </ListItem>
              {navItem.subItems && openSubMenus.includes(index) && (
                <List>
                  {navItem.subItems.map((subItem, subIndex) => (
                    <div key={subIndex}>
                      <ListItem
                        button
                        onClick={() => toggleSubDataMenu(subIndex)}
                        // disableRipple
                      >
                        <Link
                          to={subItem?.link}
                          style={{ textDecoration: "none", color: "#000" }} // Set the color to black
                        >
                          <ListItemText
                            style={{ color: "#000" }}
                            primary={subItem.title}
                          />
                        </Link>
                        {subItem.subDataItems && (
                          <span className="sub-item-arrow">
                            {openSubDataMenus.includes(subIndex) ? (
                              <KeyboardArrowUpIcon />
                            ) : (
                              <KeyboardArrowDownIcon />
                            )}
                          </span>
                        )}
                      </ListItem>
                      {subItem.subDataItems &&
                        openSubDataMenus.includes(subIndex) && (
                          <List>
                            {subItem.subDataItems.map(
                              (subDataItem, subDataIndex) => (
                                <ListItem
                                  key={subDataIndex}
                                  // disableRipple
                                >
                                  <Link
                                    to={subDataItem?.link}
                                    style={{
                                      textDecoration: "none",
                                      color: "#000",
                                    }} // Set the color to black
                                  >
                                    <ListItemText
                                      style={{ color: "#000" }}
                                      primary={subDataItem.title}
                                    />
                                  </Link>
                                </ListItem>
                              )
                            )}
                          </List>
                        )}
                    </div>
                  ))}
                </List>
              )}
            </div>
          ))}
        </List>
      </Drawer>
    </>
  );
};

export default HeaderHamburger;

import React, { useEffect, useState } from "react";
import "./index.css";
import FormRightSide from "../../../commonComponents/enquiryRightSide";
import logo from "../../../assets/achintya logo 1.png";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";

const GetInTouch = ({ enquiryCountries }) => {
  const [iframeWidth, setIframeWidth] = useState("416px");
  const [iframeHeight, setIframeHeight] = useState("174px");
  // Function to update the iframe dimensions based on the screen size
  const updateIframeDimensions = () => {
    const screenWidth = window.innerWidth;

    // You can set your desired breakpoints and corresponding iframe dimensions here
    if (screenWidth < 768) {
      setIframeWidth("250px");
      setIframeHeight("150px");
    } else if (screenWidth >= 768 && screenWidth < 1024) {
      setIframeWidth("400px");
      setIframeHeight("200px");
    } else {
      setIframeWidth("416px");
      setIframeHeight("174px");
    }
  };

  // Add event listener to update iframe dimensions on window resize
  useEffect(() => {
    updateIframeDimensions();
    window.addEventListener("resize", updateIframeDimensions);

    // Clean up event listener on unmount
    return () => {
      window.removeEventListener("resize", updateIframeDimensions);
    };
  }, []);
  const mapUrl =
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14008.30089613558!2d77.20364921347317!3d28.627507485228897!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfd3468a3cfa1%3A0x8c1560eaeb48e237!2sSURYA%20KIRAN%20BUILDING%2C%20CONNAUGHT%20PLACE%2C%20Atul%20Grove%20Road%2C%20Janpath%2C%20Connaught%20Place%2C%20New%20Delhi%2C%20Delhi%20110001!5e0!3m2!1sen!2sin!4v1690957043274!5m2!1sen!2sin";

  return (
    <div>
      <div className="container_wrapper">
        <div className="get-in-touch-outer-container">
          <div className="get-in-touch-inner-container">
            <div className="get-in-touch-left-container">
              <div className="get-in-touch-heading">
                <p>Get In Touch</p>
              </div>
              <div className="get-in-touch-form-container">
                <FormRightSide enquiryCountries={enquiryCountries} />
              </div>
            </div>

            <div className="get-in-touch-right-container">
              <div className="achintiya-form-logo">
                <img src={logo} alt="achintya logo" />
              </div>
              <div className="get-map-container">
                <iframe
                  title="Map"
                  style={{
                    borderRadius: "10px",
                    width: iframeWidth,
                    height: iframeHeight,
                  }}
                  src={mapUrl}
                ></iframe>
              </div>
              <div className="get-in-touch-loaction-container">
                <div className="conatct-loc-container">
                  <LocationOnIcon />
                  <p className="location-para">
                    1204-1205, 12th, Floor Surya Kiran Building, 19 KG Marg, New
                    Delhi-110001
                  </p>
                </div>

                <div className="conatct-loc-container">
                  <CallIcon />
                  <a href="tel:7011302054">
                    <p className="location-para">+91-7011 302054</p>
                  </a>
                </div>

                <div className="conatct-loc-container">
                  <EmailIcon />
                  <a href="mailto:immigration@achintyaconsultant.com">
                    <p className="location-para">
                      immigration@achintyaconsultant.com
                    </p>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetInTouch;

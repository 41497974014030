import React from "react";
import { Route, Routes } from "react-router-dom";
import HomePage from "../pages/home";
import AboutPage from "../pages/about";
import ContactPage from "../pages/contact";
import PermanentResidency from "../pages/permanentResidency";
import PrivacyPolicy from "../pages/privacyPolicy";
import TermsNCondition from "../pages/t&c";
import TouristVisa from "../pages/touristVisa";
import JobSearch from "../pages/jobSearch";
import Videos from "../pages/videos";
import EnquireB2B from "../pages/enquireB2B";
import Page404 from "../pages/404";
import CanadaExpress from "../pages/canadaExpress";
import CanadaExpressPNP from "../pages/canadExpressPNP";
import LatestExpressEntry from "../pages/latestExpressEntry";
import CanadaBenefitsPage from "../pages/canadaBenefitsProgram";
import FedralSkilledTradeProgram from "../pages/fedralSkilledTradeProgram";
import FedralSkilledWorker from "../pages/fedralWorkerProgram";
import ExperienceClass from "../pages/experienceClass";
import SuperVisa from "../superVisa";
import CanadaIelts from "../pages/canadaIelts";
import CrsChart from "../pages/crsChart";

const Routing = () => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />}></Route>
      <Route path="/about-us/" element={<AboutPage />}></Route>
      <Route path="/connect-us/" element={<ContactPage />}></Route>
      <Route
        path="/permanent-residency/:country"
        element={<PermanentResidency />}
      ></Route>
      <Route path="/privacy-policy/" element={<PrivacyPolicy />}></Route>
      <Route path="/terms-conditions/" element={<TermsNCondition />}></Route>
      <Route path="/travel-visa/:country" element={<TouristVisa />}></Route>
      <Route path="/job-search-visa/:country" element={<JobSearch />}></Route>
      <Route path="/videos/" element={<Videos />}></Route>
      <Route path="/partner-with-us/" element={<EnquireB2B />}></Route>
      <Route path="/canada/express-entry/" element={<CanadaExpress />}></Route>
      <Route
        path="/canada/express-entry/provisional-nominee-program/"
        element={<CanadaExpressPNP />}
      ></Route>
      <Route
        path="/canada/express-entry/latest-express-entry-draw/"
        element={<LatestExpressEntry />}
      ></Route>
      <Route
        path="/canada/express-entry/benefits-of-the-program/"
        element={<CanadaBenefitsPage />}
      ></Route>
      <Route
        path="/canada/express-entry/fedral-skilled-trade-program/"
        element={<FedralSkilledTradeProgram />}
      ></Route>
      <Route
        path="/canada/express-entry/fedral-skilled-worker-program/"
        element={<FedralSkilledWorker />}
      ></Route>
      <Route
        path="/canada/express-entry/canadian-experience-class/"
        element={<ExperienceClass />}
      ></Route>
      <Route
        path="/canada/express-entry/super-visa-from-india/"
        element={<SuperVisa />}
      ></Route>
      <Route
        path="/canada/express-entry/ielts/"
        element={<CanadaIelts />}
      ></Route>
       <Route
        path="/canada/express-entry/crs-chart/"
        element={<CrsChart />}
      ></Route>
      <Route path="*" element={<Page404 />}></Route>
    </Routes>
  );
};

export default Routing;

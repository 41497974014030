import React from "react";
import "./index.css";
import VerifiedIcon from "@mui/icons-material/Verified";

const AbroadThankYou = () => {
  return (
    <div className="abroad-thank-outer-container">
      <div className="abroad-thank-inner-container">
        <img
          src="https://achintyaconsultant.s3.ap-south-1.amazonaws.com/thank.png"
          alt="thank you"
        />
        <p className="abroad-thank-msg">Thank You</p>
        <p className="abroad-thank-submsg">
          Your application has been submitted
        </p>
        <div className="abroad-trip-verify">
          <VerifiedIcon />
        </div>
      </div>
    </div>
  );
};

export default AbroadThankYou;

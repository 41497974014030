import React from "react";
import "./index.css";
import contactImg from '../../../assets/Graphics.png'

const ContactBanner = () => {
  return (
    <div className="contact-banner-outer-container">
      <div className="container_wrapper">
        <div className="contact-inner-banner">
            <h2 className="contact-banner-heading">Contact Us</h2>
            <img className="contact-banner-img" src={contactImg} alt="contact banner"/>
        </div>
      </div>
    </div>
  );
};

export default ContactBanner;

import React from 'react'
import './index.css'

const Offerings = ({ offeringContent }) => {
    // console.log("offeringContent : ", offeringContent)
    return (
        <div className='offering-outer-container'>
            <div className='container_wrapper'>
                <h2 className='offerings-heading'>{offeringContent.heading}</h2>
                <div className='offerings-inner-container'>
                    {offeringContent?.subOffering.map((e, i) => (
                        <div className='off-flex-container' key={i}>
                            <div className='offerings-img-container'>
                                <img src={ e?.imgUrl}/>
                            </div>
                            <h2 className='off-sub-heading'>{e?.heading}</h2>
                            <div>
                                <p className='off-description'>{e?.description}</p>
                            </div>
                        </div>

                    ))}
                </div>
            </div>

        </div>
    )
}

export default Offerings
import React from "react";
import "./index.css";

const CanadaExpressEntryBanner = () => {
  return (
    <div className="banner-canada">
      <div className="image-container">
        <picture>
          <source
            media="(min-width: 769px)"
            srcSet="https://achintyaconsultant.s3.ap-south-1.amazonaws.com/canad-expree-entry/Frame+21.jpg"
          />
          <img
            src="https://achintyaconsultant.s3.ap-south-1.amazonaws.com/canad-expree-entry/canmob.png"
            alt="Mobile Image"
            className="express-mobile-image"
          />
        </picture>
        <div className="overlay-text">
          Canada Express Entry <br />
          Program
        </div>
      </div>
    </div>
  );
};

export default CanadaExpressEntryBanner;

import React from "react";
import "./index.css";
import { titleCaseWord } from "../../../Utilities/commonFunctions";

const PrBanner = ({ bannerData, country }) => {
  const dataForCountry = bannerData[country.toLowerCase()];
  // console.log("hello : ", dataForCountry.Heading);
  return (
    <div className="pr-banner-outer-container">
      <div className="pr-banner-inner-container">
        <h2 className="pr-banner-heading">
          <p>{titleCaseWord(country)} </p>
          <p className="pr-sub-heading">Permanent Residency</p>
        </h2>
        <div className="pr-banner-img">
          <img src={dataForCountry?.imgUrl} alt="PR Visa Banner Image" />
        </div>
      </div>
    </div>
  );
};

export default PrBanner;

import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Header from "../../commonComponents/header";
import Footer from "../../commonComponents/footer";
import VideoSection from "../../componenets/videoSection";
import { Helmet } from "react-helmet";

const Videos = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const canonicalURL = "https://achintyaconsultant.com/videos/";

  const touristCountries = [
    "Ireland",
    "Japan",
    "Australia",
    "Bangladesh",
    "Canada",
    "Dubai",
    "Germany",
    "Malaysia",
    "New Zealand",
    "Sri Lanka",
    "Schengen",
    "Singapore",
    "South Africa",
    "South Korea",
    "Turkey",
    "Thailand",
    "USA",
    "UAE",
    "UK",
    "Vietnam",
  ];

  const jobSearch = [
    "Malta",
    "Poland",
    "Schengen",
    "Singapore",
    "UAE",
    "UK",
    "Australia",
    "Dubai",
    "Germany",
    "Gulf",
  ];

  const otherServices = ["IELTS", "Resume"];

  const enquiryCountries = [
    { value: "Ireland", label: "Ireland" },
    { value: "Japan", label: "Japan" },
    { value: "Australia", label: "Australia" },
    { value: "Bangladesh", label: "Bangladesh" },
    { value: "Canada", label: "Canada" },
    { value: "Dubai", label: "Dubai" },
    { value: "Germany", label: "Germany" },
    { value: "Malaysia", label: "Malaysia" },
    { value: "New Zealand", label: "New Zealand" },
    { value: "Sri Lanka", label: "Sri Lanka" },
    { value: "Schengen", label: "Schengen" },
    { value: "Singapore", label: "Singapore" },
    { value: "South Africa", label: "South Africa" },
    { value: "South Korea", label: "South Korea" },
    { value: "Turkey", label: "Turkey" },
    { value: "Thailand", label: "Thailand" },
    { value: "USA", label: "USA" },
    { value: "UAE", label: "UAE" },
    { value: "UK", label: "UK" },
    { value: "Vietnam", label: "Vietnam" },
    { value: "Malta", label: "Malta" },
    { value: "Poland", label: "Poland" },
    { value: "Gulf", label: "Gulf" },
  ];

  const videoData = [
    {
      videoUrl:
        "https://achintyaconsultant.s3.ap-south-1.amazonaws.com/videos/video2.mp4",
      videoCaption:
        "Elevating Clients to the Pinnacle of Ecstasy with Our Symphony of Harmonious Services",
    },
    {
      videoUrl:
        "https://achintyaconsultant.s3.ap-south-1.amazonaws.com/videos/video1.mp4",
      videoCaption:
        "Experiencing Clients' Ecstasy within Our Harmonious Service Ensemble",
    },
    {
      videoUrl:
        "https://achintyaconsultant.s3.ap-south-1.amazonaws.com/videos/customers+review.mp4",
      videoCaption: "Thank you for choosing us!",
    },
  ];

  return (
    <div>
      <Helmet>
        <link rel="canonical" href={canonicalURL} />
        <title>Empowering Your Immigration Journey: Achintya's Videos</title>
        <meta name="robots" content="index,follow" />
        <meta
          name="description"
          content="Unlock the power of knowledge through Achintya Immigration's video, covering essential topics. Discover How We Turn Your Immigration Goals into Tangible Achievements."
        />
        <meta
          name="keywords"
          content="Permanent Residency,
 immigration services delhi "
        />
      </Helmet>
      <Header
        touristCountries={touristCountries}
        jobSearch={jobSearch}
        otherServices={otherServices}
      />
      <VideoSection enquiryCountries={enquiryCountries} videoData={videoData} />
      <Footer />
    </div>
  );
};

export default Videos;

import React from "react";
import "./index.css";

const ArrageEmployement = ({ props, points }) => {
//   console.log(points);
  return (
    <div className="arrange_employment_outer_container">
      <div className="container_wrapper">
        <div className="arrange_employement_inner_container">
          <h2>{props.introduction.heading}</h2>
          <p
            className="arrange_employement_content"
            dangerouslySetInnerHTML={{ __html: props.introduction.content }}
          />
          <div className="pointers-box-flex-contaier">
            {points.map((e, i) => (
              <div className="pointers_box_content" key={i}>
                <div className="pointer_heading">
                  <p>{e?.heading}</p>
                </div>
                <div>
                  <p dangerouslySetInnerHTML={{ __html: e?.content }} />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArrageEmployement;

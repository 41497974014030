import React from "react";
import "./index.css";

const PrProcess = ({ prProcess, country }) => {
  return prProcess.heading ? (
    <div className="pr-process-outer-container">
      <div className="container_wrapper">
        <div className="pr-process-inner-container">
          <h2 className="pr-process-heading">{prProcess.heading}</h2>
          <p className="pr-process-sub-heading">{prProcess?.description}</p>
          <div className="pr-process-pointer-container">
            {prProcess.pointers.map((item, index) => (
              <div key={index} className="pr-process-pointer">
                <div className="process-container">
                  <div className="pr-step-container">Step {index + 1}</div>

                  <div className="pr-steps-description">
                    <p className="pr-steps-heading">{item?.stepHeading}</p>
                    <p
                      className="pr-steps"
                      dangerouslySetInnerHTML={{ __html: item?.point }}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  ) : (
    ""
  );
};

export default PrProcess;

import React from "react";
import "./index.css";

function sanitizeHTML(html) {
  const doc = new DOMParser().parseFromString(html, "text/html");
  return doc.body.textContent || "";
}

const CommonTableComponent = ({ props }) => {
  return (
    <div className="common-table-outer-container">
      <table>
        <thead></thead>
        <tbody>
          {Object.values(props).map((row, rowIndex) => (
            <tr key={rowIndex}>
              {Object.values(row).map((cell, cellIndex) => (
                <td key={cellIndex} style={{ textAlign: "center" }}>
                  {typeof cell === "object" && cell.heading ? (
                    cell.heading
                  ) : (
                    <div
                      style={{ whiteSpace: "pre-line" }}
                      dangerouslySetInnerHTML={{ __html: sanitizeHTML(cell) }}
                    />
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CommonTableComponent;

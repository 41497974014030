import React from "react";
import "./index.css";
import documentsPr from "../../../assets/PR Visa/documents-pr.png";
import CheckIcon from "@mui/icons-material/Check";

const PrDocuments = ({ documentsData, country }) => {
  return documentsData.heading ? (
    <div className="pr-documents-outer-container">
      <div className="container_wrapper">
        <div className="pr-documents-inner-container">
          <h2 className="pr-documents-heading">{documentsData?.heading}</h2>
          <p className="pr-documents-sub-heading">
            {documentsData?.subHeading}
          </p>
          <div className="pr-documents-flex-container">
            <div className="pr-documents-left-container">
              {documentsData?.pointers?.map((item, index) => (
                <div key={index} className="pr-documents-pointer">
                  <CheckIcon />
                  <p>{item?.point}</p>
                </div>
              ))}
            </div>
            <div className="pr-documents-right-container">
              <img src={documentsPr} alt="documents required" />
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    ""
  );
};

export default PrDocuments;

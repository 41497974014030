import React, { useState, useEffect } from "react";
import "./index.css";
import { TextField, Autocomplete, Button } from "@mui/material";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import AbroadThankYou from "../abroadThankUForm";

const AbroadForm = ({ enquiryCountries }) => {
  const initialFormData = {
    name: "",
    email: "",
    phone: "",
    selectedCountry: null,
    file: null,
    errors: {
      name: "",
      email: "",
      phone: "",
      selectedCountry: "",
      file: "",
    },
  };

  const [formData, setFormData] = useState(initialFormData);
  const [submitForm, setSubmitForm] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    let errors = { ...formData.errors };

    // Name validation rules (you can customize these as needed)
    if (name === "name") {
      if (!value.trim()) {
        errors.name = "Name is required";
      } else if (!/^[a-zA-Z\s]*$/.test(value)) {
        errors.name = "Name should only contain letters and spaces";
      } else {
        errors.name = "";
      }
    }

    // Email validation rules (basic email pattern)
    if (name === "email") {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!value.trim()) {
        errors.email = "Email is required";
      } else if (!emailPattern.test(value)) {
        errors.email = "Invalid email address";
      } else {
        errors.email = "";
      }
    }

    // Phone number validation rules (basic pattern for demonstration)
    if (name === "phone") {
      const phonePattern = /^\d{10}$/; // Assumes a 10-digit phone number
      if (!value.trim()) {
        errors.phone = "Phone number is required";
      } else if (!phonePattern.test(value)) {
        errors.phone = "Invalid phone number";
      } else {
        errors.phone = "";
      }
    }

    setFormData({
      ...formData,
      [name]: value,
      errors,
    });
  };

  const handleCountryChange = (event, newValue) => {
    let errors = { ...formData.errors };
    if (!newValue) {
      errors.selectedCountry = "Country is required";
    } else {
      errors.selectedCountry = "";
    }

    setFormData({
      ...formData,
      selectedCountry: newValue,
      errors,
    });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    setFormData({
      ...formData,
      file,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Check for errors before submitting
    if (
      !formData.errors.name &&
      !formData.errors.email &&
      !formData.errors.phone &&
      !formData.errors.selectedCountry
    ) {
      // Form is valid, you can submit it or perform other actions
      // alert(
      //   `Name submitted: ${formData.name}, Email submitted: ${formData.email}, Phone submitted: ${formData.phone}, Country selected: ${formData.selectedCountry.label}`
      // );
      setFormData(initialFormData);
      setSubmitForm(true);
    } else {
      // Form is not valid, display errors or take appropriate action
      alert("Form contains errors. Please correct them.");
      setFormData(initialFormData);
    }
  };

  return (
    <div className="abroad-form-outer-containet">
      <div className="abroad-form-inner-container">
        {submitForm ? (
          <AbroadThankYou />
        ) : (
          <>
            <h2 className="abroad-form-heading">GET IN TOUCH</h2>
            <div className="">
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <div className="input-field-space">
                    <TextField
                      fullWidth
                      id="name"
                      name="name"
                      label="Name"
                      variant="standard"
                      value={formData.name}
                      onChange={handleChange}
                      error={!!formData.errors.name}
                      helperText={formData.errors.name}
                      required
                    />
                  </div>
                  {/* <span className="error">{formData.errors.name}</span> */}

                  <div className="input-field-space">
                    <TextField
                      fullWidth
                      id="email"
                      name="email"
                      label="Email"
                      variant="standard"
                      value={formData.email}
                      onChange={handleChange}
                      error={!!formData.errors.email}
                      helperText={formData.errors.email}
                      required
                    />
                  </div>
                  {/* <span className="error">{formData.errors.email}</span> */}

                  <div className="input-field-space">
                    <TextField
                      fullWidth
                      id="phone"
                      name="phone"
                      label="Mobile Number"
                      variant="standard"
                      value={formData.phone}
                      onChange={handleChange}
                      error={!!formData.errors.phone}
                      helperText={formData.errors.phone}
                      required
                    />
                    {/* <span className="error">{formData.errors.phone}</span> */}
                  </div>

                  <div className="input-field-space">
                    <Autocomplete
                      fullWidth
                      id="selectedCountry"
                      name="selectedCountry"
                      options={enquiryCountries}
                      getOptionLabel={(option) => option.label}
                      onChange={handleCountryChange}
                      value={formData.selectedCountry}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Destination"
                          variant="standard"
                          error={!!formData.errors.selectedCountry}
                          helperText={formData.errors.selectedCountry}
                          required
                        />
                      )}
                    />
                  </div>
                  {/* <span className="error">{formData.errors.selectedCountry}</span> */}

                  <input
                    id="file"
                    type="file"
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                  <label htmlFor="file" className="upload-file-btn">
                    <Button variant="standard" component="span" color="primary">
                      Upload Documents
                    </Button>
                    {formData.file && (
                      <p className="upload-file-name"> {formData.file.name}</p>
                    )}
                  </label>
                </div>

                <button className="takeoff-btn" type="submit">
                  HELP ME GOING ABROAD <FlightTakeoffIcon />
                </button>
              </form>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AbroadForm;

import React from "react";
import "./index.css";

const SimpleTable = ({ data }) => {
  if (!data) {
    return null; // Return null if no data is provided
  }

  // Helper function to render HTML content
  const renderHTML = (htmlContent) => {
    const div = document.createElement("div");
    div.innerHTML = htmlContent;

    // Check if there are <p> tags in the content
    const paragraphs = div.querySelectorAll("p");

    // If there are <p> tags, set the width of <td> to 1035px
    if (paragraphs.length > 0) {
      paragraphs.forEach((p, index) => {
        if (index === 0) {
          p.style.marginBottom = "0"; // Remove margin-bottom for the first <p> tag
        } else if (index === 1) {
          p.style.marginTop = "0"; // Remove margin-top for the second <p> tag
        }
        p.style.textAlign = "left"; // Set text-align to left for all <p> tags
      });

      // Set the width of <td> to 1035px for the table with the 'simple-table' class
      div.classList.add("simple-table");
    }

    return <div dangerouslySetInnerHTML={{ __html: div.innerHTML }} />;
  };

  return (
    <div className="simple-table-outer-container">
      <table className="simple-table">
        <thead>
          <tr>
            {Object.keys(data.row1).map((key, index) => (
              <th key={index}>{data.row1[key]}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Object.keys(data).map((rowKey) => {
            if (rowKey !== "row1") {
              const row = data[rowKey];
              return (
                <tr key={rowKey}>
                  {Object.keys(row).map((key, index) => (
                    <td key={index}>
                      {key === "column1" ? (
                        renderHTML(row[key])
                      ) : (
                        <p>{row[key]}</p>
                      )}
                    </td>
                  ))}
                </tr>
              );
            }
            return null;
          })}
        </tbody>
      </table>
    </div>
  );
};

export default SimpleTable;

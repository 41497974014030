import React from "react";
import "./index.css";
import FormRightSide from "../../commonComponents/enquiryRightSide";
import videoBanner from "../../assets/videoBanner.png";

const VideoSection = ({ enquiryCountries, videoData }) => {
  //   console.log("videoData : ", videoData);
  return (
    <>
      <div className="video-banner-outer-container">
        <div className="video-banner-inner-container">
          <h2>Fulfilling dreams beyond boundaries</h2>
          <img src={videoBanner} alt="video banner immage" />
        </div>
      </div>
      <div className="video-section-outer-container">
        <div className="container_wrapper">
          <div className="video-section-inner-container">
            {/* <div className="video-left-container"> */}
            <div className="video-container">
              <video width="100%" controls="controls">
                <source
                  src="https://achintyaconsultant.s3.ap-south-1.amazonaws.com/videos/Welcome+to+Achintya+immigration+services.mp4"
                  type="video/mp4"
                />
              </video>
            </div>
            {/* </div> */}
            {/* <div className="video-form-right-container">
              <FormRightSide enquiryCountries={enquiryCountries} />
            </div> */}
          </div>
          <div className="video-second-container">
            <div className="video-second-inner-container">
              {videoData.map((video, index) => (
                <div className="video-box" key={index}>
                  <video width="100%"  controls>
                    <source src={video.videoUrl} type="video/mp4" />
                    {/* Your browser does not support the video tag. */}
                  </video>
                  <p className="video-caption">{video.videoCaption}</p>
                </div>
              ))}
            </div>
          </div>
          {/* <div className="video-mobile video-form-right-container">
            <FormRightSide enquiryCountries={enquiryCountries} />
          </div> */}
        </div>
      </div>
    </>
  );
};

export default VideoSection;

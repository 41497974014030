import React from 'react'
import './index.css'
import banner1 from '../../assets/banner2.png'
import banner2 from '../../assets/banner1.png'

const HomeBanner = () => {
    return (
        <div>
            <div className='home-banner-outer-container'>
                <div className='home-banner-left-container'>
                    <div className='banner-left-image'>
                        <img src={banner1} />
                        <span className='home-banner-heading'>
                            <p className='heading1'>See the world through</p>
                            <p className='heading2'>Achintya Immigration</p>
                        </span>
                    </div>
                </div>
                <div className='home-banner-right-container'>
                    <img src={banner2} />
                </div>
            </div>
        </div>

    )
}

export default HomeBanner
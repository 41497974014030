import React from "react";
import "./index.css";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";

const B2bContent = ({ b2bContent }) => {
//   console.log("b2bContent : ", b2bContent);

  return (
    <div className="enquiry-b2b-couter-container">
      <div className="container_wrapper">
        <div className="enquiry-b2b-inner-container">
          <p className="enquiry-b2b-subHeading">
            {b2bContent.bannerSubHeading}
          </p>
          <div className="enquiry-service">
            <p className="enquiry-service-heading">
              {b2bContent.globalImmigration.heading}
            </p>
            <p className="enquiry-service-content">
              {b2bContent.globalImmigration.content}
            </p>
            <div>
              <div className="enquiry-service-pointer">
                {b2bContent.pointers.map((e, i) => {
                  return (
                    <div
                      key={i}
                      className="enquiry-service-pointer-outer-container"
                    >
                      <p className="enquiry-service-pointer-heading">
                        {e.heading}
                      </p>
                      <p className="enquiry-service-pointer-content">
                        {e.subContent}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
            <div>
              <div className="enquiry-partner-container">
                <p className="enquiry-service-heading">
                  {b2bContent.partner_with_us[0].heading}
                </p>
                <p
                  className="enquiry-partner-content"
                  dangerouslySetInnerHTML={{
                    __html: b2bContent.partner_with_us[0].content,
                  }}
                />
                <div className="enquiry-partner-flex-container">
                  <div className="enquiry-partner-left-container">
                    <p
                      className="enquiry-partner-content"
                      dangerouslySetInnerHTML={{
                        __html: b2bContent.partner_with_us[0].content2,
                      }}
                    />
                    {b2bContent.partner_with_us[0].pointers.map(
                      (item, index) => (
                        <div key={index}>
                          <ul>
                            <li
                              className="enquiry-partner-content"
                              dangerouslySetInnerHTML={{
                                __html: item.point,
                              }}
                            />
                          </ul>
                        </div>
                      )
                    )}
                  </div>
                  <div className="enquiry-partner-right-container">
                    <img
                      src="https://achintyaconsultant.s3.ap-south-1.amazonaws.com/partner-Us/partners-hand.png"
                      alt="partner image"
                    />
                  </div>
                </div>
                <p
                  className="enquiry-partner-content"
                  dangerouslySetInnerHTML={{
                    __html: b2bContent.partner_with_us[0].content3s,
                  }}
                />
              </div>
            </div>
            <div className="partner-contact-us-outer-contaier">
              <div className="partner-contact-us">
                <p className="partner-contact-heading">CONTACT US</p>
                <p className="partner-divider">|</p>
                <div className="partner-contact-sontainer">
                  <div className="partner-contact-menu">
                    <CallOutlinedIcon />
                    <a href="tel: 7011302052">
                      <p>+91 70113 02052</p>
                    </a>
                  </div>
                  <div className="partner-contact-menu partner-email">
                    <EmailOutlinedIcon />
                    <a href="mailto: immigration@achintyaconsultant.com">
                      <p>immigration@achintyaconsultant.com</p>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default B2bContent;
